import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCryptobasket } from "./useCryptobasket";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown, faX } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { nativeToken } from "../../config";
import { useWalletView } from "../../hooks/useWalletView";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Editor from "@monaco-editor/react";
function CreateCryptofFundModal({ openModal, setOpenModal }) {
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fundName, setFundName] = useState("");
  const [description, setDescription] = useState("");
  const [risk, setRisk] = useState("High");
  const [marketCap, setMarketCap] = useState("High");
  const [cryptoBasketTokens, setCryptoBasketTokens] = useState([]);
  const [minAmount, setMinAmount] = useState("");
  const appCtx = useSelector((state) => state.app);
  const [blockchain, setBlockchain] = useState(appCtx.blockchain);
  const [collectionBanner, setCollectionBanner] = useState();
  const [bannerPreview, setBannerPreview] = useState();
  const [uploadingBanner, setUploadingBanner] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [logoPreview, setLogoPreview] = useState();
  const [collectionLogo, setCollectionLogo] = useState();
  const [showUploadLogoButton, setShowUploadLogoButton] = useState(true);
  const [showUploadBannerButton, setShowUploadBannerButton] = useState(true);
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const [tokenPriceCurrency, setTokenPriceCurrency] = useState("INR");
  const [bannerUrl, setBannerUrl] = useState("");
  const [currentComponent, setCurrentComponent] = useState(1);
  const [fundTokenName, setFunTokendName] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [tokenDecimals, setTokenDecimals] = useState(18);
  const [totalSupply, setTotalSupply] = useState(1000000000);
  const [code, setCode] = useState("");
  const [setupLoader, setSetupLoader] = useState(false);
  const [fundId, setFundId] = useState(null);

  useEffect(() => {
    setCode(`pragma solidity ^0.8.4;
import "@openzeppelin/contracts/token/ERC20/ERC20.sol";

contract ${fundTokenName} is ERC20 {
    constructor() ERC20(${tokenSymbol}, ${fundTokenName}){
        _mint(msg.sender, ${totalSupply} * 10 ** ${tokenDecimals});
    }
}`);
  }, [fundTokenName, tokenSymbol, totalSupply, tokenDecimals]);

  const navigate = useNavigate();
  const fileRef = useRef();
  const bannerFileRef = useRef();
  const makeRequest = useAuthorizedHttp();

  const { getAllCoin, allCoin, isCoinLoading } = useWalletView();

  const chains = Object.keys(nativeToken)
    .filter((key) => key !== "ALGORAND")
    .reduce((obj, key) => {
      obj[key] = nativeToken[key];
      return obj;
    }, {});

  useEffect(() => {
    getAllCoin(0, 60, blockchain);
  }, [appCtx.blockchain, blockchain]);

  const handleFirstComponentsSubmit = async (event) => {
    event.preventDefault();
    // console.log("payload data fundName", fundName);
    // console.log("payload data description", description);
    // console.log("payload data minAmount", minAmount);
    // console.log("payload data logoUrl", logoUrl);
    // console.log("payload data bannerUrl", bannerUrl);

    if (!fundName || !description || !minAmount || !logoUrl || !bannerUrl) {
      toast.error("Please enter all required parameters");
      return;
    }

    setLoading(true);

    const data = {
      fundName,
      description,
      minAmount,
      blockchain,
      minAmountCurrency: tokenPriceCurrency,
      risk: risk,
      marketcapType: marketCap,
      icon: logoUrl,
      coverImage: bannerUrl,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund`,
        data,
        {
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      if (response.data) {
        toast.success("fund added successfully");
        // navigate(`/crypto-fund/${response.data.fundId}`);
        setCurrentComponent(2);
        setFundId(response?.data?.fundId);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleThirdComponentsSubmit = async (event) => {
    event.preventDefault();

    if (!fundTokenName || !tokenSymbol || !tokenDecimals || !totalSupply) {
      toast.error("Please enter all required parameters");
      return;
    }

    setLoading(true);

    const data = {
      fundId,
      blockchain,
      userId: appCtx.userDetails?.userId,
      tokenName: fundTokenName,
      symbol: tokenSymbol,
      decimals: tokenDecimals,
      totalSupply: totalSupply,
      type: "token",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/contract/deploy`,
        data,
        {
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        toast.success("contract deployed successfully");
        navigate(`/crypto-fund/${fundId}`);
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      console.log(error);
      toast.success("failed to deploy contract");
      setLoading(false);
    }
  };
  const width = window.screen.width;
  let isMobileDevice = width < 600;
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: "#FFFFFF",
      border: "1px solid  #FFFFFF",
      color: "black",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
      width: `${isMobileDevice ? "95%" : "500px"}`,
    },
  };
  function closeModal() {
    setOpenModal(false);
  }

  let riskArray = ["High", "Low", "Mid"];
  let marketCapArray = ["High", "Low", "Mid"];

  const handleBannerChange = useCallback((file) => {
    if (file) {
      setCollectionBanner(file);
      const objectUrl = URL.createObjectURL(file);
      setBannerPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, []);
  const handleLogoChange = useCallback((file) => {
    if (file) {
      setCollectionLogo(file);
      const objectUrl = URL.createObjectURL(file);
      setLogoPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, []);

  const handleUploadBanner = (type) => {
    setImageUploadLoading(true);
    const data = new FormData();
    if (type === "logo") {
      data.append("file", collectionLogo);
    } else {
      data.append("file", collectionBanner);
    }

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/web2/file/upload?type=collection-banner`,
      headers: {
        "Content-Type": "multipart/form-data",
        "X-Auth-Token": appCtx.authToken,
      },
      data: data,
    };

    const uploadPromise = axios(config)
      .then(function (response) {
        if (type === "logo") {
          setLogoUrl(response.data.fileUrl);
        } else {
          setBannerUrl(response.data.fileUrl);
        }
        setImageUploadLoading(false);
      })
      .catch(function (error) {
        setUploadingBanner(false);
      });

    toast.promise(uploadPromise, {
      pending: "Uploading image...",
      success: "Image uploaded successfully!",
      error: "Image upload failed.",
    });
  };

  useEffect(() => {
    if (collectionLogo) {
      handleUploadBanner("logo");
    }
  }, [collectionLogo]);
  useEffect(() => {
    if (collectionBanner) {
      handleUploadBanner("banner");
    }
  }, [collectionBanner]);

  const walletAddress = appCtx.walletAddress.find(
    (addr) => addr.blockchain === appCtx.blockchain
  )?.address;

  const handleWalletSetup = () => {
    setSetupLoader(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/wallet/setup`,
        data: {
          blockchain: blockchain,
          fundId: fundId,
        },
        method: "post",
      },
      (data) => {
        toast.success("Setup Successful!");
        setCurrentComponent(3);
        setSetupLoader(false);
      },
      (error) => {
        toast.error("Setup Failed");
        setSetupLoader(false);
      }
    );
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="d-flex justify-content-end m-4 position-relative">
          <button
            onClick={closeModal}
            className="btn btn-link position-absolute top-0 right-2"
          >
            <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
          </button>
        </div>
        <div className="p-3">
          {currentComponent === 1 && (
            <form className="">
              <h5 className="text-center mt-2">Create a new Crypto Fund</h5>
              <div className="mb-3">
                <label htmlFor="fundName" className="form-label">
                  Fund Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fundName"
                  placeholder="Enter Fund Name"
                  value={fundName}
                  onChange={(event) => setFundName(event.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Description
                </label>
                <textarea
                  style={{ height: "200px" }}
                  type="text"
                  className="form-control"
                  id="description"
                  placeholder="Enter description"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </div>
              <div className="d-flex gap-3">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Blockchain
                  </label>
                  <select
                    className="form-select"
                    style={{
                      border: `${
                        appCtx?.isDarkMode ? "1 px solid #F6F6F6" : "1 px solid #F6F6F6"
                      }`,
                      borderRadius: "5px",
                      backgroundColor: `${
                        appCtx.isDarkMode ? "#FFFFFF" : "#FFFFFF"
                      }`,
                      color: `${appCtx.isDarkMode ? "#717579" : "#717579"}`,
                    }}
                    id="blockchain"
                    value={blockchain}
                    onChange={(event) => setBlockchain(event.target.value)}
                  >
                    {Object.keys(chains).map((chain) => (
                      <option key={chain} value={chain.toUpperCase()}>
                        {chain.toUpperCase()}
                      </option>
                    ))}
                    <span>
                      <FontAwesomeIcon icon={faCircleChevronDown} />
                    </span>
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="risk" className="form-label">
                    Risk
                  </label>
                  <select
                    className="form-select"
                    style={{
                      border: `${
                        appCtx?.isDarkMode ? "1 px solid #F6F6F6" : "1 px solid #F6F6F6"
                      }`,
                      borderRadius: "5px",
                      backgroundColor: `${
                        appCtx.isDarkMode ? "#FFFFFF" : "#FFFFFF"
                      }`,
                      color: `${appCtx.isDarkMode ? "#717579" : "#717579"}`,
                    }}
                    id="risk"
                    value={risk}
                    onChange={(event) => setRisk(event.target.value)}
                  >
                    {riskArray?.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                    <span>
                      <FontAwesomeIcon icon={faCircleChevronDown} />
                    </span>
                  </select>
                </div>

                <div className="mb-4">
                  <label htmlFor="marketcap" className="form-label">
                    Marketcap
                  </label>
                  <div className="d-flex flex-direction-row  gap-3 ">
                    <select
                      className="form-select"
                      style={{
                        border: `${
                          appCtx?.isDarkMode ? "1 px solid #F6F6F6" : "1 px solid #F6F6F6"
                        }`,
                        borderRadius: "5px",
                        backgroundColor: `${
                          appCtx.isDarkMode ? "#FFFFFF" : "#FFFFFF"
                        }`,
                        color: `${appCtx.isDarkMode ? "#717579" : "#717579"}`,
                      }}
                      id="marketcap"
                      value={marketCap}
                      onChange={(event) => setMarketCap(event.target.value)}
                    >
                      {marketCapArray?.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                      <span>
                        <FontAwesomeIcon icon={faCircleChevronDown} />
                      </span>
                    </select>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <label htmlFor="name" className="form-label">
                  Minimum Amount
                </label>

                <div className="d-flex flex-direction-row  gap-3 ">
                  <input
                    className="form-control"
                    id="minAmount"
                    type="number"
                    placeholder="Enter minimum amount"
                    value={minAmount}
                    onChange={(event) => setMinAmount(event.target.value)}
                  />
                  <select
                    className="form-select"
                    style={{
                      border: `${
                        appCtx?.isDarkMode ? "1 px solid #F6F6F6" : "1 px solid #F6F6F6"
                      }`,
                      borderRadius: "5px",
                      backgroundColor: `${
                        appCtx.isDarkMode ? "#FFFFFF" : "#FFFFFF"
                      }`,
                      color: `${appCtx.isDarkMode ? "#717579" : "#717579"}`,
                    }}
                    id="tokenPriceCurrency"
                    value={tokenPriceCurrency}
                    onChange={(event) =>
                      setTokenPriceCurrency(event.target.value)
                    }
                  >
                    <option value="INR">
                      <span> INR</span>
                      <span>
                        <FontAwesomeIcon icon={faCircleChevronDown} />
                      </span>
                    </option>
                    <option value="USD">
                      <span> USD</span>
                      <span>
                        <FontAwesomeIcon icon={faCircleChevronDown} />
                      </span>
                    </option>
                  </select>
                </div>
              </div>

              {/* upload section  */}
              <div className="d-flex flex-row  gap-4 gap-md-5 align-items-center">
                <div className="d-flex flex-column gap-2">
                  <label htmlFor="icon" className="form-label">
                    icon
                  </label>
                  <div className="d-flex gap-2 align-items-center">
                    <div
                      className="d-flex flex-column items-center justify-center"
                      style={{
                        border: `${
                          appCtx?.isDarkMode ? "1 px solid #F6F6F6" : "1 px solid #F6F6F6"
                        }`,
                        borderRadius: "5px",
                        backgroundColor: `${
                          appCtx.isDarkMode ? "#E7E7E7" : "#E7E7E7"
                        }`,
                        color: `${appCtx.isDarkMode ? "#717579" : "#717579"}`,
                        width: "150px",
                        height: "120px",
                        cursor: "pointer",
                        position: "relative",
                      }}
                      onClick={() => fileRef.current.click()}
                    >
                      <p className="text-center my-auto">Browse for icon</p>
                      {logoPreview && (
                        <img
                          src={logoPreview}
                          alt="upload"
                          style={{
                            width: "150px",
                            height: "120px",
                            borderRadius: "5%",
                            position: "absolute",
                            // marginBottom: "-120px",
                            overflow: "hidden",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <input
                    hidden={true}
                    type="file"
                    ref={fileRef}
                    onChange={(e) => {
                      handleLogoChange(e.target.files[0]);
                      setShowUploadLogoButton(true);
                    }}
                  />
                </div>
                <div className="d-flex flex-column gap-2">
                  <label htmlFor="icon" className="form-label">
                    Cover image
                  </label>
                  <div className="d-flex gap-2 align-items-center">
                    <div
                      className="d-flex flex-column items-center justify-center"
                      style={{
                        border: `${
                          appCtx?.isDarkMode ? "1 px solid #F6F6F6" : "1 px solid #F6F6F6"
                        }`,
                        borderRadius: "5px",
                        backgroundColor: `${
                          appCtx.isDarkMode ? "#18254F" : "#E7E7E7"
                        }`,
                        color: `${appCtx.isDarkMode ? "#717579" : "#717579"}`,
                        width: "150px",
                        height: "120px",
                        cursor: "pointer",
                        position: "relative",
                      }}
                      onClick={() => bannerFileRef.current.click()}
                    >
                      <p className="text-center my-auto">
                        Browse for cover image
                      </p>
                      {bannerPreview && (
                        <img
                          src={bannerPreview}
                          alt="upload"
                          style={{
                            width: "150px",
                            height: "120px",
                            borderRadius: "5%",
                            position: "absolute",
                            // marginBottom: "-120px",
                            overflow: "hidden",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <input
                    hidden={true}
                    type="file"
                    ref={bannerFileRef}
                    onChange={(e) => {
                      e.preventDefault();
                      handleBannerChange(e.target.files[0]);
                      setShowUploadBannerButton(true);
                    }}
                  />
                </div>
                {/* <div className="">
                  <button
                    onClick={() => {
                      setOpenCodeModal(true);
                    }}
                    type="button"
                    class="btn btn-secondary"
                  >
                    View source code
                  </button>
                </div> */}
              </div>

              <div className="d-flex justify-content-center gap-4 mt-4 ">
                <button
                  className="btn btn-primary  "
                  type="submit"
                  disabled={imageUploadLoading}
                  onClick={handleFirstComponentsSubmit}
                >
                  Continue{" "}
                  <span>
                    {loading && (
                      <CircularProgress size={20} className="ml-[5px]" />
                    )}
                  </span>
                </button>
              </div>
            </form>
          )}
          {currentComponent === 2 && (
            <>
              <h5 className="text-center mt-2">Setup Wallet</h5>
              <div className="d-flex flex-column gap-2 mt-3">
                <button
                  onClick={handleWalletSetup}
                  className="btn btn-outline-primary btn-sm btn-block mt-xl-0 mt-3 d-flex align-items-center justify-content-center"
                >
                  Setup a new wallet for CryptoFund
                  {setupLoader && (
                    <CircularProgress
                      style={{
                        color: "#BBFF00",
                        marginLeft: "5px",
                      }}
                      size={20}
                    />
                  )}
                </button>
                <button
                  disabled={true}
                  // onClick={handleWalletConnectSignin}
                  className="btn btn-outline-secondary btn-sm btn-block mt-xl-0 mt-3 d-flex align-items-center justify-content-center"
                >
                  Take Fund in Smart Contract - (Coming Soon)
                  {/* {setupLoader && (
                    <CircularProgress
                      style={{
                        color: "#BBFF00",
                        marginLeft: "5px",
                      }}
                      size={20}
                    />
                  )} */}
                </button>
              </div>
            </>
          )}
          {currentComponent === 3 && (
            <form className="">
              <h5 className="text-center mt-2">Token</h5>
              <div className="d-flex gap-5">
                <div>
                  <div className="mb-3">
                    <label htmlFor="fundName" className="form-label">
                      Fund Token Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="fundTokenName"
                      placeholder="Enter Fund Token Name"
                      value={fundTokenName}
                      onChange={(event) => setFunTokendName(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="tokenSymbol" className="form-label">
                      Fund Token Symbol
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="tokenSymbol"
                      placeholder="Enter Token Symbol"
                      value={tokenSymbol}
                      onChange={(event) => setTokenSymbol(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="tokenDecimals" className="form-label">
                      Fund Token Decimals
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="tokenDecimals"
                      placeholder="Enter Token Decimals"
                      value={tokenDecimals}
                      onChange={(event) => setTokenDecimals(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="totalSupply" className="form-label">
                      Total Supply
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="totalSupply"
                      placeholder="Enter Total Supply"
                      value={totalSupply}
                      onChange={(event) => setTotalSupply(event.target.value)}
                    />
                  </div>
                </div>
                <div className="text-black">
                  <Editor
                    height="500px"
                    width="600px"
                    theme="vs-dark"
                    defaultLanguage="javascript"
                    value={code}
                    options={{
                      minimap: {
                        enabled: false,
                      },
                    }}
                    onChange={(value) => setCode(value)}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center gap-4 mt-4 ">
                <button
                  className="btn btn-primary  "
                  type="submit"
                  onClick={handleThirdComponentsSubmit} //need to integrate api
                >
                  Continue{" "}
                  <span>
                    {loading && (
                      <CircularProgress size={20} className="ml-[5px]" />
                    )}
                  </span>
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default CreateCryptofFundModal;
