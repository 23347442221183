// NavigationalPlayground.jsx

import React, { useState, useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import 'leaflet-draw'; // Imports leaflet-draw and attaches to L
import 'leaflet-draw/dist/leaflet.draw.css';
import Chip from '@mui/material/Chip';

import MainWrapper from '../wrapper/MainWrapper';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Tooltip,
  Autocomplete,
  TextField,
  CircularProgress,
  Alert,
  Modal,
  Backdrop,
  Fade,
  Paper,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Snackbar,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import { styled, useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


import {
  CloudUpload as CloudUploadIcon,
  Map as MapIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Image as ImageIcon,
  Search as SearchIcon,
  Layers as LayersIcon,
  Gesture as GestureIcon,
  PlayArrow as PlayArrowIcon,
  History as HistoryIcon,
  Tag as TagIcon,
  VideoLibrary as VideoLibraryIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  Download as DownloadIcon,
  Visibility as VisibilityIcon,
  DragIndicator as DragIndicatorIcon,
  Audiotrack as AudiotrackIcon,
  Videocam as VideocamIcon,
  TextFields as TextFieldsIcon,
  CameraAlt as CameraAltIcon,
} from '@mui/icons-material';

import { MapContainer, TileLayer, FeatureGroup, useMap, Marker, Popup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import './NavigationalPlayground.css';

// Importing ProtocolList
import { ProtocolList } from '../../pages/cryptobasket/ProtocolList'; // Adjust the path as needed

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(10),
  paddingLeft: 22,
  paddingRight: 22,
  marginLeft: 0,
  marginRight: 0,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  minHeight: '450px',
  borderRadius: '4px',
  boxShadow: theme.shadows[2],
  padding: theme.spacing(0.5),
}));

const HoverButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2.8),
  marginBottom: theme.spacing(0.8),
  width: '100%',
  transition: 'all 0.1s ease',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'none',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '80%',
  maxHeight: '80vh',
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2, 4, 3),
  bottom: '10%',
  left: '10%',
  borderRadius: '16px',
}));

const DraggableItem = styled('div')(({ theme }) => ({
  display: 'inline-block',
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  background: `linear-gradient(135deg, ${theme.palette.grey[200]} 0%, ${theme.palette.grey[300]} 100%)`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'grab',
  userSelect: 'none',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: theme.palette.grey[400],
  },
  '&:active': {
    cursor: 'grabbing',
    transform: 'scale(0.95)',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  width: '100%',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  background: 'linear-gradient(135deg, rgba(245, 250, 250, 0.7) 0%, rgba(250, 249, 246, 0.7) 10%)',
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius * 1,
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.005)',
    boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.2)',
  },
}));

const NavigationalPlayground = () => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { model: modelFromParams } = useParams();
  const navigate = useNavigate();
  const [latentSpaceSize, setLatentSpaceSize] = useState(64); // Latent space size
  const handlelatentspacechange = (event, newValue) => setLatentSpaceSize(newValue);
  
  const [model, setModel] = useState(modelFromParams || '');
  const [selectedModel, setSelectedModel] = useState(null);
  const [dataType, setDataType] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [promptText, setPromptText] = useState('');
  const [apiResponse, setApiResponse] = useState(null);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasResults, setHasResults] = useState(false);
  const [openAddDataModal, setOpenAddDataModal] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [openMapModal, setOpenMapModal] = useState(false);
  const drawnItemsRef = useRef(new L.FeatureGroup());
  const [stacFiles, setStacFiles] = useState([]);
  const [locationQuery, setLocationQuery] = useState('');
  const [mapLocationQuery, setMapLocationQuery] = useState('');
  const [outputTab, setOutputTab] = useState(0);
  const [savedTags, setSavedTags] = useState([]);
  const [history, setHistory] = useState([]);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [openTagsModal, setOpenTagsModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [formData, setFormData] = useState({
    deviceIp: '',
    accessPort: '',
    // ...add any other form fields here
  });
  
  const [audioFiles, setAudioFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [textFiles, setTextFiles] = useState([]);
  
  const [openImagingDeviceModal, setOpenImagingDeviceModal] = useState(false);
  
  const [noiseInput, setNoiseInput] = useState(0.5); // Noise input
  
  const [lossFunction, setLossFunction] = useState('Balanced'); // Loss function
  
  // Dropdowns' states
  const imputedOptions = [
    'Factual only',
    'No Politics',
    'Family-friendly',
    'No Personal opinion',
    'No Legal',
    'No Medical',
    'No racism',
  ];
  const [imputedColumns, setImputedColumns] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const addImagingDevice = () => {
    if (!formData.deviceIp || !formData.accessPort) {
      alert('Please fill in all required fields.');
      return;
    }

    // Save the imaging device data
    console.log('Imaging device added:', formData);

    // Optionally, add it to selectedItems
    setSelectedItems([...selectedItems, { type: 'imagingDevice', data: formData }]);

    // Close the modal after adding
    closeImagingDeviceDialog();
  };

  useEffect(() => {
    const foundModel = ProtocolList.find((protocol) => protocol.model === model);
    setSelectedModel(foundModel);
  }, [model]);

  useEffect(() => {
    if (selectedModel && selectedModel.dataTypes && selectedModel.dataTypes.length > 0) {
      setDataType(selectedModel.dataTypes[0]);
    } else {
      setDataType('');
    }
  }, [selectedModel]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      setImageFile(files[0]);
      const imageItems = files.map((file) => ({ type: 'image', data: file }));
      setSelectedItems([...selectedItems, ...imageItems]);
    }
  };

  const handleSTACFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setStacFiles(files);
    const stacItems = files.map((file) => ({ type: 'stac', data: file }));
    setSelectedItems([...selectedItems, ...stacItems]);
  };

  const handleAudioFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setAudioFiles(files);
    const audioItems = files.map((file) => ({ type: 'audio', data: file }));
    setSelectedItems([...selectedItems, ...audioItems]);
  };

  const handleVideoFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setVideoFiles(files);
    const videoItems = files.map((file) => ({ type: 'video', data: file }));
    setSelectedItems([...selectedItems, ...videoItems]);
  };

  const handleTextFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setTextFiles(files);
    const textItems = files.map((file) => ({ type: 'text', data: file }));
    setSelectedItems([...selectedItems, ...textItems]);
  };

  const handleGenerate = async () => {
    if (selectedItems.length === 0 && !promptText.trim()) {
      alert('Please upload data or enter a prompt.');
      return;
    }

    setLoader(true);
    setErrorMessage(null);

    const constructAndSendRequests = async () => {
      let hasErrors = false;
      let responseAggregations = [];

      // Function to handle individual API calls
      const handleApiRequest = async (apiUrl, formData, fileType) => {
        try {
          const response = await axios.post(apiUrl, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log(`${fileType} API Response:`, response);
          if (response.data.status === 200 && response.data.message === 'Success') {
            responseAggregations.push(response.data);
            setApiResponse(response.data);
            setHistory((prevHistory) => [...prevHistory, response.data]);
            setSnackbarMessage(`${fileType} generation successful!`);
            setSnackbarOpen(true);
          } else {
            hasErrors = true;
            setErrorMessage(response.data.message || `Failed to generate ${fileType}.`);
          }
        } catch (error) {
          hasErrors = true;
          if (error.response) {
            setErrorMessage(`Error: ${error.response.data.message || `Failed to generate ${fileType}.`}`);
          } else if (error.request) {
            setErrorMessage(`No response from the server for ${fileType}. Please try again later.`);
          } else {
            setErrorMessage(`Error: ${error.message}`);
          }
        }
      };

      // Function to create FormData and call APIs based on file types
      const processFiles = async () => {
        // Handle Image Files
        if (imageFile) {
          const formData = new FormData();
          formData.append('image', imageFile, imageFile.name);
          //formData.append('prompt', promptText);
          //formData.append('model', model);
          //formData.append('latentSpaceSize', latentSpaceSize);
          //formData.append('noiseInput', noiseInput);
          //formData.append('lossFunction', lossFunction);
          //formData.append('imputedColumns', imputedColumns.join(','));
          const apiUrl = `${process.env.REACT_APP_FOOD_API}?Secret-Key=${process.env.REACT_APP_FOOD_KEY}`;
          await handleApiRequest(apiUrl, formData, 'Image');
        }

        // Handle Video Files
        if (videoFiles.length > 0) {
          for (let i = 0; i < videoFiles.length; i++) {
            const formData = new FormData();
            formData.append('videoFile', videoFiles[i]);
            //formData.append('prompt', promptText);
            //formData.append('model', model);
            //formData.append('latentSpaceSize', latentSpaceSize);
            //formData.append('noiseInput', noiseInput);
            //formData.append('lossFunction', lossFunction);
            //formData.append('imputedColumns', imputedColumns.join(','));
            const apiUrl = `${process.env.REACT_APP_VIDEO_API}?Secret-Key=${process.env.REACT_APP_VIDEO_KEY}`;
            await handleApiRequest(apiUrl, formData, 'Video');
          }
        }

        // Handle Audio Files
        if (audioFiles.length > 0) {
          for (let i = 0; i < audioFiles.length; i++) {
            const formData = new FormData();
            formData.append('audioFile', audioFiles[i]);
            //formData.append('prompt', promptText);
            //formData.append('model', model);
            //formData.append('latentSpaceSize', latentSpaceSize);
            //formData.append('noiseInput', noiseInput);
            //formData.append('lossFunction', lossFunction);
            //formData.append('imputedColumns', imputedColumns.join(','));
            const apiUrl = `${process.env.REACT_APP_AUDIO_API}?Secret-Key=${process.env.REACT_APP_AUDIO_KEY}`;
            await handleApiRequest(apiUrl, formData, 'Audio');
          }
        }

        // Handle Text Files
        if (textFiles.length > 0) {
          for (let i = 0; i < textFiles.length; i++) {
            const formData = new FormData();
            formData.append('textFile', textFiles[i]);
            //formData.append('prompt', promptText);
            //formData.append('model', model);
            //formData.append('latentSpaceSize', latentSpaceSize);
            //formData.append('noiseInput', noiseInput);
            //formData.append('lossFunction', lossFunction);
            //formData.append('imputedColumns', imputedColumns.join(','));
            const apiUrl = `${process.env.REACT_APP_TEXT_API}?Secret-Key=${process.env.REACT_APP_TEXT_KEY}`;
            await handleApiRequest(apiUrl, formData, 'Text');
          }
        }

        // Handle STAC Files
        if (stacFiles.length > 0) {
          for (let i = 0; i < stacFiles.length; i++) {
            const formData = new FormData();
            formData.append(`stacFile${i}`, stacFiles[i]);
            //formData.append('prompt', promptText);
            //formData.append('model', model);
            //formData.append('latentSpaceSize', latentSpaceSize);
            //formData.append('noiseInput', noiseInput);
            //formData.append('lossFunction', lossFunction);
            //formData.append('imputedColumns', imputedColumns.join(','));
            const apiUrl = `${process.env.REACT_APP_STAC_API}?Secret-Key=${process.env.REACT_APP_STAC_KEY}`;
            await handleApiRequest(apiUrl, formData, 'STAC');
          }
        }

        // Handle Drawn Shapes
        if (drawnItemsRef.current.getLayers().length > 0) {
          const geoJsonData = drawnItemsRef.current.toGeoJSON();
          const formData = new FormData();
          formData.append('geojson', JSON.stringify(geoJsonData));
          //formData.append('prompt', promptText);
          //formData.append('model', model);
          //formData.append('latentSpaceSize', latentSpaceSize);
          //formData.append('noiseInput', noiseInput);
          //formData.append('lossFunction', lossFunction);
          //formData.append('imputedColumns', imputedColumns.join(','));
          const apiUrl = `${process.env.REACT_APP_GEOJSON_API}?Secret-Key=${process.env.REACT_APP_GEOJSON_KEY}`;
          await handleApiRequest(apiUrl, formData, 'GeoJSON');
        }

        if (!hasErrors && responseAggregations.length === 0) {
          setErrorMessage('All servers are busy, please try again later.');
        } else if (!hasErrors) {
          setHasResults(true);
        }
      };

      try {
        await processFiles();
      } catch (error) {
        console.error('Unexpected error occurred:', error);
        setErrorMessage('Unexpected error occurred. Please try again later.');
      } finally {
        setLoader(false);
      }
    };

    try {
      await constructAndSendRequests();
    } catch (error) {
      console.error('Error during generation:', error);
      setErrorMessage('Unexpected error occurred. Please try again later.');
    } finally {
      setLoader(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOutputTabChange = (event, newValue) => {
    setOutputTab(newValue);
  };

  const handleMapDraw = (e) => {
    const layer = e.layer;
    drawnItemsRef.current.addLayer(layer);
    setSelectedItems([...selectedItems, { type: 'shape', data: layer }]);
    setSnackbarMessage('Shape added to selection.');
    setSnackbarOpen(true);
  };

  const openMapDialog = () => {
    setOpenMapModal(true);
  };

  const closeMapDialog = () => {
    setOpenMapModal(false);
  };

  const openHistoryDialog = () => {
    setOpenHistoryModal(true);
  };

  const closeHistoryDialog = () => {
    setOpenHistoryModal(false);
  };

  const openTagsDialog = () => {
    setOpenTagsModal(true);
  };

  const closeTagsDialog = () => {
    setOpenTagsModal(false);
  };

  const openVideoDialog = () => {
    setOpenVideoModal(true);
  };

  const closeVideoDialog = () => {
    setOpenVideoModal(false);
  };

  const openImagingDeviceDialog = () => {
    setOpenImagingDeviceModal(true);
  };

  const closeImagingDeviceDialog = () => {
    setOpenImagingDeviceModal(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDragStart = (e, item) => {
    e.dataTransfer.setData('item', JSON.stringify(item));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const item = JSON.parse(e.dataTransfer.getData('item'));
    if (item.type === 'image' || item.type === 'stac') {
      setPromptText((prev) => `${prev} [${item.data.name}]`);
    } else if (item.type === 'shape') {
      setPromptText((prev) => `${prev} [Drawn Shape]`);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const leftGridSize = isSmDown ? 12 : 5.5;
  const rightGridSize = isSmDown ? 12 : 6.5;

  const FilterAppBar = ({
    selectedModel,
    model,
    setModel,
    dataType,
    setDataType,
    ProtocolList,

    noiseInput,
    setNoiseInput,
    imputedColumns,
    setImputedColumns,
    lossFunction,
    setLossFunction,
  }) => {
    
    const rebalancingOptions = ['None', 'Balanced', 'Generator Focused', 'Discriminator Focused'];


    return (
      <StyledAppBar position="static" elevation={0}>
        <Toolbar>
          <BoxContainer>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {'Playground'}
            </Typography>

            <Autocomplete
              options={ProtocolList.map((protocol) => protocol.model)}
              getOptionLabel={(option) => {
                const protocol = ProtocolList.find((p) => p.model === option);
                return protocol ? protocol.name : option;
              }}
              value={model}
              onChange={(event, newValue) => setModel(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Model" variant="outlined" />
              )}
              sx={{ width: '250px', marginRight: 2 }}
            />

                <Tooltip
                  title="Adjust the latent space size to control the complexity and detail of the generated data."
                  placement="top"
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', color: '#121212' }}>
  <Typography gutterBottom sx={{ marginRight: 0 }}></Typography>
    
    <TextField
  label="Latent Space"
  type="number"
  value={latentSpaceSize}
  onChange={(e) => setLatentSpaceSize(Number(e.target.value))}
  InputProps={{
    inputProps: { min: 0, max: 256, step: 1 },
    sx: { width: 100, textAlign: 'center' },
  }}
  sx={{ marginLeft: 24, marginRight: 0 }}
  />
    
    </Box>
    </Tooltip>
    
    
    
    
    
    <Tooltip
  title="Adjust the noise level to control the randomness in the model's outputs. Higher noise creates more variation, while lower noise makes the output more predictable."
  placement="top"
  >
    <Box sx={{ display: 'flex', alignItems: 'center', color: '#121212' }}>
    <Typography gutterBottom sx={{ marginRight: 0 }}></Typography>
    
    <TextField
  label="Noise"
  type="number"
  value={noiseInput}
  onChange={(e) => setNoiseInput(Number(e.target.value))}
  InputProps={{
    inputProps: { min: 0, max: 1, step: 0.01 },
    sx: { width: 80, textAlign: 'center' },
  }}
  sx={{ marginLeft: 0, marginRight: 0 }}
  />
    
    </Box>
    </Tooltip>
    
    
    <Autocomplete
  multiple
  options={imputedOptions}
  value={imputedColumns}
  onChange={(event, newValue) => setImputedColumns(newValue)}
  renderInput={(params) => (
    <Tooltip title="Guardrail AI's thoughts" placement="top">
      <TextField {...params} label="Guardrail Prompts" variant="outlined" />
      </Tooltip>
  )}
  sx={{ width: 300, marginRight: 0 }}
  />
    
    <Tooltip
  title="Select the generator loss function to balance model training behavior."
  placement="top"
  >
    <FormControl sx={{ minWidth: 200 }}>
    <InputLabel id="loss-function-label">Generator Loss</InputLabel>
    <Select
  labelId="loss-function-label"
  value={lossFunction}
  onChange={(e) => setLossFunction(e.target.value)}
  label="Generator Loss"
  >
    {rebalancingOptions.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ))}
  </Select>
    </FormControl>
    </Tooltip>
    </BoxContainer>
    </Toolbar>
    </StyledAppBar>
    );
};

return (
  <MainWrapper>
    <div>
    <FilterAppBar
  selectedModel={selectedModel}
  model={model}
  setModel={setModel}
  dataType={dataType}
  setDataType={setDataType}
  ProtocolList={ProtocolList}
  latentSpaceSize={latentSpaceSize}
  setLatentSpaceSize={setLatentSpaceSize}
  noiseInput={noiseInput}
  setNoiseInput={setNoiseInput}
  imputedColumns={imputedColumns}
  setImputedColumns={setImputedColumns}
  lossFunction={lossFunction}
  setLossFunction={setLossFunction}
  />
    <Snackbar
  open={snackbarOpen}
  autoHideDuration={3000}
  onClose={handleSnackbarClose}
  message={snackbarMessage}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
  />
    
    <StyledContainer disableGutters maxWidth={false}>
    <Grid container spacing={2}>
    <Grid item xs={12} md={leftGridSize}>
    <StyledCard>
    <CardContent>
    <Typography variant="h6">Input</Typography>
    
    <Tabs
  value={tabValue}
  onChange={handleTabChange}
  indicatorColor="primary"
  textColor="primary"
  variant="scrollable"
  scrollButtons="auto"
  >
    <Tab label="STAC" icon={<MapIcon />} />
    <Tab label="Images" icon={<CloudUploadIcon />} />
    <Tab label="Audio" icon={<AudiotrackIcon />} />
    <Tab label="Video" icon={<VideocamIcon />} />
    <Tab label="Text" icon={<TextFieldsIcon />} />
    <Tab label="Bio-Strips" icon={<SearchIcon />} />
    </Tabs>
    
    {/* STAC Tab */}
  <TabPanel value={tabValue} index={0}>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <label htmlFor="map-add">
    <Button
  variant="contained"
  color="primary"
  component="span"
  fullWidth
  startIcon={<GestureIcon />}
  sx={{
    backgroundColor: '#FFFFFF',
    color: '#000000',
    borderRadius: 1,
    fontSize: 12,
    fontWeight: 100,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#0c0678',
      color: '#FFFFFF',
    },
  }}
  onClick={openMapDialog}
  className="animated-button"
  >
    Draw on Map
  </Button>
    </label>
    </Grid>
    <Grid item xs={12} md={6}>
    <input
  accept=".csv,.kml,.tif,.geojson,.json"
  style={{ display: 'none' }}
  id="stac-file-upload"
  multiple
  type="file"
  onChange={handleSTACFileUpload}
  />
    <label htmlFor="stac-file-upload">
    <Button
  variant="contained"
  color="secondary"
  component="span"
  fullWidth
  startIcon={<InsertDriveFileIcon />}
  sx={{
    backgroundColor: '#FFFFFF',
    color: '#000000',
    borderRadius: 1,
    fontSize: 12,
    fontWeight: 100,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#0c0678',
      color: '#FFFFFF',
    },
  }}
  className="animated-button"
  >
    Upload STAC Files
  </Button>
    </label>
    </Grid>
    </Grid>
    </TabPanel>
    
    {/* Images Tab */}
  <TabPanel value={tabValue} index={1}>
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
    <label htmlFor="add-imaging-device">
    <Button
  variant="contained"
  color="primary"
  startIcon={<CameraAltIcon />}
  sx={{
    backgroundColor: '#FFFFFF',
    color: '#000000',
    borderRadius: 1,
    fontSize: 12,
    fontWeight: 100,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#0c0678',
      color: '#FFFFFF',
    },
  }}
  onClick={openImagingDeviceDialog}
  fullWidth
  className="animated-button"
  >
    Connect Imaging Device
  </Button>
    </label>
    </Grid>
    <Grid item xs={12} md={6}>
    <input
  accept="image/*"
  style={{ display: 'none' }}
  id="image-file-upload"
  multiple
  type="file"
  onChange={handleImageChange}
  />
    <label htmlFor="image-file-upload">
    <Button
  variant="contained"
  color="primary"
  component="span"
  fullWidth
  startIcon={<ImageIcon />}
  sx={{
    backgroundColor: '#FFFFFF',
    color: '#000000',
    borderRadius: 1,
    fontSize: 12,
    fontWeight: 100,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#0c0678',
      color: '#FFFFFF',
    },
  }}
  className="animated-button"
  >
    Upload Image
  </Button>
    </label>
    </Grid>
    </Grid>
    </TabPanel>
    
    {/* Audio Tab */}
  <TabPanel value={tabValue} index={2}>
    <input
  accept="audio/*"
  style={{ display: 'none' }}
  id="audio-file-upload"
  multiple
  type="file"
  onChange={handleAudioFileUpload}
  />
    <label htmlFor="audio-file-upload">
    <Button
  variant="contained"
  color="primary"
  component="span"
  fullWidth
  startIcon={<AudiotrackIcon />}
  sx={{
    backgroundColor: '#FFFFFF',
    color: '#000000',
    borderRadius: 1,
    fontSize: 12,
    fontWeight: 100,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#0c0678',
      color: '#FFFFFF',
    },
  }}
  className="animated-button"
  >
    Upload Audio Files
  </Button>
    </label>
    </TabPanel>
    
    {/* Video Tab */}
  <TabPanel value={tabValue} index={3}>
    <input
  accept="video/*"
  style={{ display: 'none' }}
  id="video-file-upload"
  multiple
  type="file"
  onChange={handleVideoFileUpload}
  />
    <label htmlFor="video-file-upload">
    <Button
  variant="contained"
  color="primary"
  component="span"
  fullWidth
  startIcon={<VideocamIcon />}
  sx={{
    backgroundColor: '#FFFFFF',
    color: '#000000',
    borderRadius: 1,
    fontSize: 12,
    fontWeight: 100,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#0c0678',
      color: '#FFFFFF',
    },
  }}
  className="animated-button"
  >
    Upload Video Files
  </Button>
    </label>
    </TabPanel>
    
    {/* Text Tab */}
  <TabPanel value={tabValue} index={4}>
    <input
  accept=".txt,.md,.doc,.docx,.pdf"
  style={{ display: 'none' }}
  id="text-file-upload"
  multiple
  type="file"
  onChange={handleTextFileUpload}
  />
    <label htmlFor="text-file-upload">
    <Button
  variant="contained"
  color="primary"
  component="span"
  fullWidth
  startIcon={<TextFieldsIcon />}
  sx={{
    backgroundColor: '#FFFFFF',
    color: '#000000',
    borderRadius: 1,
    fontSize: 12,
    fontWeight: 100,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#0c0678',
      color: '#FFFFFF',
    },
  }}
  className="animated-button"
  >
    Upload Text Files
  </Button>
    </label>
    <TextField
  label="Enter Text"
  multiline
  rows={4}
  variant="outlined"
  fullWidth
  sx={{ mt: 2 }}
  value={promptText}
  onChange={(e) => setPromptText(e.target.value)}
  />
    </TabPanel>
    
    {/* Bio-Strips Tab */}
  <TabPanel value={tabValue} index={5}>
    <TextField
  label="Search Vortx Biostrip ID"
  variant="outlined"
  fullWidth
  value={locationQuery}
  onChange={(e) => setLocationQuery(e.target.value)}
  InputProps={{
    endAdornment: (
      <IconButton onClick={() => setMapLocationQuery(locationQuery)}>
        <SearchIcon />
        </IconButton>
    ),
  }}
  />
    {searchResults.length > 0 && (
      <List>
        {searchResults.map((result, index) => (
          <ListItem
          key={index}
          button
          onClick={() => {
            setMapCenter([result.lat, result.lon]);
            setMapMarkers([
              ...mapMarkers,
              { position: [result.lat, result.lon], popup: result.display_name },
            ]);
            setSelectedItems([...selectedItems, { type: 'location', data: result }]);
            setSnackbarMessage('Location added to selection.');
            setSnackbarOpen(true);
          }}
          >
            <ListItemText primary={result.display_name} />
            </ListItem>
        ))}
      </List>
    )}
  </TabPanel>
    
    <Box sx={{ mt: 2 }}>
    <Typography variant="subtitle1"></Typography>
    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
    {['Walk in the future', 'Track history', 'what to do with this?'].map((tag) => (
      <Chip
      key={tag}
      label={tag}
      onClick={() => setPromptText((prev) => `${prev} ${tag}`)}
      sx={{ cursor: 'pointer' }}
      />
    ))}
  </Box>
    
    <TextField
  label="Enhance by Prompt"
  multiline
  rows={4}
  variant="outlined"
  fullWidth
  sx={{ mt: 2 }}
  value={promptText}
  onChange={(e) => setPromptText(e.target.value)}
  onDrop={handleDrop}
  onDragOver={handleDragOver}
  />
    </Box>
    
    <Box sx={{ marginBottom: 6, marginTop: 2 }}>
    {selectedItems.length > 0 && (
      <Typography variant="h6" fontSize={12} fontWeight={100} color="#808080">
        Total uploads: {selectedItems.length}
      </Typography>
    )}
  <Box>
    {selectedItems.map((item, index) => (
      <DraggableItem
      key={index}
      draggable
      onDragStart={(e) =>
          handleDragStart(e, {
            type: item.type,
            data: item.data ? { name: item.data.name } : null,
          })
      }
      >
        <DragIndicatorIcon fontSize="small" />
        {item.type === 'image' && item.data.name}
      {item.type === 'stac' && item.data.name}
      {item.type === 'shape' && 'Drawn Shape'}
      {item.type === 'audio' && item.data.name}
      {item.type === 'video' && item.data.name}
      {item.type === 'text' && item.data.name}
      {item.type === 'imagingDevice' && item.data.deviceIp}
      </DraggableItem>
    ))}
  </Box>
    </Box>
    </CardContent>
    
    <CardActions sx={{ width: '100%' }}>
    <HoverButton
  variant="contained"
  color="secondary"
  startIcon={<PlayArrowIcon />}
  sx={{
    backgroundColor: '#c5d1eb',
    color: '#000000',
    borderRadius: 1,
    fontSize: 16,
    border: '1px solid #aebfd1',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    fontWeight: 200,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#0c0678',
      color: '#FFFFFF',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    },
  }}
  onClick={handleGenerate}
  disabled={loader}
  >
    {loader ? 'Synthesizing Your Data...' : 'Synthesize'}
  {loader && <CircularProgress size={24} sx={{ marginLeft: 1, color: '#0c0678' }} />}
  </HoverButton>
    </CardActions>
    </StyledCard>
    </Grid>
    
    <Grid item xs={12} md={rightGridSize}>
    <StyledCard>
    <CardContent>
    <Typography variant="h6">Output</Typography>
    <Tabs
  value={outputTab}
  onChange={handleOutputTabChange}
  indicatorColor="primary"
  textColor="primary"
  variant="fullWidth"
  >
    <Tab label="Synthetic Media" icon={<ImageIcon />} />
    <Tab label="Synthetic Data" icon={<InsertDriveFileIcon />} />
    </Tabs>
    <TabPanel value={outputTab} index={0}>
    {loader ? (
      <div className="shimmer-box"></div>
    ) : (
      <>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {hasResults && apiResponse ? (
        <div>
          <Typography variant="h6">Generated Media</Typography>
          {/* Display generated media based on API response */}
        {/* For example, if image was generated */}
        {apiResponse.generatedImage && (
          <Card sx={{ position: 'relative' }}>
            <img
          src={apiResponse.generatedImage}
          alt="Synthesized"
          style={{
            maxWidth: '100%',
            borderRadius: '8px',
            marginTop: '16px',
          }}
          />
            <CardActions sx={{ position: 'absolute', top: 0, right: 0 }}>
            <IconButton color="primary">
            <ZoomInIcon />
            </IconButton>
            <IconButton color="primary">
            <ZoomOutIcon />
            </IconButton>
            <IconButton color="primary">
            <VisibilityIcon />
            </IconButton>
            <IconButton color="primary">
            <DownloadIcon />
            </IconButton>
            </CardActions>
            </Card>
        )}
        {/* Similar code can be added for video, audio, etc. */}
        </div>
      ) : (
        <div className="no-results-container">
          <LayersIcon fontSize="large" />
          <Typography variant="h6">No results yet</Typography>
          <Typography variant="body2">
          Upload data or enter a prompt, and then click "Synthesize"
        </Typography>
          </div>
      )}
      </>
    )}
  </TabPanel>
    <TabPanel value={outputTab} index={1}>
    {hasResults && apiResponse ? (
      <div>
        <Typography variant="h6">Generated Data</Typography>
        <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
        <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
        </Paper>
        </div>
    ) : (
      <Typography variant="body2">No data available.</Typography>
    )}
  </TabPanel>
    </CardContent>
    </StyledCard>
    </Grid>
    </Grid>
    </StyledContainer>
    
    {/* Imaging Device Modal */}
  <Modal
  open={openImagingDeviceModal}
  onClose={closeImagingDeviceDialog}
  closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{
    timeout: 500,
  }}
  >
    <Fade in={openImagingDeviceModal}>
    <StyledPaper>
    <Typography variant="h6" gutterBottom>
    Add Imaging Device
  </Typography>
    <TextField
  label="Device IP"
  name="deviceIp"
  fullWidth
  margin="normal"
  variant="outlined"
  value={formData.deviceIp}
  onChange={handleInputChange}
  />
    <TextField
  label="Access Port"
  name="accessPort"
  fullWidth
  margin="normal"
  variant="outlined"
  value={formData.accessPort}
  onChange={handleInputChange}
  />
    {/* Add other form fields as needed */}
  <Box mt={2} display="flex" justifyContent="flex-end">
    <Button onClick={closeImagingDeviceDialog} color="primary">
    Cancel
  </Button>
    <Button onClick={addImagingDevice} color="primary" variant="contained" sx={{ ml: 2 }}>
    Add Device
  </Button>
    </Box>
    </StyledPaper>
    </Fade>
    </Modal>
    
    {/* Map Modal */}
  <Modal
  open={openMapModal}
  onClose={closeMapDialog}
  closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{
    timeout: 500,
  }}
  >
    <Fade in={openMapModal}>
    <StyledPaper>
    <MapContainer center={mapCenter} zoom={13} style={{ height: '500px', width: '100%' }}>
    <TileLayer
  attribution="&copy; OpenStreetMap contributors"
  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
    <FeatureGroup ref={drawnItemsRef}>
    <EditControl
  position="topright"
  onCreated={handleMapDraw}
  draw={{
    rectangle: true,
    polygon: true,
    circle: true,
    polyline: true,
    marker: true,
    circlemarker: false,
  }}
  />
    </FeatureGroup>
    {mapMarkers.map((marker, index) => (
      <Marker key={index} position={marker.position}>
        <Popup>{marker.popup}</Popup>
        </Marker>
    ))}
  <MapSearch mapLocationQuery={mapLocationQuery} setSearchResults={setSearchResults} />
    </MapContainer>
    </StyledPaper>
    </Fade>
    </Modal>
    
    {/* Additional modals for history, tags, and video can be added similarly */}
  
  <style>
    {`
      .shimmer-box {
        width: 100%;
        height: 250px;
        background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
                                    background-size: 200% 100%;
                                    animation: shimmer 1.5s infinite;
                                    border-radius: 8px;
      }
      @keyframes shimmer {
        0% { background-position: 200% 0; }
      100% { background-position: -200% 0; }
  }
.no-results-container {
  text-align: center;
  margin-top: 50px;
  color: #9e9e9e;
}
.animated-button {
  transition: all 0.3s ease;
}
.animated-button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 4px 6px rgba(0,0,0,0.2);
}
`}
</style>
  </div>
  </MainWrapper>
        );
        };

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
    style={{ paddingTop: '16px' }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function MapSearch({ mapLocationQuery, setSearchResults }) {
  const map = useMap();
  
  useEffect(() => {
    if (mapLocationQuery.trim() === '') {
      return;
    }
    
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
            mapLocationQuery
          )}&format=json&limit=5`
        );
        
        if (response.data.length > 0) {
          setSearchResults(response.data);
        } else {
          alert('Location not found.');
        }
      } catch (error) {
        alert('Failed to search location. Please try again.');
      }
    };
    
    fetchData();
  }, [mapLocationQuery, map, setSearchResults]);
  
  return null;
}

export default NavigationalPlayground;
