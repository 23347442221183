import React, { useState } from "react";
// import "./AddBurnerTimeline.css";

const STEPS = [
  { title: "Select Burner Type" },
  { title: "Upload File" },
  { title: "Confirm Save" },
];

const AddBurnerTimeline = ({ currentIndex }) => {
  const width = window.screen.width;
  let isMobileDevice = width < 600;
  let multiplyvalue = isMobileDevice ? 78 : 86;

  return (
    <div className="up-timeline-container">
      <div className="up-timeline-bar">
        <div
          className={`up-timeline-line ${currentIndex > 0 ? "active" : ""}`}
          style={{
            width: `${(currentIndex / (STEPS.length - 1)) * multiplyvalue}%`,
          }}
        />
        <div className={`up-black-line`} />
        {/* add a black line after timeline to last step here */}
        {STEPS.map((step, index) => (
          <div
            key={index}
            className={`up-timeline-item ${
              index <= currentIndex ? "active" : ""
            }`}
          >
            <div className="up-timeline-circle" />
            <span
              className="up-timeline-label"
              style={{
                width: "50px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {step.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddBurnerTimeline;
