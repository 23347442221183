import React from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import MainWrapper from "../wrapper/MainWrapper";

const TermsAndCompliance = () => {
  const [showModal, setShowModal] = React.useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <MainWrapper>
      <div>
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            {/* <!-- Row --> */}
            <div class="row">
              {/* banner card  */}
              <div className="col-xl-12">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header border-0"></div>
                    <div className="card-body px-0 pt-0">
                      <Container>
                        <Row>
                          <Col>
                            <h2>Terms and Policies around using VortX LBVM</h2>
                            <p>
                              By using our VortX LBVM, you agree to our terms and
                              policies. Please review the following
                              information carefully.
                            </p>
                            <Button variant="primary" onClick={handleShow}>
                              View Terms and Compliance
                            </Button>
                          </Col>
                        </Row>

                        <Modal show={showModal} onHide={handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Terms and Compliance</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <h3>Terms of Use</h3>
                            <p>
                            Updated: August 20, 2024 

  

Terms of use 

Effective: August 08, 2024 (previous version) 

Effective Locations: Global except European Economic Area, Switzerland, or the UK. 

 

Thank you for choosing vortx.ai! 

These Terms of Use govern your utilization of LBVM and other Vortx.AI services tailored for individuals, as well as any related software applications and websites (collectively referred to as “Services”). These Terms create a binding agreement between you and Vortx AI Private Limited, CIN: U72200JH2024PTC023101, a company duly registered under the Companies Act 2013 (18 of 2013), India. Included within these Terms are our Service Terms and crucial provisions for resolving disputes through arbitration. By engaging with our Services, you signify your agreement to these Terms. 

Our Business Terms oversee the use of Vortx.AI’s LBVM Enterprise, our APIs, and additional services aimed at businesses and developers. 

Our Privacy Policy details how we collect and utilize personal information. While not part of these Terms, the Privacy Policy is a critical document that we strongly encourage you to review. 

  

  

 

  

Who we are 

Welcome to Vortx AI Private Limited. We are a dedicated AI research and deployment company, committed to the development of artificial general intelligence (AGI) with a mission to ensure its benefits reach all corners of humanity. At Vortx AI, we are driven by the potential of AI to transform lives and industries, aiming to pioneer advancements that are both innovative and ethical. 

  

For more detailed information about who we are and what we do, please visit our website: https://vortx.ai. Explore our projects, meet our team, and discover how we're working towards creating a future where AI enhances human experience universally. 

  

Registration and access 

Minimum Age Requirements: To use our Services, you must be at least 13 years old, or the minimum age required in your country to consent to use the Services. For individuals under 18, parental or legal guardian consent is required to engage with our Services. 

Account Registration: When registering for an account to access our Services, it is crucial that you provide information that is both accurate and complete. You are responsible for maintaining the confidentiality of your account credentials and are solely responsible for all activities that occur under your account. Sharing your account credentials or allowing others to access your account is strictly prohibited. 

If you are registering or using the Services on behalf of another individual or entity, you must have the authority to agree to these Terms on their behalf. Ensure that all usage complies with our terms and aligns with authorized purposes only. 

  

Using our Services 

What You Can Do: You are granted access to our services provided you adhere strictly to these Terms. In using our Services, it is imperative to comply with all applicable laws, our Sharing & Publication Policy, Usage Policies, and any other documentation or guidelines we provide. 

What You Cannot Do: Our Services must not be used for any illegal, harmful, or abusive activities. Specific prohibitions include, but are not limited to: 

 Rights Infringement: Do not use our Services in a way that infringes, misappropriates, or violates anyone's rights. 

Unauthorized Activities: You are prohibited from modifying, copying, leasing, selling, or distributing any aspect of our Services. 

Reverse Engineering: Attempting to, or assisting others to, reverse engineer, decompile, or discover the source code or underlying algorithms of our Services is forbidden (except where such restrictions are prohibited by law). 

Data Extraction: Automatically or programmatically extracting data or outputs from our Services is not allowed. 

Misrepresentation: Do not falsely claim that the outputs from our Services were human-generated if they were not. 

Service Disruption: Interfering with or disrupting our Services, including circumventing any rate limits or restrictions or bypassing any safety mitigations, is prohibited. 

Competitive Use: Using outputs to develop models that directly compete with Vortx.AI is strictly forbidden. 

Software: Our Services may allow you to download software, such as mobile apps, which may update automatically to ensure you have the latest version. This software may include open-source components governed by their own licenses. 

Corporate Domains: If you register using an email address from an organization, such as your workplace, your account may be integrated into the organization's business account. We will notify you to facilitate this transfer, unless already specified by your organization. Once transferred, your organization’s administrators will have control over your account, including access to and restrictions on your content. 

 Third-Party Services: Our services may incorporate or offer access to third-party software, products, or services ("Third Party Services"), and parts of our Services, like browsing features, may generate outputs from these services ("Third Party Output"). These are governed by their own terms, and Vortx.AI holds no responsibility for Third Party Services or Third-Party Output. 

Feedback: We value your feedback immensely. By providing feedback, you agree that we can use it without any restrictions or compensation to you. 

By adhering to these guidelines, you help ensure a secure and positive experience for all users of Vortx.AI's Services. Thank you for your cooperation and commitment to these terms. 

  

Content 

Your Content : When you use our Services, you may provide input ("Input") and receive output based on that Input ("Output"), collectively referred to as "Content." You are solely responsible for ensuring that your Content complies with applicable laws and these Terms. You affirm that you possess all necessary rights, licenses, and permissions to provide Input to our Services. 

Ownership of Content 

Regarding ownership: 

  

Input: You retain your ownership rights over the Input. 

Output: You own the Output. We assign to you all our rights, title, and interest, if any, in and to the Output. 

Please note, given the nature of artificial intelligence, the Output provided by our Services may not be unique, and similar outputs may be generated for other users. Our assignment of rights does not extend to Output generated for other users or any Third-Party Output. 

Use of Content 

We may utilize your Content to: 

Enhance and maintain our Services 

Develop new features and improvements 

Comply with applicable laws 

Enforce our terms and policies 

Ensure the safety and security of our Services 

Opting Out: If you prefer that we do not use your Content to train our models, you have the option to opt out by following the guidelines provided in this Help Center article. Be aware that opting out may affect the Service's ability to cater specifically to your needs. 

 Accuracy and Reliability: Artificial intelligence and machine learning are continuously advancing, and we strive to improve the accuracy, reliability, safety, and utility of our Services. However, due to the probabilistic nature of machine learning, the Output from our Services might not always accurately represent real people, places, or facts. 

Guidelines for Using Output 

When using our Services, please be mindful: 

 Reliability: Output may not always be accurate and should not be relied upon as the sole source of truth or as a substitute for professional advice. 

Evaluation: Carefully evaluate the accuracy and appropriateness of the Output for your specific use case, which may include human review, before usage or distribution. 

Sensitive Use: Avoid using any Output relating to individuals for decisions that could significantly impact their lives, such as credit, education, employment, housing, insurance, legal, medical, or other substantial decisions. 

Disclaimers 

Our Services might produce Output that is incomplete, incorrect, or offensive, which does not reflect the views of Vortx AI. 

Any reference to third-party products or services in the Output does not imply endorsement or affiliation with Vortx AI. 

By using our Services, you acknowledge and agree to these terms, understanding the nature and limitations of artificial intelligence as applied through our Services. 

Our IP rights 

At Vortx AI Private Limited, we and our affiliates retain complete ownership of all rights, title, and interest in and to our Services. This encompasses a wide range of intellectual property, including but not limited to, all software, technology, content, trademarks, and designs associated with our Services. 

Usage of Our Brand: Your use of the Vortx AI name, logo, or any associated branding must strictly adhere to our Brand Guidelines. Unauthorized use or modification of our intellectual property is strictly prohibited and may result in legal action. 

We encourage you to review our Brand Guidelines carefully to ensure compliance when using our branding or discussing our Services publicly or in private contexts. These guidelines are designed to maintain the integrity and recognition of the Vortx AI brand while allowing for its proper use. 

  

Paid accounts 

Billing: When purchasing any of our  Products or Services, it is essential to provide complete and accurate billing information, including a valid payment method. For those with paid subscriptions, your payment method will be automatically charged at each renewal period until you decide to cancel. All applicable taxes are your responsibility, and we will charge tax where required by law. Should there be any issues with processing your payment, we may temporarily downgrade or suspend your access to our Services until the payment issue is resolved. 

Service Credits: Some Services may be paid for in advance by purchasing service credits. These credits are governed by our Service Credit Terms, which you should review prior to purchase. 

Cancellation: You may cancel your paid subscription at any time via the products. Please note that payments are non-refundable, except as required by law. Our cancellation policy does not override any mandatory local laws that pertain to your rights to cancel. 

  

Pricing Changes: We reserve the right to modify our pricing. Should there be an increase in subscription prices, we will provide you with at least 30 days notice. The new prices will take effect at your next renewal period, allowing you the option to cancel should you disagree with the price increase. 

  

Termination and Suspension 

Termination: You may discontinue use of our Services at any time. We may suspend or terminate your access or delete your account under certain conditions: 

 If there is a breach of these Terms or our Usage Policies. 

To comply with legal obligations. 

If your usage poses a risk or harm to Vortx AI, our users, or others. 

Accounts inactive for over a year may also be terminated, but we will notify you in advance unless the account is a paid one. 

Appeals: If you believe your account has been suspended or terminated in error, you are encouraged to appeal by contacting our Support team via this link (opens in a new window). 

 Discontinuation of Services: Should we decide to discontinue our Services, we will inform you in advance and provide a refund for any services that have been paid for but not used. 

At Vortx AI, we are committed to transparency and fairness in all our billing and account management practices. Should you have any questions or need assistance with your account, please reach out to us at avijeet@vortx.ai. 

  

Disclaimer of warranties 

AS-IS BASIS: OUR SERVICES ARE PROVIDED "AS IS." VORTX AI, ALONG WITH OUR AFFILIATES AND LICENSORS, DISCLAIM ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. WE SPECIFICALLY DISCLAIM WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, NON-INFRINGEMENT, AND QUIET ENJOYMENT, AS WELL AS ANY WARRANTIES ARISING FROM ANY COURSE OF DEALING OR TRADE USAGE. 

SERVICE LIMITATIONS: WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE, OR ENTIRELY SECURE. WE ALSO CANNOT GUARANTEE THAT THE CONTENT WILL NOT BE LOST OR ALTERED DURING USE. 

RISK ACKNOWLEDGMENT: YOU ACKNOWLEDGE AND AGREE THAT ANY USE OF OUTPUTS FROM OUR SERVICE IS AT YOUR SOLE RISK. IT IS YOUR RESPONSIBILITY TO NOT RELY ON THESE OUTPUTS AS THE SOLE SOURCE OF TRUTH OR FACTUAL INFORMATION, NOR SHOULD THEY BE USED AS A SUBSTITUTE FOR PROFESSIONAL ADVICE. 

BY USING OUR SERVICES, YOU ACCEPT THESE DISCLAIMERS AND AGREE TO NOT HOLD VORTX AI RESPONSIBLE FOR ANY INTERRUPTIONS, INACCURACIES, OR POTENTIAL LOSSES RELATED TO THE USE OF OUR SERVICES. YOUR DECISION TO USE OUR SERVICES INDICATES YOUR ACCEPTANCE OF THESE TERMS. 

Limitation of liability 

NEITHER VORTX AI NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 

OUR AGGREGATE LIABILITY UNDER THESE TERMS WILL NOT EXCEED THE GREATER OF THE AMOUNT YOU PAID US FOR THE SERVICE THAT GAVE RISE TO THE CLAIM DURING THE 12 MONTHS PRIOR TO THE LIABILITY ARISING, OR TEN INDIAN RUPEES(Rs.10). 

THE LIMITATIONS IN THIS SECTION APPLY ONLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. 

  

Additional Legal Considerations 

It's important to note that certain jurisdictions do not permit the disclaimer of certain warranties or the limitation of certain damages. Therefore, some or all of the exclusions or limitations outlined above may not apply to you. In these instances, you may have additional rights. Where such exclusions or limitations are restricted or disallowed, the terms outlined here will limit our responsibilities and liabilities only to the maximum extent permitted by the laws of your country of residence. 

Furthermore, VORTX AI’s affiliates, suppliers, licensors, and distributors are intended as third-party beneficiaries of these limitations and disclaimers. This ensures that these provisions apply not just to us, but also to these other parties connected to the services we provide. 

Indemnity Agreement 

If you are a business or organization, to the extent permitted by applicable law, you agree to indemnify and hold harmless Vortx AI, our affiliates, and our personnel from any costs, losses, liabilities, and expenses (including reasonable attorneys’ fees) that arise from third-party claims related to your use of the Services and Content or any breach of these Terms. 

This indemnity covers any legal actions brought against us that are connected to your use of the Services or violations of these Terms, ensuring that we and our affiliated parties are protected from financial and legal repercussions stemming from your actions. 

  

Dispute resolution : YOU AND VORTX.AI AGREE TO THE FOLLOWING MANDATORY ARBITRATION AND CLASS ACTION WAIVER PROVISIONS 

Mandatory Arbitration: You and Vortx AI agree to resolve any disputes arising from these Terms or our Services through final and binding arbitration, irrespective of when the claim arose. You can opt out of arbitration within 30 days of account creation or after any updates to these arbitration terms by completing this form. If you opt out of an update, the last agreed-upon arbitration terms will apply. 

Informal Dispute Resolution: Before initiating formal legal action, both parties agree to attempt to resolve disputes informally. You can start this process by notifying us through mail at avijeet@vortx.ai , or mail physical copies at Vortx AI Private Limited, G1, Abhishek Tower, Lake Road, Upper bazaar, Ranchi GPO, Ranchi - 834001. We will respond using the email address (or address) associated with your account. If the dispute is not resolved within 60 days, either party may initiate arbitration. A statute of limitations will be tolled during this period. An individual settlement conference may also be requested. 

Arbitration Forum: Unresolved disputes will be handled by the relevant Indian Court with arbitration in Ranchi, Jharkhand under its rules, which are accessible here. Vortx AI will not seek attorneys’ fees unless the arbitrator finds your claim frivolous. These proceedings are governed by the Company Laws in India due to the interstate nature of our activities. 

  

Arbitration Procedures: Arbitrations will primarily be conducted via videoconference. However, in-person hearings may be arranged in Ranchi, Jharkhand, or another agreed-upon location if deemed necessary by the arbitrator. A sole arbitrator, who will be either a retired judge or an attorney licensed in Jharkhand, will oversee the process. This arbitrator has the authority to handle all aspects of any dispute. 

Exceptions: Informal resolution or arbitration is not required for individual claims in small claims court or for seeking injunctive relief against unauthorized use or abuse of our Services or for intellectual property infringement. 

Class and Jury Trial Waivers: Disputes must be brought individually and not as part of any class, consolidated, or representative proceeding. Class arbitrations and class actions are not permitted. Only individual relief is available. Both parties waive any right to a jury trial in any related litigation. 

Batch Arbitration: If multiple similar claims are filed, they may be handled in batches by relevant authorities in Jharkhand whenever applicable or within India if it is not possible in Ranchi, with each batch containing no more than 50 claimants. Each batch will be treated as a single arbitration. 

Severability: If any part of these arbitration terms is deemed illegal or unenforceable, the remainder will continue to be enforceable. If partial unenforceability allows class, consolidated, or representative actions, then the entire dispute resolution section will be deemed unenforceable. 

By using our Services, you agree to these dispute resolution terms, reflecting our commitment to resolving disagreements promptly and efficiently. 

Copyright complaints 

If you believe that your intellectual property rights have been infringed, please send notice to the address below or mail to avijeet@vortx.ai. We may delete or disable content that we believe violates these Terms or is alleged to be infringing and will terminate accounts of repeat infringers where appropriate. Additionally, in cases of repeated infringement, we reserve the right to terminate the accounts of repeat offenders in accordance with our policies and applicable laws. 

Vortx AI Private Limited 

G1, Abhishek Tower, Lake Road 

Upper Bazaar, Ranchi 834001 

Attn: General Counsel / Copyright Agent 

  

If you believe that your copyright has been infringed upon on our platform, it is important that your written claim includes the following essential information to allow us to address your concerns effectively: 

Signature: A physical or electronic signature of the person authorized to act on behalf of the owner of the copyright interest. 

Description of the Copyrighted Work: A detailed description of the copyrighted work you claim has been infringed. 

Location of Infringing Material: A precise description of where the allegedly infringing material is located on our site, enabling us to locate the material. 

Contact Information: Your address, telephone number, and email address so that we can contact you about your claim. 

Statement of Good Faith: A statement by you, made under penalty of perjury, that you have a good-faith belief that the disputed use of the material is not authorized by the copyright owner, its agent, or the law. 

Statement of Accuracy: A statement by you that the information provided in the notice is accurate, and under penalty of perjury, that you are the copyright owner or are authorized to act on behalf of the copyright owner. 

Please send your detailed infringement notification to our designated address or submit it through the specified electronic form. Prompt submission of these details will aid in our swift investigation and resolution of the issue. 

General Terms 

Assignment 

You may not assign or transfer any rights or obligations under these Terms, and any attempt to do so is void. However, Vortx AI may assign our rights or obligations to any affiliate, subsidiary, or successor in interest associated with our Services without restriction. 

Changes to Terms and Services 

Vortx AI is dedicated to continuously improving our Services. We may update these Terms or modify our Services in response to various factors, including: 

  

Changes in legal or regulatory requirements. 

Security or safety reasons. 

Events beyond our reasonable control. 

Routine developments in our Services. 

Adaptations to new technologies. 

We will provide at least 30 days' advance notice of any changes to these Terms that materially adversely impact you, either through email or an in-product notification. All other changes will become effective immediately upon posting on our website. If you disagree with the changes, you are required to cease using our Services. 

Enforcement of Terms 

Our failure to enforce any provision of these Terms is not a waiver of our right to do so later. If any portion of these Terms is found to be invalid or unenforceable, that portion will be enforced to the maximum extent permissible, and this will not affect the enforceability of the remaining terms. 

Trade Controls 

You must comply with all applicable trade laws, including sanctions and export control laws. Our Services must not be used in, or benefit, any India embargoed countries or territories, nor may they be exported or re-exported to any individuals or entities prohibited or restricted under applicable trade laws. Furthermore, the Services must not be utilized for any end uses prohibited by applicable laws, and your input must not include materials or information requiring government licensing for release or export. 

Entire Agreement 

These Terms constitute the entire agreement between you and Vortx AI concerning our Services and supersede any prior or contemporaneous agreements. No other agreements, representations, or warranties other than those provided in these Terms will bind Vortx AI. 

Governing Law 

These Terms are governed by the laws of the state of Jharkhand, India, excluding its conflicts of law principles. Any claims arising from or relating to these Terms will be brought exclusively in the courts located in Ranchi, Jharkhand, except as otherwise agreed by the parties or as described in the dispute resolution section. 

  

By continuing to use our Services, you acknowledge that you have read, understood, and agreed to be bound by these General Terms. 
                            </p>
                            <h3>Privacy Policy</h3>
                            <p>
                            Updated: August 20, 2024 

Privacy policy  

Effective: August 08, 2024 

Effective Locations: Global except European Economic Area, Switzerland, or the UK. 

At Vortx AI Private Limited (collectively referred to as "Vortx.AI", "we", "our", or "us"), we hold your privacy in the highest regard. We are deeply committed to securing any personal information we gather from or about you. This Privacy Policy outlines our approach and practices regarding the Personal Information collected from you or about you when you engage with our website, applications, and other services (collectively, "Services"). Please note, this Privacy Policy does not extend to the data processed on behalf of our business clients, such as through our API services. The handling of such data is strictly governed by the customer agreements pertinent to those specific business offerings. 

For information about how we collect and use training information to develop our language models that power Large Bio-Vision Model (LBVM) and other Services, and your choices with respect to that information, please contact us at avijeet@vortx.ai . 

 

Personal Information You Provide: 

Account Information: Creating an account with us means we will collect details such as your name, contact info, account credentials, payment card information, and transaction history. This helps us provide you with personalized and efficient service. 

User Content: Engage with our Services and we will collect the Personal Information you include in any input, file uploads, or feedback you provide. 

Communication Information: Whenever you reach out to communicate with us, we gather your name, contact information, and the details of your messages to ensure responsive and tailored communication. 

Social Media Information: Engaging with our social media pages (Instagram, Facebook, Medium, Twitter, YouTube, and LinkedIn) allows us to collect any Personal Information you choose to provide. Additionally, social media hosts may give us aggregated data and analytics about our interactions. 

Other Information You Provide: Whether you are participating in our events, responding to surveys, or providing identification information, we collect what you share to better tailor our interactions and services. 

Personal Information We Receive Automatically: 

Technical Information: 

Log Data: This includes details sent automatically by your browser or device when you use our Services, such as your IP address, browser type and settings, your testing records, and your interaction details. 

Usage Data: Insights into how you use our Services, including what content you engage with and the features you use, help us refine and improve our offerings. 

Device Information: We capture information about the device you use, its operating system, identifiers, and the browser to ensure our Services function optimally across all platforms. 

Cookies: Essential for service operation and enhancement, cookies help us tailor your experience. You have control over how these are managed through your browser settings, allowing you to accept or reject cookies to suit your preferences. 

Analytics: To continuously enhance your experience, we utilize various analytics tools that help us understand how you use our Services.  

 

2. How we use personal information 

Service Provision and Enhancement: We use your information to provide, administer, maintain, and analyze the efficacy of our Services. This helps us not only to keep the services running smoothly but also to enhance and refine them continually. 

Communication: We stay connected with you through updates about our services, upcoming events, and other communications tailored to meet your needs and interests. 

Innovation: Your information drives our innovation, helping us develop the latest programs and services that cater to evolving user needs. 

Security and Fraud Prevention: We use your information to safeguard our Services against fraud, criminal activities, and misuse. This ensures the protection of our IT systems, architecture, and networks. 

Business Transfers: In the event of a merger, acquisition, or other business transfer, we may use your information as part of the transaction process. 

Legal Compliance: We process your information to comply with legal obligations and processes and to protect our rights, privacy, safety, or property, and that of our affiliates, you, or other third parties. 

Aggregated and De-identified Information: 

Enhanced Insights: We may aggregate or de-identify your Personal Information to ensure it cannot be linked back to you. We use such data to analyze and enhance the effectiveness of our Services, add new features, and conduct various forms of research. 

Sharing Insights: Occasionally, we might analyze general behavior and characteristics of our Service users to improve our offerings. We share aggregated information like general user statistics with third parties, publish such information, or make it widely available. 

Responsible Handling: We maintain de-identified information in an anonymous form and do not attempt to reidentify it unless required by law. 

Leveraging Content for Improvement: 

Service Improvement: We may use content you provide, such as feedback or data inputs, to enhance our Services. This includes training our models that power innovative solutions like LBVM. 

Opt-Out Options: If you prefer not to have your content used for model training, we respect your choice. Please contact us at avijeet@vortx.ai on how you can opt out. 

 

3. Disclosure of personal information 

At Vortx AI Private Limited, we handle your Personal Information with utmost care and discretion. However, there are circumstances where we may share your information with third parties: 

Vendors and Service Providers: We collaborate with various service providers and vendors to manage and enhance our business operations. This may include hosting services, customer service, cloud services, email communications, and web analytics, among others. These partners are authorized to access, process, or store Personal Information solely to perform their assigned functions and are bound by our directives. 

Business Transfers: In the event of a Transaction, such as strategic business decisions, reorganization, bankruptcy, or a service transition to another provider, your Personal Information may be disclosed during the diligence process and transferred to a successor or affiliate as part of that Transaction along with other assets. 

  

Legal Requirements: We may disclose your Personal Information if required by law or in the good faith belief that such action is necessary to: 

Comply with legal obligations. 

Protect and defend our rights or property. 

Ensure compliance with our terms, policies, or applicable laws. 

Detect or prevent fraud or other illegal activities. 

Protect the safety, security, and integrity of our products, employees, users, or the public. 

Protect against legal liability. 

Affiliates: We may share your Personal Information with our affiliates—entities that control, are controlled by, or are under common control with Vortx.AI. Our affiliates must handle this information in line with this Privacy Policy. 

Business Account Administrators: If you are part of an enterprise or business account, such as LBVM Enterprise, administrators of that account may access and control your Vortx.AI account. If your account is created with an email address from your employer or another organization, we may notify your employer or organization about your Vortx.AI account and share certain information like your email address to facilitate your addition to their business account. 

  

Other Users and Third Parties You Share Information With: Some features of our services allow you to share information with other users or third parties. For instance, you might share LBVM conversations and testing intelligence through shared links or send information to third-party applications via custom actions for analytics. It is important to only share your information with users and third parties you trust. 

Your trust is vital to us, and we are committed to ensuring that your Personal Information is disclosed responsibly and in accordance with applicable laws and our high standards of privacy protection.  

4. Your rights 

At Vortx AI Private Limited, we recognize and respect your rights related to your Personal Information. Depending on your location, you may be entitled to certain statutory rights: 

Access: You have the right to access your Personal Information and obtain details about how we process it. 

Deletion: You can request that we delete your Personal Information from our records. 

Rectification: If your information is inaccurate or incomplete, you have the right to have it updated or corrected. 

Data Portability: You have the right to transfer your Personal Information to another organization or directly to you. 

Restriction: You can request that we limit how we process your Personal Information. 

Withdraw Consent: At any time, you can withdraw your consent where we rely on it as the legal basis for processing your Personal Information. 

Objection: You have the right to object to the processing of your Personal Information. 

Lodge a Complaint: You can lodge a complaint with your local data protection authority if you feel your rights are being infringed. 

Exercising Your Rights: To exercise any of these rights, you can directly make changes through your Vortx.AI account, or if your needs go beyond what can be handled through the account, please reach out to us at avijeet@vortx.ai . 

A Note on Accuracy: 

Our services, such as LBVM, generate responses by predicting words, images, and videos likely to follow from a user's input. This means that sometimes the output may not be factually accurate. We encourage you not to rely solely on the factual accuracy of the responses from our models. If you find any information about you in the LBVM output to be factually incorrect, you can request a correction through avijeet@vortx.ai or by submitting a correction request directly to avijeet@vortx.ai. Due to the complexity of our models, while we strive to correct inaccuracies, it may not always be possible. In such cases, you may request the removal of your Personal Information from LBVM’s outputs by contacting us at avijeet@vortx.ai . 

For further guidance on how to assert your rights over data we collect from the internet to train our models, please contact us at avijeet@vortx.ai . 

At Vortx AI, we are committed to ensuring that you have full control over your Personal Information and understand how to exercise your rights effectively. 

  

5. Additional U.S. State Disclosures 

  

At Vortx AI, we are committed to transparency about the Personal Information we collect, how it is used, and how it is shared. Below you will find detailed disclosures categorized by the type of Personal Information we handle. 

  

Categories of Personal Information and Disclosure Practices 

Identifiers such as your name, contact details, testing details, IP address, and other device identifiers: 

Disclosure: Shared with our affiliates, vendors, service providers, law enforcement, third parties for legal reasons, involved parties in Transactions, corporate administrators of enterprise or team accounts, and other users and third parties at your discretion. 

Commercial Information such as your transaction history: 

Disclosure: Shared with our affiliates, vendors and service providers, law enforcement, third parties for legal reasons, and involved parties in Transactions. 

Network Activity Information such as your interactions with our Services and the Content you engage with: 

Disclosure: Shared with our affiliates, vendors and service providers, law enforcement, third parties for legal reasons, involved parties in Transactions, and other users and third parties at your discretion. 

  

Geolocation Data: 

Disclosure: Shared with our affiliates, vendors and service providers, law enforcement, third parties for legal reasons, and involved parties in Transactions. 

Sensitive Personal Information such as your account login credentials and payment card information: 

Disclosure: Shared with our affiliates, vendors and service providers, law enforcement, and involved parties in Transactions. 

Your Privacy Rights 

Under local laws, and subject to applicable exceptions, you may have the following rights regarding your Personal Information: 

  

Right to Know: Access specific pieces of Personal Information we have collected about you. 

Right to Delete: Request deletion of your Personal Information from our records. 

Right to Correct: Update inaccuracies in your Personal Information. 

Non-Discrimination: Exercise your privacy rights without discrimination. 

Our Commitment 

Data Sale and Sharing: We do not "sell" or "share" Personal Information for cross-contextual behavioral advertising as defined under applicable laws. Also, we do not process sensitive Personal Information to infer characteristics about a consumer. 

Exercising Your Rights 

Making a Request: You can exercise your privacy rights by submitting a request at avijeet@vortx.ai or by contacting us at avijeet@vortx.ai . 

Verification: To protect your data, we require verification of your identity before processing requests. This may include verifying your credentials, or, in cases of suspected fraudulent or malicious activity, additional information and proof of residency. 

Authorized Agents: You may use an authorized agent to submit requests on your behalf. Agents must provide signed permission, and you may need to verify your identity and residency. 

Appeals: Should you disagree with a decision we make, you have the right to appeal by contacting us at avijeet@vortx.ai . 

At Vortx AI, your trust is paramount. We strive to ensure that your Personal Information is handled with care and respect, aligning with both our ethical standards and legal obligations. If you have questions or need further clarification, our team is ready to assist you. 

 

6. Children 

At Vortx.AI, we are committed to safeguarding the privacy of our younger users. Our Services are not intentionally designed for or directed at children under the age of 13. In line with this commitment: 

Underage Privacy: Vortx.AI does not knowingly collect or solicit Personal Information from children under the age of 13. If we learn that we have collected Personal Information from a child under 13 without verification of parental consent, we will take steps to remove that information from our servers promptly. 

Parental Notification: If you believe that a child under 13 has provided us with Personal Information without parental or guardian consent, please contact us immediately at avijeet@vortx.ai . We take such concerns seriously and will promptly investigate and remove any inadvertently collected information. 

Teens' Use of Our Services: If you are aged 13 or older but under 18, you must have permission from your parent or guardian to access and use our Services. We encourage families to discuss their household internet policies and to monitor their children's use of the internet actively. 

Vortx.AI is dedicated to complying with all applicable laws and regulations regarding the collection of Personal Information from children. We aim to provide a safe and secure environment for all our users. 

 We collect information globally, primarily storing it in India and the USA. Information collected within the European Economic Area ("EEA") may be transferred to countries outside of the EEA under data protection measures. 

 

7. Links to other websites 

At Vortx.AI, our Service may offer links to external websites not under our operation or control, including various social media platforms ("Third Party Sites"). These links are provided for your convenience and to enhance your experience with our Service. 

Privacy Beyond Vortx.AI: 

Privacy Policies: It is important to note that any Personal Information you provide on Third Party Sites is not covered by our Privacy Policy. Instead, your data will be subject to the privacy policies and terms of service of the respective Third-Party Sites. 

Independence from Third Party Sites: The presence of links to Third Party Sites does not constitute an endorsement, sponsorship, or recommendation of their content, products, or services. We do not review these websites and are not responsible for their content or privacy practices. 

Due Diligence: We encourage you to review the privacy policies and terms of service of any Third-Party Sites before sharing your Personal Information. This will help you make an informed decision about the safety and suitability of sharing your data. 

By linking to these Third-Party Sites, Vortx.AI aims to provide you with choices and additional information that may be beneficial to you. However, we emphasize the importance of privacy and the need to stay informed about how various sites handle your personal data. If you have any questions about how these Third-Party Sites manage privacy, please reach out to them directly. 

  

8. Security and retention 

At Vortx.AI, we prioritize the security of your Personal Information. We are committed to protecting your data from loss, misuse, and unauthorized access through the implementation of robust technical, administrative, and organizational measures. Despite our efforts, it is important to remember that no method of transmission over the Internet or method of electronic storage is 100% secure. Email communications may not be secure, so we advise caution regarding the information you send us via email or through our Service. 

Security Practices 

Data Protection: We use commercially reasonable security measures to safeguard your Personal Information across all our platforms, both online and offline. 

Vulnerability Awareness: Please be aware that while we strive to protect your Personal Information, factors beyond our control may affect security. For example, our privacy settings and security measures may be circumvented, and third-party websites linked to our Service may have different security practices. 

Data Retention Policy 

Retention Period: We retain your Personal Information only for as long as necessary to provide you with our Service and for legitimate business purposes. These purposes include resolving disputes, maintaining security, and complying with our legal obligations. 

Retention Factors: The duration for which we keep your information depends on several factors, including: 

Nature and Sensitivity: The amount, nature, and sensitivity of the data. 

Risk of Harm: The potential risk of harm from unauthorized use or disclosure of your data. 

Processing Purposes: Our specific purposes for processing your information. 

Legal Requirements: Applicable legal, tax, or regulatory requirements that specify how long certain types of data must be retained. 

As we navigate the complexities of data protection, we remain committed to upholding the highest standards of privacy and security. If you have questions about our security practices or how long we retain your data, please contact us. We encourage you to remain vigilant and informed about your digital privacy rights and our ongoing efforts to protect your Personal Information. 

  

9. International users 

At Vortx.AI, we serve users globally, and we want you to be informed about the handling of your Personal Information, regardless of where you are located. 

Global Data Processing and Storage 

International Processing: By using our Service, you acknowledge that your Personal Information will be processed and stored in our facilities in the United States. Additionally, we may disclose your information to our service providers and affiliates in other jurisdictions. 

Data Protection Officer: For any inquiries related to the processing of your Personal Information, you can contact our Data Protection Officer at privacy@Vortx.AI.com. 

Legal Bases for Processing 

Performance of a Contract: We process your Personal Information such as Account Information, Content, and Technical Information to fulfill our contractual obligations and provide you with our Services. Without this information, our ability to deliver our Services might be impacted. 

Legitimate Interests: We process your Personal Information based on our legitimate interests in protecting our Services from fraud, abuse, or security risks, and in developing, improving, or promoting our Services. This includes training our models with the data we collect. For instructions on how to opt out of our use of your information for model training, please refer to our instructions. 

Consent: For certain processes, we rely on your consent, which we will seek at the time we collect your Personal Information. You have the right to withdraw your consent at any time. 

Legal Obligations: We may also use your Personal Information to comply with legal obligations or to protect the rights, safety, and property of Vortx.AI, our affiliates, our users, or third parties. 

Data Transfers 

Safe Transfers: We commit to transferring Personal Information outside of certain countries using legally valid transfer mechanisms to ensure the data is handled with the same level of protection it would receive in your home country. 

Safeguards: Where required, we implement appropriate safeguards to ensure the lawful transfer of Personal Information across borders. 

At Vortx.AI, we understand the importance of your privacy and are committed to transparent and responsible data handling practices. We respect international data protection laws and strive to ensure that your Personal Information is treated securely and in accordance with this privacy policy. Should you have questions or need more clarification, please contact us at avijeet@vortx.ai . 

10. Changes to the privacy policy 

We may update this Privacy Policy sometimes. When we do, we will post an updated version on this page, unless another type of notice is required by applicable law. 

11. How to contact us 

Please contact avijeet@vortx.ai  if you have any questions or concerns not already addressed in this Privacy Policy. 
                            </p>
                            
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

export default TermsAndCompliance;
