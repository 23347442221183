import { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { toast } from "react-toastify";
import KeysModal from "./KeysModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
var CryptoJS = require("crypto-js");

function PrivateKeyPasswordModal({
  openModal,
  setOpenModal,
  setOpenPreviousModal,
  setup,
  passphrase,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passWord, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openKeysModal, setOpenKeysModal] = useState(false);

  const width = window.screen.width;
  const appCtx = useSelector((state) => state.app);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: "#FFFFFF",

      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      border: `${appCtx?.isDarkMode ? "#FFFFFF" : "#FFFFFF"}`,
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: "400px",
      padding: "20px",
    },
  };

  function closeModal() {
    setOpenModal(false);
    // setOpenPreviousModal(true);
  }
  const handlePassword = () => {
    if (passWord) {
      let ciphertext = CryptoJS.SHA256(JSON.stringify(passWord)).toString();

      if (ciphertext === appCtx.password) {
        setOpenKeysModal(true);
        closeModal();
      } else {
        toast.error("please enter correct password");
      }
    }
  };

  const themedColor = "black";
  return (
    <>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="flex flex-col justify-center items-center lg:px-[20px]">
          <div className="w-100 d-flex justify-content-center align-items-center">
            <img
              src="/images/talelogo.png"
              className=""
              style={{ width: "100px", height: "100px" }}
              alt="tale_logo"
            />
          </div>

          <div className="">
            <>
              <div className="font-medium mb-2">Enter Your Password</div>
              <div>
                <div className="input-group">
                  <input
                    className="form-control border rounded ps-3"
                    type={showPassword ? "text" : "password"}
                    placeholder={"Enter your password here"}
                    value={passWord}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span className="input-group-text">
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{
                        color: themedColor,
                      }}
                    />
                  </span>
                </div>
              </div>
            </>
            <div className="d-flex justify-content-center pb-2 pt-3">
              <button
                className="btn btn-primary d-flex justify-content-center align-items-center gap-2"
                onClick={() => {
                  handlePassword();
                }}
              >
                <div>Submit</div>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <KeysModal openModal={openKeysModal} setOpenModal={setOpenKeysModal} />
    </>
  );
}

export default PrivateKeyPasswordModal;
