import React, { useState, useMemo, useEffect } from "react";
import MainWrapper from "../wrapper/MainWrapper";
import { Row, Col, Card, Button, Table, Badge, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHistory,
  faCreditCard,
  faDownload,
  faEye,
  faCheck,
  faTrash,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import MuiCard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import axios from "axios";

const Billing = () => {
  const [activeTab, setActiveTab] = useState("billingHistory");
  const [showPayModal, setShowPayModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [billingHistory, setBillingHistory] = useState([]);
  const [totalAmountDue, setTotalAmountDue] = useState(0);

  // Fetch user ID from local storage
  const userId = localStorage.getItem("userId");

  // Fetch billing history from API
  const fetchBillingHistory = async () => {
    try {
      const response = await axios.get(`https://backend.vortx.ai/user/${userId}/billing`, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": process.env.REACT_APP_AUTH_TOKEN || "46eba9763c91dc8241437f1a307eab0a",
        },
      });
      setBillingHistory(response.data);

      // Calculate total unpaid amount
      const unpaidTotal = response.data
        .filter((invoice) => invoice.status !== "Paid")
        .reduce((acc, invoice) => acc + parseFloat(invoice.amount), 0);
      setTotalAmountDue(unpaidTotal);
    } catch (error) {
      console.error("Error fetching billing history:", error);
    }
  };

  useEffect(() => {
    if (userId) fetchBillingHistory();
  }, [userId]);

  const handlePayInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    setShowPayModal(true);
  };

  const processPayment = () => {
    const options = {
      key: "YOUR_RAZORPAY_KEY_ID", // Replace with your Razorpay key ID
      amount: selectedInvoice.amount * 100, // Convert to smallest currency unit (paise for INR)
      currency: "INR",
      name: "Vortx.ai",
      description: `Payment for Invoice #${selectedInvoice.invoiceNumber}`,
      handler: (response) => {
        console.log("Payment successful:", response);
        setShowPayModal(false);
        // Optionally update the invoice status to "Paid" in your backend
      },
      prefill: {
        email: "customer@example.com",
        contact: "9876543210",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };

  const renderContent = () => {
    if (activeTab === "billingHistory") {
      return (
        <div className="col-12">
          <MuiCard className="mb-4">
            <CardContent>
              <Typography variant="h6" component="div">
                Total Amount Due
              </Typography>
              <Typography variant="h4" component="div">
                ${totalAmountDue.toFixed(2)}
              </Typography>
            </CardContent>
          </MuiCard>
          <h4 className="mb-4">
            <FontAwesomeIcon icon={faHistory} className="me-2" />
            Billing History
          </h4>
          <Card className="shadow-sm border-0">
            <Card.Body>
              <Table hover responsive className="mb-0">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Invoice Number</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th className="text-end">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {billingHistory.map((invoice) => (
                    <tr key={invoice.id}>
                      <td>{invoice.date}</td>
                      <td>{invoice.invoiceNumber}</td>
                      <td>${invoice.amount}</td>
                      <td>
                        <Badge bg={invoice.status === "Paid" ? "success" : "warning"}>
                          {invoice.status}
                        </Badge>
                      </td>
                      <td className="text-end">
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => alert(`Viewing invoice ${invoice.invoiceNumber}`)}
                        >
                          <FontAwesomeIcon icon={faEye} /> View
                        </Button>
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => alert(`Downloading invoice ${invoice.invoiceNumber}`)}
                        >
                          <FontAwesomeIcon icon={faDownload} /> Download
                        </Button>
                        {invoice.status !== "Paid" && (
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => handlePayInvoice(invoice)}
                          >
                            <FontAwesomeIcon icon={faCreditCard} /> Pay
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      );
    }
    return null;
  };

  return (
    <MainWrapper>
      <div className="container-fluid mt-4" style={{ paddingBottom: "70px" }}>
        {/* Page Header */}
        <Row className="mb-4">
          <Col xs={12}>
            <h2 className="fw-bold">Billing Dashboard</h2>
          </Col>
        </Row>

        {/* Navigation Tabs with Icons */}
        <Row className="mb-4">
          <Col xs={12}>
            <ul className="nav nav-pills">
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === "billingHistory" ? "active" : ""}`}
                  onClick={() => setActiveTab("billingHistory")}
                >
                  <FontAwesomeIcon icon={faHistory} className="me-2" />
                  Monthly Billing Cycle
                </button>
              </li>
            </ul>
          </Col>
        </Row>

        {/* Main Content */}
        <Row>{renderContent()}</Row>

        {/* Pay Invoice Modal */}
        <Modal show={showPayModal} onHide={() => setShowPayModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Pay Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedInvoice && (
              <div>
                <p>
                  <strong>Invoice Number:</strong> {selectedInvoice.invoiceNumber}
                </p>
                <p>
                  <strong>Amount Due:</strong> ${selectedInvoice.amount}
                </p>
                <Form>
                  <Form.Group controlId="paymentMethod" className="mb-3">
                    <Form.Label>Select Payment Method</Form.Label>
                    <Form.Select>
                      <option value="card">Credit/Debit Card</option>
                      <option value="paypal">PayPal</option>
                      <option value="crypto">Crypto Wallet</option>
                    </Form.Select>
                  </Form.Group>
                  <Button variant="primary" onClick={processPayment}>
                    Pay Now
                  </Button>
                </Form>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </MainWrapper>
  );
};

export default Billing;
