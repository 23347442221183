import { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import "./addDataFileUpload.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp, faFileImage } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import uploadFilesService from "../KYC_verification/services/upload-files.service";

const AddDataFileUpload = ({ setCurrentIndex }) => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [fileInfos, setFileInfos] = useState([]);

  const appCtx = useSelector((state) => state.app);

  const upload = async () => {
    if (!selectedFiles) return;

    let currentFile = selectedFiles[0];
    setCurrentFile(currentFile);
    setProgress(0);

    try {
      await uploadFilesService.upload(currentFile, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      });
      setMessage("File uploaded successfully.");
      const response = await uploadFilesService.getFiles();
      setFileInfos(response.data);
    } catch (error) {
      setProgress(0);
      setMessage("Could not upload the file!");
      setCurrentFile(undefined);
      console.error("Error uploading file:", error);
    }

    setSelectedFiles(undefined);
  };

  const onDrop = (files) => {
    setMessage("");
    if (files.length > 0) {
      setCurrentIndex(0);
      setSelectedFiles(files);
    }
  };
  const themedColor = "black";
  const themedCardBgColor = `${appCtx?.isDarkMode ? "#fafafa2" : "#fafafa"}`;

  return (
    <div className="px-0 px-md-4 w-100 w-md-50 mb-3 mb-md-5 pb-4">
      {currentFile && (
        <div className="progress mb-3">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}

      <Dropzone onDrop={onDrop} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps({ className: "dropzone" })}
              style={{
                backgroundColor: themedCardBgColor,
              }}
            >
              <input {...getInputProps()} />
              {selectedFiles && selectedFiles[0].name ? (
                <div className="selected-file minheight">
                  <FontAwesomeIcon
                    icon={faFileImage}
                    style={{
                      color: themedColor,
                      fontSize: "30px",
                    }}
                  />
                  <h5 className="my-2">
                    {selectedFiles && selectedFiles[0].name}
                  </h5>
                </div>
              ) : (
                <div className="">
                  <FontAwesomeIcon
                    icon={faCloudArrowUp}
                    style={{ color: themedColor, fontSize: "30px" }}
                  />
                  <h3>Add your document</h3>
                  <p className="">
                    Upload a document that supports: JPG, PNG, PDF
                  </p>
                  <div className="">
                    <p className="cursor-pointer border rounded-lg p-2">
                      Drag and drop file here, or click to select file
                    </p>
                  </div>
                </div>
              )}
            </div>
            {selectedFiles && (
              <div className="selected-file-wrapper mb-5">
                <button
                  className="btn btn-upload px-5 py-1 rounded"
                  onClick={() => {
                    setCurrentIndex(1);
                    upload();
                  }}
                >
                  Upload
                </button>
              </div>
            )}
          </section>
        )}
      </Dropzone>
      {message && (
        <div className="alert alert-light mt-2 text-danger" role="alert">
          {message}
        </div>
      )}

      {fileInfos.length > 0 && (
        <div className="card ">
          <div className="card-header">List of Files</div>
          <ul className="list-group list-group-flush">
            {fileInfos.map((file, index) => (
              <li className="list-group-item" key={index}>
                <a href={file.url}>{file.name}</a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AddDataFileUpload;
