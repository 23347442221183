import React, { useState, useEffect } from "react";
import MainWrapper from "../../component/wrapper/MainWrapper";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Avatar,
  Button,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Tooltip,
  Autocomplete,
  TextField,
  Chip,
  Badge,
  IconButton,
} from "@mui/material";
import {
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
  Memory as MemoryIcon,
  Assignment as AssignmentIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Storage as StorageIcon,
  SwapHoriz as SwapHorizIcon,
  Settings as SettingsIcon,
  Devices as DevicesIcon,
  GpsFixed as GpsFixedIcon,
  CalendarToday as CalendarTodayIcon,
  Notifications as NotificationsIcon,
  AccountCircle as AccountCircleIcon,
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
} from "@mui/icons-material";
import SummaryTab from "./SummaryTab";
import WorkflowTab from "./WorkflowTab";
import ConnectionsTab from "./ConnectionsTab";
import ArtifactsTab from "./ArtifactsTab";
import TransactionsTab from "./TransactionsTab";
import SettingsTab from "./SettingsTab";
import AddDataModal from "../../component/pojectsComponents/addDataModal/AddDataModal";
import AddSequesterModal from "../../component/pojectsComponents/addSequesterModal/AddSequesterModal";
import AddBurnerModal from "../../component/pojectsComponents/addBurnerModal/AddBurnerModal";
import "../../css/style.css";
import AoITab from "./AoITab";
import CalendarTab from "./CalendarTab";
import { CalendarIcon, MapIcon, MapPinIcon } from "lucide-react";

const ProjectsDetails = () => {
  const { projectId } = useParams(); // Get projectId from URL parameters



  const location = useLocation();
  const navigate = useNavigate();

  const [selectedProject, setSelectedProject] = useState(
    location.state?.selectedProject || null
  );
  const [allProjects, setAllProjects] = useState(
    location.state?.allProjects || []
  );
  const [selectedTab, setSelectedTab] = useState("workflow");
  const [openAddDataModal, setOpenAddDataModal] = useState(false);
  const [openAddSequesterModal, setOpenAddSequesterModal] = useState(false);
  const [openAddBurnerModal, setOpenAddBurnerModal] = useState(false);
  const [notifications, setNotifications] = useState(3);
  const [darkMode, setDarkMode] = useState(false);



  useEffect(() => {
    if (!selectedProject) {
      // Fallback in case the project is not in state (e.g., user directly accesses the URL)
      const project = allProjects.find((p) => p.id === Number(projectId));
      setSelectedProject(project);
    }
  }, [projectId, selectedProject, allProjects]);

  const handleSelectProject = (project) => {
    // Update selected project
    setSelectedProject(project);
    // Update the URL to reflect the new project selection
    navigate(`/projects/${project.id}`, {
      state: {
        selectedProject: project,
        allProjects,
      },
    });
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    // Implement theme switching logic here
  };

  return (
    <MainWrapper>
      <Box sx={{ padding: 2 }}>
        {/* Top App Bar */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h4"> </Typography>
          <Box display="flex" alignItems="center">

          </Box>
        </Box>

        {/* Top Section with Buttons */}
        <Paper elevation={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            flexWrap="wrap"
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src="/images/food-donation.gif"
                alt="Project"
                sx={{ width: 45, height: 45, mr: 2 }}
              />
              <Box>
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" component="div" sx={{ mr: 2 }}>
                    {selectedProject ? selectedProject.name : "Select a Project"}
                  </Typography>
                  {selectedProject && (
                    <Chip label="Active" color="success" size="small" />
                  )}
                </Box>
                <Typography variant="body2">
                  {selectedProject?.description}
                </Typography>
                {/* Project Stats */}
                {selectedProject && (
                  <Box display="flex" alignItems="center" gap={2} mt={1}>

                  </Box>
                )}
              </Box>
            </Box>
            <Box display="flex" gap={2} alignItems="center" flexWrap="wrap">

            <Autocomplete
              options={allProjects}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              value={selectedProject || null}
              onChange={(event, newValue) => {
                // Check if newValue is null or undefined before calling handleSelectProject
                if (newValue) {
                  handleSelectProject(newValue);
                } else {
                  console.error("No project selected or value is null");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Project"
                  variant="outlined"
                />
              )}
            />

            </Box>
          </Box>
        </Paper>

        {/* Tab Navigation Bar */}
        <Box sx={{ mt: 4 }}>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTab-root": {
                minWidth: 120,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                },
              },
            }}
          >
            {/* <Tab
              value="summary"
              label={
                <Box display="flex" alignItems="center">
                  <InsertDriveFileIcon sx={{ mr: 1 }} />
                  Stats
                </Box>
              }
            /> */}
            <Tab
              value="workflow"
              label={
                <Box display="flex" alignItems="center">
                  <AssignmentIcon sx={{ mr: 1 }} />
                  Workflow
                </Box>
              }
            />
            <Tab
              value="connections"
              label={
                <Box display="flex" alignItems="center">
                  <DevicesIcon sx={{ mr: 1 }} />
                  Connections
                </Box>
              }
            />
            <Tab
              value="artifacts"
              label={
                <Box display="flex" alignItems="center">
                  <StorageIcon sx={{ mr: 1 }} />
                  Artifacts
                </Box>
              }
            />
            <Tab
              value="aoi"
              label={
                <Box display="flex" alignItems="center">
                  <GpsFixedIcon sx={{ mr: 1 }} />
                  Geo Coverage
                </Box>
              }
            />
            <Tab
              value="calendar"
              label={
                <Box display="flex" alignItems="center">
                  <CalendarTodayIcon sx={{ mr: 1 }} />
                  Calendar
                </Box>
              }
            />
            <Tab
              value="settings"
              label={
                <Box display="flex" alignItems="center">
                  <SettingsIcon sx={{ mr: 1 }} />
                  Settings
                </Box>
              }
            />
            {/* <Tab
              value="transactions"
              label={
                <Box display="flex" alignItems="center">
                  <SwapHorizIcon sx={{ mr: 1 }} />
                  Transactions
                </Box>
              }
            /> */}
          </Tabs>
          <Box mt={2}>
            {/* {selectedTab === "summary" && (
              <SummaryTab projectId={selectedProject?.id} />
            )} */}
            {selectedTab === "workflow" && (
              <WorkflowTab projectId={selectedProject?.id} />
            )}
            {selectedTab === "connections" && (
              <ConnectionsTab projectId={selectedProject?.id} />
            )}
            {selectedTab === "artifacts" && (
              <ArtifactsTab projectId={selectedProject?.id} />
            )}
            {/* {selectedTab === "transactions" && (
              <TransactionsTab projectId={selectedProject?.id} />
            )} */}
            {selectedTab === "aoi" && (
              <AoITab projectId={selectedProject?.id} />
            )}
            {selectedTab === "calendar" && (
              <CalendarTab projectId={selectedProject?.id} />
            )}
            {selectedTab === "settings" && (
              <SettingsTab projectId={selectedProject?.id} />
            )}
          </Box>
        </Box>
      </Box>

      {/* Modals */}
      {openAddDataModal && (
        <AddDataModal
          openModal={openAddDataModal}
          setOpenModal={setOpenAddDataModal}
          projectId={selectedProject?.id}
        />
      )}
      {openAddSequesterModal && (
        <AddSequesterModal
          openModal={openAddSequesterModal}
          setOpenAddSequesterModal={setOpenAddSequesterModal}
          projectId={selectedProject?.id}
        />
      )}
      {openAddBurnerModal && (
        <AddBurnerModal
          openModal={openAddBurnerModal}
          setOpenAddSequesterModal={setOpenAddBurnerModal}
          projectId={selectedProject?.id}
        />
      )}
    </MainWrapper>
  );
};

export default ProjectsDetails;
