import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrashAlt, faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';

const SettingsTab = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");

  // Retrieve the project name from local storage
  const initialProjectName = localStorage.getItem("selectedProjectName") || "";

  const [name, setName] = useState(initialProjectName); // Initialize with local storage value
  const [description, setDescription] = useState("");
  const [showDeleteSection, setShowDeleteSection] = useState(false);

  // Fetch current project details on component mount
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`https://backend.vortx.ai/projects/${projectId}`, {
          headers: { 'X-Auth-Token': authToken }
        });
        const project = response.data;
        setName(project.name || initialProjectName); // Update if fetched name is different
        setDescription(project.description || "");
      } catch (error) {
        console.error("Error fetching project details:", error.response);
      }
    };

    fetchProject();
  }, [projectId, authToken, initialProjectName]);

  const handleSave = async (e) => {
    e.preventDefault();
    const apiUrl = `https://backend.vortx.ai/projects/${projectId}`;
    const projectData = {
      name,
      description,
    };

    try {
      await axios.post(apiUrl, projectData, {
        headers: { 'X-Auth-Token': authToken }
      });
      localStorage.setItem("selectedProjectName", name); // Update local storage
      alert('Project updated successfully!');
    } catch (error) {
      console.error("Error updating project:", error.response);
      if (error.response && error.response.data && error.response.data.message) {
        alert(`Failed to update project: ${error.response.data.message}`);
      } else {
        alert('Failed to update project.');
      }
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this project? This action cannot be undone.")) {
      const apiUrl = `https://backend.vortx.ai/projects/${projectId}`;
      try {
        await axios.delete(apiUrl, {
          headers: { 'X-Auth-Token': authToken }
        });
        alert('Project deleted successfully!');
        navigate('/projects'); // Navigate to the projects page after successful deletion
      } catch (error) {
        console.error("Error deleting project:", error.response);
        alert('Failed to delete project.');
      }
    }
  };

  return (
    <Card className="p-3">
      <h4>Settings</h4>
      <p>Configure your project settings.</p>

      <Form onSubmit={handleSave}> {/* Consolidate forms into one */}
        <Row className="mt-4">
          <Col md={6} className="mb-4">
            <Card className="shadow-sm h-100">
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faBoxOpen} className="me-2" />
                  Project Information
                </Card.Title>
                <Form.Group className="mb-3" controlId="formProjectName">
                  <Form.Label>Project Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter project name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <Form.Text className="text-muted">
                    Project names must be unique.
                  </Form.Text>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="mb-4">
            <Card className="shadow-sm h-100">
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faBoxOpen} className="me-2" />
                  Project Description
                </Card.Title>
                <Form.Group className="mb-3" controlId="formProjectDescription">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="A brief description of the project."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className="d-flex justify-content-end">
          <Button variant="success" type="submit">
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Save Changes
          </Button>
        </div>
      </Form>

      <div className="mt-4">
        <Button
          variant="danger"
          onClick={() => setShowDeleteSection(!showDeleteSection)}
          aria-controls="delete-confirmation"
          aria-expanded={showDeleteSection}
        >
          <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
          Delete Project
        </Button>
        <CSSTransition
          in={showDeleteSection}
          timeout={300}
          classNames="collapse"
          unmountOnExit
        >
          <Card className="mt-3 p-3 bg-light">
            <p>Are you sure you want to delete this project? This action cannot be undone.</p>
            <div className="d-flex justify-content-end">
              <Button variant="secondary" className="me-2" onClick={() => setShowDeleteSection(false)}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Confirm Delete
              </Button>
            </div>
          </Card>
        </CSSTransition>
      </div>
    </Card>
  );
};

export default SettingsTab;
