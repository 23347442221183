import { Edit } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { KycHeaderPannel } from "./KycHeaderPannel";
import { Button } from "react-bootstrap";
import MainWrapper from "../wrapper/MainWrapper";

export const KycStatus = () => {
  const navigate = useNavigate();
  const appCtx = useSelector((state) => state.app);
  const makeRequest = useAuthorizedHttp();
  const handleDocumentUpdate = () => {
    let url = `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/kyc`;

    makeRequest(
      {
        // data: data,
        url: url,
        method: "get",
        headers: {
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
          "X-Auth-Token": appCtx.authToken,
          "Content-Type": "application/json",
        },
      },
      (data) => {
        const payload = {
          country: appCtx.country,
          document: `${appCtx?.document} Front`,
          documentUrl: appCtx.frontSide,
          // "id": data?.id,
          otherDocument: `${appCtx?.document} Back`,
          otherDocumentUrl: appCtx.backSide,
          selfieUrl: appCtx.picture,
          userId: appCtx.userDetails.userId,
          verified: false,
          rejectionReason: null,
        };
        if (data) {
          toast.promise(
            () =>
              makeRequest(
                {
                  data: payload,
                  url: `${url}?reUpload=true`,
                  method: "PUT",
                  headers: {
                    "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
                    "X-Auth-Token": appCtx.authToken,
                    "Content-Type": "application/json",
                  },
                },
                (data) => {
                  navigate("/kyc/information");
                }
              ),

            {
              pending: "Registering you...",
              success: "You have successfully registered!",
              error: "Looks like there was a problem!",
            }
          );
        } else {
          toast.promise(
            () =>
              makeRequest(
                {
                  data: payload,
                  url: url,
                  method: "POST",
                  headers: {
                    "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
                    "X-Auth-Token": appCtx.authToken,
                    "Content-Type": "application/json",
                  },
                },
                (data) => {
                  navigate("/kyc/information");
                }
              ),

            {
              pending: "Registering you...",
              success: "You have successfully registered!",
              error: "Looks like there was a problem!",
            }
          );
        }
      },
      (error) => {}
    );
  };
  return (
    <MainWrapper>
      <div className={`${appCtx?.isDarkMode ? "text-black" : "text-black"}`}>
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            {/* <!-- Row --> */}
            <div class="row">
              {/* banner card  */}
              <div className="col-xl-12">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header border-0"></div>
                    <div className="card-body px-0 pt-0">
                      <div className="d-flex flex-column justify-content-center align-items-center pb-5 gap-4 mt-5 px-4">
                        <KycHeaderPannel
                          header="KYC Verification Status"
                          document={true}
                          selfie={true}
                        />
                        <div>
                          Please check the information below to make sure
                          everything is correct
                        </div>
                        <div className="d-flex flex-column w-100 justify-content-center align-items-center gap-5 lg min-h-300 py-2">
                          <div className="fs-30 fw-medium">
                            Identity Documents
                          </div>
                          <div className="d-flex justify-content-center gap-5 w-100">
                            <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                              <div>
                                {appCtx.frontSide ? (
                                  <img
                                    src={appCtx.frontSide}
                                    alt="idcard"
                                    style={{
                                      width: "200px",
                                      height: "200px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src="/KYC/status1.svg"
                                    alt="idcard"
                                    style={{
                                      width: "200px",
                                      height: "200px",
                                    }}
                                  />
                                )}
                              </div>
                              <div className="d-flex gap-3">
                                <div className="fs-20">Document</div>
                                <div>
                                  <Edit
                                    className="cursor-pointer"
                                    onClick={() => {
                                      navigate("/kyc/upload/document");
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                              <div>
                                {appCtx.picture ? (
                                  <img
                                    src={appCtx.picture}
                                    alt="idcard"
                                    style={{
                                      width: "200px",
                                      height: "200px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src="/KYC/status2.svg"
                                    alt="idcard"
                                    style={{
                                      width: "200px",
                                      height: "200px",
                                    }}
                                  />
                                )}
                              </div>
                              <div className="d-flex gap-3">
                                <div className="fs-20">Selfie</div>
                                <div>
                                  <Edit
                                    className="cursor-pointer"
                                    onClick={() => {
                                      navigate("/kyc/upload/selfie");
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <Button
                            primary={true}
                            className="w-200"
                            onClick={() => {
                              handleDocumentUpdate();
                            }}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};
