// src/component/addProjectModal/AddProjectModal.jsx

import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import "./AddProjectModal.css"; // Ensure this CSS file is updated as needed

function AddProjectModal({ openModal, setOpenModal }) {
  const [loading, setLoading] = useState(false);
  const appCtx = useSelector((state) => state.app);
  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!projectName || !description || !startDate || !endDate) {
      toast.error("Please fill in all fields.");
      return;
    }

    setLoading(true);

    const data = {
      description,
      endDate,
      name: projectName,
      startDate,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VORTX_URL}/project`,
        data,
        {
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        toast.success("Project added successfully");
        setLoading(false);
        closeModal();
        // Optionally, reset form fields
        setProjectName("");
        setDescription("");
        setStartDate("");
        setEndDate("");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to add project. Please try again.");
      setLoading(false);
    }
  };

  function closeModal() {
    setOpenModal(false);
  }

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      fullWidth
      maxWidth="sm"
      aria-labelledby="add-project-dialog-title"
      PaperProps={{
        style: {
          backgroundColor:  "#FFFFFF",
          border: "1px solid  #FFFFFF",
          color:  "black",
          borderRadius: "10px",
        },
      }}
    >
      <DialogTitle id="add-project-dialog-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        Add a New Project
        <IconButton onClick={closeModal} aria-label="close" size="large">
          <CloseIcon style={{ color:  "black" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit} id="add-project-form">
          <div className="form-group">
            <TextField
              label="Project Name"
              fullWidth
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              margin="normal"
              required
              variant="outlined"
            />
          </div>
          <div className="form-group">
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              margin="normal"
              required
              variant="outlined"
            />
          </div>
          <div className="form-group">
            <label className="form-label">Duration</label>
            <div className="d-flex gap-3">
              <TextField
                label="Start Date"
                type="date"
                fullWidth
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                required
                variant="outlined"
              />
              <TextField
                label="End Date"
                type="date"
                fullWidth
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                required
                variant="outlined"
                InputProps={{
                  inputProps: {
                    min: startDate || undefined,
                  },
                }}
              />
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="secondary" disabled={loading}>
          Cancel
        </Button>
        <Button type="submit" form="add-project-form" color="primary" variant="contained" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Add Project"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddProjectModal;
