import React from "react";
import { useSelector } from "react-redux";

const GimmiAi = () => {
  const appCtx = useSelector((state) => state.app);

  let selectedTheme =  "light";

  const iframeUrl = `https://20.2.88.25:3000/?selectedTheme=${selectedTheme}`;

  return (
    <div>
      <iframe
        src={iframeUrl}
        style={{ width: "100%", minHeight: "80vh", border: "none" }}
        title="Embedded App"
      />
    </div>
  );
};

export default GimmiAi;
