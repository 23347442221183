// AoITab.js

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Snackbar,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Paper,
  Badge,
  List,
  Stack,
  Autocomplete,
  Checkbox,
  Divider,
  InputAdornment,
  DialogContentText,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
  ZoomInMap as ZoomInMapIcon,
  Download as DownloadIcon,
  Terrain as TerrainIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
} from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { MapContainer, TileLayer, GeoJSON, FeatureGroup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import axios from 'axios';
import * as turf from '@turf/turf';
import * as toGeoJSON from '@mapbox/togeojson';
import JSZip from 'jszip';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  '&:hover': {
    boxShadow: theme.shadows[6],
    transition: 'box-shadow 0.3s',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  transition: 'background-color 0.3s, transform 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transform: 'scale(1.02)',
  },
}));

// Projection Options
const projections = [
  { value: 'EPSG:4326', label: 'WGS 84 (EPSG:4326)' },
  { value: 'EPSG:3857', label: 'Web Mercator (EPSG:3857)' },
  { value: 'EPSG:4269', label: 'NAD83 (EPSG:4269)' },
  { value: 'EPSG:32633', label: 'WGS 84 / UTM zone 33N (EPSG:32633)' },
  { value: 'EPSG:27700', label: 'OSGB 1936 / British National Grid (EPSG:27700)' },
  { value: 'EPSG:102100', label: 'WGS 84 / Pseudo-Mercator (EPSG:102100)' },
  { value: 'EPSG:3005', label: 'NAD83 / BC Albers (EPSG:3005)' },
  { value: 'EPSG:3413', label: 'NSIDC Sea Ice Polar Stereographic North (EPSG:3413)' },
  { value: 'EPSG:3031', label: 'Antarctic Polar Stereographic (EPSG:3031)' },
  { value: 'EPSG:54004', label: 'World Mercator (EPSG:54004)' },
  { value: 'EPSG:2154', label: 'RGF93 / Lambert-93 (EPSG:2154)' },
  { value: 'ESRI:102003', label: 'USA Contiguous Albers Equal Area Conic (ESRI:102003)' },
  // Add more projections as needed
];

// Basemap Options
const baseMaps = [
  { value: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', label: 'OpenStreetMap' },
  { value: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png', label: 'Wikimedia Maps' },
  { value: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', label: 'Google Maps' },
  { value: 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', label: 'Google Satellite' },
  // Add more base maps as needed
];

// Basemap Attribution Function
const basemapAttribution = (basemap) => {
  if (basemap.includes('openstreetmap')) {
    return '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
  } else if (basemap.includes('google')) {
    return '&copy; Google Maps';
  } else if (basemap.includes('stadiamaps')) {
    return '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>';
  } else if (basemap.includes('hereapi')) {
    return '&copy; HERE Maps';
  } else if (basemap.includes('bing')) {
    return '&copy; Microsoft Bing';
  } else if (basemap.includes('vrt')) {
    return '&copy; VRT Map Tiles';
  } else {
    return '&copy; Map data contributors';
  }
};



// Geocode API URL
const geocodeApiUrl = 'https://nominatim.openstreetmap.org/search';

// Main AoITab Component
const AoITab = () => {
  const { projectId: projectIdParam } = useParams();
  const projectId = Number(projectIdParam);

  console.log("project id:",projectId)
  // State Variables
  const [aois, setAois] = useState([]);
  const [filteredAois, setFilteredAois] = useState([]);
  const [aoiSearchQuery, setAoiSearchQuery] = useState('');
  const [currentAoi, setCurrentAoi] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [newAoi, setNewAoi] = useState({
    project_id: projectId, // Dynamic project_id
    name: '',
    description: '',
    projection: 'EPSG:4326',
    geojson: null,
    url: '',
  });
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [basemap, setBasemap] = useState(baseMaps[0].value);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [selectedAois, setSelectedAois] = useState([]);
  const [measurements, setMeasurements] = useState({});
  const [downloadAllOpen, setDownloadAllOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(null);

  const mapRef = useRef();

  // Backend API Configuration

  const authToken = localStorage.getItem("authToken");
  //const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

  // Fetch AOIs from Backend API
  const fetchAois = async () => {
    setLoading(true);
    try {
      console.log("Auth token:", authToken);

      const BACKEND_API_URL_GET = `${process.env.REACT_APP_VORTX_URL1}/projects/${projectId}/aoi`;
      console.log('list url:',BACKEND_API_URL_GET);
      const response = await axios.get(BACKEND_API_URL_GET, {
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': authToken,
        },
      });

      setAois(response.data);
      setFilteredAois(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch AOIs', error);
      setLoading(false);
      setSnackbarMessage('Failed to fetch AOIs.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchAois();
    } else {
      console.error('No projectId provided to AoITab component.');
      setSnackbarMessage('Invalid project ID.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]); // Re-fetch AOIs when projectId changes

  // Handle Search Query Changes
  useEffect(() => {
    if (aoiSearchQuery.trim() !== '') {
      const filtered = aois.filter((aoi) =>
        aoi.name.toLowerCase().includes(aoiSearchQuery.toLowerCase())
      );
      setFilteredAois(filtered);
    } else {
      setFilteredAois(aois);
    }
  }, [aoiSearchQuery, aois]);

  // Handle Search Input Change
  const handleSearchChange = async (event, value, reason) => {
    setAoiSearchQuery(value);
    if (value.length > 2) {
      try {
        const response = await axios.get(geocodeApiUrl, {
          params: {
            q: value,
            format: 'json',
            limit: 5,
          },
        });
        const suggestions = response.data.map((item) => ({
          label: item.display_name,
          lat: parseFloat(item.lat),
          lon: parseFloat(item.lon),
        }));
        setLocationSuggestions(suggestions);
      } catch (error) {
        console.error('Failed to fetch location suggestions', error);
      }
    } else {
      setLocationSuggestions([]);
    }
  };

  // Handle Location Selection from Autocomplete
  const handleLocationSelect = (event, value) => {
    if (value && value.lat && value.lon) {
      const lat = parseFloat(value.lat);
      const lon = parseFloat(value.lon);
      // Access the map instance to fly to the selected location
      if (mapRef.current) {
        mapRef.current.flyTo([lat, lon], 13, { animate: true, duration: 1.0 });
      }
    }
  };

  // Handle Adding AOI
  const handleAddAoi = () => {
    setUploadDialogOpen(true);
    setCurrentAoi(null);
    setNewAoi({
      project_id: projectId, // Ensure project_id is set
      name: '',
      description: '',
      projection: 'EPSG:4326',
      geojson: null,
      url: '',
    });
  };

  // Handle Editing AOI
  const handleEditAoi = (aoi) => {
    setCurrentAoi(aoi);
    setUploadDialogOpen(true);
    setNewAoi(aoi);
  };

  // Handle Deleting AOIs
  const handleDeleteAois = async () => {
    if (
      window.confirm(
        'Are you sure you want to delete the selected AOIs? This action cannot be undone.'
      )
    ) {
      try {
        const BACKEND_API_URL_GET = `${process.env.REACT_APP_VORTX_URL1}/projects/${projectId}/aoi`;
        // Assuming backend supports bulk deletion; adjust API endpoint as needed

        console.log("Auth token:", authToken);
        console.log('list url:',BACKEND_API_URL_GET);
        await Promise.all(
          selectedAois.map((aoiId) =>
            axios.delete(`${BACKEND_API_URL_GET}/${aoiId}`, {
              headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken,
              },
            })
          )
        );
        setAois((prevAois) =>
          prevAois.filter((aoi) => !selectedAois.includes(aoi.id))
        );
        setFilteredAois((prevAois) =>
          prevAois.filter((aoi) => !selectedAois.includes(aoi.id))
        );
        setSnackbarMessage('AOIs deleted successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setSelectedAois([]);
      } catch (error) {
        console.error('Failed to delete AOIs', error);
        setSnackbarMessage('Failed to delete AOIs.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  // Handle Saving AOI (Add or Edit)
  const handleSaveAoi = async () => {
    if (!newAoi.name || !newAoi.description) {
      setSnackbarMessage('Please provide both name and description for the AOI.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    if (currentAoi) {
      // Update existing AOI
      try {
        console.log("Auth token:", authToken);
        console.log('list url:',BACKEND_API_URL_GET);
        const BACKEND_API_URL_GET = `${process.env.REACT_APP_VORTX_URL1}/projects/${projectId}/aoi`;
        const response = await axios.put(
          `${BACKEND_API_URL_GET}/${currentAoi.id}`,
          newAoi,
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Auth-Token': authToken,
            },
          }
        );
        setAois((prevAois) =>
          prevAois.map((aoi) => (aoi.id === currentAoi.id ? response.data : aoi))
        );
        setFilteredAois((prevAois) =>
          prevAois.map((aoi) => (aoi.id === currentAoi.id ? response.data : aoi))
        );
        setSnackbarMessage('AOI updated successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setUploadDialogOpen(false);
        setCurrentAoi(null);
        setNewAoi({
          project_id: projectId,
          name: '',
          description: '',
          projection: 'EPSG:4326',
          geojson: null,
          url: '',
        });
      } catch (error) {
        console.error('Failed to update AOI', error);
        setSnackbarMessage('Failed to update AOI.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      // Add new AOI using the provided POST API
      try {
        const BACKEND_API_URL_POST = `${process.env.REACT_APP_VORTX_URL1}/projects/${projectId}/aoi`;
        console.log("post api ur:",BACKEND_API_URL_POST)
        console.log("auth token:",authToken)
        const response = await axios.post(BACKEND_API_URL_POST, newAoi, {
          headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': authToken,
          },
        });
        setAois((prevAois) => [...prevAois, response.data]);
        setFilteredAois((prevAois) => [...prevAois, response.data]);
        setSnackbarMessage('AOI added successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setUploadDialogOpen(false);
        setNewAoi({
          project_id: projectId,
          name: '',
          description: '',
          projection: 'EPSG:4326',

          url: '',
        });
      } catch (error) {
        console.error('Failed to add AOI', error);
        setSnackbarMessage('Failed to add AOI.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  // Handle Clicking on AOI to Zoom
  const handleAoiClick = (aoi) => {
    if (aoi.geojson && mapRef.current) {
      const layer = L.geoJSON(aoi.geojson);
      mapRef.current.fitBounds(layer.getBounds(), {
        animate: true,
        duration: 1.0,
      });
    }
  };

  // Handle Checkbox Toggle for AOI Selection
  const handleCheckboxToggle = (aoiId) => {
    setSelectedAois((prevSelected) =>
      prevSelected.includes(aoiId)
        ? prevSelected.filter((id) => id !== aoiId)
        : [...prevSelected, aoiId]
    );
  };

  // Handle File Upload
  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const extension = fileName.split('.').pop().toLowerCase();
      const reader = new FileReader();

      reader.onload = (event) => {
        let data;
        try {
          if (extension === 'geojson' || extension === 'json') {
            data = JSON.parse(event.target.result);
          } else if (extension === 'kml' || extension === 'gpx') {
            const xmlData = new DOMParser().parseFromString(
              event.target.result,
              'text/xml'
            );
            data =
              extension === 'kml'
                ? toGeoJSON.kml(xmlData)
                : toGeoJSON.gpx(xmlData);
          } else {
            alert('Unsupported file format');
            return;
          }

          if (currentAoi) {
            setCurrentAoi((prevAoi) => ({ ...prevAoi, geojson: data }));
            setNewAoi((prevAoi) => ({ ...prevAoi, geojson: data }));
          } else {
            setNewAoi((prevNewAoi) => ({ ...prevNewAoi, geojson: data }));
          }
          setUploadSuccess(true);
          setSnackbarMessage('Geo-data uploaded successfully.');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        } catch (error) {
          console.error('Error parsing file:', error);
          alert('Error parsing the file. Please make sure it is in the correct format.');
          setUploadSuccess(false);
          setSnackbarMessage('Failed to parse the uploaded file.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      };

      reader.readAsText(file);
      setUploading(true);
      setTimeout(() => {
        setUploading(false);
      }, 1000);
    }
  };

  // Handle Downloading Single AOI
  const handleDownloadAoi = (aoi) => {
    const dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(aoi.geojson));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', `${aoi.name}.geojson`);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  // Handle Downloading All AOIs
  const handleDownloadAllAois = () => {
    setDownloadAllOpen(true);
  };

  const confirmDownloadAll = async () => {
    const zip = new JSZip();
    const now = new Date();
    const datetimeStr = `${now.getFullYear()}_${(now.getMonth() + 1)
      .toString()
      .padStart(2, '0')}_${now.getDate().toString().padStart(2, '0')}_${now
      .getHours()
      .toString()
      .padStart(2, '0')}_${now.getMinutes().toString().padStart(2, '0')}`;

    // Add each AOI to the zip file
    aois.forEach((aoi) => {
      const geojsonStr = JSON.stringify(aoi.geojson, null, 2);
      zip.file(`${aoi.name}.geojson`, geojsonStr);
    });

    // Generate the zip file
    const zipContent = await zip.generateAsync({ type: 'blob' });

    // Create a download link for the zip file
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.href = URL.createObjectURL(zipContent);
    downloadAnchorNode.setAttribute(
      'download',
      `project${projectId}_aoi_${datetimeStr}.zip`
    );
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();

    setDownloadAllOpen(false);
  };

  // Handle Measurement Toggle
  const handleMeasurementToggle = (aoiId) => {
    if (!measurements[aoiId]) {
      const aoi = aois.find((item) => item.id === aoiId);
      if (aoi) {
        const length = turf.length(aoi.geojson, { units: 'kilometers' });
        const area = turf.area(aoi.geojson) / 1000000;
        setMeasurements((prev) => ({ ...prev, [aoiId]: { length, area } }));
      }
    } else {
      setMeasurements((prev) => {
        const newMeasurements = { ...prev };
        delete newMeasurements[aoiId];
        return newMeasurements;
      });
    }
  };

  // Map Component to Access Map Instance
  const MapComponent = () => {
    const map = useMap();
    useEffect(() => {
      mapRef.current = map;
    }, [map]);

    return null;
  };

  // Handle File Upload with Feedback Icons
  const renderUploadFeedback = () => {
    if (uploading) {
      return <CircularProgress size={24} sx={{ ml: 2 }} />;
    }
    if (uploadSuccess === true) {
      return <CheckCircleIcon color="success" sx={{ ml: 2 }} />;
    }
    if (uploadSuccess === false) {
      return <ErrorIcon color="error" sx={{ ml: 2 }} />;
    }
    return null;
  };

  // Render GeoJSON Layers
  const renderGeoJSONLayers = () => {
    return filteredAois.map((aoi, index) => (
      <GeoJSON
        key={index}
        data={aoi.geojson}
        style={() => ({
          color: 'blue',
          weight: 2,
        })}
        eventHandlers={{
          click: () => {
            handleAoiClick(aoi);
          },
        }}
      />
    ));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <StyledCard>
        {/* Header Section */}
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h4" gutterBottom>
              Target Synthesis Geo-Coverage
              <Badge
                badgeContent={aois.length}
                color="primary"
                sx={{ ml: 2 }}
              ></Badge>
            </Typography>
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              {/* Location Search */}
              <Autocomplete
                freeSolo
                options={locationSuggestions}
                getOptionLabel={(option) => option.label || ''}
                onInputChange={handleSearchChange}
                onChange={handleLocationSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search Locations..."
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ minWidth: '300px' }}
                  />
                )}
              />
              {/* Basemap Selection */}
              <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
                <InputLabel id="basemap-label">Basemap</InputLabel>
                <Select
                  labelId="basemap-label"
                  label="Basemap"
                  value={basemap}
                  onChange={(e) => setBasemap(e.target.value)}
                >
                  {baseMaps.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Add AOI Button */}
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddAoi}
              >
                Add AOI
              </Button>
              {/* Delete and Download All Buttons */}
              {selectedAois.length > 0 && (
                <>
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteAois}
                  >
                    Delete Selected AOIs
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadAllAois}
                  >
                    Download All AOIs
                  </Button>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Typography variant="body1" gutterBottom>
          Select or upload your own AOI to finetune model synthesis target.
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* Main Content */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {/* AOIs List */}
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ padding: 2, height: '100%' }}>
              {loading ? (
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                  <CircularProgress />
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Loading AOIs...
                  </Typography>
                </Box>
              ) : (
                <List sx={{ mt: 2 }}>
                  {filteredAois.map((aoi) => (
                    <React.Fragment key={aoi.id}>
                      <StyledListItem
                        button
                        onClick={() => handleAoiClick(aoi)}
                      >
                        <ListItemAvatar>
                          <Checkbox
                            edge="start"
                            checked={selectedAois.includes(aoi.id)}
                            tabIndex={-1}
                            disableRipple
                            onChange={() => handleCheckboxToggle(aoi.id)}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={aoi.name}
                          secondary={aoi.description}
                        />
                        <Stack direction="row" spacing={1}>
                          <Tooltip title="Zoom to AOI" arrow>
                            <IconButton
                              color="primary"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAoiClick(aoi);
                              }}
                              sx={{
                                backgroundColor: '#e3f2fd',
                                '&:hover': {
                                  backgroundColor: '#bbdefb',
                                },
                                marginRight: 1,
                              }}
                            >
                              <ZoomInMapIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit" arrow>
                            <IconButton
                              color="secondary"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditAoi(aoi);
                              }}
                              sx={{
                                backgroundColor: '#f3e5f5',
                                '&:hover': {
                                  backgroundColor: '#e1bee7',
                                },
                                marginRight: 1,
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Measure" arrow>
                            <IconButton
                              color="info"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMeasurementToggle(aoi.id);
                              }}
                              sx={{
                                backgroundColor: '#e0f7fa',
                                '&:hover': {
                                  backgroundColor: '#b2ebf2',
                                },
                                marginRight: 1,
                              }}
                            >
                              <TerrainIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Download AOI" arrow>
                            <IconButton
                              color="success"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDownloadAoi(aoi);
                              }}
                              sx={{
                                backgroundColor: '#e8f5e9',
                                '&:hover': {
                                  backgroundColor: '#c8e6c9',
                                },
                              }}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                        {measurements[aoi.id] && (
                          <Typography variant="body2" sx={{ mt: 1 }}>
                            Length: {measurements[aoi.id].length.toFixed(2)} km,
                            Area: {measurements[aoi.id].area.toFixed(2)} sq. km
                          </Typography>
                        )}
                      </StyledListItem>
                    </React.Fragment>
                  ))}
                </List>
              )}
            </Paper>
          </Grid>

          {/* Map */}
          <Grid item xs={12} md={8}>
            <Paper elevation={3}>
              <MapContainer
                center={[51.505, -0.09]}
                zoom={2}
                style={{ height: '650px', width: '100%' }}
              >
                <MapComponent />
                <TileLayer
                  attribution={basemapAttribution(basemap)}
                  url={basemap}
                />
                {renderGeoJSONLayers()}
                <FeatureGroup>
                  <EditControl
                    position="topright"
                    onCreated={(e) => {
                      const { layerType, layer } = e;
                      if (
                        layerType === 'polygon' ||
                        layerType === 'rectangle' ||
                        layerType === 'polyline'
                      ) {
                        const newPolygon = {
                          project_id: projectId,
                          id: aois.length ? Math.max(...aois.map((a) => a.id)) + 1 : 1,
                          name: `AOI ${aois.length + 1}`,
                          description: 'Newly created AOI',
                          projection: 'EPSG:4326',
                          geojson: layer.toGeoJSON(),
                          url: '',
                        };
                        setAois((prevAois) => [...prevAois, newPolygon]);
                        setFilteredAois((prevFilteredAois) => [
                          ...prevFilteredAois,
                          newPolygon,
                        ]);
                        setSnackbarMessage('New AOI created successfully.');
                        setSnackbarSeverity('success');
                        setSnackbarOpen(true);
                        setUploadDialogOpen(true);
                        setCurrentAoi(newPolygon);
                      }
                    }}
                    onEdited={(e) => {
                      const layers = e.layers;
                      layers.eachLayer((layer) => {
                        const editedAoi = aois.find(
                          (aoi) =>
                            aoi.geojson &&
                            JSON.stringify(aoi.geojson) === JSON.stringify(layer.toGeoJSON())
                        );
                        if (editedAoi) {
                          const updatedGeoJSON = layer.toGeoJSON();
                          setAois((prevAois) =>
                            prevAois.map((aoi) =>
                              aoi.id === editedAoi.id
                                ? { ...aoi, geojson: updatedGeoJSON }
                                : aoi
                            )
                          );
                          setFilteredAois((prevAois) =>
                            prevAois.map((aoi) =>
                              aoi.id === editedAoi.id
                                ? { ...aoi, geojson: updatedGeoJSON }
                                : aoi
                            )
                          );
                          setSnackbarMessage('AOI edited successfully.');
                          setSnackbarSeverity('success');
                          setSnackbarOpen(true);
                        }
                      });
                    }}
                    onDeleted={(e) => {
                      const layers = e.layers;
                      layers.eachLayer((layer) => {
                        const deletedAoi = aois.find(
                          (aoi) =>
                            aoi.geojson &&
                            JSON.stringify(aoi.geojson) === JSON.stringify(layer.toGeoJSON())
                        );
                        if (deletedAoi) {
                          setAois((prevAois) => prevAois.filter((aoi) => aoi.id !== deletedAoi.id));
                          setFilteredAois((prevAois) =>
                            prevAois.filter((aoi) => aoi.id !== deletedAoi.id)
                          );
                          setSnackbarMessage('AOI deleted successfully.');
                          setSnackbarSeverity('success');
                          setSnackbarOpen(true);
                        }
                      });
                    }}
                    draw={{
                      rectangle: true,
                      circle: false,
                      circlemarker: false,
                      marker: false,
                      polyline: true,
                      polygon: true,
                    }}
                  />
                </FeatureGroup>
              </MapContainer>
            </Paper>
          </Grid>
        </Grid>
      </StyledCard>

      {/* Upload/Edit AOI Dialog */}
      <Dialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Slide}
      >
        <DialogTitle>{currentAoi ? 'Edit AOI' : 'Add AOI'}</DialogTitle>
        <DialogContent>
          <Box component="form">
            <Grid container spacing={2}>
              {/* AOI Name */}
              <Grid item xs={12}>
                <TextField
                  label="AOI Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={newAoi.name}
                  onChange={(e) =>
                    setNewAoi({ ...newAoi, name: e.target.value })
                  }
                />
              </Grid>
              {/* Description */}
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  value={newAoi.description}
                  onChange={(e) =>
                    setNewAoi({ ...newAoi, description: e.target.value })
                  }
                />
              </Grid>
              {/* Projection Selection */}
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="projection-label">Projection</InputLabel>
                  <Select
                    labelId="projection-label"
                    label="Projection"
                    value={newAoi.projection}
                    onChange={(e) =>
                      setNewAoi({ ...newAoi, projection: e.target.value })
                    }
                  >
                    {projections.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Geo-data Upload */}
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Geo-data
                  <input
                    type="file"
                    hidden
                    accept=".geojson,.json,.kml,.gpx"
                    onChange={handleUploadFile}
                  />
                  {renderUploadFeedback()}
                </Button>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Supported formats: GeoJSON, JSON, KML, GPX
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setUploadDialogOpen(false)}
            color="secondary"
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSaveAoi}
          >
            {currentAoi ? 'Save Changes' : 'Add AOI'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Download All Confirmation Dialog */}
      <Dialog
        open={downloadAllOpen}
        onClose={() => setDownloadAllOpen(false)}
      >
        <DialogTitle>Download All AOIs</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to download all AOIs?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDownloadAllOpen(false)}
            color="secondary"
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button
            onClick={confirmDownloadAll}
            color="primary"
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Feedback Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          sx: {
            backgroundColor:
              snackbarSeverity === 'success'
                ? 'green'
                : snackbarSeverity === 'error'
                ? 'red'
                : 'orange',
          },
        }}
      />
    </Box>
  );
};

// Prop Validation
AoITab.propTypes = {
  projectId: PropTypes.number.isRequired,
};

export default AoITab;
