import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { getBlockchainTransactionUrl } from "../../config";

const SwapSuccessModal = ({
  openModal,
  setOpenModal,
  transactionData,
  isPerformingSwap,
}) => {
  const [transactionStatusLoading, setTransactionStatusLoading] =
    useState(false);
  const [transactionStatusData, setTransactionStatusData] = useState(null);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const [timer, setTimer] = useState(20);
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const makeAuthorizedRequest = useAuthorizedHttp();
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      backgroundColor: "#FFFFFF",
      border: "1px solid  #FFFFFF",
      color: "black",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
      zIndex: 9999,
      // width: width > 1200 ? "600px" : "370px",
    },
  };

  const themedColor = {
    color: "black",
  };
  function closeModal() {
    setOpenModal(false);
    // navigate("/");
  }

  const getTransactionStatus = () => {
    setTransactionStatusLoading(true);
    makeAuthorizedRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/transaction/${transactionData?.transactionId}/status`,
      },

      (data) => {
        setTransactionStatusData(data);
        // setTransactionStatusData({
        //   blockchainTransactionId: null,
        //   transactionStatus: "success",
        // });

        setTransactionStatusLoading(false);
        setTimer(20);
      },
      (rej) => {
        // setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (transactionData?.transactionId) {
      if (
        transactionStatusData?.blockchainTransactionId ||
        transactionStatusData?.transactionStatus
      ) {
        return;
      }
      let intervalId;

      const fetchData = () => {
        getTransactionStatus();
      };

      if (transactionStatusData?.transactionStatus !== "FAILED") {
        fetchData(); // Call immediately when conditions are met

        // Set up interval to call the method every 20 seconds
        intervalId = setInterval(fetchData, 20000);
      }

      return () => {
        clearInterval(intervalId); // Clean up the interval on component unmount
      };
    }
  }, []);

  useEffect(() => {
    if (transactionData?.transactionId) {
      // Update the timer every second
      const timerId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      // Clear the timer when it reaches 0
      if (timer === 0) {
        clearInterval(timerId);
      }

      return () => {
        clearInterval(timerId); // Clean up the timer on component unmount
      };
    }
  }, [timer]);

  const handleCopyAddress = (value) => {
    setIsAddressCopied(!isAddressCopied);
    navigator.clipboard.writeText(value);
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="w-100 d-flex justify-content-end m-2 position-relative">
        <div
          onClick={closeModal}
          className="btn btn-link position-absolute top-0 right-0 me-3"
          style={{ cursor: "pointer" }}
        >
          <i className="fa fa-times text-20px" style={themedColor}></i>
        </div>
      </div>
      <div className="p-5">
        {transactionData?.transactionId ? (
          <div className="">
            <div className="">
              {transactionStatusData?.transactionStatus === "FAILED" ? (
                <div className="w-100 d-flex flex-column gap-3 justify-content-center align-items-center p-4">
                  <img
                    src="/images/failicon.jpg"
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                  />
                  <h6 className="">Transaction Failed</h6>
                </div>
              ) : transactionStatusData?.blockchainTransactionId ? (
                <div className="w-100 d-flex flex-column gap-3 justify-content-center align-items-center">
                  <img
                    src="/images/successicon.png"
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                  />

                  <h6 className="mb-0">Trade Executed Successfully</h6>
                  <h6 className="mb-0">Transaction receipt</h6>
                  <div class="d-flex gap-1 align-items-center px-2">
                    <div
                      onClick={() => {
                        const blockchain = appCtx?.blockchain;
                        const blockchainLink = getBlockchainTransactionUrl(
                          blockchain,
                          transactionStatusData?.blockchainTransactionId
                        );

                        if (blockchainLink !== "") {
                          window.open(blockchainLink);
                        }
                      }}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    >
                      {transactionStatusData?.blockchainTransactionId}
                    </div>
                    <button
                      className="btn btn-link"
                      onClick={() =>
                        handleCopyAddress(
                          transactionStatusData?.blockchainTransactionId
                        )
                      }
                    >
                      {isAddressCopied ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className=""
                          style={themedColor}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCopy}
                          className=""
                          style={themedColor}
                        />
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="w-100 d-flex flex-column gap-3 justify-content-center align-items-center p-4">
                  <img src="/images/Spinner.gif" alt="" />
                  <h6 className="mb-0">Trade Submitted</h6>
                  {transactionData?.transactionInfo && (
                    <p className="">{transactionData?.transactionInfo}</p>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="p-5 position-relative w-100">
            <h4 className="font-semibold text-2xl">
              Order placed successfully
            </h4>
            <div className="d-flex justify-content-center w-100">
              <button
                className="btn btn-primary"
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SwapSuccessModal;
