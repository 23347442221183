import axios from "axios";
import { encode } from "base-64";
import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { appActions } from "../../context/app-slice";
import { Notes } from "./common/Notes";
import { KycHeaderPannel } from "./KycHeaderPannel";
import WebCamComponent from "./webcamComponent";
import MainWrapper from "../wrapper/MainWrapper";
import { Button } from "react-bootstrap";

export const UploadSelfie = () => {
  const appCtx = useSelector((state) => state.app);
  const [picture, setPicture] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileRef = useRef();
  const [fileUpload, setFileUpload] = useState(false);
  const [uploadedPic, setUploadedPic] = useState("");
  const [openWebcapm, setOpenWebcam] = useState("");
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleUpload = () => {
    const data = new FormData();
    // data.append('file', blob);
    data.append("fileName", "Selfie.jpeg");
    data.append("fileUrl", picture?.split("/")[3]);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_FILE_UPLOAD}/image/upload/base64?type=kyc`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": appCtx.authToken,
        "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
      },
      data: {
        fileName: "selfie",
        fileUrl: picture.substring(23, picture.length),
        type: "png",
      },
    };
    toast.promise(
      () =>
        axios(config)
          .then(function (response) {
            dispatch(appActions.setPicture(response.data?.fileUrl));
            navigate("/kyc/verification/status");
            toast.success("Picture Uploaded Successfully");
          })
          .catch(function (error) {
            toast.error("Uploading Picture failed!");
          }),
      {
        pending: "uploading Picture",
        // success: 'Settings updated successfully!',
        // error: 'Uh-oh! Something went wrong'
      }
    );
  };
  const handleSelfieChange = useCallback((file) => {
    if (file && file?.type?.split("/")[0] === "image") {
      // setBackSide(file.File);
      // dispatch(appActions.setBackSideFile(file))
      const objectUrl = URL.createObjectURL(file);
      // setBacksidePreview(objectUrl);
      const data = new FormData();
      data.append("file", file);
      data.append("fileName", `selfie`);
      data.append("fileUrl", objectUrl);
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_FILE_UPLOAD}/kyc/document/upload`,
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Auth-Token": appCtx.authToken,
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
        },
        data: data,
      };
      toast.promise(
        () =>
          axios(config)
            .then(function (response) {
              setUploadedPic(response?.data?.fileUrl);
              dispatch(appActions.setPicture(response?.data?.fileUrl));
              toast.success("Selfie Uploaded Successfully");
            })
            .catch(function (error) {
              toast.error("Uploading Selfie failed!");
            }),
        {
          pending: "uploading Selfie",
          // success: 'Settings updated successfully!',
          // error: 'Uh-oh! Something went wrong'
        }
      );
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      toast.error("Documents uploaded should be an Image");
    }
  }, []);
  const handleSelfieRef = (e) => {
    fileRef.current.click();
    setFileUpload(true);
  };
  return (
    <MainWrapper>
      <div className={`${appCtx?.isDarkMode ? "text-black" : "text-black"}`}>
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            {/* <!-- Row --> */}
            <div class="row">
              {/* banner card  */}
              <div className="col-xl-12">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header border-0"></div>
                    <div className="card-body px-0 pt-0">
                      <div className="d-flex flex-column justify-center align-items-center pb-5 gap-5 mt-5 px-4">
                        <KycHeaderPannel document={true} />
                        <div className="">
                          <div className="d-flex justify-between gap-3 w-100 mb-4">
                            <Button
                              primary={openWebcapm}
                              onClick={() => setOpenWebcam(true)}
                            >
                              OpenWebcam
                            </Button>
                            <Button
                              primary={!openWebcapm}
                              onClick={() => setOpenWebcam(false)}
                            >
                              upload selfie
                            </Button>
                          </div>
                          <div className="d-flex gap-5">
                            {openWebcapm ? (
                              <WebCamComponent
                                picture={picture}
                                setPicture={setPicture}
                                handleUpload={handleUpload}
                              />
                            ) : (
                              <div className="d-flex flex-column gap-5 align-items-center">
                                <div
                                  className="w-350 min-h-220"
                                  onClick={(e) => handleSelfieRef(e)}
                                >
                                  <input
                                    hidden={true}
                                    accept="image/png, image/jpeg, image/svg, image/jpg"
                                    type="file"
                                    ref={fileRef}
                                    onChange={(e) => {
                                      handleSelfieChange(e.target.files[0]);
                                    }}
                                  />
                                  {appCtx.picture ? (
                                    <img src={appCtx.picture} alt="logo" />
                                  ) : (
                                    <div
                                      className="d-flex justify-content-center align-items-center cursor-pointer"
                                      style={{
                                        height: "120px",
                                        width: "500px",
                                        border: "2px solid black",
                                      }}
                                    >
                                      Click here to Upload your Selfie
                                    </div>
                                  )}
                                </div>
                                {appCtx.picture && (
                                  <Button
                                    primary={false}
                                    className="w-50 h-40"
                                    onClick={() => {
                                      if (appCtx.picture) {
                                        navigate("/kyc/verification/status");
                                      } else {
                                        toast.error(
                                          "Uploading a selfie of yours is mandatory"
                                        );
                                      }
                                    }}
                                  >
                                    Next
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <Notes />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};
