import React from 'react'
import { useSelector } from 'react-redux';

export const Notes = () => {
  const appCtx = useSelector((state) => state.app);
  return (
    <div className="w-100 d-flex flex-column gap-3">
      <div className="text-20 font-weight-bold">Note:</div>
      {appCtx.country === "India" && (
        <div className="d-flex justify-start align-items-center">
          <img src="/KYC/bulletpoint.svg" alt="bulletpoint" />
          <div>
            Those who do no have a pan card may upload their addhar card
          </div>
        </div>
      )}
      {appCtx.country !== "India" && (
        <div className="d-flex justify-start align-items-center">
          <img src="/KYC/bulletpoint.svg" alt="bulletpoint" />
          <div>
            Those who do no have a passport may upload any government issued
            identity card.
          </div>
        </div>
      )}
      <div className="d-flex justify-start align-items-center">
        <img src="/KYC/bulletpoint.svg" alt="bulletpoint" />
        <div>
          To Complete verification process, you must submit one document and
          your selfie
        </div>
      </div>
      <div className="d-flex justify-start align-items-center">
        <img src="/KYC/bulletpoint.svg" alt="bulletpoint" />
        <div>
          It should be noted that KYC is required to process fiat payments and
          redeem rewards.
        </div>
      </div>
    </div>
  );
}
