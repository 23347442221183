// src/Footer.jsx
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported

const Footer = () => {
  return (
    <footer
      className="footer mt-auto d-none d-md-block" // Hide on mobile devices
      style={{
        borderTop: "1px solid #ddd",
        backgroundColor: "#fff",
        position: "fixed",       // Fixes the footer at the bottom
        bottom: "0",
        left: "0",
        width: "100%",           // Ensures the footer spans the full width
        zIndex: "1000",          // Ensures the footer stays above other elements
        boxShadow: "0 -2px 5px rgba(0,0,0,0.1)", // Optional: Adds a subtle shadow
        padding: "0",            // Removes all padding
        height: "32px",          // Adjusted height to accommodate larger font
      }}
    >
      <div
        className="container d-flex flex-wrap justify-content-end align-items-center"
        style={{ height: "100%" }}
      >
        <div
          className="d-flex flex-wrap align-items-center"
          style={{ fontSize: "11px" }}
        >
          <span
            style={{
              color: "#6c757d",
              marginRight: "15px",
            }}
          >
            © Global - 2024 Vortx AI Private Limited.
          </span>
          <a
            href="https://vortx.ai/terms-conditions"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              marginRight: "15px",
              color: "#6c757d",
              textDecoration: "none",
              fontSize: "11px",
            }}
          >
            Terms & Conditions
          </a>
          <a
            href="https://vortx.ai/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              marginRight: "15px",
              color: "#6c757d",
              textDecoration: "none",
              fontSize: "11px",
            }}
          >
            Privacy
          </a>
          <a
            href="https://vortx.statuspage.io/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              marginRight: "15px",
              color: "#6c757d",
              textDecoration: "none",
              fontSize: "11px",
            }}
          >
            API Status
            <span
              style={{
                color: "green",
                marginLeft: "5px",
                fontSize: "11px",
              }}
            >
              ●
            </span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
