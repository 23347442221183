import Modal from "react-modal";
import { useSelector } from "react-redux";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FundCurrencyModal = ({
  openModal,
  setOpenModal,
  setOpenCryptoFundOnrampModal,
  setPayUsingTokenModal,
  setSelectedTokenOption,
}) => {
  const appCtx = useSelector((state) => state.app);

  const tokensData = [
    {
      tokenId: 0,
      contractAddress: "",
      symbol: "INR",
      tokenShortName: "INR",
      tokenName: "FIAT",
      tokenIcon: "/images/fiat.png",
    },
    {
      tokenId: 8,
      contractAddress: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      symbol: "USDT",
      tokenShortName: "USDT",
      tokenName: "USDT",
      tokenIcon:
        "https://talewallet-assets.s3.ap-southeast-1.amazonaws.com/token-icon/USDT.png",
      decimals: 6,
      marketcapType: null,
      blockchain: "POLYGON",
    },
  ];

  function closeModal() {
    setOpenModal(false);
  }

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9990,
    },
    content: {
      top: "53%",
      backgroundColor: "#FFFFFF",
      border: "1px solid  #FFFFFF",
      color: "black",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      zIndex: 9991,
    },
  };
  const themedColor = "black";

  return (
    <>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="Example Modal"
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <div className="w-100 d-flex justify-content-end m-2 position-relative">
          <div
            onClick={closeModal}
            className="btn btn-link position-absolute top-0 right-0 me-3"
            style={{ cursor: "pointer" }}
          >
            <i
              className="fa fa-times text-20px"
              style={{
                color: themedColor,
              }}
            ></i>
          </div>
        </div>
        <div class="overflow-auto rounded-xl " style={{ maxHeight: "70vh" }}>
          <h3 className="text-center">Select Payment Mode</h3>
          <div className="px-5 pt-2 pb-5" style={{ minWidth: "400px" }}>
            {tokensData?.map((token) => (
              <div className="">
                <button
                  type="button"
                  className={` btn  ${
                    appCtx?.isDarkMode ? "btn-primary" : "btn-primary"
                  } my-1 w-100`}
                  onClick={() => {
                    if (token?.symbol === "INR") {
                      setSelectedTokenOption("INR");
                      setOpenCryptoFundOnrampModal(true);
                    } else {
                      setSelectedTokenOption("USDT");
                      setPayUsingTokenModal(true);
                    }
                  }}
                  key={token?.tokenId}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <img
                        src={token?.tokenIcon}
                        alt=""
                        style={{ width: "30px", height: "30px" }}
                        className="rounded-circle"
                      />
                      <div className="">
                        <span className="font-weight-semibold">
                          {" "}
                          {token?.tokenName}
                        </span>
                      </div>
                    </div>
                    <div className="cursor-pointer">
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className=""
                        style={{
                          color: "white",
                        }}
                      />
                    </div>
                  </div>
                </button>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FundCurrencyModal;
