import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { appActions } from "../../context/app-slice";
import { KycHeaderPannel } from "./KycHeaderPannel";
import MainWrapper from "../wrapper/MainWrapper";
import { Button, Dropdown } from "react-bootstrap";
import CountrySelectionDropdown from "../countrySelectionDropdown/CountrySelectionDropdown";
import { BiChevronDown } from "react-icons/bi";
import country from "./countries.json";

export const KycSelectCountry = ({ open, setOpen }) => {
  const [inputValue, setInputValue] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appCtx = useSelector((state) => state.app);

  const [value, setValue] = useState(appCtx.country || "");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  // console.log("country", country);

  return (
    <div >
      <h5>Your Country</h5>
      <div
        className="mt-1 position-relative border rounded-lg px-4 py-3"
        style={{
          cursor: "pointer",
        }}
      >
        <div className="" onClick={() => setOpen(!open)}>
          <div
            className={` d-flex align-items-center justify-content-between`}
            id="receiveButton"
          >
            {appCtx.country || "Select Your Country"}
            <BiChevronDown
              size={20}
              className={` ms-1`}
              style={{
                transform: open ? "rotate(180deg)" : "none",
              }}
              id="receiveButton"
            />
          </div>
        </div>
        {open && (
          <CountrySelectionDropdown
            searchTerm={inputValue}
            setSearchTerm={setInputValue}
            country={country}
            isDrodownOpen={open}
            setIsDropdownOpen={setOpen}
            additionalStyles={{ top: "55px", left: "0px" }}
          />
        )}
      </div>
    </div>
  );
};
