import axios from "axios";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

export const useCrops = () => {
  const [cropListLoading, setCropListLoading] = useState(false);
  const [cropBasketListLoading, setCropBasketListLoading] = useState(false);
  const [singleCropBasketListLoading, setSingleCropBasketListLoading] =
    useState(false);
  const [cropList, setCropList] = useState([]);
  const [cropBasketList, setCropBasketList] = useState([]);
  const [singleCropBasketData, setSingleCropBasketData] = useState([]);
  const [privateCropBasketListLoading, setPrivateCropBasketListLoading] = useState(false);
  const [privateCropBasketList, setPrivateCropBasketList] = useState([]);

  const appCtx = useSelector((state) => state.app);

  const getCropList = async () => {
    setCropListLoading(true);
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_VORTX_URL}/crop/master/list`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
        },
      };
      const response = await axios(config);
      setCropList(response.data);
      setCropListLoading(false);
      return response.data;
    } catch (error) {
      console.error(error);
      setCropListLoading(false);

      // throw error;
    }
  };
  const getPublicCropBasketList = async () => {
    setCropBasketListLoading(true);
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_VORTX_URL}/crop/basket/list?privacy=PUBLIC`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
        },
      };
      const response = await axios(config);
      setCropBasketList(response?.data?.content);
      setCropBasketListLoading(false);
      return response.data;
    } catch (error) {
      console.error(error);
      setCropBasketListLoading(false);


    }
  };
  const getPrivateCropBasketList = async () => {
    setPrivateCropBasketListLoading(true);
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_VORTX_URL}/crop/basket/list?privacy=PRIVATE`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
        },
      };
      const response = await axios(config);
      setPrivateCropBasketList(response?.data?.content);
      setPrivateCropBasketListLoading(false);
      return response.data;
    } catch (error) {
      console.error(error);
      setPrivateCropBasketListLoading(false);


    }
  };
  const getSingleCropBasketList = async (basketId) => {
    setSingleCropBasketListLoading(true);
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_VORTX_URL}/crop/basket/${basketId}/detail
`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
        },
      };
      const response = await axios(config);
      setSingleCropBasketData(response.data);
      setSingleCropBasketListLoading(false);
      return response.data;
    } catch (error) {
      console.error(error);
      setSingleCropBasketListLoading(false);

      // throw error;
    }
  };


  return {
    cropListLoading,
    cropList,
    getCropList,
    cropBasketListLoading,
    cropBasketList,
    getPublicCropBasketList,
    singleCropBasketListLoading,
    singleCropBasketData,
    getSingleCropBasketList,
    privateCropBasketListLoading,
    privateCropBasketList,
    getPrivateCropBasketList,
  };
};
