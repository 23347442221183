import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { EmailOutlined } from "@mui/icons-material";
import axios from "axios";
import KeysModal from "./KeysModal";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
var CryptoJS = require("crypto-js");

function PrivateKeysLoginModal({ openModal, setOpenModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();
  const appCtx = useSelector((state) => state.app);
  const width = window.screen.width;
  const dark = false;
  const [userData, setUserData] = React.useState({
    email: appCtx.userDetails?.email || "",
    password: "",
  });
  const [otpLoginUserEmail, setOtpLoginUserEmail] = React.useState(
    appCtx.userDetails?.email
  );
  const [handleEnterOtp, setHandleEnterOtp] = React.useState(false);
  const [clickledOnLoginOtp, setClickledOnLoginOtp] = React.useState(false);
  const [otp1, setOtp1] = React.useState("");
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [reSendOtp, setReSendOtp] = React.useState(false);
  const [isVerifiedOtp, setIsVerifiedOtp] = React.useState(false);
  const [privateKeyData, setPrivateKeyData] = React.useState("");
  const [loader, setLoader] = useState(false);
  const [openKeysModal, setOpenKeysModal] = useState(false);
  let distance1 = 300,
    resendDistance = 300;
  if (reSendOtp === 0) {
    resendDistance = 300;
    distance1 = 0;
  }
  const [data, setData] = useState({});

  let loginType = "type=login";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevValue) => ({ ...prevValue, [name]: value }));
    setOtpLoginUserEmail(e.target.value);
  };

  const themedColor = "black";

  const handleSendOtp = () => {
    if (userData.email?.trim().length !== 0) {
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email)
      ) {
        setLoader(true);
        makeRequest(
          {
            url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/send?${loginType}`,
            data: { email: otpLoginUserEmail },
            method: "post",
          },
          (data) => {
            toast("OTP Sent SuccesFully!");
            setClickledOnLoginOtp(true);
            if (data.message === "Success" && data.status === true) {
              setHandleEnterOtp(true);
              setLoader(false);
              var x = setInterval(function () {
                setMinutes(Math.floor(distance1 / 60));
                setSeconds(distance1 % 60);
                distance1--;
                if (isVerifiedOtp === true) {
                  distance1 = 0;
                  resendDistance = 0;
                  clearInterval(x);
                }
                // if (distance1 < 0) {
                //     clearInterval(x);
                //     navigate("/signup");
                // }
                if (distance1 === -1) {
                  clearInterval(x);
                  // navigate("/");
                }
              }, 1000);
            }
          },
          (error) => {
            toast.error("Please try after sometime");
            setLoader(false);
          }
        );
      } else toast.error("Email ID is incorrect !");
    } else {
      toast.error("Email Id cannot be empty !");
    }
  };
  const handleCheckOtp = async () => {
    setLoader(true);

    let config = {
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/key/list`,
      method: "post",
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
      data: {
        email: otpLoginUserEmail,
        otp: otp1,
      },
    };

    axios(config)
      .then((response) => {
        console.log("Private key fetch successful", response.data);
        console.log(response.data);

        setPrivateKeyData(response.data);

        setLoader(false);
        setOpenKeysModal(true);
        closeModal();
      })
      .catch((error) => {
        console.log("Error in fetching private key: ", error);
        setLoader(false);
      });
  };
  const handleResendOtp = () => {
    let header = {};

    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/send?${loginType}&resend=true`,
        data: { email: otpLoginUserEmail },
        method: "post",
      },
      (data) => {
        toast("OTP Sent SuccesFully!");
        setReSendOtp(true);
        distance1 = 0;
        setOtp1("");
        if (data.message === "Success" && data.status === true) {
          setHandleEnterOtp(true);
          setLoader(false);
        }
      },
      () => {}
    );
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: "#FFFFFF",

      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      border: `${appCtx?.isDarkMode ? "#FFFFFF" : "#FFFFFF"}`,
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: width > 1200 ? "400px" : "370px",
    },
  };
  function closeModal() {
    setOpenModal(false);
    // setOpenPreviousModal(true);
  }

  return (
    <>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex flex-col justify-center items-center lg:px-[20px]">
          <div className="w-100 d-flex justify-content-center align-items-center">
            <img
              src="/images/talelogo.png"
              className=""
              style={{ width: "100px", height: "100px" }}
              alt="tale_logo"
            />
          </div>

          <div className="">
            <div className={`${!clickledOnLoginOtp ? "h-[100px]" : ""}`}>
              {/* style={{ marginTop: !clickledOnLoginOtp ? "" : "59px" }}> */}
              <div className="mb-[8px]">
                {!clickledOnLoginOtp ? (
                  <>
                    <div className="font-medium mb-2">Your Email Id</div>
                    <div>
                      <div className="input-group">
                        <input
                          className="form-control border rounded ps-3"
                          value={otpLoginUserEmail}
                          placeholder="Enter your Email ID"
                          onChange={handleChange}
                          name="email"
                        />
                        <span className="input-group-text">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            style={{
                              color: themedColor,
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-center mt-2">
                      <input
                        value={otp1}
                        onChange={(e) => {
                          setOtp1(e.target.value);
                        }}
                        className=" form-control py-2"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between pb-2">
              <div
                className="font-weight-bold cursor-pointer"
                style={{
                  visibility: !reSendOtp ? "hidden" : "visible",
                  display: !clickledOnLoginOtp ? "none" : "block",
                }}
                onClick={() => {
                  handleResendOtp();
                }}
              >
                Resend OTP
              </div>
              <div className="font-weight-bold">
                {!(minutes === 0 && seconds === 0) ? (
                  <>
                    {minutes >= 0 && minutes <= 9 ? `0${minutes}` : minutes}:
                    {seconds >= 0 && seconds <= 9 ? `0${seconds}` : seconds}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center pb-2 pt-2">
              <button
                className="btn btn-primary d-flex justify-content-center align-items-center gap-2"
                onClick={() =>
                  !clickledOnLoginOtp ? handleSendOtp() : handleCheckOtp()
                }
              >
                <div>{!clickledOnLoginOtp ? `Send OTP` : "Verify OTP"}</div>
                {loader ? (
                  <CircularProgress
                    style={{
                      color: "#BBFF00",
                      fontSize: "10px",
                      width: "25px",
                      height: "25px",
                    }}
                    className="ml-2"
                    fontSize="small"
                  />
                ) : (
                  <span className="ml-2">
                    <EastIcon />
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <KeysModal
        openModal={openKeysModal}
        setOpenModal={setOpenKeysModal}
        privateKeyData={privateKeyData}
      />
    </>
  );
}

export default PrivateKeysLoginModal;
