import { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBlockchainAddressUrl } from "../../config";

function AccountDetailsModal({ openModal, setOpenModal }) {
  const [copyId, setCopyId] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appCtx = useSelector((state) => state.app);
  const width = window.screen.width;
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: "#FFFFFF",
      border: "1px solid  #FFFFFF",
      color: "black",

      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
    },
  };
  function closeModal() {
    setOpenModal(false);
  }
  const copyToClipboard = (text, blockchain) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="mt-4">
        <h4 className="text-center mt-1 mb-3">Account Details</h4>
        {appCtx?.walletAddress?.map((item) => (
          <>
            <div
              className="d-flex gap-2 align-items-center px-3 "
              onClick={() => {
                const blockchain = item?.blockchain;

                const blockchainLink = getBlockchainAddressUrl(
                  blockchain,
                  item?.address
                );
                

                if (blockchainLink !== "") {
                  window.open(blockchainLink);
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <p
                className="mb-0"
                onMouseOver={(e) => {
                  e.target.style.color = "blue";
                }}
                onMouseOut={(e) => {
                  e.target.style.color = "";
                }}
              >
                <span className="font-w600">{item?.blockchain}</span>:{" "}
                {item?.address}
              </p>
            </div>
            <div className="border-bottom border-gray-400 my-2"></div>
          </>
        ))}

        <div class="w-100 d-flex justify-content-center">
          <div class="w-50">
            <button
              onClick={() => {
                closeModal();
              }}
              class="mb-3 py-2 text-md font-weight-bold text-white bg-success border border-success rounded active:text-secondary focus:outline-none focus:ring w-100"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AccountDetailsModal;
