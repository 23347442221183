import { DocumentScanner, Upload } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";

export const KycHeaderPannel = ({ header, document, selfie }) => {
  const navigate = useNavigate();
  return (
    <div className="w-80 d-flex flex-column align-items-center">
      <div className="card-title">{header ? header : "KYC Verification"}</div>
      <div className="d-flex justify-content-center align-items-center w-100 gap-2 mt-5">
        <div
          className="d-flex flex-column justify-content-center"
          // onClick={()=>{navigate('/kyc/upload/document')}}
        >
          <div className="d-flex justify-content-center">
            <div
              className={`${
                document ? "bg-success" : "bg-warning"
              } rounded-circle d-flex justify-content-center align-items-center`}
              style={{
                width: "50px",
                height: "50px",
              }}
            >
              <img
                src="/KYC/uploaddocument.svg"
                alt="document"
                style={{
                  width: "25px",
                }}
                
              />
            </div>
          </div>
          <div className="text-17 font-weight-medium">Document</div>
        </div>

        <div
          className="d-flex flex-column justify-content-center"
          // onClick={()=>navigate('/kyc/upload/selfie')}
        >
          <div className="d-flex justify-content-center">
            <div
              className={`${
                selfie ? "bg-success" : "bg-warning"
              } rounded-circle d-flex justify-content-center align-items-center `}
              style={{
                width: "50px",
                height: "50px",
              }}
            >
              <img
                src="/KYC/uploadselfie.svg"
                alt="document"
                style={{
                  width: "25px",
                }}
              />
            </div>
          </div>
          <div className="text-17 text-center font-weight-medium">Selfie</div>
        </div>
      </div>
    </div>
  );
};
