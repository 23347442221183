import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import GimmiAi from "./GimmiAi";
import MainWrapper from "../../component/wrapper/MainWrapper";

const GimmiAiPage = () => {
  const location = useLocation();
  const appCtx = useSelector((state) => state.app);

  // Extract the route name from the current URL path
  const routeName = location.pathname.split("/")[1] || "Home";

  return (
    <MainWrapper>
      <div className={ "text-black"}>
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            {/* <!-- Row --> */}
            <div class="row">
              {/* portfolio card  */}
              <div className="col-12">
                <div className="card">
                  <div className="card-header border-0 pb-3">
                    {/* <h4 className="card-title">My Portfolio</h4> */}
                  </div>
                  <div className="card-body px-0 pt-0">
                    <div className="w-75 mx-auto">
                      <GimmiAi />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

export default GimmiAiPage;
