import { CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import Webcam from 'react-webcam'
const WebcamComponent = () => <Webcam />
const videoConstraints = {
  width: '100%',
  height: '100%',
  facingMode: 'user',
}
const WebCamComponent = ({ picture, setPicture, handleUpload }) => {
  const webcamRef = React.useRef(null)
  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot()
    setPicture(pictureSrc)
  })
  return (
    <div className="h-[100%] flex flex-col gap-10 justify-center items-center">

      <div>
        {picture == '' ? (
          <Webcam
            audio={false}
            height={400}
            ref={webcamRef}
            onProgress={<CircularProgress />}
            width={400}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
          
        ) : (
          <img src={picture} alt="" />
        )}
      </div>
      <div className='w-[100%] flex justify-center'>
        {picture != '' ? (
          <div className='flex gap-3 w-[100%]'>
            <Button
              onClick={(e) => {
                e.preventDefault()
                setPicture('')
              }}
              className="w-[50%]" primary={true}
            >
              Retake
            </Button>
            <Button primary={false} className="w-[50%] h-[40px]" onClick={() => { handleUpload() }}>Next</Button>
          </div>
        ) : (
          <Button
            onClick={(e) => {
              e.preventDefault()
              capture()
            }}
            className="w-[300px]" primary={true}
          >
            Capture
          </Button>


        )}
      </div>
    </div>
  )
}
export default WebCamComponent