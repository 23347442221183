import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { toast } from "react-toastify";

var CryptoJS = require("crypto-js");

function PasswordModal({ openModal, setOpenModal, setProceedToPay }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passWord, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const width = window.screen.width;
  const appCtx = useSelector((state) => state.app);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
      width: width > 1200 ? "500px" : "370px",
    },
  };

  function closeModal() {
    setOpenModal(false);
  }
  const handlePassword = () => {
    if (passWord) {
      let ciphertext = CryptoJS.SHA256(JSON.stringify(passWord)).toString();

      if (ciphertext === appCtx.password) {
        setProceedToPay(true);
        toast.success("password is correct. you can proceed further")
        closeModal();
      } else {
        toast.error("please enter correct password");
      }
    }
  };
  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="flex flex-col py-[20px] ">
        <div className="p-4 flex flex-col justify-center items-center w-[100%] gap-4">
          <div className="text-[20px] lg:text-[30px] text-purple-600 font-semibold">
            Enter your Password
          </div>

          <div className="flex flex-col justify-start w-[100%]">
            <input
              type={showPassword ? "text" : "password"}
              placeholder={"Enter your password here"}
              value={passWord}
              onChange={(e) => setPassword(e.target.value)}
              className="w-[100%] h-[50px] shadow-xl border border-gray-300 rounded-xl"
            />
            {showPassword ? (
              <RemoveRedEyeOutlinedIcon
                className="relative left-[90%] top-[-35px] cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <VisibilityOffOutlinedIcon
                className="relative left-[90%] top-[-35px] cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </div>

          <button
            primary={true}
            className="w-[100%] lg:w-[50%]"
            onClick={() => {
              handlePassword();
            }}
          >
            Submit
          </button>
        </div>

        {/* <p className="text-center mt-[50px]">Powered by <b>Vortx</b></p> */}
      </div>
      {/* <SecretPassphraseModal openModal={openSecretPassphraseModal} setOpenModal={setOpenSecretPassphraseModal} setOpenPreviousModal={setOpenModal}/> */}
    </Modal>
  );
}

export default PasswordModal;
