// ApiKey.jsx
import React, { useState, useMemo } from "react";
import MainWrapper from "../wrapper/MainWrapper";
import { FaCopy, FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button, Form, Table } from "react-bootstrap";
import ReactApexChart from 'react-apexcharts';

const ApiKey = () => {
  const navigate = useNavigate();

  // State to manage API keys
  const [apiKeys, setApiKeys] = useState([
    { id: 1, key: "vortx***************", status: "Active", createdAt: "2023-10-01" },
    { id: 2, key: "vortx***************", status: "Inactive", createdAt: "2023-09-15" },
  ]);

  // Function to generate a new API key
  const handleGenerateKey = () => {
    const newKey = {
      id: Date.now(),
      key: "vortx" + Math.random().toString(36).substr(2, 16).toUpperCase(),
      status: "Active",
      createdAt: new Date().toISOString().split('T')[0],
    };
    setApiKeys([newKey, ...apiKeys]);
    alert("New Synthesis key generated!");
  };

  // Function to delete an API key
  const handleDeleteKey = (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Synthesis key?");
    if (confirmDelete) {
      setApiKeys(apiKeys.filter(key => key.id !== id));
    }
  };

  // Function to copy an API key to clipboard
  const handleCopyKey = (key) => {
    navigator.clipboard.writeText(key);
    alert("Synthesis key copied!");
  };

  // Sample KPI Data (Replace with actual API data)
  const kpiData = useMemo(() => [
    { label: "Total Synthesis", value: "25,000" },
    { label: "Active Sessions", value: "12" },
    { label: "Synthesis This Month", value: "5,000" },
    { label: "Errors This Month", value: "50" },
  ], []);

  // Sample data for graphs
  const apiUsageData = useMemo(() => ({
    options: {
      chart: {
        id: 'api-usage',
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
        },
      },
      xaxis: {
        categories: ["Further back", "Last Month", "Last Week", "Today"],
      },
      colors: ['#0d6efd'],
      stroke: {
        curve: 'smooth',
      },
    },
    series: [{
      name: 'Synthesized scenes',
      data: [500, 7000, 8000, 9430],
    }],
  }), []);

  const errorRateData = useMemo(() => ({
    options: {
      chart: {
        id: 'error-rate',
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
        },
      },
      xaxis: {
        categories: ["Further back", "Last Month", "Last Week", "Today"],
      },
      colors: ['#dc3545'],
    },
    series: [{
      name: 'Error Rate (%)',
      data: [1.2, 0.8, 0.5, 1.0],
    }],
  }), []);

  return (
    <MainWrapper>
      <div className="container-fluid mt-3">
        {/* Header Section */}
        <Row className="mb-3">
          <Col>
            <h2 style={{ fontSize: "24px", fontWeight: "700", color: "#333" }}>
              Key Management
            </h2>
            <p style={{ fontSize: "12px", fontWeight: "400", color: "#808080" }}>
              Securely manage your Synthesis keys and Wallet Keys.
            </p>
          </Col>
        </Row>

        {/* API Key Management Section */}
        <Row className="mb-3">
          <Col>
            <Card className="p-3 shadow-sm border-0">
              <Card.Body>
                <div className="d-flex mb-3">
                  <Button variant="primary" size="sm" onClick={handleGenerateKey}>
                    Generate New Key
                  </Button>
                </div>
                {/* API Keys Table */}
                <Table responsive bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apiKeys.map((key) => (
                      <tr key={key.id}>
                        <td>
                          <Form.Control
                            type="text"
                            value={key.key}
                            readOnly
                            style={{
                              fontSize: "12px",
                              backgroundColor: "#f5f5f5",
                            }}
                          />
                        </td>
                        <td>{key.status}</td>
                        <td>{key.createdAt}</td>
                        <td>
                          <Button
                            variant="outline-secondary"
                            size="sm"
                            onClick={() => handleCopyKey(key.key)}
                            className="me-2"
                          >
                            <FaCopy /> Copy
                          </Button>
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleDeleteKey(key.id)}
                          >
                            <FaTrashAlt /> Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* KPIs Section */}
        <Row className="mb-3">
          <Col>
            <Card className="p-2 shadow-sm border-0">
              <Row className="g-1">
                {kpiData.map((kpi, index) => (
                  <Col xs={6} sm={3} md={3} key={index}>
                    <Card className="text-center h-100 border-0 bg-grey">
                      <Card.Body className="p-2">
                        <Card.Title
                          className="fs-6 text-uppercase text-muted"
                          style={{ fontSize: '12px' }}
                        >
                          {kpi.label}
                        </Card.Title>
                        <Card.Text
                          className="fw-bold text-dark"
                          style={{ fontSize: '14px' }}
                        >
                          {kpi.value}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card>
          </Col>
        </Row>

        {/* Graphs Section with reduced height */}
        <Row className="mb-3">
          <Col md={6} sm={12}>
            <Card className="p-2 shadow-sm border-0 ">
              <Card.Body>
                <h5
                  className="card-title "
                  style={{ fontSize: '12px', color: '#808080' }}
                >
                  Your Synthesis Journey : API Usage over time
                </h5>
                <ReactApexChart
                  options={apiUsageData.options}
                  series={apiUsageData.series}
                  type="line"
                  height={250}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} sm={12}>
            <Card className="p-2 shadow-sm border-0">
              <Card.Body>
                <h5 className="card-title" style={{ fontSize: '12px' , color: '#808080'}}>
                Error Rate (%)
                </h5>
                <ReactApexChart
                  options={errorRateData.options}
                  series={errorRateData.series}
                  type="bar"
                  height={250}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </MainWrapper>
  );
};

export default ApiKey;
