import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appActions } from '../../context/app-slice';
import { Notes } from './common/Notes';
import { KycHeaderPannel } from './KycHeaderPannel'
import MainWrapper from '../wrapper/MainWrapper';

export const KycUploadDocument = () => {
    const navigate = useNavigate();
    const dispatch= useDispatch();
    const appCtx = useSelector((state)=>state.app);
    return (
      <div class="">
        {/* <!-- row --> */}
        <div class="container-fluid">
          {/* <!-- Row --> */}
          <div class="row">
            {/* banner card  */}
            <div class="col-xl-12">
              <div className="col-12">
                <div className="card">
                  <div className="card-header border-0 "></div>
                  <div className="card-body px-0 pt-0">
                    <div className="d-flex flex-column justify-center align-items-center pb-5 gap-4 mt-5 px-4">
                      <KycHeaderPannel />
                      <div className="d-flex flex-column lg:flex-row gap-4 w-100">
                        <div
                          className="d-flex flex-column gap-3 justify-center align-items-center w-100 lg:w-50 cursor-pointer group"
                          onClick={() => {
                            dispatch(
                              appActions.setDocument(
                                appCtx.country === "India"
                                  ? "Pancard"
                                  : "Passport"
                              )
                            );
                            navigate("/kyc/upload/document");
                          }}
                        >
                          <div className="w-100 h-300 d-flex justify-content-center align-items-center border border-dashed  group ">
                            <img
                              src="/KYC/bluedocument.svg"
                              className="w-250 group-hover:w-260"
                              alt="document"
                            />
                            <div className="text-20 font-weight-medium">
                              {appCtx.country === "India"
                                ? "Pancard"
                                : "Passport"}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center font-weight-medium">
                          OR
                        </div>
                        <div
                          className="d-flex flex-column gap-3 justify-center align-items-center w-100 lg:w-50 cursor-pointer group"
                          onClick={() => {
                            dispatch(
                              appActions.setDocument(
                                appCtx.country === "India"
                                  ? "Adhar Card"
                                  : "Any Other Document"
                              )
                            );
                            navigate("/kyc/upload/document");
                          }}
                        >
                          <div className="w-100 h-300 d-flex justify-content-center align-items-center border border-dashed ">
                            <img
                              src="/KYC/bluedocument.svg"
                              className="w-250 group-hover:w-260"
                              alt="document"
                            />
                            <div className="text-20 font-weight-medium">
                              {appCtx.country === "India"
                                ? "Adhar Card"
                                : "Any Other Document"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Notes />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
