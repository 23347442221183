import React, { useState } from "react";
import { useEffect } from "react";
import MainWrapper from "../../component/wrapper/MainWrapper";
import { useWalletView } from "../../hooks/useWalletView";
import TaleWalletOnRampModal from "../../component/TaleWalletOnRamp/TaleWalletOnRampModal";
import SendAlgosModal from "../../component/BuyAndSellAlgos/sendAlgos/SendAgosModal";
import PaymentStatusLoader from "../../component/paymentStatusLoader/PaymentStatusLoader";
import ModalPaymentSuccess from "../../component/modal-payment-success/ModalPaymentSuccess";
import SwapModal from "../../component/swap/SwapModal";
import TaleWalletOffRampModal from "../../component/TaleWalletOffRamp/TaleWalletOffRampModal";
import ModalPaymentFailure from "../../component/modal-payment-failure/ModalPaymentFailure";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { getImageUrl, nativeToken } from "../../config";
import { useCirclePayment } from "../../component/paymnetgateways/CircleCli/useCirclePayment";
import { CircularProgress } from "@mui/material";
import TokenCard from "../Tokens/TokenCard";
import { AccountAddressBar } from "./AccountAddressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import MetamaskSwapSuccessModal from "../../component/metamaskSwapSuccess/MetamaskSwapSuccessModal";
import { useMetamask } from "../../hooks/useMetamask";
import SwapSuccessModal from "../../component/orderbook/SwapSuccessModal";
import AddTokenModal from "../../component/addTokenModal/AddTokenModal";
import { useCrops } from "../../hooks/useCrops";
import HomeCropCard from "../dashboard/HomeCropCard";

const Wallets = () => {
  const [pausePriceApiCall, setPausePriceApiCall] = useState(false);
  const [openAddTokenModal, setOpenAddTokenModal] = useState(false);
  const [openSendAlgosModal, setOpenSendAlgosModal] = useState(false);
  const [isCropLoading, setIsCropLoading] = useState(false);
  const [allCrops, setAllCrops] = useState([]);
  const [openTalewalletOnrampModal, setOpenTalewalletOnrampModal] =
    useState(false);
  const [openOfframpModal, setOpenOfframpModal] = useState(false);
  const [openTransferSuccessModal, setOpenTransferSuccessModal] =
    useState(false);
  const [transactionData, setTransactionData] = useState(null);

  const [isMetamaskSwapPending, setIsMetamaskSwapPending] = useState(null);
  const [isMetamaskSwapConfirming, setIsMetamaskSwapConfirming] =
    useState(null);
  const [isMetamaskSwapConfirmed, setIsMetamaskSwapConfirmed] = useState(null);
  const [metmaskTransactionHash, setMetmaskTransactionHash] = useState(null);
  const [openMetamaskSwapSuccessModal, setOpenMetamaskSwapSuccessModal] =
    useState(false);
  const [sendTokenId, setSendTokenId] = useState("");
  const [receiveTokenId, setReceiveTokenId] = useState("");

  const makeRequest = useAuthorizedHttp();
  const { switchChainandOpenModal } = useMetamask();
    const { cropListLoading, cropList, getCropList } = useCrops();
  const {
    token,
    accountAsset,
    setAccountAsset,
    amount,
    setAmount,
    assetUrl,
    setAssetUrl,
    handleOptIn,
    showAssets,
    appCtx,
    optedIn,
    taleAmount,
    showTaleData,
    optInSuccessfull,
    setOptInSuccessfull,
    handleCustodialOptIn,
    minBalance,
    getAssetsById,
    assetLoader,
    allCoin,
    allCoinPrice,
    usersToken,
    isAssetLoading,
    setIsAssetLOading,
    isCoinLoading,
    isCoinPriceLOading,
    icon,
    otheraccountAsset,
    otherassetLoader,
    openSwapModal,
    setopenSwapModal,
    openSellModal,
    setopenSellModal,
    getAllCoin,
    getAllCoinPrice,
    getUsersToken,
    totalpage,
    setAllcoinPrice,
    handleRefresh,
    listedToken,
    listedTokenLoading,
    getListedTokens,
    singleTokenPriceLoading,
    singleTokenBalance,
    getSingleTokenBalanceBySymbol,
  } = useWalletView();

  const {
    openModalPaymentFailure,
    openModalPaymentSuccess,
    setOpenModalPaymentFailure,
    setOpenModalPaymentSuccess,
    paymentStatusLoader,
    handleCirclePayment,
    getUserCardList,
    handleCountryCode,
  } = useCirclePayment();
  const address = appCtx.walletAddress.find(
    (addr) => addr.blockchain === appCtx.blockchain
  )?.address;

  useEffect(() => {
    getAllCoin(0, 100);
    getAllCoinPrice();
    getUsersToken();
    getListedTokens();
    getSingleTokenBalanceBySymbol(address, nativeToken[appCtx?.blockchain]);
    getCropList();
  }, [appCtx.blockchain]);

  let usersBalance = (singleTokenBalance && singleTokenBalance?.balance) || 0;
  let nativeTokenValue =
    (allCoinPrice &&
      allCoinPrice[nativeToken[appCtx?.blockchain]]?.price * usersBalance) ||
    0;

  // calling additional price api

  useEffect(() => {
    if (
      allCoin.length !== 0 &&
      Object.keys(allCoinPrice).length !== 0 &&
      allCoin.length !== Object.keys(allCoinPrice).length &&
      !isCoinLoading &&
      !isCoinPriceLOading &&
      !pausePriceApiCall
    ) {
      const tokenSymbolsWithNoPrice = allCoin
        ?.filter((coin) => !allCoinPrice[coin.symbol?.toUpperCase()]?.price)
        ?.map((coin) => coin?.symbol);

      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/price`,
          data: tokenSymbolsWithNoPrice,
          method: "post",
          headers: {
            "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
            "Content-Type": "application/json",
          },
        },
        (data) => {
          setAllcoinPrice((prev) => ({ ...prev, ...data }));
          setPausePriceApiCall(true);
        },
        (error) => {}
      );
    }
  }, [allCoin, allCoinPrice, appCtx.blockchain]);

  const themedColor = "black";

    useEffect(() => {
      setIsCropLoading(true);
      makeRequest(
        {
          url: `${process.env.REACT_APP_VORTX_URL}/crop/master/list`,
        },
        (data) => {
          setAllCrops(data);
          setIsCropLoading(false);
        },
        (error) => {
          setIsCropLoading(false);
        }
      );
    }, []);

  return (
    <>
      <MainWrapper>
        <div class="">
          <div class="container-fluid">
            <div className="row">
              {/* top section  */}
              <div className="col-xl-12">
                <div class="card coin-content">
                  <div class="card-body">
                    <div className="d-flex justify-content-center  ">
                      <AccountAddressBar address={address} />
                    </div>
                    {!singleTokenPriceLoading && allCoinPrice ? (
                      <div className="d-flex justify-content-center align-items-center gap-3   my-4 ">
                        <div>
                          <img
                            src={getImageUrl(appCtx?.blockchain)}
                            alt=""
                            style={{ width: "40px" }}
                          />
                        </div>
                        <div>
                          <p className="fs-16 font-w400 mb-0">
                            Balance: {usersBalance || "0.00"}{" "}
                          </p>
                          <p className="fs-16 font-w400 mb-0">
                            Value:{" "}
                            {appCtx.blockchain !== "ALGORAND" &&
                              `$${nativeTokenValue?.toFixed(4)}`}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div
                        class="d-flex justify-content-center align-items-center "
                        style={{ minHeight: "100px" }}
                      >
                        <CircularProgress
                          className="py-10"
                          fontSize="small"
                          style={{
                            width: "25px",
                            height: "25px",
                            color: "#BBFF00",
                            fontSize: "10px",
                          }}
                        />
                      </div>
                    )}
                    <div className="d-flex gap-5 justify-content-center my-2 position-relative">
                      <div
                        onClick={() => {
                          setOpenTalewalletOnrampModal(true);
                        }}
                        className="d-flex flex-column  justify-content-center align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="/images/buyicon.svg"
                          alt="Button Image"
                          className="w-15"
                        />
                        <h5>Buy</h5>
                      </div>
                      <div
                        onClick={() => {
                          if (appCtx?.custodial) {
                            showTaleData();
                            allCoin &&
                              allCoinPrice &&
                              usersToken &&
                              setOpenSendAlgosModal(true);
                          } else {
                            showTaleData();
                            switchChainandOpenModal(setOpenSendAlgosModal);
                          }
                        }}
                        className="d-flex flex-column  justify-content-center align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="/images/sendicon.svg"
                          alt="Button Image"
                          className="w-15"
                        />
                        <h5>Send</h5>
                      </div>
                      <div
                        onClick={() => {
                          setOpenOfframpModal(true);
                        }}
                        className="d-flex flex-column  justify-content-center align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="/images/sellicon.svg"
                          alt="Button Image"
                          className="w-15"
                        />
                        <h5>Sell</h5>
                      </div>
                      <div
                        onClick={() => {
                          setopenSwapModal(true);
                        }}
                        className="d-flex flex-column  justify-content-center align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="/images/swap.svg"
                          alt="Button Image"
                          className="w-15"
                        />
                        <h5>Swap</h5>
                      </div>
                      <div
                        onClick={handleRefresh}
                        className="position-absolute top-0 end-0 cursor-pointer"
                      >
                        <FontAwesomeIcon
                          icon={faArrowsRotate}
                          style={{
                            color: themedColor,
                            fontSize: "16px",
                          }}
                        />
                      </div>
                      <div className="position-absolute bottom-0 end-0 cursor-pointer">
                        <button
                          onClick={() => {
                            setOpenAddTokenModal(true);
                          }}
                          type="button"
                          class="btn btn-primary"
                        >
                          Add Crops
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Crops section  */}

              {/* my Crops  */}

              <div class="col-12 my-order-ile">
                <div class="card">
                  <div class="card-header border-0 pb-3">
                    <h4 class="card-title">My Crops</h4>
                  </div>
                  <div class="card-body px-0 pt-0 ">
                    {isCoinLoading || listedTokenLoading ? (
                      <div
                        class="d-flex justify-content-center align-items-center "
                        style={{ minHeight: "100px" }}
                      >
                        <CircularProgress
                          className="py-10"
                          fontSize="small"
                          style={{
                            width: "25px",
                            height: "25px",
                            fontSize: "10px",
                          }}
                        />
                      </div>
                    ) : !isCoinLoading &&
                      (usersToken.length === 0 ||
                        usersToken.every((item) => item?.balance === 0)) ? (
                      <div
                        class="alert alert-danger alert-dismissible fade show"
                        style={{
                          width: "60%",
                          margin: "50px auto",
                          textAlign: "center",
                        }}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          stroke="currentColor"
                          stroke-width="2"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="me-2 alert-icon"
                        >
                          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                          <line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>
                        You don't have any Crop
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="btn-close"
                        ></button>
                      </div>
                    ) : (
                      allCoin.map((coin, index) => {
                        const TokenBalance = usersToken.filter(
                          (element) =>
                            coin.symbol === element.token &&
                            coin.blockchain === element.blockchain
                        );

                        if (TokenBalance[0]?.balance > 0) {
                          let listedTokenObject = listedToken?.find(
                            (token) => token.symbol === coin.symbol
                          );
                          return (
                            <div>
                              <TokenCard
                                key={index}
                                coin={coin}
                                allCoinPrice={allCoinPrice}
                                usersToken={usersToken}
                                listedTokenObject={listedTokenObject}
                              />
                            </div>
                          );
                        }
                      })
                    )}
                  </div>
                </div>
              </div>

              {/*Crops card  */}

              <div class="col-xl-12" style={{ minHeight: "300px" }}>
                {cropListLoading ? (
                  <div class="d-flex justify-content-center align-items-center card crypto-chart">
                    <CircularProgress
                      style={{
                        color: "#BBFF00",
                        fontSize: "10px",
                        width: "25px",
                        height: "25px",
                      }}
                      className="py-10"
                      fontSize="small"
                    />
                  </div>
                ) : (
                  <>
                    {cropList?.length === 0 ? (
                      <div className="card crypto-chart">
                        <div className="mt-5">
                          <p className="text-center  ">No Crop Found</p>
                        </div>
                      </div>
                    ) : (
                      <div class="card">
                        <div class="card-header pb-0 border-0 flex-wrap">
                          <div class="mb-0">
                            <h4 class="card-title">Explore Crops</h4>
                          </div>
                        </div>
                        <div class="card-body pt-2">
                          {cropList?.map((crop) => (
                            <HomeCropCard crop={crop} />
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {openTalewalletOnrampModal && (
                <TaleWalletOnRampModal
                  openModal={openTalewalletOnrampModal}
                  setOpenModal={setOpenTalewalletOnrampModal}
                  // amount={amount}
                  // basketId={basketId}
                  // tokenPercentArray={tokenPercentArray}
                  // tokenArray={tokenArray}
                  // basketBlockchain={basketBlockchain}
                  symbol={nativeToken[appCtx?.blockchain]}
                />
              )}
            </div>
          </div>
        </div>

        {openSendAlgosModal && (
          <SendAlgosModal
            openModal={openSendAlgosModal}
            setOpenModal={setOpenSendAlgosModal}
            allCoin={allCoin}
            allCoinPrice={allCoinPrice}
            usersToken={usersToken}
          />
        )}

        {paymentStatusLoader && (
          <PaymentStatusLoader openModal={paymentStatusLoader} />
        )}

        {openModalPaymentFailure && (
          <ModalPaymentFailure
            openModalPaymentFailure={openModalPaymentFailure}
            setOpenModalPaymentFailure={setOpenModalPaymentFailure}
          />
        )}
        {openModalPaymentSuccess && (
          <ModalPaymentSuccess
            openModalPaymentSuccess={openModalPaymentSuccess}
            setOpenModalPaymentSuccess={setOpenModalPaymentSuccess}
          />
        )}
        {openSwapModal && (
          <SwapModal
            openModal={openSwapModal}
            setOpenModal={setopenSwapModal}
            allCoinPrice={allCoinPrice}
            usersToken={usersToken}
            allCoin={allCoin}
            symbol={nativeToken[appCtx?.blockchain]}
            setTransactionData={setTransactionData}
            setOpenTransferSuccessModal={setOpenTransferSuccessModal}
            isMetamaskSwapPending={isMetamaskSwapPending}
            setIsMetamaskSwapPending={setIsMetamaskSwapPending}
            isMetamaskSwapConfirming={isMetamaskSwapConfirming}
            setIsMetamaskSwapConfirming={setIsMetamaskSwapConfirming}
            isMetamaskSwapConfirmed={isMetamaskSwapConfirmed}
            setIsMetamaskSwapConfirmed={setIsMetamaskSwapConfirmed}
            metmaskTransactionHash={metmaskTransactionHash}
            setMetmaskTransactionHash={setMetmaskTransactionHash}
            setOpenMetamaskSwapSuccessModal={setOpenMetamaskSwapSuccessModal}
            sendTokenId={sendTokenId}
            setSendTokenId={setSendTokenId}
            receiveTokenId={receiveTokenId}
            setReceiveTokenId={setReceiveTokenId}
          />
        )}
        {openOfframpModal && (
          <TaleWalletOffRampModal
            openModal={openOfframpModal}
            setOpenModal={setOpenOfframpModal}
            // closeBuyModal={closeModal}

            allCoin={allCoin}
            allCoinPrice={allCoinPrice}
            usersToken={usersToken}
            // amount={amount}
            // basketId={basketId}
            // tokenPercentArray={tokenPercentArray}
            // tokenArray={tokenArray}
            // basketBlockchain={basketBlockchain}
            symbol={nativeToken[appCtx?.blockchain]}
          />
        )}

        {openTransferSuccessModal && (
          <SwapSuccessModal
            openModal={openTransferSuccessModal}
            setOpenModal={setOpenTransferSuccessModal}
            transactionData={transactionData}
            isPerformingSwap={true}
          />
        )}

        {(isMetamaskSwapPending ||
          isMetamaskSwapConfirming ||
          metmaskTransactionHash) && (
          <MetamaskSwapSuccessModal
            openModal={openMetamaskSwapSuccessModal}
            setOpenModal={setOpenMetamaskSwapSuccessModal}
            isMetamaskSwapPending={isMetamaskSwapPending}
            isMetamaskSwapConfirming={isMetamaskSwapConfirming}
            isMetamaskSwapConfirmed={isMetamaskSwapConfirmed}
            metmaskTransactionHash={metmaskTransactionHash}
            setopenSwapModal={setopenSwapModal}
            sendTokenId={sendTokenId}
            receiveTokenId={receiveTokenId}
          />
        )}
        <AddTokenModal
          openModal={openAddTokenModal}
          setOpenModal={setOpenAddTokenModal}
        />
      </MainWrapper>
    </>
  );
};

export default Wallets;
