import { useRef, useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmSendPaymentModal from "./ConfirmSendPayment";
import { GetDecryptedData } from "../../GetDecryptedData/GetDecryptedData";
import { useWalletView } from "../../../hooks/useWalletView";
import AlgorandClient from "../../services/algorandsdk";
import useAuthorizedHttp from "../../../hooks/use-authorized-http";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronDown,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { ethers } from "ethers";
import web3 from "web3";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useChainId } from "wagmi";
import { nativeToken } from "../../../config";
import SuccessModal from "./SuccessModal";
import { CircularProgress } from "@mui/material";
import TokenSelectionDropdown from "../../tokenSelectionDropdown/TokenSelectionDropdown";
import { erc20Abi } from "../../Abi/abi";

function SendAlgosModal({
  openModal,
  setOpenModal,
  allCoinPrice,
  usersToken,
  isTokenDetailsPage,
  tokenname,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const makeAuthorizedRequest = useAuthorizedHttp();
  const [amount1, setAmount1] = useState(0);
  const [algos, setAlgos] = useState(0);
  const [openPayment, setOpenPayment] = useState(false);
  const [address, setAddress] = useState("");
  const [info, setInfo] = useState(false);

  const appCtx = useSelector((state) => state.app);
  const width = window.screen.width;
 let isMobileDevice = width < 600;
  const { decryptedData } = GetDecryptedData();
  const [openSetupPassphrase, setOpenSetupPassphrase] = useState(false);
  const [allTokenName, setAllTokenName] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [sendTokenName, setsendTokenName] = useState(null);

  const [isMetaMaskInstall, setIsMetaMaskInstall] = useState();
  const [isMetaMaskConnected, setIsMetaMaskConnected] = useState(true);
  const [networkVersion, setNetworkVersion] = useState();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [metamaskTransactionHash, setMetamaskTransactionHash] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [
    openSelfCustodySendTransferSuccessModal,
    setOpenSelfCustodySendTransferSuccessModal,
  ] = useState(false);

  const [option, setOption] = useState(
    tokenname || nativeToken[appCtx?.blockchain]
  );
  const [metamaskTransferLoading, setMetamaskTransferLoading] = useState(false);
  const componentRef = useRef(null);

  const chainId = useChainId() || 137;

  const { getAllCoin, allCoin } = useWalletView();
  useEffect(() => {
    getAllCoin(0, 60);
  }, [appCtx.blockchain]);

  let sendTokenObject = allCoin
    ? allCoin.find((obj) => obj.symbol === option)
    : {};

  //const themedColor = "black";
  const themedColor = "black";


  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9994,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9995,
      backgroundColor: "#FFFFFF",
      border: "1px solid  #FFFFFF",
      color: "black",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: `${isMobileDevice ? "95%" : "500px"}`,
    },
  };

  function closeModal() {
    setOpenModal(false);
    setInfo(false);
    setAlgos(0);
  }

  const { amount, optedIn, showAssets, taleAmount, showTaleData, minBalance } =
    useWalletView();

  const usersWalletAddress = appCtx?.walletAddress?.find(
    (addr) => addr?.blockchain === appCtx?.blockchain
  )?.address;

  useEffect(() => {
    showTaleData();
  }, []);

  const getWeb3 = () => {
    if (window.ethereum) {
      let provider = new ethers.BrowserProvider(window.ethereum);
      return provider;
    } else {
      return false;
    }
  };

  const provider = getWeb3();

  // const setAccountDetails = async () => {
  //   const accounts = await provider.listAccounts();
  //   if (accounts.length === 0) {
  //     setIsMetaMaskConnected(false);
  //   } else {
  //     setIsMetaMaskConnected(true);
  //   }
  // };
  // // subscription for account change detection
  // const accountChangeDetection = async () => {
  //   window.ethereum.on("accountsChanged", async function () {
  //     setIsDataLoading(true);
  //     await setAccountDetails().finally(() => setIsDataLoading(false));
  //   });
  // };

  const handleClick = (event) => {
    event.preventDefault();
    // Check if the clicked element has the 'button-id' ID
    if (event.target.id === "sendButton") {
      setOpen(true);
    } else if (
      event.target.id === "searchbar" ||
      event.target.id === "searchIcon"
    ) {
    } else if (event.target.id === "receiveButton") {
      // setReceiveOpen(true);
    } else {
      setOpen(false);
      // setReceiveOpen(false);
    }
  };

  useEffect(() => {
    if (!appCtx?.custodial) {
      const init = async () => {
        try {
          await provider.send("eth_requestAccounts", []);
          setIsMetaMaskInstall(true);
          // first time set current network version Id.
          const { chainId } = await provider.getNetwork();
          setNetworkVersion(chainId);
          // first time call for load initial details
          // await setAccountDetails();
          // subscription for account change detection
          // await accountChangeDetection();
        } catch (e) {
          setIsMetaMaskInstall(false);
          setIsDataLoading(false);
          setIsMetaMaskConnected(false);
          const eMessage = e.message.split("{")[0] || "";
          console.log(eMessage);
        }
      };
      init().then((r) => setIsDataLoading(false));
    }
  }, []);

  useEffect(() => {
    if (!appCtx?.custodial) {
      const networkVersionChangeDetection = async () => {
        window.ethereum.on("chainChanged", function (networkId) {
          setNetworkVersion(Number(networkId));
        });
      };

      // Call the async function
      networkVersionChangeDetection();

      // Ensure proper cleanup to avoid memory leaks
      return () => {
        // Remove the listener if it's needed; replace with the appropriate cleanup code
        // window.ethereum.removeListener("chainChanged", networkVersionChangeDetection);
      };
    }
  }, [networkVersion]);

  const handleERC20Transfer = async () => {
    setMetamaskTransferLoading(true);
    //Calling the metamask plugin
    const Web3 = new web3(window.ethereum);

    let sendTokensDecimal = sendTokenObject?.decimals;

    const ethValue = ethers.parseUnits(algos.toString(), sendTokensDecimal);
    let contractAddress =
      sendTokenObject?.contractAddress ||
      "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";

    // const ethValueBigInt = 1n * 10n ** 6;
    //   const ethValue = Number(ethValueBigInt);

    //Intialize the contract
    var tokenContract = new Web3.eth.Contract(erc20Abi, contractAddress, {
      from: usersWalletAddress,
    });

    // Call the contract method transfer to send token to recipient id
    const transaction = tokenContract.methods.transfer(
      address, //receiver address
      ethValue
    );
    const gas = await transaction.estimateGas({
      from: usersWalletAddress,
    });
    const gasPrice = await Web3.eth.getGasPrice();

    transaction
      .send({
        from: usersWalletAddress,
        gas,
        gasPrice,
      })
      .on("transactionHash", (hash) => {
        setStatus(`Transaction sent! Hash: ${hash}`);
        setMetamaskTransactionHash(hash);
        setOpenSelfCustodySendTransferSuccessModal(true);
        setMetamaskTransferLoading(false);
      })
      .on("receipt", (receipt) => {
        setStatus("Transfer successful");
        setMetamaskTransferLoading(false);
      })
      .on("error", (error) => {
        if (error.message.includes("User denied transaction signature")) {
          // User denied transaction signature
          setStatus("User denied the transfer.");
        } else {
          console.log("Error:", error);
          setStatus("Error during the transfer");
        }
        setMetamaskTransferLoading(false);
      });
  };

  console.log("status", status);

  const handleNativeTransfer = async () => {
    if (!provider || !usersWalletAddress) return;
    setMetamaskTransferLoading(true);

    let sendTokensDecimal = sendTokenObject?.decimals;

    const ethValue = ethers.parseUnits(algos.toString(), sendTokensDecimal);

    const tx = {
      to: address, //receiver address
      value: ethValue,
      chainId,
    };

    try {
      const signer = await provider.getSigner();
      const txResponse = await signer.sendTransaction(tx);
      setStatus(`Transaction sent! Hash: ${txResponse.hash}`);
      setMetamaskTransactionHash(txResponse.hash);
      setOpenSelfCustodySendTransferSuccessModal(true);

      // Listen for transaction confirmation (optional)
      provider.once(txResponse.hash, (transaction) => {
        if (transaction.confirmations) {
          setStatus("Transfer successful");
          setMetamaskTransferLoading(false);
        }
      });
    } catch (error) {
      const { code, message } = error.info.error;
      if (code === 4001) {
        setStatus("User denied the transaction.");
      }
      setMetamaskTransferLoading(false);
    }
  };

  // useEffect(() => {
  //   if (appCtx.isLoggedIn) {
  //     if (appCtx.custodial !== true && decryptedData === "") {
  //       closeModal();
  //     }
  //   }
  // }, [appCtx.custodial, appCtx.isLoggedIn, decryptedData]);

  const checkDollars = () => {
    makeAuthorizedRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/convert/algorand/${algos}/to/usd`,
        method: "get",
      },
      (data) => {
        setAmount1(data.usd);
      },
      (rej) => {
        setAmount1(0);
      }
    );
  };

  useEffect(() => {
    if (appCtx.isLoggedIn) {
      if (appCtx.custodial !== true && decryptedData === "") {
        setOpenSetupPassphrase(true);
      } else {
        setOpenSetupPassphrase(false);
      }
    } else {
      setOpenSetupPassphrase(false);
    }
  }, [appCtx.custodial, appCtx.isLoggedIn, decryptedData]);

  const handleNext = async () => {
    if (appCtx?.custodial) {
      if (appCtx.blockchain === "ALGORAND") {
        if (option !== "tale") {
          if (algos > 0) {
            if (algos <= amount) {
              setOpenPayment(true);
            } else {
              toast.error("You dont have enough Algos or Tale coin !!");
            }
          } else {
            toast.error(
              "Algos or Tale Coin you are sending should be greater than 0"
            );
          }
        } else {
          if (optedIn) {
            if (algos > 0) {
              setOpenPayment(true);
            } else {
              toast.error(
                "Algos or Tale Coin you are sending should be greater than 0"
              );
            }
          } else {
            toast.error(
              "You need to first optIn tale coin asset to send tale coins!!!"
            );
          }
        }
      } else {
        // need to modify this
        if (algos > 0) {
           setOpenPayment(true);
        } else {
          toast.error("Coin you are sending should be greater than 0");
        }
      }
    } else {
      // code for metamask send

      if (sendTokenObject?.symbol === nativeToken[appCtx?.blockchain]) {
        handleNativeTransfer();
      } else {
        handleERC20Transfer();
      }
    }
  };

  const handleConfirmChange = async (address) => {
    setAddress(address);
    showAssets();
    showTaleData();
    if (address !== appCtx.walletAddress[0]?.address) {
      try {
        if (appCtx.blockchain === "ALGORAND") {
          let accountInfo = await AlgorandClient.accountInformation(
            address
          ).do();
          if (accountInfo) {
            setInfo(true);
          } else {
            setInfo(false);
          }
        } else {
          setInfo(true);
        }
      } catch {
        // toast.error('Please enter algorand wallet address !!!');
        setInfo(false);
        setAlgos(0);
      }
    } else {
      toast.error("You cannot transfer Coin to your own account ");
    }
  };

  const getsinglebal =
    usersToken &&
    usersToken.some(
      (singleToken) =>
        singleToken.token === option &&
        singleToken.blockchain === appCtx.blockchain
    )
      ? usersToken
          .filter(
            (singleToken) =>
              singleToken.blockchain === appCtx.blockchain &&
              singleToken.token === option
          )
          ?.map((singleToken) => `${singleToken.balance}`)
          .join("")
      : "0";
  const NativeTokenBalance =
    usersToken &&
    usersToken.some(
      (singleToken) =>
        singleToken.token === nativeToken[appCtx?.blockchain] &&
        singleToken.blockchain === appCtx.blockchain
    )
      ? usersToken
          .filter(
            (singleToken) =>
              singleToken.blockchain === appCtx.blockchain &&
              singleToken.token === nativeToken[appCtx?.blockchain]
          )
          ?.map((singleToken) => `${singleToken.balance}`)
          .join("")
      : "0";

  let initialtokenName = allCoin
    ? allCoin.find((obj) => obj.symbol === option)?.tokenName
    : "";

  let tokensIcon = allCoin
    ? allCoin.find((obj) => obj.symbol === option)?.tokenIcon
    : "";

  const handleMaxButtonClick = () => {
    setAlgos(getsinglebal);
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="w-[100%] flex justify-end mr-[20px] pt-[10px] ">
        <div className="d-flex justify-content-end m-2 position-relative">
          <button
            onClick={closeModal}
            className="btn btn-link position-absolute top-0 right-0"
          >
            <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
          </button>
        </div>
      </div>
      <div
        class="d-flex flex-column justify-content-center align-items-center px-3 pb-4 gap-1 "
        ref={componentRef}
        onClick={handleClick}
      >
        <h4 className="mb-3">Send To</h4>
        <div className="" style={{ width: "100%" }}>
          <div class="position-relative">
            <input
              class="w-100 rounded-lg border py-2 px-4 form-control"
              style={{
                color: themedColor,
              }}
              placeholder="Paste wallet address"
              defaultValue={address}
              onChange={(e) => {
                handleConfirmChange(e.target.value);
              }}
            />
            {!info ? (
              <FontAwesomeIcon
                icon={faSearch}
                className="position-absolute end-0 top-50 translate-middle-y me-4"
                style={{
                  color: themedColor,
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCheck}
                className="position-absolute end-0 top-50 translate-middle-y me-4"
                style={{
                  color: themedColor,
                }}
              />
            )}
          </div>
        </div>
        {info ? (
          <>
            <div className="w-100 d-flex  align-items-center mt-4  ">
              <h5 className="mb-0" style={{ width: "130px" }}>
                Asset:
              </h5>
              <div className="w-100 d-flex gap-1 justify-content-start text-[13px] align-items-center border p-1">
                <div>
                  {appCtx.blockchain === "ALGORAND" ? (
                    <img
                      src={
                        option === "tale"
                          ? `/images/talecoinstack.svg`
                          : `/images/Algorand.png`
                      }
                      alt="Algorand"
                      style={{ width: "30px" }}
                    />
                  ) : (
                    <img
                      src={tokensIcon}
                      style={{ width: "30px" }}
                      alt={option}
                    />
                  )}
                </div>
                <h6 className="d-flex flex-column w-100 justify-content-between ms-2 font-w400 ">
                  <div>Balance: </div>
                  <div>{`${
                    appCtx.blockchain === "ALGORAND"
                      ? option === "tale"
                        ? `${taleAmount} Tale`
                        : `${amount} Algo`
                      : `${getsinglebal}`
                  }`}</div>
                </h6>
                <div className="p-1 border position-relative">
                  {appCtx.blockchain === "ALGORAND" ? (
                    <select
                      value={option}
                      onChange={(e) => {
                        setOption(e.target.value);
                        setAlgos(0);
                      }}
                    >
                      <option value="algo">Algo</option>
                      <option value="tale">Tale Coin</option>
                    </select>
                  ) : (
                    <>
                      {/* dropdown  */}
                      <div className="position-relative">
                        <div
                          onClick={() => setOpen(!open)}
                          style={{
                            width: `${isMobileDevice ? "50px" : "100px"}`,

                            backgroundColor: `${
                              appCtx.isDarkMode ? "#FFFFFF" : "#FFFFFF"
                            }`,
                            color: `${
                              appCtx.isDarkMode ? "#717579" : "#717579"
                            }`,
                            cursor: "pointer",
                          }}
                        >
                          <div
                            className={`d-flex align-items-center justify-content-between rounded-lg  font-w400 mb-0`}
                            id="sendButton"
                          >
                            <p className="mb-0" id="sendButton">
                              {sendTokenName
                                ? sendTokenName?.length > 15
                                  ? sendTokenName?.substring(0, 15) + "..."
                                  : sendTokenName
                                : initialtokenName}
                            </p>
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              rotation={open ? 180 : 0}
                              className="ms-1"
                              style={{
                                color: themedColor,
                              }}
                            />
                          </div>
                        </div>
                        {open && (
                          <TokenSelectionDropdown
                            searchTerm={inputValue}
                            setSearchTerm={setInputValue}
                            allCoin={allCoin}
                            isDrodownOpen={open}
                            setIsDropdownOpen={setOpen}
                            setToken={setOption}
                            setTokenName={setsendTokenName}
                            additionalStyles={{ top: "-60px", right: "0px" }}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 d-flex  align-items-center mt-4 ">
              <h5 className="mb-0" style={{ width: "130px" }}>
                Amount:
              </h5>
              <div className="w-100 d-flex gap-1 justify-content-start text-[13px] align-items-center border p-1">
                <div>
                  {appCtx.blockchain === "ALGORAND" ? (
                    <img
                      src={
                        option === "tale"
                          ? `/images/talecoinstack.svg`
                          : `/images/Algorand.png`
                      }
                      style={{ width: "30px" }}
                      alt="Algorand"
                    />
                  ) : (
                    <img
                      src={tokensIcon}
                      style={{ width: "30px" }}
                      alt={option}
                    />
                  )}
                </div>
                <div>
                  <div className="d-flex gap-2 align-items-center">
                    {/* <div className="ms-2">
                      <input
                        className="rounded-none border py-2 px-2 "
                        placeholder={`Enter amount`}
                        value={algos}
                        type="number"
                        onChange={(e) => {
                          setAlgos(e.target.value);
                          setAmount1(0);
                        }}
                        style={{
                          backgroundColor: `${
                            appCtx?.isDarkMode ? "#09153F" : "white"
                          }`,
                          color: "black",
                        }}
                      />
                    </div> */}

                    <div class="position-relative d-flex">
                      <div class="position-relative d-flex align-items-center">
                        <input
                          id="sendAmount"
                          placeholder="Enter amount"
                          name="sendAmount"
                          type="number"
                          min="0"
                          step="any"
                          value={algos}
                          onChange={(e) => {
                            setAlgos(e.target.value);
                            setAmount1(0);
                          }}
                          class="form-control"
                          style={{
                            width: `${isMobileDevice ? "180px" : "210px"}`,
                          }}
                        />
                        <button
                          type="button"
                          class="position-absolute text-sm text-gray-700 bg-transparent border border-gray-400 rounded px-2 py-1"
                          onClick={handleMaxButtonClick}
                          style={{
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: "5px",
                          }}
                        >
                          <h6 class="font-s12 font-w400 mb-0">max</h6>
                        </button>
                      </div>
                    </div>
                    <h6 className=" font-w400 mb-0">
                      {appCtx.blockchain === "ALGORAND" ? (
                        option === "tale" ? (
                          <div>Tale</div>
                        ) : (
                          <div>Algo</div>
                        )
                      ) : (
                        option
                      )}
                    </h6>

                    {appCtx.blockchain === "ALGORAND" && option !== "tale" && (
                      <button
                        className={`h-[20px] flex justify-center items-center rounded-lg w-[50px] ml-[20px]`}
                        onClick={() => {
                          checkDollars();
                        }}
                      >
                        check
                      </button>
                    )}
                  </div>
                  {appCtx.blockchain === "ALGORAND" &&
                    option !== "tale" &&
                    amount1 !== 0 &&
                    amount1 && <div>{amount1} USD</div>}
                </div>
              </div>
            </div>

            {!appCtx?.custodial && (
              <div className="w-100">
                <p className="text-danger mt-2">{status}</p>
              </div>
            )}

            <div class="w-100 mt-5 mb-4 d-flex gap-4">
              <div class="w-50">
                <button
                  onClick={() => {
                    closeModal();
                  }}
                  class="mb-3  btn  d-flex justify-content-center align-items-center gap-2 w-100 py-2"
                  style={{
                    backgroundColor: "#44037D",
                  }}
                >
                  Cancel
                </button>
              </div>
              <div class="w-50">
                <button
                  disabled={metamaskTransferLoading}
                  onClick={() => {
                    if (appCtx.blockchain === "ALGORAND") {
                      if (
                        parseFloat(
                          parseFloat(amount) - parseFloat(algos) - 0.01
                        ) > parseFloat(minBalance)
                      ) {
                        if (amount1 === 0 && algos !== 0) checkDollars();
                        handleNext();
                      } else {
                        toast.error("You don't have enough token to send");
                      }
                    } else {
                      if (NativeTokenBalance < 0.0003) {
                        toast.error(
                          `You don't have enough ${
                            nativeToken[appCtx?.blockchain]
                          } to pay the Gas Fee`
                        );
                      } else if (algos <= getsinglebal) {
                        handleNext();
                      } else {
                        toast.error("You don't have enough token to send");
                      }
                    }
                  }}
                  class="mb-3  btn btn-primary d-flex justify-content-center align-items-center gap-2 w-100 py-2"
                >
                  Next{" "}
                  {metamaskTransferLoading && (
                    <span>
                      <CircularProgress
                        style={{
                          fontSize: "10px",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    </span>
                  )}
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div class="w-100 border rounded-lg h-80 d-flex flex-column justify-content-center align-items-start mt-2 mb-4 p-2">
              <p class="font-w600 mb-1">Note :-</p>
              <p class="font-weight-normal text-sm mb-0 ">
                You must enter a valid address to transfer token.
              </p>
            </div>
          </>
        )}
      </div>
      {/* <SetupPassphrase
        openModal={openSetupPassphrase}
        setOpenModal={setOpenSetupPassphrase}
      /> */}
      <ConfirmSendPaymentModal
        openModal={openPayment}
        setOpenModal={setOpenPayment}
        setPreviousOpen={setOpenModal}
        address={address}
        amount={amount1}
        algos={algos}
        setAlgos={setAlgos}
        showAssets={showAssets}
        setInfo={setInfo}
        option={option}
        allCoin={allCoin}
      />

      {openSelfCustodySendTransferSuccessModal && (
        <SuccessModal
          openModal={openSelfCustodySendTransferSuccessModal}
          setOpenModal={setOpenSelfCustodySendTransferSuccessModal}
          amount={amount}
          option={option}
          address={address}
          setPreviousOpen={setOpenModal}
          setConfirmOpen={setConfirmOpen} // no work
          transactionHash={metamaskTransactionHash}
        />
      )}
    </Modal>
  );
}

export default SendAlgosModal;
