import { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { KycHeaderPannel } from "./KycHeaderPannel";
import SignupPageModal from "../CustodialWalletSetup/SignupPageModal";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { appActions } from "../../context/app-slice";
import MainWrapper from "../wrapper/MainWrapper";
import { Button } from "react-bootstrap";
import { Skeleton, Stack } from "@mui/material";
import { KycSelectCountry } from "./KycSelectCountry";
import { FrontAndBackDocument } from "./FrontAndBackDocument";
import Timeline from "./Timeline";
import { KycSelectDocumentType } from "./KycSelectDocumentType";

function KycVerification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const makeAuthorizedRequest = useAuthorizedHttp();
  const appCtx = useSelector((state) => state.app);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [kycLoading, setKycLoading] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDocumentTypeSelectionModal, setOpenDocumentTypeSelectionModal] =
    useState(false);
  const [openSignupModal, setOpenSignupModal] = useState(
    !appCtx.authToken ? true : false
  );
  const [currentIndex, setCurrentIndex] = useState(0);

  let themedbgcolor = `${appCtx?.isDarkMode ? "#9C1EE9" : "#9C1EE9"}`;

  const componentRef = useRef(null);
  useEffect(() => {
    if (!appCtx.userDetails?.email) {
      setOpenSignupModal(true);
      // toast.error('You need to complete your KYC to Buy ALgos and Tale Coin assets !!')
    } else {
      if (
        appCtx.country &&
        (appCtx.frontSide || appCtx.backSide) &&
        appCtx.picture
      ) {
        navigate("/kyc/information");
      } else {
        if (appCtx.verified) {
          navigate("/kyc/information");
        }
      }
      setOpenSignupModal(false);
    }
  }, [
    appCtx.authToken,
    appCtx.backSide,
    appCtx.country,
    appCtx.frontSide,
    appCtx.picture,
    appCtx.userDetails,
    appCtx.verified,
    navigate,
  ]);

  useEffect(() => {
    if (appCtx.authToken) {
      setKycLoading(true);
      let url = `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/kyc`;

      makeAuthorizedRequest(
        {
          url: url,
          method: "get",
          headers: {
            "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
        },
        (data) => {
          dispatch(appActions.setCountry(data.country));
          dispatch(appActions.setDocument(data.document));
          dispatch(appActions.setPicture(data.selfieUrl));
          dispatch(appActions.setFrontSide(data.documentUrl));
          dispatch(appActions.setBackSide(data.otherDocumentUrl));
          dispatch(appActions.setVerified(data.verified));
          dispatch(appActions.setRejection(data.rejectionReason));
          setKycLoading(false);
        },
        (error) => {
          setKycLoading(false);
        }
      );
    }
  }, [appCtx.authToken, dispatch, makeAuthorizedRequest]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
    // Check if the clicked element has the 'button-id' ID
    if (event.target.id === "sendButton") {
      //  setSendOpen(true);
    } else if (
      event.target.id === "searchbar" ||
      event.target.id === "searchIcon"
    ) {
    } else if (event.target.id === "receiveButton") {
      setOpen(true);
    } else {
      //  setSendOpen(false);
      setOpen(false);
    }
  };

  return (
    <>
      <MainWrapper>
        <div>
          <div class="">
            {/* <!-- row --> */}
            <div class="container-fluid">
              {/* <!-- Row --> */}
              <div class="row">
                {/* banner card  */}
                <div class="col-xl-12">
                  <div class="">
                    {kycLoading ? (
                      <div className="d-flex flex-row justify-between gap-2 mb-4">
                        {[...Array(4)].map((_, index) => (
                          <div className="flex-grow-1" key={index}>
                            <Stack spacing={1}>
                              <Skeleton height={130} variant="rounded" />
                            </Stack>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        {!nextClicked && (
                          <div className="col-12">
                            <div className="card">
                              <div className="card-header border-0 "></div>
                              <div className="card-body px-0 pt-0">
                                <div className="d-flex flex-column justify-content-center align-items-center pb-3 mt-3 gap-3">
                                  <KycHeaderPannel />
                                  <SignupPageModal
                                    openModal={openSignupModal}
                                    setOpenModal={setOpenSignupModal}
                                    rewards={true}
                                  />
                                  <div className="w-100 px-5">
                                    <div className="d-flex flex-column-reverse flex-lg-row justify-content-between align-items-center gap-2 gap-md-5 ">
                                      <div
                                        className="lg-w-50 px-4 d-flex flex-column justify-content-center align-items-startrounded-3 gap-3 py-5"
                                        style={{
                                          backgroundColor: themedbgcolor,
                                        }}
                                      >
                                        <div className="text-16 text-justify font-weight-semibold text-white">
                                          Please prepare your identity document
                                          and ensure that it is valid before you
                                          begin. We also require you to accept
                                          our terms and conditions and consent
                                          to the processing of your personal
                                          data.
                                        </div>
                                        <div className="d-flex align-items-center text-12 gap-2 text-white">
                                          <input
                                            type="checkbox"
                                            className="w-30 h-20 "
                                            checked={checked1}
                                            onClick={() =>
                                              setChecked1(!checked1)
                                            }
                                          />
                                          By clicking next. I accept terms and
                                          Policy
                                        </div>
                                        <div className="d-flex align-items-center text-12 gap-2 text-white">
                                          <input
                                            type="checkbox"
                                            className="w-30 h-20"
                                            checked={checked2}
                                            onClick={() =>
                                              setChecked2(!checked2)
                                            }
                                          />
                                          I agree to the processing of my data
                                          as describe to the content of data
                                          processing.{" "}
                                        </div>
                                      </div>
                                      <div className="w-75 lg-w-25">
                                        <img
                                          src="/KYC/kycverify.svg"
                                          alt=""
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {checked1 && checked2 && (
                                    <Button
                                      primary={true}
                                      className="w-250 h-40"
                                      onClick={() => {
                                        setNextClicked(true);
                                      }}
                                    >
                                      Next
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {nextClicked && (
                          <div className="card">
                            <Timeline
                              currentIndex={currentIndex}
                              setCurrentIndex={setCurrentIndex}
                            />
                            <div ref={componentRef}>
                              {currentIndex === 0 && (
                                <div className=" justify-content-center">
                                  <div className="fs-26 fw-bold text-center">
                                    Upload a proof of your identity
                                  </div>
                                  <p className="mb-0 text-center mt-2">
                                    <span className="fw-bold ">Vortex</span>{" "}
                                    Required a valid government issue ID(drivers
                                    license, passport, national ID)
                                  </p>
                                  <div className="w-75 mx-auto">
                                    <div className="w-100 d-flex flex-column flex-md-row gap-x-5">
                                      <div className="w-100 w-md-50 mt-3 mt-md-5 px-0 px-md-3">
                                        <KycSelectCountry
                                          open={open}
                                          setOpen={setOpen}
                                        />
                                      </div>
                                      <div className="w-100 w-md-50 mt-3 mt-md-5 px-0 px-md-3">
                                        <KycSelectDocumentType
                                          open={openDocumentTypeSelectionModal}
                                          setOpen={
                                            setOpenDocumentTypeSelectionModal
                                          }
                                        />
                                      </div>
                                    </div>
                                    <FrontAndBackDocument />
                                    <div className="d-flex align-items-center text-12 gap-2">
                                      <input
                                        type="checkbox"
                                        className="w-30 h-20"
                                        checked={checked3}
                                        onClick={() => setChecked3(!checked3)}
                                      />
                                      I Confirm that I uploaded valid
                                      government-issued photo ID. This ID
                                      include my picture, signature, name, date
                                      of birth, and address.
                                    </div>

                                    <button
                                      className="cursor-pointer my-4 py-2 rounded-pill w-100"
                                      disabled={!checked3}
                                      style={{ backgroundColor: themedbgcolor }}
                                      onClick={() => {
                                        setCurrentIndex(1);
                                      }}
                                    >
                                      <h4 className="text-white mb-0 text-center">
                                        Continue
                                      </h4>
                                    </button>
                                  </div>
                                </div>
                              )}
                              {currentIndex === 1 && (
                                <div className=" justify-content-center">
                                  <div className="fs-26 fw-bold text-center">
                                    Upload your business document
                                  </div>
                                  <p className="mb-0 text-center mt-2">
                                    <span className="fw-bold ">Vortex</span>{" "}
                                    Required a valid government issue ID(drivers
                                    license, passport, national ID)
                                  </p>
                                  <div className="w-75 mx-auto">
                                    <div className="w-100 d-flex flex-column flex-md-row gap-x-5">
                                      <div className="w-100 w-md-50 mt-3 mt-md-5 px-0 px-md-3">
                                        <KycSelectCountry
                                          open={open}
                                          setOpen={setOpen}
                                        />
                                      </div>
                                      <div className="w-100 w-md-50 mt-3 mt-md-5 px-0 px-md-3">
                                        <KycSelectDocumentType
                                          open={openDocumentTypeSelectionModal}
                                          setOpen={
                                            setOpenDocumentTypeSelectionModal
                                          }
                                        />
                                      </div>
                                    </div>
                                    <FrontAndBackDocument />
                                    <div className="d-flex align-items-center text-12 gap-2">
                                      <input
                                        type="checkbox"
                                        className="w-30 h-20"
                                        checked={checked4}
                                        onClick={() => setChecked4(!checked4)}
                                      />
                                      I Confirm that I uploaded valid
                                      government-issued photo ID. This ID
                                      include my picture, signature, name, date
                                      of birth, and address.
                                    </div>

                                    <button
                                      className="cursor-pointer my-4 py-2 rounded-pill w-100"
                                      disabled={!checked4}
                                      style={{ backgroundColor: themedbgcolor }}
                                      onClick={() => {
                                        setCurrentIndex(2);
                                      }}
                                    >
                                      <h4 className="text-white mb-0 text-center">
                                        Continue
                                      </h4>
                                    </button>
                                  </div>
                                </div>
                              )}
                              {currentIndex === 2 && (
                                <div className=" justify-content-center">
                                  <div className="fs-26 fw-bold text-center">
                                    Upload your compliances
                                  </div>
                                  <p className="mb-0 text-center mt-2">
                                    <span className="fw-bold ">Vortex</span>{" "}
                                    Required a valid government issue ID(drivers
                                    license, passport, national ID)
                                  </p>
                                  <div className="w-75 mx-auto">
                                    <div className="w-100 d-flex flex-column flex-md-row gap-x-5">
                                      <div className="w-100 w-md-50 mt-3 mt-md-5 px-0 px-md-3">
                                        <KycSelectCountry
                                          open={open}
                                          setOpen={setOpen}
                                        />
                                      </div>
                                      <div className="w-100 w-md-50 mt-3 mt-md-5 px-0 px-md-3">
                                        <KycSelectDocumentType
                                          open={openDocumentTypeSelectionModal}
                                          setOpen={
                                            setOpenDocumentTypeSelectionModal
                                          }
                                        />
                                      </div>
                                    </div>
                                    <FrontAndBackDocument />
                                    <div className="d-flex align-items-center text-12 gap-2">
                                      <input
                                        type="checkbox"
                                        className="w-30 h-20"
                                        checked={checked5}
                                        onClick={() => setChecked5(!checked5)}
                                      />
                                      I Confirm that I uploaded valid
                                      government-issued photo ID. This ID
                                      include my picture, signature, name, date
                                      of birth, and address.
                                    </div>

                                    <button
                                      className="cursor-pointer my-4 py-2 rounded-pill w-100"
                                      disabled={!checked5}
                                      style={{ backgroundColor: themedbgcolor }}
                                      onClick={() => {
                                        // setCurrentIndex(2);
                                      }}
                                    >
                                      <h4 className="text-white mb-0 text-center">
                                        Continue
                                      </h4>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
}

export default KycVerification;
