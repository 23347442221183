import { useEffect, useState } from "react";
import MainWrapper from "../../component/wrapper/MainWrapper";
import "./esg.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { CircularProgress } from "@mui/material";
import moment from "moment";

const EsgPools = () => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();
  const [isProjectsLoading, setIsProjectsLoading] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [poolsList, setPoolsList] = useState([]);
  const [poolsLoading, setPoolsLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const location = useLocation();

  useEffect(() => {
    setIsProjectsLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_VORTX_URL}/project/list`,
      },
      (data) => {
        setAllProjects(data);
        setIsProjectsLoading(false);
      },
      (error) => {
        setIsProjectsLoading(false);
      }
    );
  }, []);
  const themedColor = "black";
  //const themedColor = "black";
  const themedbgColor =  "#E7ECF0";
  const themedCardBgColor = "#F5F6FA";
  const images = [
    {
      id: 1,
      src: "/images/esg/esg1.png",
      largeSrc: "/images/esg/esg1.png",
      name: "Image 1",
    },
    {
      id: 2,
      src: "/images/esg/esg2.png",
      largeSrc: "/images/esg/esg2.png",
      name: "Image 2",
    },
  ];

  const dropdownVariant =  "light";

  useEffect(() => {
    if (selectedProject) {
      setPoolsLoading(true);
      makeRequest(
        {
          url: `${process.env.REACT_APP_VORTX_URL}/project/${selectedProject?.projectId}/esg/pool/list`,
        },
        (data) => {
          setPoolsList(data);
          setPoolsLoading(false);
        },
        (error) => {
          setPoolsLoading(false);
        }
      );
    }
  }, [selectedProject?.projectId]);
  useEffect(() => {
    if (allProjects?.length > 0) {
      const selectedProjecObject = allProjects[0];
      setSelectedProject(selectedProjecObject);
      //  if (!selectedProject?.projectId) {
      //    const url = `${location.pathname}/${selectedProjecObject?.projectId}`;
      //    navigate(url);
      //  }
    }
  }, [allProjects]);


  return (
    <MainWrapper>
      <div>
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            {/* <!-- Row --> */}
            <div class="row">
              {/* portfolio card  */}
              <div className="col-12">
                <div className="card">
                  <div className="card-header border-0 pb-3">
                    {/* <h4 className="card-title">My Portfolio</h4> */}
                  </div>
                  <div className="card-body px-4 pt-0">
                    {/* top bar  */}
                    <div class="d-flex justify-content-end align-items-center w-100">
                      <div class="w-50">
                        <h3>Vortx ESG Pools</h3>
                      </div>
                      <div class="d-flex justify-content-end align-items-center w-50 gap-2 ">
                        <div className="">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant={dropdownVariant}
                              className="rounded-md"
                            >
                              {selectedProject?.name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {allProjects?.map((project) => (
                                <Dropdown.Item
                                  key={project?.projectId}
                                  onClick={() => {
                                    setSelectedProject(project);
                                  }}
                                >
                                  {project?.name}
                                </Dropdown.Item>
                              ))}

                              {/* {country?.map((res) => (
                            <Dropdown.Item
                              key={res?.country}
                              value={res?.country}
                              onClick={() => {
                                setValue(res?.country);
                                dispatch(appActions.setCountry(res?.country));
                              }}
                            >
                              {res?.country}
                            </Dropdown.Item>
                          ))} */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div
                          class="form "
                          style={{
                            position: "relative",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="fa-search"
                            style={{
                              position: "absolute",
                              top: "15px",
                              left: "15px",
                              color: "black",
                            }}
                          />
                          <input
                            type="text"
                            class="form-control form-input bg-white rounded-pill"
                            placeholder="Search"
                          />
                        </div>
                        <div
                          className="bg-white rounded-circle d-flex justify-content-center align-items-center"
                          style={{
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faBars}
                            className="p-2 cursor-pointer"
                            style={{
                              color: "black",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                {isProjectsLoading || poolsLoading ? (
                  <div
                    class="d-flex justify-content-center align-items-center card"
                    style={{ minHeight: "300px" }}
                  >
                    <CircularProgress
                      className=""
                      fontSize="small"
                      style={{ width: "25px", height: "25px" }}
                    />
                  </div>
                ) : (
                  <>
                    {poolsList?.length === 0 ? (
                      <div
                        className="card justify-content-center align-items-center"
                        style={{ minHeight: "300px" }}
                      >
                        <p className="text-center  ">No Pools found</p>
                      </div>
                    ) : (
                      <div className="col-md-12 mt-3">
                        <div
                          className="w-100 card mr-0"
                          style={{
                            borderRadius: "25px",
                          }}
                        >
                          <div className="card-header border-0 flex-wrap pb-0">
                            <div className="mb-2">
                              <h4 className="card-title">Pools preference</h4>
                            </div>
                          </div>
                          <div className="card-body ">
                            <div className="tab-content" id="myTabContent1">
                              <div
                                className="tab-pane fade show active"
                                id="Week"
                                role="tabpanel"
                                aria-labelledby="Week-tab"
                              >
                                <div className="table-responsive">
                                  <table className="table table-responsive-md">
                                    <tbody className="px-4">
                                      {poolsList.map((pool) => (
                                        <tr
                                          onClick={() => {
                                            navigate(`/esg-pool/${1}`);
                                          }}
                                          className="cursor-pointer"
                                        >
                                          <td>
                                            <img
                                              src={pool?.icon}
                                              alt=""
                                              className="rounded-circle"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <h4 className="mb-0">
                                              {pool?.poolName}
                                            </h4>
                                          </td>
                                          <td>
                                            {moment(pool?.createdDate).format(
                                              "D MMM YYYY"
                                            )}
                                          </td>

                                          <td>
                                            <div className="d-flex align-items-center">
                                              <img
                                                src="/images/avatar/1.jpg"
                                                className="rounded-circle me-2"
                                                width={20}
                                                alt=""
                                              />{" "}
                                              <span className="w-space-no">
                                                {pool?.whitelisted
                                                  ? "White Listed"
                                                  : "Black Listed"}
                                              </span>
                                            </div>
                                          </td>

                                          <td className="text-end">
                                            <FontAwesomeIcon
                                              icon={faChevronRight}
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* <div className="col-12 col-md-6 ">
                <div className="card coin-content">
                  <div className="card-body">
                    <div className="w-100">
                      <div className="w-100 d-flex flex-row justify-content-center">
                        <div className="text-center position-relative border border-light rounded-circle p-5 mt-5">
                          <img
                            src={selectedImage.largeSrc}
                            alt=""
                            className="rounded-circle"
                          />
                          <div
                            className="position-absolute   text-center"
                            style={{
                              borderRadius: "5px",
                              border: "1px solid white",
                              padding: "5px 10px",
                              backgroundColor: "white",
                              bottom: "-15px",
                              left: "50%",
                              transform: "translateX(-50%)",
                            }}
                          >
                            <p className="text-black mb-0">
                              {selectedImage.name}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-100 d-flex flex-row justify-content-center gap-2 mt-5">
                        {images.map((image) => (
                          <div
                            key={image.id}
                            className="d-inline-block position-relative"
                          >
                            <div
                              onClick={() => setSelectedImage(image)}
                              className={` p-4 rounded-lg`}
                              style={{
                                cursor: "pointer",
                                backgroundColor: themedCardBgColor,
                                marginTop:
                                  image?.id === selectedImage?.id
                                    ? "-20px"
                                    : "0px",
                              }}
                            >
                              <img
                                src={image.src}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                }}
                                alt=""
                              />
                            </div>
                          </div>
                        ))}

                        <div className="d-inline-block position-relative">
                          <div
                            //   onClick={() => setSelectedImage(image)}
                            className={` p-4 rounded-lg`}
                            style={{
                              cursor: "pointer",
                              backgroundColor: themedCardBgColor,
                            }}
                          >
                            <div
                              className="d-flex flex-column justify-content-center align-items-center w-100"
                              style={{
                                width: "100px",
                                height: "100px",
                              }}
                            >
                              <div className="">
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  style={{ color: "black" }}
                                />
                              </div>
                              <h6>
                                Request New <br />
                                Pool
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 ">
                <div className="row">
                  <div className="col-md-8  mb-md-0">
                    <div
                      className="card h-100"
                      style={{
                        backgroundColor: themedCardBgColor,
                        borderRadius: "25px",
                        padding: "5px",
                      }}
                    >
                      <p className="fs-20 fw-medium text-center mt-3">
                        Offset vs pricing history
                      </p>
                      <img
                        src="/images/esg/esg1.png"
                        className="card-img h-100"
                        alt="Main"
                        style={{
                          objectFit: "cover",
                          borderRadius: "25px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 d-flex flex-column m-4 m-md-0">
                    <div className="row  mb-3">
                      <div
                        className="card h-100"
                        style={{
                          backgroundColor: themedCardBgColor,
                          borderRadius: "25px",
                          padding: "5px",
                        }}
                      >
                        <img
                          src="/images/esg/esg1.png"
                          className="card-img h-100"
                          alt="Small 1"
                          style={{
                            objectFit: "cover",
                            borderRadius: "25px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="row  mt-3 mt-md-0">
                      <div
                        className="card h-100"
                        style={{
                          backgroundColor: themedCardBgColor,
                          borderRadius: "25px",
                          padding: "5px",
                        }}
                      >
                        <img
                          src="/images/esg/esg1.png"
                          className="card-img h-100"
                          alt="Small 2"
                          style={{
                            objectFit: "cover",
                            borderRadius: "25px",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div
                      className="w-100 card mr-0"
                      style={{
                        borderRadius: "25px",
                      }}
                    >
                      <div className="card-header border-0 flex-wrap pb-0">
                        <div className="mb-2">
                          <h4 className="card-title">Pools preference</h4>
                        </div>
                      </div>
                      <div className="card-body ">
                        <div className="tab-content" id="myTabContent1">
                          <div
                            className="tab-pane fade show active"
                            id="Week"
                            role="tabpanel"
                            aria-labelledby="Week-tab"
                          >
                            <div className="table-responsive">
                              <table className="table table-responsive-md">
                                <tbody className="px-4">
                                  <tr
                                    onClick={() => {
                                      navigate(`/esg-pools/${1}`);
                                    }}
                                    className="cursor-pointer"
                                  >
                                    <td>
                                      <img
                                        src="/images/esg/esg1.png"
                                        alt=""
                                        className="rounded-circle"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <h4 className="mb-0">Pool A</h4>
                                    </td>
                                    <td>100% acceptence</td>

                                    <td>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src="/images/avatar/1.jpg"
                                          className="rounded-circle me-2"
                                          width={20}
                                          alt=""
                                        />{" "}
                                        <span className="w-space-no">
                                          Available
                                        </span>
                                      </div>
                                    </td>

                                    <td className="text-end">
                                      <FontAwesomeIcon icon={faChevronRight} />
                                    </td>
                                  </tr>
                                  <tr
                                    onClick={() => {
                                      navigate(`/esg-pools/${1}`);
                                    }}
                                    className="cursor-pointer"
                                  >
                                    <td>
                                      <img
                                        src="/images/esg/esg1.png"
                                        alt=""
                                        className="rounded-circle"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <h4 className="mb-0">Pool A</h4>
                                    </td>
                                    <td>100% acceptence</td>

                                    <td>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src="/images/avatar/1.jpg"
                                          className="rounded-circle me-2"
                                          width={20}
                                          alt=""
                                        />{" "}
                                        <span className="w-space-no">
                                          Available
                                        </span>
                                      </div>
                                    </td>

                                    <td className="text-end">
                                      <FontAwesomeIcon icon={faChevronRight} />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

export default EsgPools;
