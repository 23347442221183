export const ProtocolList = [
  {
    id: 1,
    name: "Synthesize from Images",
    title: "Synthesize from Images",
    description: "e.g. Input Mobile Images of Apples to get {Output: 'Food, Apples, 4, Honeycrisp' , 'Grown in shade, on Hills, with plenty water' , 'India, HP, Shimla'} ",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Accuracy:75%+", "LIVE"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Gaps in supply chain tracking can result in inefficiencies and lost revenue. Our solution fills in missing location data to optimize operations.",
    detailed_desc2: "To test our model, upload images of fruits and vegetables to predict their origin, track their movement, and gain insights into their journey across the supply chain.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] // Added data types
  },
  {
    id: 2,
    name: "Synthesize from Audio",
    title: "Synthesize from Audio",
    description: "e.g. Connect Customer Calls to get {Output : 'Urban Indian, Young Female, Educated, potentially from Odisha' , 'Evening, Cold, Happy'}   ",
    image: "/images/coming-soon.gif",
    link: "/generate-data/rgb-1m-v2", 
    link_playground: "/playground/rgb-1m-v2", 
    tags: ["Coming Soon", "Accuracy:low"],
    model: "rgb-1m-v2",
    icon: "/icons/rgb-icon.png",
    no_code: "no",
    level: "Audio",
    scoring: "98",
    video_url: "https://youtu.be/cSqOarZdMQg", 
    detailed_desc1: "Daily imagery at 1m resolution is perfect for infrastructure and urban planning.",
    detailed_desc2: "High-definition RGB imagery captures every detail with precision.",
    dataTypes: ["Satellite Image", "RGB Image"] 
    },
  {
    id: 3,
    name: "Synthesize from Prompts.",
    title: "Synthesize from Prompts.",
    description: "e.g. Connect LLM Prompts to get {Output : 'Direct : Louvre Museum, Paris, 48.860131, 2.338459 ' , 'Inferred : Indian Restaurant, walkable distance'}",
    image: "/images/coming-soon.gif",
    link: "/generate-data/rgb-3m-v1", // Updated link
    link_playground: "/playground/rgb-3m-v1", // New playground link
    tags: ["Coming Soon", "Accuracy:medium"],
    model: "rgb-3m-v1",
    icon: "/icons/rgb-icon.png",
    no_code: "no",
    level: "Text",
    scoring: "97",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Ideal for capturing large-scale environments like forests or cityscapes.",
    detailed_desc2: "This resolution balances detail with coverage for versatile applications.",
    dataTypes: ["Satellite Image", "RGB Image", "Landscape Data"] // Added data types
  },
  {
    id: 4,
    name: "Synthesize from IoT",
    title: "Synthesize from IoT",
    description: "e.g. Connect Sensor Mesh to get periodic {Output : 'temperature_map, surge_map'}",
    image: "/images/coming-soon.gif",
    link: "/generate-data/rgb-nir-5m-v1", // Updated link
    link_playground: "/playground/rgb-nir-5m-v1", // New playground link
    tags: ["Coming Soon", "Accuracy:low"],
    model: "rgb-nir-5m-v1",
    icon: "/icons/nir-icon.png",
    no_code: "no",
    level: "Text",
    scoring: "38",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Use AI to analyze audio conversations and identify accents, vocabulary, and linguistic features to determine caller location.",
    detailed_desc2: "Useful for customer service optimization, fraud detection, and regional insights..",
    dataTypes: ["Audio Data", "Linguistic Data", "Location Data"] 
  },
  
  {
    id: 8,
    name: "Synthesize from Location",
    title: "Synthesize from Location",
    description: "e.g. Connect GPS to get {Output : 'Satellite_map, Street_view'}",
    image: "/images/coming-soon.gif",
    link: "/generate-data/satellite-draw-v1", // Updated link
    link_playground: "/playground/satellite-draw-v1", // New playground link
    tags: ["Coming Soon", "Accuracy:good"],
    model: "satellite-draw-v1",
    icon: "/icons/drawing-icon.png",
    no_code: "no",
    level: "Text",
    scoring: "34",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Turn simple drawings into satellite-grade images.",
    detailed_desc2: "Perfect for concept development and creative applications.",
    dataTypes: ["Drawing", "Satellite Image", "AI-generated Data"] // Added data types
  },
  {
    id: 9,
    name: "Synthesize from Texts",
    title: "Synthesize from Texts",
    description: "e.g. Connect Texts to get {Output : Urban Indian, Young Female, Educated, potentially from Odisha' , 'Evening, Cold, Happy'}",
    image: "/images/coming-soon.gif",
    link: "/generate-data/custom-start-v1", // Updated link
    link_playground: "/playground/custom-start-v1", // New playground link
    tags: ["Coming Soon", "Accuracy:low"],
    model: "custom-start-v1",
    icon: "/icons/start-icon.png",
    no_code: "no",
    level: "Text",
    scoring: "29",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Choose from custom or pre-built satellite imaging models.",
    detailed_desc2: "Tailor your project to your specific needs.",
    dataTypes: ["Satellite Image", "Custom Data"] // Added data types
  },
  // Add more protocols as needed
];
