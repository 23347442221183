import React, { useState } from "react";
import "./timeline.css";

const STEPS = [
  { title: "Business KYC", content: "Business KYC details here." },
  { title: "Account KYC", content: "Account KYC details here." },
  { title: "Compliance", content: "Compliance details here." },
];

const Timeline = ({ currentIndex, setCurrentIndex }) => {
  const width = window.screen.width;
  let isMobileDevice = width < 600;
  let multiplyvalue = isMobileDevice ? 78 : 86;

  return (
    <div className="timeline-container">
      <div className="timeline-bar">
        <div
          className={`timeline-line ${currentIndex > 0 ? "active" : ""}`}
          style={{
            width: `${(currentIndex / (STEPS.length - 1)) * multiplyvalue}%`,
          }}
        />
        <div className={`black-line`} />
        {/* add a black line after timeline to last step here */}
        {STEPS.map((step, index) => (
          <div
            key={index}
            className={`timeline-item ${index <= currentIndex ? "active" : ""}`}
            // onClick={() => setCurrentIndex(index)}
          >
            <div className="timeline-circle" />
            <span className="timeline-label">{step.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
