import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { appActions } from "../../context/app-slice";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import FrontSideUpload from "./FrontSideUpload";
import BackSideUpload from "./BackSideUpload";

export const FrontAndBackDocument = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileRef = useRef();
  const frontref = useRef();
  const makeRequest = useAuthorizedHttp();
  const appCtx = useSelector((state) => state.app);
  const [backside, setBackSide] = useState("");
  const [backsidePreview, setBacksidePreview] = useState("");
  const [frontSide, setFrontSide] = useState("");
  const [frontsidePreview, setFrontSidePreview] = useState("");
  const handleBackUpload = (e) => {
    fileRef.current.click();
  };
  const handleBackChange = useCallback((file) => {
    if (file && file?.type?.split("/")[0] === "image") {
      setBackSide(file.File);
      // dispatch(appActions.setBackSideFile(file))
      const objectUrl = URL.createObjectURL(file);
      setBacksidePreview(objectUrl);
      const data = new FormData();
      data.append("file", file);
      data.append("fileName", `${appCtx.document}Back`);
      data.append("fileUrl", objectUrl);
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_FILE_UPLOAD}/kyc/document/upload`,
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Auth-Token": appCtx.authToken,
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
        },
        data: data,
      };
      toast.promise(
        () =>
          axios(config)
            .then(function (response) {
              // setLogoUrl(response.data.fileUrl);
              // const marketplace = {
              //     marketplaceId: marketplaceId,
              //     collectionLogo: response.data.fileUrl
              // };
              dispatch(appActions.setBackSide(response.data?.fileUrl));

              toast.success("Back Side Uploaded Successfully");
            })
            .catch(function (error) {
              toast.error("Uploading Back Side failed!");
            }),
        {
          pending: "uploading Back side",
          // success: 'Settings updated successfully!',
          // error: 'Uh-oh! Something went wrong'
        }
      );
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      toast.error("Documents uploaded should be an Image");
    }
  }, []);
  const handleFrontUpload = (e) => {
    frontref.current.click();
  };
  const handleFrontChange = useCallback((file) => {
    if (file && file?.type?.split("/")[0] === "image") {
      // if (file.size > 3 * 1024 * 1024) {
      //     toast.error('File size exceeds 3mb!');
      //     return false;
      // }
      setFrontSide(file);
      // dispatch(appActions.setFrontSideFile({...file}))
      const objectUrl = URL.createObjectURL(file);
      setFrontSidePreview(objectUrl);

      const data = new FormData();
      data.append("file", file);
      data.append("fileName", `${appCtx.document}Front`);
      data.append("fileUrl", objectUrl);
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_FILE_UPLOAD}/kyc/document/upload`,
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Auth-Token": appCtx.authToken,
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
        },
        data: data,
      };
      toast.promise(
        () =>
          axios(config)
            .then(function (response) {
              // setLogoUrl(response.data.fileUrl);
              // const marketplace = {
              //     marketplaceId: marketplaceId,
              //     collectionLogo: response.data.fileUrl
              // };
              dispatch(appActions.setFrontSide(response.data?.fileUrl));
              toast.success("Front Side Uploaded Successfully");
            })
            .catch(function (error) {
              toast.error("Uploading Front Side failed!");
            }),
        {
          pending: "uploading front side",
          // success: 'Settings updated successfully!',
          // error: 'Uh-oh! Something went wrong'
        }
      );

      return () => URL.revokeObjectURL(objectUrl);
    } else {
      toast.error("Documents uploaded should be an Image");
    }
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  // useEffect(()=>{

  // },[dispatch, frontSide])
  // useEffect(()=>{
  // },[backside, dispatch])
  return (
    // <div className="d-flex flex-column justify-center align-items-center pb-5 mt-5 px-4">
    //   <div className="d-flex flex-column flex-md-row gap-4 w-100">
    //     <div
    //       className="d-flex flex-column gap-3 justify-content-start align-items-center w-100 h-300 lg:w-50 cursor-pointer group"
    //       onClick={(e) => {
    //         handleFrontUpload(e);
    //       }}
    //     >
    //       <input
    //         hidden={true}
    //         accept="image/png, image/jpeg, image/svg, image/jpg"
    //         type="file"
    //         ref={frontref}
    //         onChange={(e) => {
    //           handleFrontChange(e.target.files[0]);
    //         }}
    //       />
    //       <div className="text-20 text-left w-100 font-normal">Front Side</div>
    //       <div className="w-100 min-h-320 h-60 d-flex justify-center align-items-center border border-dashed ">
    //         {appCtx.frontSide ? (
    //           <img
    //             src={appCtx.frontSide}
    //             className="p-2 h-100"
    //             alt="document"
    //           />
    //         ) : (
    //           <img
    //             src="/KYC/frontside.svg"
    //             className="p-2 h-100"
    //             alt="document"
    //           />
    //         )}
    //       </div>
    //     </div>
    //     <div
    //       className="d-flex flex-column gap-3 justify-start align-items-center w-100 h-300 lg:w-50 cursor-pointer group"
    //       onClick={(e) => {
    //         handleBackUpload(e);
    //       }}
    //     >
    //       <input
    //         hidden={true}
    //         accept="image/png, image/jpeg, image/svg, image/jpg"
    //         type="file"
    //         ref={fileRef}
    //         onChange={(e) => {
    //           handleBackChange(e.target.files[0]);
    //         }}
    //       />
    //       <div className="text-20 text-left w-100 font-normal">Back Side</div>
    //       <div className="w-100 h-60 d-flex justify-center align-items-center border border-dashed ">
    //         {appCtx.backSide ? (
    //           <img src={appCtx.backSide} className="p-2 h-100" alt="document" />
    //         ) : (
    //           <img
    //             src="/KYC/backside.svg"
    //             className="p-2 h-100"
    //             alt="document"
    //           />
    //         )}
    //       </div>
    //     </div>
    //   </div>
    //   <div className="mt-3">Upload back and front of your document</div>
    //   <Button
    //     primary={true}
    //     className="w-250 h-40"
    //     onClick={() => {
    //       if (appCtx.frontSide) {
    //         navigate("/kyc/verification/status");
    //       } else {
    //         toast.error("uploading Front Side of your document is mandatory");
    //       }
    //     }}
    //   >
    //     Next
    //   </Button>
    // </div>
    <>
      <div className="w-100 w-md-75 d-flex flex-column flex-md-row mt-3 mt-md-5  ">
        <FrontSideUpload />
        <BackSideUpload />
      </div>
    </>
  );
};
