import React, { useState, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaEdit, FaDownload } from "react-icons/fa";
import { usePDF } from "react-to-pdf";
import CKTextEditor from "../../component/common/CkTextEditor";
import jsPDF from "jspdf";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2canvas from "html2canvas";
import MainWrapper from "../../component/wrapper/MainWrapper";
import { useSelector } from "react-redux";
import Modal from "react-modal";
 
const EsgReportMaker = () => {
  const appCtx = useSelector((state) => state.app);
  const [reports, setReports] = useState([
    {
      id: 1,
      content: "<h4>DGU-4.1.1 Protocol &emsp;       &emsp;    &emsp; &emsp;   &emsp;      &emsp; To Monitor Carbon History of Food. &emsp;      &emsp;     &emsp; &emsp;              &emsp; Developed & Maintained by : Vortx.ai</h4><h3>hello</h3> <p>To Calculate Carbon Credit from produce</p>",
    },
  ]);
 
  const [editorContent, setEditorContent] = useState("");
  const [currentReport, setCurrentReport] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const pdfRef = useRef();
 
  const handleEditClick = (report) => {
    setEditorContent(report.content);
    setCurrentReport(report);
    setShowModal(true);
  };
 
  const handleSaveClick = () => {
    setReports(
      reports.map((report) => {
        if (report.id === currentReport.id) {
          return { ...report, content: editorContent };
        }
        return report;
      })
    );
    setShowModal(false);
  };
 
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: "#FFFFFF",
      border: "1px solid  #FFFFFF",
      color: "black",
 
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: "70vw",
    },
  };
  function closeModal() {
    setShowModal(false);
  }
 
  const generatePDF = () => {
    const input = pdfRef.current;
 
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = (pdfHeight - imgHeight * ratio) / 2;
 
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("document.pdf");
    });
  };
 
  return (
    <MainWrapper>
      <div className={ "text-black"}>
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            {/* <!-- Row --> */}
            <div class="row">
              {/* portfolio card  */}
              <div className="col-12">
                <div className="card">
                  <div className="card-header border-0 pb-3">
                    <h4 className="card-title">Image Synthesis Protocols Detailed Architectures - Updated 27 Sep, 2024</h4>
                  </div>
                  <div className="card-body px-0 pt-0">
                    <div className="px-4">
                      <Row className="">
                        {reports.map((report) => (
                          <Col md={12} className="my-3" key={report.id}>
                            <div
                              ref={pdfRef}
                              style={{
                                padding: "1rem",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                              }}
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: report.content,
                                }}
                              />
                            </div>
                            <div className="d-flex gap-2 mt-2">
                              <Button
                                variant="primary"
                                onClick={() => handleEditClick(report)}
                              >
                                <FaEdit /> Redesign Synthesis Protocol
                              </Button>
                              <Button
                                variant="secondary"
                                onClick={() => {
                                  setCurrentReport(report);
                                  generatePDF();
                                }}
                              >
                                <FaDownload /> Download Synthesis Whitepaper
                              </Button>
                            </div>
                          </Col>
                        ))}
                      </Row>
                      <Modal
                        isOpen={showModal}
                        // onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                      >
                        <div>
                          <h4>Editing the Protocol should be done with care, make a copy first.</h4>
                        </div>
                        <div>
                          <CKTextEditor
                            initData={currentReport?.content}
                            data={editorContent}
                            onChange={({ editor }) =>
                              setEditorContent(editor.getData())
                            }
                          />
                        </div>
                        <div className="d-flex flex-row gap-3 justify-content-center align-items-center mt-4">
                          <Button
                            variant="secondary"
                            onClick={() => setShowModal(false)}
                          >
                            Close
                          </Button>
                          <Button variant="primary" onClick={handleSaveClick}>
                            Save Changes
                          </Button>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};
 
export default EsgReportMaker;