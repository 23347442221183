// src/components/profileDrawer/ProfileDrawer.jsx

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../context/app-slice";
import { persistor } from "../../context/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBusinessTime,
  faHandHoldingMedical,
  faHeadset,
  faImage,
  faLink,
  faPersonDigging,
  faReceipt,
  faRightFromBracket,
  faUnlockKeyhole,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { getBlockchainAddressUrl } from "../../config";

const ProfileDrawer = ({
  setOpenSupportModal,
  setOpenAccountDetailsModal,
  setOpenPrivateKeyLoginModal,
  setOpenPrivateKeyPasswordModal,
  triggerElement, // Accept the trigger element as a prop
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appCtx = useSelector((state) => state.app);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const address = appCtx.walletAddress.find(
    (addr) => addr.blockchain === appCtx.blockchain
  )?.address;

  const handleLogout = () => {
    dispatch(appActions.logout());
    dispatch(appActions.updateAuthToken(undefined));
    dispatch(appActions.setWalletAddress([{ address: "" }]));
    dispatch(
      appActions.updateUserDetails({
        userId: "",
        email: "",
        name: "",
      })
    );
    dispatch(appActions.setCountry(""));
    dispatch(appActions.setDocument(""));
    dispatch(appActions.setFrontSide(""));
    dispatch(appActions.setBackSide(""));
    dispatch(appActions.setPicture(""));
    dispatch(appActions.setVerified(false));
    dispatch(appActions.setPassword(""));
    dispatch(appActions.setRewardData({}));
    dispatch(appActions.setMetaData(""));
    dispatch(appActions.setCustodial(""));
    dispatch(appActions.setMnemonicCode('""'));
    dispatch(appActions.setAlgoMnemonicCode(""));
    dispatch(appActions.setEvmMnemonicCode(""));
    dispatch(appActions.setSolanaMnemonicCode(""));
    dispatch(appActions.setEncrypt(""));
    dispatch(appActions.setAlgoEncrypt(""));
    dispatch(appActions.setEvmEncrypt(""));
    dispatch(appActions.setSolanaEncrypt(""));
    persistor.purge().then(() => {
      navigate(`/`);
    });
    navigate("/login");
  };

  const themedColor = appCtx?.isDarkMode ? "black" : "black";

  const menuItems = [
    
    {
      icon: faHeadset,
      label: "Support",
      onClick: () => {
        setOpenSupportModal(true);
        setIsDrawerOpen(false);
      },
    },
    
    {
      icon: faBusinessTime,
      label: "Profile",
      onClick: () => {
        navigate("/business-details");
        setIsDrawerOpen(false);
      },
    },

    {
      icon: faReceipt,
      label: "Terms and Compliance",
      onClick: () => {
        navigate("/terms-and-compliance");
        setIsDrawerOpen(false);
      },
    },
    {
      icon: faHandHoldingMedical,
      label: "Help",
      onClick: () => {
        navigate("/help");
        setIsDrawerOpen(false);
      },
    },
    {
      icon: faRightFromBracket,
      label: "Logout",
      onClick: () => {
        handleLogout();
        setIsDrawerOpen(false);
      },
    },
  ];

  return (
    <>
      {/* Use the triggerElement prop */}
      <div onClick={() => setIsDrawerOpen(true)}>{triggerElement}</div>

      {/* Overlay when drawer is open */}
      {isDrawerOpen && (
        <div
          className="drawer-overlay"
          onClick={() => setIsDrawerOpen(false)}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
        />
      )}

      {/* Drawer */}
      <div
        className={`profile-drawer ${isDrawerOpen ? "open" : ""}`}
        style={{
          position: "fixed",
          top: 0,
          right: isDrawerOpen ? 0 : "-300px",
          width: "300px",
          height: "100%",
          backgroundColor:  "#FFFFFF",
          color: themedColor,
          boxShadow: "-2px 0 5px rgba(0,0,0,0.3)",
          transition: "right 0.3s ease-in-out",
          zIndex: 1000,
          display: "flex",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <div
          className="drawer-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <h2>Menu</h2>
          <button
            onClick={() => setIsDrawerOpen(false)}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              fontSize: "24px",
              color: themedColor,
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="drawer-content" style={{ flex: 1, overflowY: "auto" }}>
          {menuItems.map((item, index) => (
            <div key={index}>
              <div
                className="drawer-item"
                onClick={item.onClick}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0",
                  cursor: "pointer",
                  transition: "all 0.2s",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = appCtx?.isDarkMode
                    ? "#f0f0f0"
                    : "#f0f0f0";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                }}
              >
                <FontAwesomeIcon
                  icon={item.icon}
                  style={{
                    color: themedColor,
                    width: "20px",
                    marginRight: "10px",
                  }}
                />
                <p className="mb-0">{item.label}</p>
              </div>
              {index < menuItems.length - 1 && (
                <div
                  className="divider"
                  style={{
                    borderBottom: "1px solid #ccc",
                    margin: "5px 0",
                  }}
                ></div>
              )}
            </div>
          ))}
        </div>
        <div
          className="drawer-footer"
          style={{
            textAlign: "center",
            padding: "10px 0",
            borderTop: "1px solid #ccc",
          }}
        >
          <p style={{ fontSize: "12px" }}>© 2024 Vortx.ai</p>
        </div>
      </div>
    </>
  );
};

export default ProfileDrawer;
