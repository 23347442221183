import axios from "axios";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

export const useEsgPools = () => {
  const [poolsLoading, setPoolsLoading] = useState(false);
  const [poolsList, setPoolsList] = useState([]);

  const appCtx = useSelector((state) => state.app);

  const getEsgPoolsList = async () => {
    setPoolsLoading(true);
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_VORTX_URL}/esg/pool/master/list`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
        },
      };
      const response = await axios(config);
      setPoolsList(response.data);
      setPoolsLoading(false);
      return response.data;
    } catch (error) {
      console.error(error);
      setPoolsLoading(false);
    }
  };

  return {
    poolsLoading,
    poolsList,
    getEsgPoolsList,
  };
};
