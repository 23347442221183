import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { CircularProgress } from "@mui/material";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";

const SupportModal = ({ openModal, setOpenModal }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]); // Store project list
  const [selectedProject, setSelectedProject] = useState(""); // Track selected project

  const [selectedSupport, setSelectedSupport] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [formData, setFormData] = useState({
    callback: "",
    contactMail: "",
    description: "",
  });

  const authToken = localStorage.getItem("authToken");
  const width = window.screen.width;
  const isMobileDevice = width < 600; // Define isMobileDevice here

  const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 9998,
  },
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    zIndex: 9999,
    backgroundColor: "#FFFFFF",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    border: `${appCtx?.isDarkMode ? "#FFFFFF" : "#FFFFFF"}`,
    borderRadius: "10px",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
    width: `${isMobileDevice ? "95%" : "500px"}`,
  },
};

  useEffect(() => {
    // Fetch project list on component mount
    makeRequest(
      {
        url: `${process.env.REACT_APP_VORTX_URL1}/v1/project/list`,
        method: "GET",
        headers: {
          "X-Auth-Token": authToken,
          "Content-Type": "application/json",
        },
      },
      (response) => {
        setProjects(response || []);
        console.log("Projects fetched:", response);
      },
      (error) => {
        console.error("Failed to fetch projects:", error);
        toast.error("Unable to load projects");
      }
    );
  }, [makeRequest, authToken]);

  function closeModal() {
    setOpenModal(false);
  }

  const handleChange = (e) => {
    console.log("Field Changed:", e.target.name, e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData, selectedSupport, selectedPriority);
    setLoading(true);

    makeRequest(
      {
        url: `${process.env.REACT_APP_VORTX_URL1}/projects/11/support`, // Assuming projectId is 11
        method: "POST",
        data: {
          supportType: selectedSupport,
          priority: selectedPriority,
          callback: formData.callback,
          contactMail: formData.contactMail,
          description: formData.description,
        },
        headers: {
          "X-Auth-Token": authToken,
          "Content-Type": "application/json",
        },
      },
      (response) => {
        console.log("API Response:", response);
        toast("Support request created successfully!");
        setSelectedSupport("");
        setSelectedPriority("");
        setFormData({
          callback: "",
          contactMail: "",
          description: "",
        });
        setLoading(false);
        closeModal(); // Close the modal on success
        navigate("/"); // Redirects to the home page after success
      },
      (error) => {
        console.error("API Error:", error);
        toast.error("Failed to create support request");
        setLoading(false);
      }
    );
  };


  return (
    <Modal
      isOpen={openModal}
      style={customStyles}
      contentLabel="Support Modal"
      ariaHideApp={false}
    >
      <div className="w-100  position-relative">
        <div className="d-flex justify-content-end position-relative">
          <button
            onClick={closeModal}
            className="btn btn-link position-absolute top-1 right-2"
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
        <Form onSubmit={handleSubmit} className="p-4 border rounded">
          <h3 className="mb-3 text-center">Vortx Support System</h3>

          <Row className="mb-3">
            <Form.Group controlId="projectDropdown">
              <Form.Label>Select Project</Form.Label>
              <Form.Control
                as="select"
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.target.value)}
                required
              >
                <option value="">Select Project</option>
                {projects.map((project) => (
                  <option key={project.projectId} value={project.projectId}>
                    {project.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Label>Select Support Type</Form.Label>
            <ButtonGroup>
              {["Help", "Bugs", "Compliances", "Pricing"].map((type, index) => (
                <ToggleButton
                  key={type}
                  type="radio"
                  variant={selectedSupport === type ? "primary" : "outline-primary"}
                  name="support"
                  value={type}
                  checked={selectedSupport === type}
                  onClick={() => setSelectedSupport(type)}
                  className={`${index === 0 ? "rounded-start" : ""} ${
                    index === 3 ? "rounded-end" : ""
                  }`}
                >
                  {type}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Row>

          <Row className="mb-3">
            <Form.Label>Select Priority</Form.Label>
            <ButtonGroup className="w-100">
              {["Urgent", "Normal", "Lite"].map((priority, index) => (
                <ToggleButton
                  key={priority}
                  type="radio"
                  variant={
                    selectedPriority === priority ? "primary" : "outline-primary"
                  }
                  name="priority"
                  value={priority}
                  checked={selectedPriority === priority}
                  onClick={() => setSelectedPriority(priority)}
                  className={`flex-grow-1 ${
                    index === 0 ? "rounded-start" : ""
                  } ${index === 2 ? "rounded-end" : ""}`}
                >
                  {priority}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Row>

          <Row className="mb-3">
            <Form.Group controlId="callback">
              <Form.Label>Request Callback</Form.Label>
              <Form.Control
                type="text"
                name="callback"
                value={formData.callback}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group controlId="contactMail">
              <Form.Label>Contact on Mail</Form.Label>
              <Form.Control
                type="email"
                name="contactMail"
                value={formData.contactMail}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group controlId="description">
              <Form.Label>Explain Please</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                placeholder="Please describe"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Button type="submit" className="me-3">
              {loading ? <CircularProgress size={20} /> : "Send"}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default SupportModal;
