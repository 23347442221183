import { useNavigate } from "react-router-dom";
import "../navbar/Navbar.css";
import { useDispatch, useSelector } from "react-redux";

import { useCallback, useEffect, useState } from "react";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { useWalletView } from "../../hooks/useWalletView";

const SearchDropdown = ({
  searchVisible,
  setSearchVisible,
  searchTerm,
  setSearchTerm,
  allCoin,
}) => {
  const [loading, setLoading] = useState(false);
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const makeRequest = useAuthorizedHttp();

  const [filteredTokens, setFilteredTokens] = useState(allCoin);

  useEffect(() => {
    const filteredTokens =
      allCoin.length > 0
        ? allCoin.filter((token) =>
            token.tokenName.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : [];
    setFilteredTokens(filteredTokens);
  }, [searchTerm]);

  const width = window.screen.width;
  let isMobileDevice = width < 600;

  return (
    <div className="border">
      <div
        class="input-group  d-md-none"
        // onClick={(e) => {
        //   setSearchVisible(!searchVisible);
        // }}
        style={{
          width: `${isMobileDevice ? "200px" : "350px"}`,
          backgroundColor: "#FFFFFF",
        }}
      >
        <input
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          type="text"
          class="form-control"
          placeholder="Search Token"
        />
        <span class="input-group-text">
          <a href="javascript:void(0)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              // xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              class="svg-main-icon"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                  fill="var(--primary)"
                  fill-rule="nonzero"
                  opacity="0.3"
                ></path>
                <path
                  d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                  fill="var(--primary)"
                  fill-rule="nonzero"
                ></path>
              </g>
            </svg>
          </a>
        </span>
      </div>
      <div
        className="d-flex flex-column  "
        style={{
          width: `${isMobileDevice ? "200px" : "350px"}`,
          maxHeight: "400px",
          overflow: "auto",
          padding: "10px",
          backgroundColor: "#FFFFFF",
        }}
      >
        {filteredTokens?.map((token) => (
          <div
            className=""
            key={token.tokenId}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSearchVisible(false);
              navigate(`/token/${token?.tokenId}/${token?.symbol}`);
            }}
          >
            <div className=" d-flex gap-2 align-items-center">
              <img
                src={token.tokenIcon}
                alt={token.tokenName}
                style={{ width: "30px", height: "30px" }}
              />
              <div className="">
                <h5 className="m-0 p-0">{token.tokenName}</h5>
                <span className="">{token.symbol}</span>
              </div>
            </div>
            <div className="border-bottom border-gray-400 my-2"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchDropdown;
