// SummaryTab.js - Component for the Summary Tab
import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Grid,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  IconButton,
  Tooltip,
  CircularProgress,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  LinearProgress,
  Stack,
  Badge,
  Alert,
  Snackbar,
} from '@mui/material';
import {
  DataUsage as DataUsageIcon,
  ModelTraining as ModelTrainingIcon,
  Security as SecurityIcon,
  People as PeopleIcon,
  Storage as StorageIcon,
  PlayCircle as PlayCircleIcon,
  PauseCircle as PauseCircleIcon,
  Error as ErrorIcon,
  Refresh as RefreshIcon,
  CheckCircle as CheckCircleIcon,
  Assessment as AssessmentIcon,
  DoneAll as DoneAllIcon,
  CloudDone as CloudDoneIcon,
  Warning as WarningIcon,
  Timeline as TimelineIcon,
  History as HistoryIcon,
  FilterList as FilterListIcon,
  Info as InfoIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import useAuthorizedHttp from '../../hooks/use-authorized-http'; // Custom hook for API calls

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'scale(1.02)',
    transition: 'transform 0.2s',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const SummaryTab = () => {
  const [kpiData, setKpiData] = useState({
    totalDataGenerated: '1.2 TB',
    numberOfDataModels: 15,
    dataQuality: 95,
    activeUsers: 120,
    dataUsage: ['Analytics', 'Machine Learning', 'Reporting'],
    workflowsRunning: 5,
    connectionsActive: 8,
    vulnerabilityIssues: 2,
    runHistory: [],
    dataQualityHistory: [],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const makeRequest = useAuthorizedHttp(); // Custom hook for making API calls

  useEffect(() => {
    // Fetch data from APIs
    const fetchData = async () => {
      try {
        setLoading(true);
        // Replace the following dummy data with actual API calls using makeRequest
        /*
        const response = await makeRequest({
          url: 'your-api-endpoint',
          method: 'GET',
        });
        setKpiData(response.data);
        */
        // Simulate API delay
        setTimeout(() => {
          setKpiData((prevData) => ({
            ...prevData,
            runHistory: [
              {
                id: 1,
                workflow: 'Data Sync',
                status: 'Success',
                timestamp: '2023-10-07 10:00 AM',
              },
              {
                id: 2,
                workflow: 'Data Backup',
                status: 'Failed',
                timestamp: '2023-10-07 09:30 AM',
              },
              {
                id: 3,
                workflow: 'Data Cleanup',
                status: 'Success',
                timestamp: '2023-10-07 09:00 AM',
              },
            ],
            dataQualityHistory: [
              { timestamp: '2023-10-07', quality: 95 },
              { timestamp: '2023-10-06', quality: 94 },
              { timestamp: '2023-10-05', quality: 93 },
            ],
          }));
          setLoading(false);
        }, 1000);
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    };
    fetchData();
  }, [makeRequest]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleRefresh = () => {
    // Implement refresh logic
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSnackbarOpen(true);
    }, 1000);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      

      {loading ? (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Loading data...
          </Typography>
        </Box>
      ) : error ? (
        <Alert severity="error">Failed to load data. Please try again later.</Alert>
      ) : (
        <>
          {/* KPI Cards */}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {/* Total Data Generated */}
            

            {/* Number of Data Models */}
           
            {/* Active Users */}
            

            {/* Workflows Running */}
            <Grid item xs={12} sm={6} md={4}>
              <StyledCard elevation={3}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Avatar sx={{ bgcolor: 'warning.main', mr: 2 }}>
                      <PlayCircleIcon />
                    </Avatar>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="subtitle1">Workflows Running</Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {kpiData.workflowsRunning}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={kpiData.workflowsRunning}
                      sx={{ mt: 1 }}
                    />
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>

            {/* Connections Active */}
            <Grid item xs={12} sm={6} md={4}>
              <StyledCard elevation={3}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Avatar sx={{ bgcolor: 'error.main', mr: 2 }}>
                      <CloudDoneIcon />
                    </Avatar>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="subtitle1">Connections Active</Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {kpiData.connectionsActive}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={kpiData.connectionsActive}
                      sx={{ mt: 1 }}
                    />
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>

            {/* Data Quality */}
            <Grid item xs={12} sm={6} md={4}>
              <StyledCard elevation={3}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Avatar sx={{ bgcolor: 'success.main', mr: 2 }}>
                      <CheckCircleIcon />
                    </Avatar>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="subtitle1">Data Quality</Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {kpiData.dataQuality}%
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={kpiData.dataQuality}
                      sx={{ mt: 1 }}
                    />
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>
          </Grid>

          {/* Tabs Section */}
          <Box sx={{ mt: 4 }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Summary Tabs"
              variant="fullWidth"
              centered
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab icon={<TimelineIcon />} label="Run History" />
              <Tab icon={<SecurityIcon />} label="Vulnerability Assessment" />
              <Tab icon={<AssessmentIcon />} label="Data Quality" />
              <Tab icon={<HistoryIcon />} label="Activity Log" />
            </Tabs>

            {/* Tab Panels */}
            {tabValue === 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Recent Runs
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Workflow</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Timestamp</TableCell>
                        <TableCell align="right">
                          <Tooltip title="Refresh">
                            <IconButton onClick={handleRefresh}>
                              <RefreshIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {kpiData.runHistory.map((run) => (
                        <StyledTableRow key={run.id}>
                          <TableCell>{run.workflow}</TableCell>
                          <TableCell>
                            <Chip
                              label={run.status}
                              color={
                                run.status === 'Success'
                                  ? 'success'
                                  : run.status === 'Failed'
                                  ? 'error'
                                  : 'default'
                              }
                              icon={
                                run.status === 'Success' ? (
                                  <CheckCircleIcon />
                                ) : run.status === 'Failed' ? (
                                  <ErrorIcon />
                                ) : (
                                  <PauseCircleIcon />
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>{run.timestamp}</TableCell>
                          <TableCell align="right">
                            <Tooltip title="More Info">
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}

            {tabValue === 1 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Vulnerability Assessment
                </Typography>
                <Alert severity="warning" sx={{ mb: 2 }}>
                  {kpiData.vulnerabilityIssues} potential vulnerabilities detected.
                </Alert>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'error.main' }}>
                        <WarningIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Outdated SSL Certificate"
                      secondary="Detected on 2023-10-07"
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'error.main' }}>
                        <WarningIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Unencrypted Data Transfer"
                      secondary="Detected on 2023-10-06"
                    />
                  </ListItem>
                </List>
              </Box>
            )}

            {tabValue === 2 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Data Quality Over Time
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Quality (%)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {kpiData.dataQualityHistory.map((dq, index) => (
                        <StyledTableRow key={index}>
                          <TableCell>{dq.timestamp}</TableCell>
                          <TableCell>
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Typography>{dq.quality}%</Typography>
                              <LinearProgress
                                variant="determinate"
                                value={dq.quality}
                                sx={{ width: '100px' }}
                              />
                            </Stack>
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}

            {tabValue === 3 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Recent Activities
                </Typography>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'primary.main' }}>
                        <DoneAllIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="User John Doe updated the Data Model."
                      secondary="2023-10-07 09:45 AM"
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'secondary.main' }}>
                        <PlayCircleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Workflow 'Data Sync' started."
                      secondary="2023-10-07 09:30 AM"
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'error.main' }}>
                        <ErrorIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Workflow 'Data Backup' failed."
                      secondary="2023-10-07 09:15 AM"
                    />
                  </ListItem>
                </List>
              </Box>
            )}
          </Box>

          {/* Snackbar for Refresh */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message="Data refreshed successfully"
            action={
              <IconButton size="small" aria-label="close" onClick={handleCloseSnackbar}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </>
      )}
    </Box>
  );
};

export default SummaryTab;
