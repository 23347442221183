import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function useNavbar() {
  const navigate = useNavigate();
  const [logo, setLogo] = useState();
  const [collapsed, setCollapsed] = useState(true);
  const [currencycollapsed, setcurrencyCollapsed] = useState(true);
  const [addcurrencycollapsed, setaddcurrencyCollapsed] = useState(true);
  const [searchVisible, setSearchVisible] = useState(false);
  const [mobileSearchVisible, setMobileSearchVisible] = useState(false);
  const appCtx = useSelector((state) => state.app);
  const defaultProfilePic =
    "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/summer-flowers-star-flower-1648071187.jpg";
  const userId = useSelector((state) => state.app.userDetails.userId);
  // useEffect(() => {
  //     setLogo(logoSrc);
  // }, [logoSrc]);

  const toggleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const togglecurrencyCollapse = useCallback(() => {
    setcurrencyCollapsed(!currencycollapsed);
  }, [currencycollapsed]);

  const toggleAddcurrencyCollapse = useCallback(() => {
    setaddcurrencyCollapsed(!addcurrencycollapsed);
  }, [addcurrencycollapsed]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [visible, setVisible] = useState(false);

  const handleVisible = (e) => {
    if (appCtx.isLoggedIn) {
      if (visible === true) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    }
  };

  const [currencyvisible, setCurrencyVisible] = useState(false);
  const handleCurrencyVisible = (e) => {
    if (appCtx.isLoggedIn) {
      if (currencyvisible === true) {
        setCurrencyVisible(false);
      } else {
        setCurrencyVisible(true);
      }
    }
  };
 const [notificationVisible, setNotificationVisible] = useState(false);
 const handleNotificationVisible = (e) => {
   if (appCtx.isLoggedIn) {
     if (notificationVisible === true) {
       setNotificationVisible(false);
     } else {
       setNotificationVisible(true);
     }
   }
 };

  const [profileVisible, setProfileVisible] = useState(false);
  const handleProfileVisible = (e) => {
    setProfileVisible(true);
  };

  const [addcurrencyvisible, setaddCurrencyVisible] = useState(false);
  const handleaddCurrencyVisible = (e) => {
    if (appCtx.isLoggedIn) {
      if (addcurrencyvisible === true) {
        setaddCurrencyVisible(false);
      } else {
        setaddCurrencyVisible(true);
      }
    }
  };

  const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //     setAnchorEl(null);
  // };
  // console.log(visible);
  // console.log(ref,wrapperRef);

  return {
    logo,
    collapsed,
    currencycollapsed,
    addcurrencycollapsed,
    // logoSrc,
    userId,
    toggleCollapse,
    togglecurrencyCollapse,
    toggleAddcurrencyCollapse,

    visible,
    currencyvisible,
    addcurrencyvisible,
    open,
    handleVisible,
    handleCurrencyVisible,
    handleaddCurrencyVisible,
    setVisible,
    setCurrencyVisible,
    setaddCurrencyVisible,
    searchVisible,
    setSearchVisible,
    mobileSearchVisible,
    setMobileSearchVisible,
    profileVisible,
    setProfileVisible,
    handleProfileVisible,
    notificationVisible,
    setNotificationVisible,
    handleNotificationVisible,
  };
}
