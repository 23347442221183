import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCryptobasket } from "./useCryptobasket";
import { CircularProgress, Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendUp,
  faCheck,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { appActions } from "../../context/app-slice";
import MainWrapper from "../../component/wrapper/MainWrapper";
import Accordion from "react-bootstrap/Accordion";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { useCryptoFund } from "./useCryptoFund";
import { useWalletView } from "../../hooks/useWalletView";
import CryptoFundAreaChart from "../../component/areachart/CryptoFundAreaChart";
import { Button, Collapse } from "react-bootstrap";
import CryptoFundOnRampModal from "../../component/CryptoFundOnRampModal/CryptoFundOnRampModal";
import TransactionsTablesCard from "../../component/Transactions/TransactionsTablesCard";
import CryptoFundOffRampModal from "../../component/CryptoFundOffRampModal/CryptoFundOffRampModal";
import TransactionHistoryComponent from "../../component/tokenBottomTable/TransactionHistoryComponent";
import { getBlockchainAddressUrl } from "../../config";
import FundCurrencyModal from "./FundCurrencyModal";
import PayusingTokenModal from "./PayusingTokenModal";
import TaleWalletOnRampModal from "../../component/TaleWalletOnRamp/TaleWalletOnRampModal";
import { useMetamask } from "../../hooks/useMetamask";

const CryptoFundDetails = () => {
  // const [closeClicked, setCloseClicked] = useState(false);
  const appCtx = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [stats, setStats] = useState({});
  const [composition, setComposition] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const [isContractAddressCopied, setIsContractAddressCopied] = useState(false);

  const [TokenList, setTokenList] = useState([]);
  const [TokenLoad, setTokenLoad] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [openCryptoFundOnrampModal, setOpenCryptoFundOnrampModal] =
    useState(false);
  const [openCryptoFundOfframpModal, setOpenCryptoFundOfframpModal] =
    useState(false);
  const [subscribeStatus, setSubscribeStatus] = useState("");
  const [isSubscribedToCryptoFundLoading, setIsSubscribedToCryptoFundLoading] =
    useState(false);
  const [subscribedFundData, setSubscribedFundData] = useState({});
  const [selectedFundId, setSelectedFundId] = useState(null);
  const [openFundCurrencyModal, setOpenFundCurrencyModal] = useState(false);
  const [payUsingTokenModal, setPayUsingTokenModal] = useState(false);
  const [selectedTokenOption, setSelectedTokenOption] = useState(null);
  const [openTalewalletOnrampModal, setOpenTalewalletOnrampModal] =
    useState(false);

  const makeAuthorizedRequest = useAuthorizedHttp();
  const { isUserSubscribed } = useCryptoFund();
  const { switchChainandOpenModal } = useMetamask();

  const {
    token,
    accountAsset,
    setAccountAsset,
    amount,
    setAmount,
    assetUrl,
    setAssetUrl,
    handleOptIn,
    showAssets,
    optedIn,
    taleAmount,
    showTaleData,
    optInSuccessfull,
    setOptInSuccessfull,
    handleCustodialOptIn,
    minBalance,
    getAssetsById,
    assetLoader,
    allCoin,
    allCoinPrice,
    usersToken,
    isAssetLoading,
    setIsAssetLOading,
    isCoinLoading,
    isCoinPriceLOading,
    otheraccountAsset,
    otherassetLoader,
    openSwapModal,
    setopenSwapModal,
    openSellModal,
    setopenSellModal,
    getAllCoin,
    getAllCoinPrice,
    getUsersToken,
    totalpage,
    handleRefresh,
    setAllcoinPrice,
  } = useWalletView();

  useEffect(() => {
    getAllCoin(0, 100);
    getAllCoinPrice();
  }, [appCtx.blockchain]);
  useEffect(() => {
    setDataLoading(true);
    makeAuthorizedRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/${id}/stats`,
        method: "get",
      },
      (data) => {
        setStats(data);
        setDataLoading(false);
      },

      (err) => {}
    );
  }, []);

  console.log("stats", stats);

  useEffect(() => {
    makeAuthorizedRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/${id}/composition`,
        method: "get",
      },
      (data) => {
        setComposition(data);
        setDataLoading(false);
      },

      (err) => {
        setDataLoading(false);
      }
    );
  }, []);
  useEffect(() => {
    makeAuthorizedRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/${id}/contracts`,
        method: "get",
      },
      (data) => {
        setContracts(data);
      },

      (err) => {}
    );
  }, []);

  console.log("contracts", contracts);
  console.log("composition", composition);
  console.log("stats", stats);

  const Data = [
    {
      id: 0,
      value: "1W",
      range: "week",
    },
    {
      id: 1,
      value: "1M",
      range: "month",
    },
    {
      id: 2,
      value: "6M",
      range: "6month",
    },
    {
      id: 3,
      value: "1Y",
      range: "year",
    },
  ];

  const [selectedItem, setSelectedItem] = useState(Data[0]?.id);
  const [newsData, setNewsData] = useState([]);
  const [selectedRange, setSelectedRange] = useState(Data[selectedItem]?.range);

  const makeRequest = useAuthorizedHttp();
  const navigate = useNavigate();

  useEffect(() => {
    if (stats?.tokenSymbol) {
      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/crypto/lens/tag/post/list`,
          method: "post",
          data: [stats?.tokenSymbol],
        },
        (data) => {
          setNewsData(data?.content);
        },
        () => {
          console.log("Get news Data Error: ");
        },
        () => {
          // navigate('/login')
        }
      );
    }
  }, [stats]);

  // accordion

  // console.log("stats", stats);
  // console.log("composition", composition);
  // console.log("contracts", contracts);
  // console.log("subscribedFundData", subscribedFundData);
  // console.log("newsData", newsData);

  const handleCopyAddress = (value) => {
    setIsAddressCopied(!isAddressCopied);
    navigator.clipboard.writeText(value);
  };
  const handleCopyContractAddress = (value) => {
    setIsContractAddressCopied(!isContractAddressCopied);
    navigator.clipboard.writeText(value);
  };

  const result = appCtx?.walletAddress.filter(
    (item) => item?.blockchain == appCtx?.selectedChain?.toUpperCase()
  );
  const address = contracts?.fundInWallet
    ? contracts?.fundWalletAddress
    : contracts?.contractAddress;

  const truncatedAddress = `${address?.substring(
    0,
    6
  )}.....${address?.substring(address?.length - 6)}`;

  const truncatedContractAddress = `${contracts?.contractAddress?.substring(
    0,
    6
  )}.....${contracts?.contractAddress?.substring(
    contracts?.contractAddress?.length - 6
  )}`;

  const getTokenIcon = (tokenname) => {
    const icon = allCoin?.find(
      (token) => token?.symbol === tokenname
    )?.tokenIcon;

    return icon;
  };

  const [collapsed, setCollapsed] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
  ]); // Array of collapsed states

  useEffect(() => {
    isUserSubscribed(
      id,
      setSubscribeStatus,
      setIsSubscribedToCryptoFundLoading,
      setSubscribedFundData
    );
  }, [
    openCryptoFundOnrampModal === false,
    openCryptoFundOfframpModal === false,
  ]);

  const toggleAccordion = (index) => {
    const newCollapsedState = [...collapsed];
    newCollapsedState[index] = !newCollapsedState[index];
    setCollapsed(newCollapsedState);
  };

  const handlePress = useCallback(async (url) => {
    window.open(url, "_blank");
  }, []);

  console.log("selected range", selectedRange);

  let myRetunrs =
    subscribedFundData?.tokenQuantity *
      (stats?.cryptoFundStats?.tokenPrice * appCtx?.usdPrice) -
    subscribedFundData?.totalInvestedAmount;

  const themedColor = "black";

  let investQuantity =
    subscribedFundData?.tokenQuantity *
    (stats?.cryptoFundStats?.tokenPrice * appCtx?.usdPrice);

  let returnPercentage =
    ((investQuantity - subscribedFundData?.totalInvestedAmount) /
      subscribedFundData?.totalInvestedAmount) *
    100;

  const width = window.screen.width;
  let isMobileDevice = width < 600;

  return (
    <MainWrapper>
      <div class={`${appCtx?.isDarkMode ? "" : ""}`}>
        <div class="container-fluid ">
          {dataLoading ? (
            <div className="pt-[5%] w-75 mx-auto">
              {Array.from({ length: 12 }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="text"
                  sx={{ fontSize: index === 11 ? "2rem" : "1rem" }}
                  className={
                    index === 2 || index === 9 || index === 10 || index === 11
                      ? "w-[60%] mx-auto mt-3"
                      : index === 3
                      ? "w-[70%] mx-auto mt-5"
                      : "w-[80%] mx-auto"
                  }
                  height={index === 3 ? 300 : undefined}
                />
              ))}
            </div>
          ) : (
            <>
              <button
                className="btn btn-primary mb-2"
                onClick={() => navigate("/crypto-fund")}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ fontSize: "12px" }}
                />
              </button>
              <div class="row">
                <div class="col-xl-12  col-sm-12 ">
                  <div class="card ">
                    <div class="card-body">
                      <div class="w-100 d-flex justify-content-between align-items-center  flex-wrap  ">
                        <div className="d-flex flex-column gap-1">
                          <h4 class="fs-20 font-w600 mb-0">
                            {stats?.fundName}
                          </h4>
                          {stats?.managedBy && (
                            <p class="fs-14 mb-0">by {stats?.managedBy}</p>
                          )}

                          {contracts?.contractAddress && (
                            <div class="d-flex gap-1 align-items-center">
                              <div
                                onClick={() => {
                                  const blockchain = contracts?.blockchain;
                                  const blockchainLink =
                                    getBlockchainAddressUrl(
                                      blockchain,
                                      contracts?.contractAddress
                                    );

                                  if (blockchainLink !== "") {
                                    window.open(blockchainLink);
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                                className={`${
                                  appCtx?.isDarkMode
                                    ? "text-primary"
                                    : "text-primary"
                                }`}
                              >
                                {truncatedContractAddress}
                              </div>
                              <div
                                onClick={() =>
                                  handleCopyAddress(contracts?.contractAddress)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {isAddressCopied ? (
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className=""
                                    style={{
                                      fontSize: "16px",
                                      color: themedColor,
                                    }}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faCopy}
                                    className=""
                                    style={{
                                      fontSize: "16px",
                                      color: themedColor,
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                          <div className="d-flex gap-2 align-items-center">
                            <h4
                              className={`font-w600 mb-0  ${
                                stats?.cryptoFundStats?.cagr > 0
                                  ? "text-success"
                                  : "text-danger"
                              }`}
                            >
                              {stats?.cryptoFundStats?.cagr || "--"}%
                            </h4>
                            <p className="fs-12 d-block mb-0 ">CAGR</p>
                          </div>
                        </div>

                        <div className="d-flex gap-4 align-items-center gap-2   border-0 pt-2 pt-md-0">
                          {appCtx.isLoggedIn ? (
                            <button
                              onClick={() => {
                                if (!appCtx?.custodial) {
                                  switchChainandOpenModal(
                                    setOpenFundCurrencyModal,
                                    stats?.blockchain
                                  );
                                } else {
                                  setOpenFundCurrencyModal(true);
                                }
                              }}
                              type="button"
                              className="btn btn-success d-flex align-items-center justify-content-between"
                            >
                              Invest
                              <FontAwesomeIcon
                                icon={faArrowTrendUp}
                                style={{ transform: "rotate(90deg)" }}
                              />
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                navigate("/login");
                              }}
                              type="button"
                              className="btn btn-success d-flex align-items-center justify-content-between"
                            >
                              Login to Invest
                              <FontAwesomeIcon
                                icon={faArrowTrendUp}
                                style={{ transform: "rotate(90deg)" }}
                              />
                            </button>
                          )}

                          {(subscribeStatus === "INVESTED" ||
                            subscribeStatus === "VERIFIED") && (
                            <button
                              onClick={() => {
                                setOpenCryptoFundOfframpModal(true);
                              }}
                              type="button"
                              className="btn btn-danger  d-flex align-items-center justify-content-between"
                            >
                              Redeem
                              <FontAwesomeIcon icon={faArrowTrendUp} />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* status card  */}
                {subscribeStatus &&
                  subscribeStatus !== "INVESTED" &&
                  subscribeStatus !== "VERIFIED" && (
                    <div className="col-xl-12">
                      <div class="card">
                        <div class="card-body">
                          <div className="">
                            {
                              <>
                                {subscribeStatus === "INITIATED" && (
                                  <div class="alert alert-primary alert-dismissible fade show">
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="me-2 alert-icon"
                                    >
                                      <circle cx="12" cy="12" r="10"></circle>
                                      <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                      <line
                                        x1="9"
                                        y1="9"
                                        x2="9.01"
                                        y2="9"
                                      ></line>
                                      <line
                                        x1="15"
                                        y1="9"
                                        x2="15.01"
                                        y2="9"
                                      ></line>
                                    </svg>
                                    Awaiting Payment Confirmation
                                  </div>
                                )}
                                {subscribeStatus === "FAILED" && (
                                  <div class="alert alert-danger alert-dismissible fade show">
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="me-2 alert-icon"
                                    >
                                      <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                      <line
                                        x1="15"
                                        y1="9"
                                        x2="9"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="9"
                                        y1="9"
                                        x2="15"
                                        y2="15"
                                      ></line>
                                    </svg>
                                    Payment failed. Try again
                                  </div>
                                )}
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {/* details section  */}
                <div class="col-xl-3 col-sm-12 ">
                  <div
                    class="card "
                    style={{
                      height: `${isMobileDevice ? "auto" : "500px"}`,
                      overflow: "auto",
                    }}
                  >
                    <div class="card-header border-0 pb-3">
                      <h4 class="card-title">Details</h4>
                    </div>
                    <div class="card-body">
                      <div class="text-center">
                        <div class="profile-photo">
                          {stats?.icon === null ? (
                            <img
                              src="/images/basketCardimg.png"
                              className=""
                              style={{
                                width: "45px",
                                height: "45px",
                                borderRadius: "50%",
                              }}
                              class="img-fluid rounded-circle"
                              alt=""
                            />
                          ) : (
                            <img
                              src={stats?.icon}
                              className=""
                              style={{
                                width: "45px",
                                height: "45px",
                                borderRadius: "50%",
                              }}
                              class="img-fluid rounded-circle"
                              alt=""
                            />
                          )}
                        </div>
                        <h5 class="mt-4 mb-1">{stats?.tokenSymbol}</h5>
                        <h5 class="">{stats?.tokenName}</h5>
                        <p
                          onClick={() => {
                            const blockchain = contracts?.blockchain;
                            const transactionId = contracts?.tokenAddress;

                            const blockchainLink = getBlockchainAddressUrl(
                              blockchain,
                              transactionId
                            );

                            if (blockchainLink !== "") {
                              window.open(blockchainLink);
                            }
                          }}
                          className=""
                        >
                          <span className="font-semibold">Token Address:</span>{" "}
                          <span
                            className="text-primary"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {contracts?.tokenAddress}
                          </span>
                        </p>

                        <h4>
                          1 {stats?.tokenSymbol} ={" "}
                          {stats?.cryptoFundStats?.tokenPrice}{" "}
                          {stats?.cryptoFundStats?.tokenPriceCurrency}
                        </h4>

                        <p className="fs-10 font-w400">{stats?.description}</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/*data & chart  */}
                <div
                  className="col-xl-9 col-sm-12"
                  style={{
                    height: `${isMobileDevice ? "auto" : "500px"}`,
                    overflow: "auto",
                  }}
                >
                  <div className="row">
                    {(subscribeStatus === "INVESTED" ||
                      subscribeStatus === "VERIFIED") && (
                      <div className="col-xl-12">
                        <div class="card coin-content">
                          <div class="card-header border-0 flex-wrap pb-0">
                            <div class="mb-xl-0 mb-2">
                              <h4 class="card-title">My Investment</h4>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between flex-wrap">
                              <div class="d-flex align-items-end justify-content-between flex-wrap">
                                <div class="price-content">
                                  <h4 class="fs-20 font-w600 mb-0">
                                    INR{" "}
                                    {investQuantity
                                      ? investQuantity?.toFixed(2)
                                      : "--"}
                                  </h4>
                                  <span class="d-block mb-2">Balance</span>
                                </div>
                                <div class="price-content">
                                  <h4 class="fs-20 font-w600 mb-2">
                                    Rs{" "}
                                    {subscribedFundData?.totalInvestedAmount
                                      ? subscribedFundData?.totalInvestedAmount
                                      : 0}
                                  </h4>
                                  <span class="d-block mb-0">Invested</span>
                                </div>
                                <div class="price-content">
                                  <h4 class="fs-20 font-w600 mb-2">
                                    Rs {parseInt(myRetunrs) || "--"}
                                  </h4>
                                  <span class="d-block mb-0">Return</span>
                                </div>
                                <div class="price-content">
                                  <h4
                                    class={`fs-20 font-w600 mb-2 ${
                                      returnPercentage > 0
                                        ? "text-success"
                                        : "text-danger"
                                    }`}
                                  >
                                    {returnPercentage?.toFixed(2)} %
                                  </h4>
                                  <span class="d-block mb-0">Return %</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-xl-12">
                      <div
                        class="card "
                        style={{
                          height: `${isMobileDevice ? "auto" : "500px"}`,
                          overflow: "auto",
                        }}
                      >
                        <div class="card-header border-0 pb-2">
                          <h4 class="card-title">History</h4>
                        </div>
                        <div class="card-body">
                          <div className="d-flex flex-direction-row justify-content-between ">
                            <div className="w-100 d-flex flex-direction-row justify-content-end">
                              <ul
                                class="nav nav-pills"
                                id="myTab1"
                                role="tablist"
                              >
                                {Data.map((item) => (
                                  <li
                                    class="nav-item"
                                    role="presentation"
                                    key={item.id}
                                  >
                                    <button
                                      onClick={() => {
                                        setSelectedItem(item.id);
                                        setSelectedRange(Data[item?.id]?.range);
                                      }}
                                      class={`nav-link   ${
                                        selectedItem === item.id ? "active" : ""
                                      }`}
                                      role="tab"
                                      aria-selected="true"
                                    >
                                      {item.value}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <div id="bitcoinhChart">
                            <CryptoFundAreaChart
                              fundId={id}
                              selectedRange={selectedRange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Transaction card  */}

                <div class="col-xl-6  col-sm-12">
                  <div
                    class="card "
                    style={{
                      height: `${isMobileDevice ? "auto" : "500px"}`,
                      overflow: "auto",
                    }}
                  >
                    <div class="card-header border-0 pb-3">
                      <h4 class="card-title">Transaction</h4>
                    </div>
                    <>
                      <div class="col-lg-12">
                        <div class="card transaction-table">
                          <div class="card-body p-0">
                            <TransactionHistoryComponent
                              selectedFundId={id}
                              setSelectedFundId={setSelectedFundId}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>

                {/* allocation card  */}

                <div class="col-xl-3  col-sm-12 ">
                  <div
                    class="card "
                    style={{
                      height: `${isMobileDevice ? "auto" : "500px"}`,
                      overflow: "auto",
                    }}
                  >
                    <div class="card-header border-0 pb-3">
                      <h4 class="card-title">Allocation</h4>
                      {contracts?.fundWalletAddress && (
                        <button
                          onClick={() => {
                            const blockchain = contracts?.blockchain;
                            const blockchainLink = getBlockchainAddressUrl(
                              blockchain,
                              contracts?.fundWalletAddress
                            );

                            if (blockchainLink !== "") {
                              window.open(blockchainLink);
                            }
                          }}
                          className="btn btn-primary m-0 px-1"
                        >
                          <p className="fs-10 font-w400 mb-0">View on chain</p>
                        </button>
                      )}
                    </div>
                    <div class="card-body px-0 pt-0 dlab-scroll">
                      {composition?.map((item, index) => (
                        <div
                          key={index}
                          class="d-flex justify-content-between align-items-center market-preview py-2"
                        >
                          <div class="d-flex align-items-center">
                            <span>
                              <img
                                src={item.tokenIcon}
                                alt=""
                                style={{ width: "36px", height: "36px" }}
                                className="rounded-circle"
                              />
                            </span>
                            <div class="ms-3">
                              <a href="javascript:void(0);">
                                <h5 class="fs-14 font-w600 mb-0">
                                  {item.token}
                                </h5>
                              </a>
                            </div>
                          </div>
                          <div class="d-flex align-items-center">
                            <div class="ms-3">
                              <span class="">{item?.tokenPercentage}%</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* fees card  */}
                <div class="col-xl-3  col-sm-12 my-order-ile-2">
                  <div
                    class="card trade-history"
                    style={{
                      height: `${isMobileDevice ? "auto" : "500px"}`,
                      overflow: "auto",
                    }}
                  >
                    <div class="card-header border-0 pb-3 ">
                      <h4 class="card-title">Fees</h4>
                    </div>
                    <div class="card-body overflow-hidden">
                      <div className="price-content mb-2">
                        <p>{stats?.feeDescription}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {openFundCurrencyModal && (
        <FundCurrencyModal
          openModal={openFundCurrencyModal}
          setOpenModal={setOpenFundCurrencyModal}
          setSelectedTokenOption={setSelectedTokenOption}
          setOpenCryptoFundOnrampModal={setOpenCryptoFundOnrampModal}
          setPayUsingTokenModal={setPayUsingTokenModal}
        />
      )}

      {payUsingTokenModal && (
        <PayusingTokenModal
          openModal={payUsingTokenModal}
          setOpenModal={setPayUsingTokenModal}
          symbol={selectedTokenOption}
          name={stats?.fundName}
          fundId={id}
          truncatedAddress={truncatedAddress}
          fundWalletAddress={contracts?.fundWalletAddress}
          setOpenTalewalletOnrampModal={setOpenTalewalletOnrampModal}
          allCoin={allCoin}
        />
      )}
      {openCryptoFundOnrampModal && (
        <CryptoFundOnRampModal
          openModal={openCryptoFundOnrampModal}
          setOpenModal={setOpenCryptoFundOnrampModal}
          // symbol={selectedTokenOption}
          // selectedInvestmentOption={selectedInvestmentOption}
          name={stats?.fundName}
          fundId={id}
          tokenPriceCurrency={stats?.tokenSymbol}
          fundWalletAddress={contracts?.fundWalletAddress}
          tokenPrice={stats?.cryptoFundStats?.tokenPrice}
          contractAddress={contracts?.contractAddress}
          fundsBlockchain={contracts?.blockchain}
          truncatedAddress={truncatedAddress}
        />
      )}

      {openCryptoFundOfframpModal && (
        <CryptoFundOffRampModal
          openModal={openCryptoFundOfframpModal}
          setOpenModal={setOpenCryptoFundOfframpModal}
          // symbol={selectedTokenOption}
          // selectedInvestmentOption={selectedInvestmentOption}
          name={stats?.fundName}
          fundId={id}
          tokenPriceCurrency={stats?.tokenSymbol}
          fundWalletAddress={contracts?.fundWalletAddress}
          tokenPrice={stats?.cryptoFundStats?.tokenPrice}
          contractAddress={contracts?.contractAddress}
          fundsBlockchain={contracts?.blockchain}
          truncatedAddress={truncatedAddress}
          investedAmount={subscribedFundData?.totalInvestedAmount}
        />
      )}

      {openTalewalletOnrampModal && (
        <TaleWalletOnRampModal
          openModal={openTalewalletOnrampModal}
          setOpenModal={setOpenTalewalletOnrampModal}
          // amount={amount}
          // basketId={basketId}
          // tokenPercentArray={tokenPercentArray}
          // tokenArray={tokenArray}
          // basketBlockchain={basketBlockchain}
          symbol={selectedTokenOption}
          isTokenDetailsPage={true}
        />
      )}
    </MainWrapper>
  );
};

export default CryptoFundDetails;
