import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { ProtocolList } from "./ProtocolList"; // Import the shared data
import MainWrapper from "../../component/wrapper/MainWrapper";
import CreateBasketModal from "./CreateBasketModal";

const CropBasket = () => {
  const [openCreateBasketModal, setOpenCreateBasketModal] = useState(false);
  const [closeClicked, setCloseClicked] = useState(false);
  const [showAllPrivateBasket, setShowAllPrivateBasket] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("");
  const [noCodeConsoleOnly, setNoCodeConsoleOnly] = useState(false);
  const [selectedLevels, setSelectedLevels] = useState([]); // Added state for selected levels
  const [suggestions, setSuggestions] = useState([]);

  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Extract all unique model types initially from ProtocolList
  const allModelTypes = Array.from(new Set(ProtocolList.map((item) => item.model)));

  // Handler for level checkbox changes
  const handleLevelChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedLevels((prevLevels) => [...prevLevels, value]);
    } else {
      setSelectedLevels((prevLevels) => prevLevels.filter((level) => level !== value));
    }
  };

  // Filter protocol list based on search term (tag), selected model type, no-code filter, and selected levels
  const filteredProtocols = ProtocolList.filter((basket) => {
    const matchesSearchTerm = searchTerm
      ? basket.tags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()))
      : true;

    const matchesModelType = filterType ? basket.model === filterType : true;

    const matchesNoCode = noCodeConsoleOnly ? basket.no_code === "yes" : true;

    const matchesLevel = selectedLevels.length > 0 ? selectedLevels.includes(basket.level) : true;

    return matchesSearchTerm && matchesModelType && matchesNoCode && matchesLevel;
  });

  const filteredModelTypes = searchTerm
    ? Array.from(
        new Set(
          ProtocolList.filter((basket) =>
            basket.tags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()))
          ).map((basket) => basket.model)
        )
      )
    : allModelTypes;

  useEffect(() => {
    if (searchTerm) {
      const tagSuggestions = ProtocolList.filter((basket) => {
        return basket.tags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()));
      })
        .flatMap((basket) => basket.tags)
        .filter((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()))
        .slice(0, 5); // Limit the number of suggestions
      setSuggestions(tagSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [searchTerm]);

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    setSuggestions([]); // Close the suggestion dropdown after selection
  };

  return (
    <MainWrapper>
      <div className="blueprints-container">
        <div className="row">
          <div className="col-12">
            <div className="crop-basket-section">
              <h4 className="section-title">Protocols</h4>

              <div className="filter-section" style={{ position: "relative", display: "flex", flexWrap: "wrap", gap: "10px" }}>
                <input
                  type="text"
                  placeholder="Search by tags..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ padding: "5px" }}
                />
                {suggestions.length > 0 && (
                  <div className="suggestions" style={{ position: "absolute", background: "#fff", border: "1px solid #ccc", zIndex: 1000, width: "200px" }}>
                    {suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className="suggestion-item"
                        onClick={() => handleSuggestionClick(suggestion)}
                        style={{ cursor: "pointer", padding: "5px 10px" }}
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                )}

                <select
                  value={filterType}
                  onChange={(e) => setFilterType(e.target.value)}
                  style={{ padding: "5px" }}
                >
                  <option value="">Model Type</option>
                  {filteredModelTypes.map((model, index) => (
                    <option key={index} value={model}>
                      {model}
                    </option>
                  ))}
                </select>

                <label style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <input
                    type="checkbox"
                    checked={noCodeConsoleOnly}
                    onChange={(e) => setNoCodeConsoleOnly(e.target.checked)}
                  />
                  Active Models
                </label>

                {/* Responsive Level Filters (L1, L2, L3) */}
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                  <label>
                    <input
                      type="checkbox"
                      value="image&video"
                      checked={selectedLevels.includes("image&video")}
                      onChange={handleLevelChange}
                    />
                    Images & Video
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      value="Audio"
                      checked={selectedLevels.includes("Audio")}
                      onChange={handleLevelChange}
                    />
                    Audio
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      value="Text"
                      checked={selectedLevels.includes("Text")}
                      onChange={handleLevelChange}
                    />
                    Text
                  </label>
                </div>
              </div>

              <div className="row p-3">
                {filteredProtocols.length > 0 ? (
                  filteredProtocols.map((basket) => (
                    <div className="col-md-4 mb-3" key={basket.id}>
                      <div
                        className="card shadow-sm"
                        style={{ backgroundColor: "#fff", borderRadius: "8px", padding: "20px" }}
                      >
                        <div className="d-flex align-items-center mb-3" style={{ textAlign: "left" }}>
                          <img
                            src={basket.image}
                            alt={basket.name}
                            style={{ height: "40px", width: "40px", marginRight: "10px" }}
                          />
                          <h5 style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>{basket.name}</h5>
                        </div>
                        <div className="card-body p-0" style={{ textAlign: "left" }}>
                          <p style={{ fontSize: "14px", color: "#6c757d", marginTop: "10px" }}>{basket.description}</p>
                          <div className="tags-section mb-3">
                            {basket.tags.map((tag, index) => (
                              <span className="tag-item" key={index} style={{ display: "inline-block", background: "#f1f1f1", padding: "3px 8px", borderRadius: "12px", marginRight: "5px", fontSize: "12px" }}>
                                {tag}
                              </span>
                            ))}
                          </div>
                          <div className="level-indicator mb-3">
                            <span
                              style={{
                                display: "inline-block",
                                background:
                                  basket.level === "image&video"
                                    ? "#d1e7dd"
                                    : basket.level === "Audio"
                                    ? "#cff4fc"
                                    : "#f8d7da",
                                color:
                                  basket.level === "image&video"
                                    ? "#0f5132"
                                    : basket.level === "Audio"
                                    ? "#055160"
                                    : "#842029",
                                padding: "3px 8px",
                                borderRadius: "12px",
                                fontSize: "12px",
                              }}
                            >
                              {basket.level}
                            </span>
                          </div>
                          <button
                            className="btn btn-outline-primary mt-3"
                            onClick={() => {
                              navigate(basket.link);
                            }}
                            style={{
                              fontSize: "14px",
                              padding: "5px 15px",
                              borderRadius: "8px",
                            }}
                          >
                            Select
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-12">
                    <p style={{ textAlign: "center", color: "#6c757d" }}>No protocols found matching the selected filters.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {openCreateBasketModal && (
          <CreateBasketModal
            openModal={openCreateBasketModal}
            setOpenModal={setOpenCreateBasketModal}
            closeClicked={closeClicked}
            setCloseClicked={setCloseClicked}
          />
        )}
      </div>
    </MainWrapper>
  );
};

export default CropBasket;
