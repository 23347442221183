import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Card,
  Button,
  Grid,
  TextField,
  Typography,
  Snackbar,
  Tabs,
  Tab,
  AppBar,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Stop as StopIcon,
  ContentCopy as ContentCopyIcon,
  Search as SearchIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import { useParams } from 'react-router-dom';

const WorkflowForm = ({
  currentWorkflow,
  setCurrentWorkflow,
  handleSaveWorkflow,
  isEditingWorkflow,
  setWorkflowModalOpen,
  availableConnections,
  availableArtifacts,
  availableGeoRegions,
}) => {
  const frequencyOptions = ['Daily', 'Weekly', 'Monthly', 'Yearly'];
  const notificationOptions = ['Email', 'SMS', 'Push Notification'];

  return (
    <Box component="form" onSubmit={handleSaveWorkflow}>
      {/* General Settings */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">General Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {/* Workflow Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Workflow Name"
                variant="outlined"
                fullWidth
                required
                value={currentWorkflow ? currentWorkflow.name : ''}
                onChange={(e) =>
                  setCurrentWorkflow({
                    ...currentWorkflow,
                    name: e.target.value,
                  })
                }
              />
            </Grid>




            {/* Data Input Connector */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="data-input-label">Data Input Connector</InputLabel>
                <Select
                  labelId="data-input-label"
                  label="Data Input Connector"
                  value={currentWorkflow ? currentWorkflow.dataInput : ''}
                  onChange={(e) =>
                    setCurrentWorkflow({
                      ...currentWorkflow,
                      dataInput: e.target.value,
                    })
                  }
                  required
                >
                  {availableConnections.map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Data Output Connector */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="data-output-label">Data Output Connector</InputLabel>
                <Select
                  labelId="data-output-label"
                  label="Data Output Connector"
                  value={currentWorkflow ? currentWorkflow.dataOutput : ''}
                  onChange={(e) =>
                    setCurrentWorkflow({
                      ...currentWorkflow,
                      dataOutput: e.target.value,
                    })
                  }
                  required
                >
                  {availableConnections.map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Model */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Model"
                variant="outlined"
                fullWidth
                required
                value={currentWorkflow ? currentWorkflow.model : ''}
                onChange={(e) =>
                  setCurrentWorkflow({
                    ...currentWorkflow,
                    model: e.target.value,
                  })
                }
              />
            </Grid>

            {/* Frequency */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="frequency-label">Frequency</InputLabel>
                <Select
                  labelId="frequency-label"
                  label="Frequency"
                  value={currentWorkflow ? currentWorkflow.frequency : ''}
                  onChange={(e) =>
                    setCurrentWorkflow({
                      ...currentWorkflow,
                      frequency: e.target.value,
                    })
                  }
                  required
                >
                  {frequencyOptions.map((freq) => (
                    <MenuItem value={freq} key={freq}>
                      {freq}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Status */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  label="Status"
                  value={currentWorkflow ? currentWorkflow.status : ''}
                  onChange={(e) =>
                    setCurrentWorkflow({
                      ...currentWorkflow,
                      status: e.target.value,
                    })
                  }
                  required
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>

          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Additional sections like Advanced Settings can be added here */}

      {/* Form Actions */}
      <Box sx={{ mt: 3, textAlign: 'right' }}>
        {setWorkflowModalOpen && (
          <Button
            onClick={() => setWorkflowModalOpen(false)}
            color="secondary"
            startIcon={<CloseIcon />}
            sx={{ mr: 2 }}
          >
            Cancel
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<SaveIcon />}
        >
          {isEditingWorkflow ? 'Save Changes' : 'Add Workflow'}
        </Button>
      </Box>
    </Box>
  );
};



const WorkflowTab = () => {
  const { projectId } = useParams();
  //const [availableConnections, setAvailableConnections] = useState([]);

  const [workflows, setWorkflows] = useState([]);
  const [filteredWorkflows, setFilteredWorkflows] = useState([]);
  const [workflowSearchQuery, setWorkflowSearchQuery] = useState('');
  const [workflowModalOpen, setWorkflowModalOpen] = useState(false);
  const [isEditingWorkflow, setIsEditingWorkflow] = useState(false);
  const [currentWorkflow, setCurrentWorkflow] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const makeRequest = useAuthorizedHttp();



  // Simulated data for available connections, artifacts, and geo-regions
  const [availableConnections, setAvailableConnections] = useState([]);
  const [availableArtifacts, setAvailableArtifacts] = useState([]);
  const [availableGeoRegions, setAvailableGeoRegions] = useState([]);

  useEffect(() => {
    const fetchConnections = async () => {
      const url = `${process.env.REACT_APP_VORTX_URL1}/${projectId}/connections`;
      const authToken = localStorage.getItem("authToken");

      console.log('Fetching connections from URL:', url);
      console.log('Using Auth Token:', authToken);

      try {
        const response = await axios.get(url, {
          headers: {
            "X-Auth-Token": authToken,
            "Content-Type": "application/json",
          },
        });
        setAvailableConnections(response.data); // Assuming response.data contains the connections array
        console.log("Connections loaded:", response.data);
      } catch (error) {
        console.error("Failed to load connections:", error);
        setError('Failed to load connections.');
      }
    };

    fetchConnections();
  }, [projectId]);


  useEffect(() => {
  // Fetch available connections


      // Fetch available artifacts
      makeRequest(
        {
          url: `${process.env.REACT_APP_VORTX_URL}/artifacts/list?project_id=${projectId}`,
          method: 'GET',
        },
        (data) => {
          setAvailableArtifacts(data);
        },
        (error) => {
          setError('Failed to load artifacts.');
        }
      );

      // Fetch available geo-regions
      makeRequest(
        {
          url: `${process.env.REACT_APP_VORTX_URL}/geo-coverage/list?project_id=${projectId}`,
          method: 'GET',
        },
        (data) => {
          setAvailableGeoRegions(data);
        },
        (error) => {
          setError('Failed to load geo-regions.');
        }
      );
    }, [projectId, makeRequest]);


  useEffect(() => {
    if (projectId) {
      setLoading(true);
      makeRequest(
        {
          url: `${process.env.REACT_APP_VORTX_URL}/workflow/list?project_id=${projectId}`,
          method: 'GET',
        },
        (data) => {
          setWorkflows(data);
          setFilteredWorkflows(data);
          setError(null);
          setLoading(false);
        },
        (error) => {
          setError('Failed to load workflows. Please try again.');
          setWorkflows([]);
          setFilteredWorkflows([]);
          setLoading(false);
        }
      );
    }
  }, [projectId, makeRequest]);

  useEffect(() => {
    let filtered = [...workflows];
    if (workflowSearchQuery.trim() !== '') {
      filtered = filtered.filter((workflow) =>
        workflow.name.toLowerCase().includes(workflowSearchQuery.toLowerCase())
      );
    }
    setFilteredWorkflows(filtered);
  }, [workflowSearchQuery, workflows]);

  const handleAddWorkflow = () => {
    setIsEditingWorkflow(false);
    setCurrentWorkflow({
      name: '',
      dataInput: '',
      dataOutput: '',
      model: '',
      frequency: '',
      notification: '',
      cronCode: '',
      status: 'New',
      latentSpace: '',
      noiseLevel: 0,
      guardrailPrompts: '',
      generatorLoss: '',
      artifacts: [],
      geoCoverage: [],
      startDateTime: '',
    });
    setWorkflowModalOpen(true);
  };

  const handleEditWorkflow = (workflow) => {
    setIsEditingWorkflow(true);
    setCurrentWorkflow(workflow);
    setWorkflowModalOpen(true);
  };

  const handleSaveWorkflow = async (e) => {
    e.preventDefault();

    const url = isEditingWorkflow
      ? `https://backend.vortx.ai/${projectId}/workflows/${currentWorkflow.id}`
      : `https://backend.vortx.ai/${projectId}/workflows`;
    const method = isEditingWorkflow ? 'PUT' : 'POST';

    try {
      const response = await axios({
        method,
        url,
        headers: {
          "X-Auth-Token": "46eba9763c91dc8241437f1a307eab0a",
          "Content-Type": "application/json",
        },
        data: {
          project_id: projectId,
          key: "workflow_key_unique_001",
          display_name: "Unique Workflow Display Name",
          value: "Initial setup of the workflow.",
          data_input: currentWorkflow.dataInput,
          data_output: currentWorkflow.dataOutput,
          model: currentWorkflow.model,
          frequency: currentWorkflow.frequency,
          status: currentWorkflow.status,
          name: currentWorkflow.name,
        },
      });

      if (isEditingWorkflow) {
        setWorkflows((prev) => prev.map((wf) => (wf.id === response.data.id ? response.data : wf)));
      } else {
        setWorkflows((prev) => [...prev, response.data]);
      }

      setWorkflowModalOpen(false);
      setSuccessMessage(isEditingWorkflow ? 'Workflow updated!' : 'Workflow added!');
      setError(null);
    } catch (err) {
      console.error("Failed to save workflow:", err);
      setError('Failed to save workflow. Please check the input data and try again.');
      setSuccessMessage(null);
    }
  };
  

  const handleDeleteWorkflow = (workflowId) => {
    if (
      window.confirm(
        'Are you sure you want to delete this workflow? This action cannot be undone.'
      )
    ) {
      makeRequest(
        {
          url: `${process.env.REACT_APP_VORTX_URL}/workflow/delete/${workflowId}`,
          method: 'DELETE',
        },
        () => {
          setWorkflows((prevWorkflows) =>
            prevWorkflows.filter((wf) => wf.id !== workflowId)
          );
          setFilteredWorkflows((prevWorkflows) =>
            prevWorkflows.filter((wf) => wf.id !== workflowId)
          );
          setSuccessMessage('Workflow deleted successfully.');
          setError(null);
        },
        (error) => {
          setError('Failed to delete workflow. Please try again.');
          setSuccessMessage(null);
        }
      );
    }
  };

  const handleCancelWorkflow = (workflowId) => {
    if (window.confirm('Are you sure you want to stop this workflow?')) {
      makeRequest(
        {
          url: `${process.env.REACT_APP_VORTX_URL}/workflow/update/${workflowId}`,
          method: 'PUT',
          data: { status: 'Inactive' },
        },
        (updatedWorkflow) => {
          setWorkflows((prevWorkflows) =>
            prevWorkflows.map((wf) =>
              wf.id === updatedWorkflow.id ? updatedWorkflow : wf
            )
          );
          setFilteredWorkflows((prevWorkflows) =>
            prevWorkflows.map((wf) =>
              wf.id === updatedWorkflow.id ? updatedWorkflow : wf
            )
          );
          setSuccessMessage('Workflow stopped successfully.');
          setError(null);
        },
        (error) => {
          setError('Failed to stop workflow. Please try again.');
          setSuccessMessage(null);
        }
      );
    }
  };

  const handleCopyCronCode = (cronCode) => {
    navigator.clipboard.writeText(cronCode);
    setSuccessMessage('Cron code copied to clipboard!');
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [pageSize, setPageSize] = useState(5);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'dataInput',
      headerName: 'Data Input',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'dataOutput',
      headerName: 'Data Output',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'model',
      headerName: 'Model',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <IconButton
              color="primary"
              size="small"
              onClick={() => handleEditWorkflow(params.row)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              size="small"
              onClick={() => handleDeleteWorkflow(params.row.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Stop">
            <IconButton
              color="warning"
              size="small"
              onClick={() => handleCancelWorkflow(params.row.id)}
            >
              <StopIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const rows = filteredWorkflows.map((wf) => ({
    id: wf.id,
    ...wf,
  }));

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    if (error) {
      setSnackbarMessage(error);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (successMessage) {
      setSnackbarMessage(successMessage);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [error, successMessage]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setError(null);
    setSuccessMessage(null);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Card sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Workflow Management
        </Typography>
        <Typography variant="body1">
          Configure and manage your data synthesis workflows.
        </Typography>

        {/* Tabs */}
        <AppBar position="static" color="default" sx={{ mt: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Workflows" />
            <Tab label="Add Workflow" />
            <Tab label="Best Practices" />
          </Tabs>
        </AppBar>

        {tabValue === 0 && (
          <Box sx={{ mt: 3 }}>
            {/* Search and Add Workflow Section */}
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search workflows..."
                  value={workflowSearchQuery}
                  onChange={(e) => setWorkflowSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <IconButton edge="start">
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddWorkflow}
                >
                  Add Workflow
                </Button>
              </Grid>
            </Grid>

            {/* Workflows DataGrid */}
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 25]}
                pagination
                loading={loading}
                sx={{
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: '#f5f5f5',
                  },
                }}
              />
            </div>
          </Box>
        )}

        {tabValue === 1 && (
          <Box sx={{ mt: 3 }}>
            {/* Add/Edit Workflow Form */}
            <Typography variant="h6" gutterBottom>
              {isEditingWorkflow ? 'Edit Workflow' : 'Add Workflow'}
            </Typography>
            <WorkflowForm
              currentWorkflow={currentWorkflow}
              setCurrentWorkflow={setCurrentWorkflow}
              handleSaveWorkflow={handleSaveWorkflow}
              isEditingWorkflow={isEditingWorkflow}
              availableConnections={availableConnections}  // Pass connections as prop
              availableArtifacts={availableArtifacts}
              availableGeoRegions={availableGeoRegions}
              setWorkflowModalOpen={setWorkflowModalOpen}
            />
          </Box>
        )}

        {tabValue === 2 && (
          <Box sx={{ mt: 3 }}>
            {/* Best Practices Content */}
            <Typography variant="h6">
              Best Practices for Data Synthesis
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              {/* Add best practices content here */}
              - Clearly define your data inputs and outputs.
              <br />
              - Utilize artifacts to fine-tune synthesis results.
              <br />
              - Specify geo-coverage to target specific regions.
              <br />
              - Schedule your workflows appropriately to optimize resources.
              <br />
              - Regularly monitor and adjust parameters like latent space and noise levels.
            </Typography>
          </Box>
        )}
      </Card>

      {/* Add/Edit Workflow Dialog */}
      <Dialog
        open={workflowModalOpen}
        onClose={() => setWorkflowModalOpen(false)}
        fullWidth
        maxWidth="md"
        TransitionComponent={Slide}
      >
        <DialogTitle>
          {isEditingWorkflow ? 'Edit Workflow' : 'Add Workflow'}
        </DialogTitle>
        <DialogContent>
          <WorkflowForm
            currentWorkflow={currentWorkflow}
            setCurrentWorkflow={setCurrentWorkflow}
            handleSaveWorkflow={handleSaveWorkflow}
            isEditingWorkflow={isEditingWorkflow}
            availableConnections={availableConnections}
            availableArtifacts={availableArtifacts}
            availableGeoRegions={availableGeoRegions}
            setWorkflowModalOpen={setWorkflowModalOpen}
          />
        </DialogContent>
      </Dialog>

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          sx: {
            backgroundColor:
              snackbarSeverity === 'success' ? 'green' : 'red',
          },
        }}
      />
    </Box>
  );
};

export default WorkflowTab;
