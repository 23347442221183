// src/SideBar.jsx
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../context/app-slice";

const SideBar = ({ children }) => {
  const dispatch = useDispatch();
  const appCtx = useSelector((state) => state.app);
  const isOpen = appCtx?.isSideBarOpen;
  const location = useLocation();

  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const iconsStyle = {
    width: "24px",
    height: "24px",
    minWidth: "24px",
    minHeight: "24px",
  };

  const toggleSidebar = () => {
    dispatch(appActions.setIsSideBarOpen(!isOpen));
  };

  // Function to load SVG icons from the public folder
  const getSvgIcon = (iconName) => (
    <img
      src={`/images/icons/${iconName}.svg`}
      alt={iconName}
      className="sidebar-icon"
      style={iconsStyle}
    />
  );

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Automatically collapse sidebar on route change if it's open, except for specified routes
    const exceptionPaths = ["/", "/projects", "/crop-basket", "/transactions", "/billing"];
    if (!exceptionPaths.includes(location.pathname) && isOpen) {
      dispatch(appActions.setIsSideBarOpen(false));
    }
  }, [location.pathname]);

  // Routes that are part of the main section
  const routes = [
    {
      path: "/",
      name: "Home",
      icon: getSvgIcon("dashboard"),
    },
    {
      path: "/projects",
      name: "Projects",
      icon: getSvgIcon("project"),
    },
    {
      path: "/crop-basket",
      name: "DGUs",
      icon: getSvgIcon("protocol"),
    },
  ];

  // Routes that are part of the "Account" section
  const accountRoutes = [
    {
      path: "/transactions",
      name: "Transactions",
      icon: getSvgIcon("transactions"),
    },
    {
      path: "/billing",
      name: "Billing",
      icon: getSvgIcon("credit_card"),
    },
  ];

  // Sidebar width values
  const sidebarWidth = isOpen ? 250 : 80;

  return (
    <>
      <div
        className="main-container"
        style={{
          display: "flex",
        }}
      >
        {/* Desktop Sidebar */}
        <div className="d-none d-md-block">
          <motion.div
            className={`sidebar pt-4`}
            initial={{ width: sidebarWidth }}
            animate={{ width: sidebarWidth }}
            transition={{ duration: 0, ease: "easeInOut" }}
            style={{
              backgroundColor:  "#FFFFFF",
              position: "fixed",
              top: "0",
              left: "0",
              height: "100vh",
              overflowY: "auto",
              zIndex: 1000,
              borderRight: "1px solid #ddd",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Main Section Routes */}
            <section className="routes">
              <div className="mt-4">
                <div
                  className={`link d-flex ${
                    isOpen
                      ? "flex-row justify-content-between"
                      : "flex-column justify-content-center"
                  } align-items-center`}
                  style={{
                    margin: "20px 20px 10px 20px",
                    textDecoration: "none",
                    cursor: "pointer",
                    borderRadius: "8px",
                    transition: "background-color 0.3s ease, color 0.3s ease",
                    padding: "10px",
                    color: appCtx?.isDarkMode ? "#212121" : "#212121",
                  }}
                  onClick={toggleSidebar}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      appCtx?.isDarkMode ? "#F0F0F0" : "#F0F0F0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "transparent")
                  }
                >
                  <div
                    className="icon"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "30px",
                      height: "30px",
                    }}
                  >
                    {isOpen ? (
                      <img
                        src="/images/icons/collapse.svg"
                        alt="Collapse"
                        style={{
                          ...iconsStyle,
                          transform: "rotate(90deg)",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    ) : (
                      <img
                        src="/images/icons/expand.svg"
                        alt="Expand"
                        style={{
                          ...iconsStyle,
                          transform: "rotate(90deg)",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    )}
                  </div>
                </div>

                {/* Existing Routes */}
                {routes.map((route, index) => {
                  const isActive = route.path === location.pathname;

                  return (
                    <Link
                      to={route.path}
                      key={index}
                      className={`link d-flex ${
                        isOpen ? "flex-row" : "flex-column"
                      } ${!isOpen && "justify-content-center"} align-items-center ${
                        isActive ? "navitemactive" : "navitemnotactive"
                      }`}
                      style={{
                        margin: "10px 20px",
                        textDecoration: "none",
                        borderRadius: "8px",
                        transition:
                          "background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease",
                        padding: "10px",
                        backgroundColor: isActive ? "#BBDEFB" : "transparent",
                        color: isActive
                          ? "#0D47A1"
                          : appCtx?.isDarkMode
                          ? "#212121"
                          : "#212121",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          appCtx?.isDarkMode ? "#F0F0F0" : "#F0F0F0")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          isActive ? "#BBDEFB" : "transparent")
                      }
                    >
                      <div
                        className={`icon ${
                          isActive || appCtx?.isDarkMode
                            ? "text-black"
                            : "text-black"
                        }`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "30px",
                          height: "30px",
                        }}
                      >
                        {route.icon}
                      </div>
                      {isOpen && (
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0, ease: "easeInOut" }}
                          style={{
                            marginLeft: "10px",
                            color: isActive ? "#0D47A1" : "#212121",
                            fontWeight: isActive ? "bold" : "normal",
                            whiteSpace: "nowrap",
                            fontSize: "16px",
                          }}
                        >
                          {route.name}
                        </motion.span>
                      )}
                    </Link>
                  );
                })}
              </div>
            </section>

            {/* Account Section */}
            <section className="routes mt-4">
              {isOpen && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0, ease: "easeInOut" }}
                  className="account-section-title"
                  style={{
                    marginLeft: "20px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginTop: "10px",
                    color: "#424242",
                  }}
                >
                  Accounts
                </motion.div>
              )}
              <div className="mt-2">
                {accountRoutes.map((route, index) => {
                  const isActive = route.path === location.pathname;

                  return (
                    <Link
                      to={route.path}
                      key={index}
                      className={`link d-flex ${
                        isOpen ? "flex-row" : "flex-column"
                      } ${!isOpen && "justify-content-center"} align-items-center ${
                        isActive ? "navitemactive" : "navitemnotactive"
                      }`}
                      style={{
                        margin: "10px 20px",
                        textDecoration: "none",
                        borderRadius: "8px",
                        transition:
                          "background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease",
                        padding: "10px",
                        backgroundColor: isActive ? "#BBDEFB" : "transparent",
                        color: isActive
                          ? "#0D47A1"
                          : appCtx?.isDarkMode
                          ? "#212121"
                          : "#212121",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          appCtx?.isDarkMode ? "#F0F0F0" : "#F0F0F0")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          isActive ? "#BBDEFB" : "transparent")
                      }
                    >
                      <div
                        className={`icon ${
                          isActive || appCtx?.isDarkMode
                            ? "text-black"
                            : "text-black"
                        }`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "30px",
                          height: "30px",
                        }}
                      >
                        {route.icon}
                      </div>
                      {isOpen && (
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0, ease: "easeInOut" }}
                          style={{
                            marginLeft: "10px",
                            color: isActive ? "#0D47A1" : "#212121",
                            fontWeight: isActive ? "bold" : "normal",
                            whiteSpace: "nowrap",
                            fontSize: "16px",
                          }}
                        >
                          {route.name}
                        </motion.span>
                      )}
                    </Link>
                  );
                })}
              </div>
            </section>
          </motion.div>
        </div>

        {/* Main Content */}
        <main
          style={{
            flex: 1,
            marginLeft: windowWidth >= 768 ? sidebarWidth : 0,
            paddingTop: "50px",
            paddingBottom: windowWidth < 768 ? "60px" : "0",
            overflowY: "auto",
            minHeight: "100vh",
          }}
        >
          {children}
        </main>
      </div>
    </>
  );
};

export default SideBar;