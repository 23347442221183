// src/pages/users/UsersDashboard.jsx

import React, { useEffect, useState, useRef } from "react";
import MainWrapper from "../../component/wrapper/MainWrapper";
import { useNavigate } from "react-router-dom";
import { ProtocolList } from "../cryptobasket/ProtocolList"; // Import the shared data
import { Dropdown, DropdownButton, Spinner, Alert } from "react-bootstrap"; // Import necessary components from React-Bootstrap
import useAuthorizedHttp from "../../hooks/use-authorized-http"; // Import the custom authorized HTTP hook
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS for styling

const UsersDashboard = () => {
  const navigate = useNavigate();

  // State variables for projects
  const [allProjects, setAllProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [isProjectsLoading, setIsProjectsLoading] = useState(false);
  const [error, setError] = useState(null);

  // State variables for search and sorting
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("score"); // Example sort option

  // State variables for menu handling
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentProjectId, setCurrentProjectId] = useState(null);

  // Ref for menu click outside (optional, depending on your implementation)
  const menuRef = useRef(null);

  // Initialize the custom authorized HTTP hook
  const makeRequest = useAuthorizedHttp();

  // Function to open the menu
  const handleMenuOpen = (event, projectId) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentProjectId(projectId);
  };

  // Function to close the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentProjectId(null);
  };



  // Function to fetch projects from the API using useAuthorizedHttp
  const fetchProjects = () => {
    setIsProjectsLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_VORTX_URL}/project/list`,
        method: "GET",
      },
      (data) => {
        console.log("API Response:", data); // Log the response for debugging

        // Adjust based on actual response structure
        const projects = data.projects || data;
        setAllProjects(projects);
        setFilteredProjects(projects);
        setIsProjectsLoading(false);
        setError(null);
      },
      (error) => {
        console.error("API Error:", error); // Log the error for debugging
        setIsProjectsLoading(false);
        setError("Failed to load projects. Please try again.");
      }
    );
  };

  
  // Fetch projects when the component mounts
  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // Debounce search input to improve performance
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleFilterAndSort(searchTerm, sortOption);
    }, 300); // 300ms delay

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, sortOption, allProjects]);

  // Function to filter and sort projects
  const handleFilterAndSort = (searchValue, sortOption) => {
    console.log("Filtering and Sorting with:", searchValue, sortOption); // Debugging
    let filtered = allProjects;

    if (searchValue.trim() !== "") {
      filtered = allProjects.filter((project) =>
        project.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    // Implement sorting based on sortOption
    if (sortOption === "score") {
      filtered.sort((a, b) => b.score - a.score); // Example sorting by score
    } else if (sortOption === "name") {
      filtered.sort((a, b) => a.name.localeCompare(b.name)); // Sort by name
    }

    setFilteredProjects(filtered);
  };

  // Sort the ProtocolList by scoring and get the top 9 items
  const topProtocols = [...ProtocolList]
    .sort((a, b) => b.scoring - a.scoring) // Sort by descending score
    .slice(0, 9); // Limit to the top 9

  // Function to handle project actions (view, edit, duplicate, delete)
  const handleProjectAction = (action, projectId) => {
    switch (action) {
      case "view":
        navigate(`/projects/${projectId}`);
        break;
      case "edit":
        alert(`Edit project with ID: ${projectId}`); // Replace with real edit functionality
        break;
      case "duplicate":
        alert(`Duplicate project with ID: ${projectId}`); // Replace with real duplicate functionality
        break;
      case "delete":
        alert(`Delete project with ID: ${projectId}`); // Replace with real delete functionality
        break;
      default:
        break;
    }
    handleMenuClose();
  };

  return (
    <MainWrapper>
      <div className="container-fluid">
        <div className="row">
          {/* Dashboard Header Section */}
          <div className="col-12 d-flex justify-content-between align-items-center mt-4 mb-3 flex-wrap">
            <div className="mb-3">
              <h2
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#333",
                  marginTop: "10px",
                }}
              >
                My Dashboard
              </h2>
              {/*<p
                style={{
                  fontSize: "14px",
                  color: "#6c757d",
                  marginTop: "5px",
                }}
              >
                Generate Synthetic Satellite Images.
              </p>*/}
            </div>
            <div
              className="d-flex align-items-center mt-3 mt-md-0"
              onClick={() => navigate("/projects")} // Navigate to Projects page on click
              style={{ cursor: "pointer" }} // Make the section clickable
            >
              <div
                className={`project-count-btn ${isProjectsLoading ? "shimmer" : ""}`}
                style={{
                  fontSize: "14px",
                  color: isProjectsLoading ? "#ccc" : "#333",
                  marginRight: "15px",
                }}
              >
                {/* Dynamic Project Count */}
                {isProjectsLoading ? "Loading Projects..." : `${filteredProjects.length} Project${filteredProjects.length !== 1 ? "s" : ""}`}
              </div>
            </div>
          </div>

          {/* Protocol Cards */}
          <div className="col-12" style={{ minHeight: "300px" }}>
            <div className="card">
              <div className="card-header pb-0 border-0 flex-wrap">
                <div className="mb-0 d-flex justify-content-between align-items-center w-100 flex-wrap">
                  <h4 className="card-title mb-3">   </h4>
                  <button
                    onClick={() => navigate("/crop-basket")}
                    className="btn btn-primary mb-3"
                  >
                    See all DGUs
                  </button>
                </div>
              </div>

              <div className="row p-3">
                {topProtocols.map((protocol) => (
                  <div className="col-12 col-md-6 col-lg-4 mb-3" key={protocol.id}>
                    <div
                      className="card shadow-sm h-100"
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: "8px",
                        padding: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(protocol.link)}
                    >
                      <div className="d-flex flex-column align-items-start mb-3">
                        <img
                          src={protocol.image}
                          alt={protocol.name}
                          style={{
                            height: "40px",
                            width: "40px",
                            marginBottom: "10px",
                          }}
                        />
                        <h5
                          style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            color: "#333",
                            margin: 0,
                          }}
                        >
                          {protocol.name}
                        </h5>
                      </div>
                      <div
                        className="card-body p-0"
                        style={{ textAlign: "left" }}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#6c757d",
                            marginTop: "10px",
                          }}
                        >
                          {protocol.description}
                        </p>
                        <div className="tags-section mb-3">
                          {protocol.tags.map((tag, index) => (
                            <span key={index} className="tag-item" style={tagStyle}>
                              {tag}
                            </span>
                          ))}
                        </div>
                        <button
                          className="btn btn-outline-primary mt-3"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent card onClick
                            navigate(protocol.link);
                          }}
                          style={{
                            fontSize: "14px",
                            padding: "5px 15px",
                            borderRadius: "8px",
                          }}
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

         
        </div>
      </div>
    </MainWrapper>
  );
};

// Define the tag style as a reusable constant
const tagStyle = {
  backgroundColor: "#fff",
  border: "1px solid #4a90e2",
  color: "#4a90e2",
  padding: "5px 10px",
  borderRadius: "20px",
  fontSize: "12px",
  marginRight: "5px",
};

// Custom CSS for the shimmer effect with a light grey shade
const shimmerStyle = `
  .shimmer {
    background: linear-gradient(to right, #f2f2f2 0%, #e0e0e0 20%, #f2f2f2 40%, #f2f2f2 100%);
    background-size: 200% auto;
    animation: shimmer 1.5s linear infinite;
    color: transparent !important;
    border: none;
    padding: 10px 20px;
    cursor: default;
  }

  @keyframes shimmer {
    0% {
      background-position: 200% center;
    }
    100% {
      background-position: -200% center;
    }
  }
`;

// Append the shimmer styles to the document head
const styleElement = document.createElement('style');
styleElement.innerHTML = shimmerStyle;
document.head.appendChild(styleElement);

export default UsersDashboard;
