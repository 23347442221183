import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal";

import { CircularProgress } from "@mui/material";

function PaymentStatusLoader({ openModal, setOpenModal }) {
  const appCtx = useSelector((state) => state.app);
  const dark = "light";
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      background: "transparent",
      // backgroundColor: dark ? 'black' : 'white',
      color: dark ? "white" : "black",
      padding: "100px",
      height: "450px",
      border: "0px",
    },
  };

  return (
    <Modal
      isOpen={openModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="flex flex-col gap-5 justify-center items-center w-[100%] h-[100%]">
        <div className=" text-[30px] font-bold text-white">
          Payment: Processing...
        </div>
        <div>
          {/* <CircularProgress /> */}
          <img
            src="/images/payment-methods/loading-gif.gif"
            style={{ width: "100px", height: "100px" }}
            alt="gif"
            className="bg-transparent"
          />
        </div>
      </div>
    </Modal>
  );
}

export default PaymentStatusLoader;
