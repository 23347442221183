import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { appActions } from "../../context/app-slice";
import { red } from "@mui/material/colors";
import { useLocation } from "react-router-dom";
import { Line } from "peity-react";
import CryptoFundLineChart from "../cryptoFund/CryptoFundLineChart";

const HomeCropCard = ({ crop }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

 

  return (
    <div
      class="d-flex justify-content-between align-items-center  market-preview"
      style={{ cursor: "pointer" }}
      // onClick={() =>
      //   navigate(`/crop/${crop?.tokenId}/${crop?.symbol}`)
      // }
    >
      <div class="w-100">
        <div class="row">
          <div className="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center col-5 col-md-8">
              <span>
                {crop?.icon ? (
                  <img
                    src={crop?.icon}
                    alt=""
                    style={{ width: "36px", height: "36px" }}
                    className="rounded-circle m-0 p-0"
                  />
                ) : (
                  <img
                    src="/images/basketimage.svg"
                    alt=""
                    style={{ width: "36px", height: "36px" }}
                    className="rounded-circle m-0 p-0"
                  />
                )}
              </span>
              <div class="ms-3">
                <h6 class="fs-12 font-w400 mb-0">{crop?.cropName}</h6>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between gap-5 col-7 col-md-4">
            
              {/* <div className="d-flex flex-column align-items-end justify-content-end">
                <div className="">djflsfj</div>
               
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCropCard;
