import { CKEditor } from "ckeditor4-react";

function CKTextEditor({ initData, data, onChange }) {
  const editorConfig = {
    height: "70vh", // Set your desired height here
    // Other custom configurations can go here
  };

  return (
    <CKEditor
      initData={initData}
      data={data}
      onChange={onChange}
      config={editorConfig}
    />
  );
}

export default CKTextEditor;
