import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { KycHeaderPannel } from "./KycHeaderPannel";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { appActions } from "../../context/app-slice";
import MainWrapper from "../wrapper/MainWrapper";
import { Button } from "react-bootstrap";

export const Thankyou = () => {
  const navigate = useNavigate();
  const appCtx = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const makeRequest = useAuthorizedHttp();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    let url = `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/kyc`;
    makeRequest(
      {
        url: url,
        method: "get",
        headers: {
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
          "X-Auth-Token": appCtx.authToken,
          "Content-Type": "application/json",
        },
      },
      (data) => {
        if (!data) {
          navigate("/kyc/verification");
        }
        dispatch(appActions.setRejection(data.rejectionReason));
      }
    );
  }, [appCtx.authToken, dispatch, makeRequest, navigate]);

  const download = (image, name) => {
    var element = document.createElement("a");
    var file = new Blob([image], { type: "image/*" });
    element.href = URL.createObjectURL(file);
    element.download = `${name}.jpg`;
    element.click();
  };
  const handleDownload = (image) => {
    download(image, "selfie");
  };
  const handleDocumentDownload = () => {
    download(appCtx.frontSide, "documentFront");
    download(appCtx.backSide, "documentBack");
  };
  return (
    <MainWrapper>
      <div className={`${appCtx?.isDarkMode ? "text-black" : "text-black"}`}>
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            {/* <!-- Row --> */}
            <div class="row">
              {/* banner card  */}
              <div className="col-xl-12">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header border-0"></div>
                    <div className="card-body px-0 pt-0">
                      <div className="d-flex flex-column justify-content-center align-items-center pb-3 mt-4 px-4 px-lg-5">
                        <KycHeaderPannel
                          header="KYC Information"
                          document={true}
                          selfie={true}
                        />
                        {!appCtx.verified ? (
                          appCtx.rejection ? (
                            <>
                              <h2 className="text-center fw-medium">
                                Thank you for your patience.
                              </h2>
                              <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                                <div className="w-100 d-flex justify-content-center">
                                  <img
                                    src="/images/failed.svg"
                                    className="w-100 w-lg-70"
                                    alt="svg"
                                  />
                                </div>
                                <div className="d-flex flex-column w-100 justify-content-center align-items-center gap-5 rounded-xl min-h-300 py-2">
                                  <div className="px-4 px-lg-5 text-justify">
                                    Something wrong with your verification
                                    information.We request you to please
                                    resubmit your document to complete your KYC
                                  </div>
                                  <div className="d-flex justify-content-between justify-content-sm-around w-100">
                                    <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                                      <div
                                        onClick={() => {
                                          handleDocumentDownload();
                                        }}
                                      >
                                        {appCtx.frontSide ? (
                                          <img
                                            src={appCtx.frontSide}
                                            alt="idcard"
                                            style={{
                                              width: "200px",
                                              height: "200px",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src="/KYC/status1.svg"
                                            alt="idcard"
                                            style={{
                                              width: "200px",
                                              height: "200px",
                                            }}
                                          />
                                        )}
                                      </div>
                                      <div className="d-flex gap-3">
                                        <div className="text-4">
                                          Identity Document
                                        </div>
                                        {/* <div><Edit className='cursor-pointer' onClick={() => { navigate('/kyc/upload/document') }} /></div> */}
                                      </div>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                                      <div
                                        onClick={() =>
                                          appCtx.picture &&
                                          handleDownload(appCtx.picture)
                                        }
                                      >
                                        {appCtx.picture ? (
                                          <img
                                            src={appCtx.picture}
                                            alt="idcard"
                                            style={{
                                              width: "200px",
                                              height: "200px",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src="/KYC/status2.svg"
                                            alt="idcard"
                                            style={{
                                              width: "200px",
                                              height: "200px",
                                            }}
                                          />
                                        )}
                                      </div>
                                      <div className="d-flex gap-3">
                                        <div className="text-4">
                                          Picture Uploaded
                                        </div>
                                        {/* <div><Edit className='cursor-pointer' onClick={() => { }} /></div> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column items-center gap-5 w-100 w-lg-80">
                                    <div className="d-flex flex-column gap-2">
                                      <div className="font-weight-bold text-5">
                                        Status : Rejected
                                      </div>
                                      <div className="font-weight-medium text-3">
                                        Reason : {appCtx.rejection}
                                      </div>
                                    </div>
                                    <Button
                                      primary={false}
                                      className="w-[200px] h-[40px]"
                                      onClick={() => {
                                        navigate("/kyc/selectcountry");
                                      }}
                                    >
                                      Resubmit
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="fs-18 fw-medium mt-5">
                                Thank you
                              </div>
                              <p className=" mb-5">
                                We are currently checking your data
                              </p>
                              <div className="d-lg-flex justify-content-lg-center align-items-ld-center">
                                <div className="w-100d-flex justify-content-center">
                                  <img src="/KYC/thankyou.svg" alt="thankyou" />
                                </div>
                                <div className="flex flex-col">
                                  <div className="fs-18 fw-bold mb-5">
                                    The verification status will be updated
                                    automatically and can take upto 24 hours
                                  </div>
                                  <div className="fs-17 fw-medium mb-[10px]">
                                    When your documents get verified and you get
                                    a verified status, you can claim your
                                    rewards.
                                  </div>
                                  <div
                                    className="d-flex "
                                    style={{
                                      width: "104px",
                                    }}
                                  >
                                    {appCtx.verified ? (
                                      <img
                                        className="d-flex align-items-center"
                                        src="/KYC/verified.svg"
                                        alt="verified"
                                      />
                                    ) : (
                                      <img
                                        src="/KYC/pending.svg"
                                        alt="pending"
                                      />
                                    )}
                                    <div className="text-[20px]">
                                      -&nbsp;Identity&nbsp;Document
                                    </div>
                                  </div>
                                  <div className="d-flex "
                                    style={{
                                      width: "104px",
                                    }}>
                                    {appCtx.verified ? (
                                      <img
                                        className="d-flex align-items-center"
                                        src="/KYC/verified.svg"
                                        alt="verified"
                                      />
                                    ) : (
                                      <img
                                        src="/KYC/pending.svg"
                                        alt="pending"
                                      />
                                    )}
                                    <div className="text-[20px]">
                                      -&nbsp;Selfie
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        ) : (
                          <>
                            {/* <div className='text-[40px]'>Thank you</div> */}
                            <div className="text-[25px] font-medium mb-[10px]">
                              Thank you for your patience, your verification is
                              complete
                            </div>
                            <div className="d-lg-flex justify-content-lg-center items-lg-center">
                              <div className="d-flex justify-content-center">
                                <img
                                  src="/KYC/verifiedimage.svg"
                                  alt="thankyou"
                                />
                              </div>
                              <div className="d-flex flex-column">
                                <div className="fs-18 fw-bold mb-5">
                                  Your verification status is updated !
                                </div>
                                <div className="d-flex "
                                    style={{
                                      width: "104px",
                                    }}>
                                  <img
                                    className="d-flex align-items-center"
                                    src="/KYC/verified.svg"
                                    alt="verified"
                                  />
                                  <div className="text-[20px]">
                                    -&nbsp;Identity&nbsp;Document
                                  </div>
                                </div>
                                <div className="d-flex "
                                    style={{
                                      width: "104px",
                                    }}>
                                  <img
                                    className="d-flex align-items-center"
                                    src="/KYC/verified.svg"
                                    alt="verified"
                                  />
                                  <div className="text-[20px]">
                                    -&nbsp;Selfie
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};
