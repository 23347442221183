import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import styles from "./TaleWalletOnRampModal.module.css";
import { useSelector } from "react-redux";
import {
  faCheck,
  faChevronDown,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { usehandleBuyTaleCoin } from "../../hooks/handleBuyTaleCoin";
import { useWalletView } from "../../hooks/useWalletView";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { nativeToken } from "../../config";
import { Card } from "react-bootstrap";
import TokenSelectionDropdown from "../tokenSelectionDropdown/TokenSelectionDropdown";

const TaleWalletOnRampModal = ({
  openModal,
  setOpenModal,
  amount,
  basketId,
  tokenPercentArray,
  tokenArray,
  basketBlockchain,
  isTokenDetailsPage,
  symbol,
  totalInvestment,
  singleBasket,
  address,
  blockchain,
}) => {
  const appCtx = useSelector((state) => state.app);
  const [sendToken, setSendToken] = useState("");
  const [receiveTokenPrice, setReceiveTokenPrice] = useState(null);

  const [isCopied, setIsCopied] = useState(false);
  const [isBankDataCopied, setIsBankDataCopied] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [expanded, setExpanded] = useState(null);

  const blockchainFromMobile = new URLSearchParams(window.location.search).get(
    "blockchain"
  );

  const [receiveToken, setReceiveToken] = useState(
    symbol || nativeToken[blockchain || appCtx.blockchain]
  );

  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);

  const networks = {
    POLYGON: "matic20",
    BSC: "bep20",
    ETHEREUM: "erc20",
    ALGORAND: "algo",
    SOLANA: "sol",
  };

  const hardCodeGasFee = {
    POLYGON: 10,
    BSC: 30,
    ETHEREUM: 300,
    ALGORAND: 0,
    SOLANA: 10,
  };

  var network;
  let basketAmount = amount * appCtx?.usdPrice;
  network =
    networks[blockchainFromMobile || basketBlockchain || appCtx.blockchain];

  const [receiveAmount, setReceiveAmount] = useState(null);
  const [currentComponent, setCurrentComponent] = useState(1);

  const [upiClicked, setUpiClicked] = useState(false);
  const [bankClicked, setBankClicked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [fourDigit, setFourDigit] = useState(null);
  const [loader, setLoader] = useState(false);
  const [networkFee, setNetworkFee] = useState(0);
  const [isBasketDetailsPage, setIsBasketDetailsPage] = useState(false);
  const [receiveAmountLoading, setReceiveAmountLoading] = useState(false);
  const [receiveTokenName, setReceiveTokenName] = useState(null);
  const [sendAmount, setSendAmount] = useState(basketAmount || amount || 5000);

  const componentRef = useRef(null);

  const {
    getAllCoin,
    getAllCoinPrice,
    allCoin,
    allCoinPrice,
    isCoinPriceLOading,
  } = useWalletView();

  const makeRequest = useAuthorizedHttp();
  const queryParams = new URLSearchParams(window.location.search);

  let isOpenedfromMobile = queryParams.get("address");
  console.log("amount", amount);

  function closeModal() {
    setOpenModal(false);
  }
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: "#FFFFFF",
      border: "1px solid  #FFFFFF",
      color: "black",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: "400px",
    },
  };
  const formattedDate = moment().format("D MMM'YY, h:mm a");

  useEffect(() => {
    const url = window.location.href;
    const searchStrings = [
      "subscribe-crypto-basket",
      "crypto-basket",
      "basketid",
    ];
    let isPageFound = false;

    for (const searchString of searchStrings) {
      if (url.includes(searchString)) {
        isPageFound = true;
        break;
      }
    }

    setIsBasketDetailsPage(isPageFound);
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleBankDataCopy = (value, index) => {
    navigator.clipboard.writeText(value);
    const updatedCopiedState = [...isBankDataCopied];
    updatedCopiedState[index] = true;
    setIsBankDataCopied(updatedCopiedState);
  };

  // fetching receive token details

  useEffect(() => {
    setReceiveAmountLoading(true);
    if (sendAmount < 5000) {
      toast.error("Minimum amount is 5000");
    } else {
      if (sendAmount >= 5000) {
        makeRequest(
          {
            url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/quote`,
            data: {
              fiatAmount: sendAmount,
              token: receiveToken,
              network: network,
            },
            method: "post",
            headers: {
              "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
              "Content-Type": "application/json",
            },
          },
          (data) => {
            setReceiveTokenPrice(
              data?.data?.rate ||
                appCtx?.singleTokenData?.priceOfToken * appCtx?.usdPrice
            );

            console.log("is quantify available", data?.data?.quantity);
            console.log("appCtx?.usdPrice", appCtx?.usdPrice);
            console.log(
              " sendAmount / appCtx?.singleTokenData?.priceOfToken * appCtx?.usdPrice",
              sendAmount /
                (appCtx?.singleTokenData?.priceOfToken * appCtx?.usdPrice)
            );

            if (data?.data?.quantity) {
              setReceiveAmount(data?.data?.quantity);
            } else {
              setReceiveAmount(
                sendAmount /
                  (appCtx?.singleTokenData?.priceOfToken * appCtx?.usdPrice)
              );
            }
            setNetworkFee(
              data?.data?.gasFee || hardCodeGasFee[appCtx?.blockchain]
            );
            setReceiveAmountLoading(false);
          },
          () => {
            setReceiveAmountLoading(false);
          },
          (error) => {
            setReceiveAmountLoading(false);
          }
        );
      }
    }
  }, [sendAmount, receiveToken]);

  useEffect(() => {
    // let sendTokenPriceinUsd = sendAmount / 84;
    // let receiveTokenSymbol = allCoin?.find(
    //   (coin) => coin?.tokenShortName === receiveToken
    // )?.symbol;
    // let receiveTokenPriceinUsd = allCoinPrice[receiveTokenSymbol]?.price;
    // setReceiveTokenPrice(receiveTokenPriceinUsd);
    // if (sendTokenPriceinUsd && receiveTokenPriceinUsd) {
    //   const receiveAmount = sendTokenPriceinUsd / receiveTokenPriceinUsd;
    //   setReceiveAmount(receiveAmount.toFixed(2));
    // } else {
    //   setReceiveAmount("");
    // }
  }, [receiveToken, sendAmount, allCoinPrice, sendToken]);
  useEffect(() => {
    const min = 1000; // Minimum 4-digit number
    const max = 9999; // Maximum 4-digit number
    const fourDigit = Math.floor(Math.random() * (max - min + 1)) + min;
    setFourDigit(fourDigit);
  }, []);

  const handleSendAmountChange = (event) => {
    setSendAmount(event.target.value);
  };

  const availableTokens = allCoin?.map((token) => token);
  const filteredTokens = availableTokens.filter(
    (token) => token.tokenShortName !== sendToken
  );
  console.log("filteredTokens", filteredTokens);

  let platformFee;
  let totalFee;

  if (sendAmount !== null && sendAmount !== undefined) {
    platformFee = ((sendAmount * 0.4) / 100).toFixed(2);
  } else {
    // Handle the case when sendAmount is null or undefined
    // For example:
    platformFee = "0.00";
  }

  if (networkFee !== null && networkFee !== undefined) {
    totalFee = (parseFloat(platformFee) + parseFloat(networkFee)).toFixed(2);
  } else {
    // Handle the case when networkFee is null or undefined
    // For example:
    totalFee = platformFee;
  }

  let userAddressFromUrl = queryParams.get("address");
  const userAddress =
    appCtx?.walletAddress?.find(
      (addr) => addr?.blockchain === appCtx?.blockchain
    )?.address || userAddressFromUrl;

  const handlePaid = () => {
    setLoader(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/inhouse/onramp/transaction`,
        data: {
          fiatAmount: sendAmount,

          fiatCurrency: "INR",

          cryptoToken: isBasketDetailsPage
            ? nativeToken[basketBlockchain]
            : receiveToken,

          cryptoAmount: receiveAmount,

          blockchain: isBasketDetailsPage
            ? basketBlockchain
            : isTokenDetailsPage
            ? blockchainFromMobile
            : appCtx.blockchain,

          note: fourDigit,
          platformFee: platformFee,
          networkFee: networkFee,
          tokenPrice: receiveTokenPrice,
          address: address || userAddress,
          ...(isBasketDetailsPage && { basketId: basketId }),
        },
        method: "post",
      },
      (data) => {
        setLoader(false);
        setCurrentComponent(3);
        // setOpenModal(false);
      },
      (error) => {
        // setLoader(false);
      },
      () => {}
    );
  };

  const navigate = useNavigate();
  let params = {
    walletAddress: [
      {
        address: queryParams.get("address"),
      },
    ],
  };
  if (isOpenedfromMobile) {
    var { handleBuyAlgos } = usehandleBuyTaleCoin(params);
  } else {
    var { handleBuyAlgos } = usehandleBuyTaleCoin(appCtx);
  }
  var { handleBuyAllToken, handleBuyNativeTokenFromWeb } =
    usehandleBuyTaleCoin(appCtx);

  useEffect(() => {
    getAllCoin();
    getAllCoinPrice();
  }, []);

  const handleBasketSubscribe = () => {
    let fiatAmount = totalInvestment;
    handleBuyNativeTokenFromWeb(
      fiatAmount,
      singleBasket?.basketId,
      singleBasket?.minAmountCurrency,
      singleBasket?.blockchain,
      address || userAddress
    );
  };

  let initialtokenName = allCoin
    ? allCoin.find((obj) => obj.symbol === receiveToken)?.tokenName
    : "";

  const themedColor = {
    color: "black",
  };

  const width = window.screen.width;
  let isMobileDevice = width < 600;

  const handleClick = (event) => {
    event.preventDefault();
    // Check if the clicked element has the 'button-id' ID
    if (event.target.id === "sendButton") {
      //  setSendOpen(true);
    } else if (
      event.target.id === "searchbar" ||
      event.target.id === "searchIcon"
    ) {
    } else if (event.target.id === "receiveButton") {
      setOpen(true);
    } else {
      //  setSendOpen(false);
      setOpen(false);
    }
  };

  const getBasketsSingleTokensPrice = (token) => {
    let price =
      isBasketDetailsPage &&
      Object?.keys(allCoinPrice)?.length > 0 &&
      (sendAmount * token?.tokenPercent) /
        100 /
        (allCoinPrice[token?.symbol]?.price * appCtx?.usdPrice);
    if (price) {
      price = price.toFixed(2);
    } else {
      price = 0;
    }
    return price;
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup} `}
      >
        <div
          class=" d-flex justify-content-between align-items-center  py-2 position-relative px-4 rounded-top-lg"
          style={{
            backgroundColor: "#9376B1",
            borderRadius: "5px 5px 0px 0px",
          }}
        >
          <div class="w-15%">
            <img
              src="https://tale-invest-assets.s3.ap-southeast-1.amazonaws.com/assets/images/tale-invest-logo-sm.png"
              alt=""
              style={{ width: "35px", height: "35px" }}
            />
          </div>

          <h5 class="mb-0">Talewallet</h5>
          <div class="">
            <div
              className="btn btn-link"
              onClick={() => {
                closeModal();
              }}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
            </div>
          </div>
        </div>
        <div
          class="overflow-auto bg-EEEEEE rounded-xl"
          style={{ maxHeight: "70vh" }}
          ref={componentRef}
          onClick={handleClick}
        >
          {currentComponent === 1 && (
            <div>
              <div class="px-4 py-2">
                <p class="text-md my-2">Minimum amount is 5000 INR</p>
                <p class="text-md my-2">Enter Amount</p>

                <div
                  className="mb-3 d-flex justify-content-between align-items-center py-1 border"
                  style={{ border: "#ced4da", borderRadius: "5px" }}
                >
                  <div className="">
                    <input
                      id="sendAmount"
                      placeholder="Enter amount"
                      name="sendAmount"
                      type="number"
                      min="0"
                      value={sendAmount}
                      disabled={isBasketDetailsPage || address}
                      onChange={handleSendAmountChange}
                      className="ms-3"
                      style={{
                        backgroundColor: `${
                          appCtx?.isDarkMode ? "white" : "white"
                        }`,
                        color: "black",
                        width: "100px",
                        border: "none",
                      }}
                    />
                  </div>
                  <div class="w-2 h-12 d-flex justify-content-center align-items-center">
                    <div
                      class="border border-end"
                      style={{
                        width: "2px",
                        height: "30px",
                        border: "#ced4da",
                      }}
                    ></div>
                  </div>

                  <div
                    id="sendToken"
                    name="sendToken"
                    class="d-flex align-items-center gap-2 ms-2 "
                    style={{ width: "150px" }}
                  >
                    <img
                      src="/images/indianflag.png"
                      alt=""
                      class=""
                      style={{ width: "25px", height: "25px" }}
                    />
                    <div class="">
                      <p class="mb-0">INR</p>
                      <p class="mb-0">Indian Rupee</p>
                    </div>
                  </div>
                </div>

                <p class="text-md mt-2 mb-2">Amount you will receive</p>

                {!isBasketDetailsPage && (
                  <div
                    class="d-flex justify-content-between align-items-center  border"
                    style={{ border: "#ced4da", borderRadius: "5px" }}
                  >
                    <div class="">
                      {!receiveAmountLoading ? (
                        <input
                          id="receiveAmount"
                          placeholder="you will receive"
                          name="receiveAmount"
                          type="number"
                          min="0"
                          step="any"
                          value={receiveAmount?.toFixed(6)}
                          readonly
                          className="ms-3"
                          style={{
                            backgroundColor: `${
                              appCtx?.isDarkMode ? "white" : "white"
                            }`,
                            color: "black",
                            width: "100px",
                            border: "none",
                          }}
                        />
                      ) : (
                        <div
                          class="px-3 d-flex align-items-center"
                          style={{ width: "100px" }}
                        >
                          <CircularProgress size={20} className="ml-3" />
                        </div>
                      )}
                    </div>
                    <div class="w-2 h-12 d-flex justify-content-center align-items-center my-1">
                      <div
                        class="border border-end"
                        style={{
                          width: "2px",
                          height: "30px",
                          border: "#ced4da",
                        }}
                      ></div>
                    </div>

                    <div
                      class="d-flex align-items-center gap-2 ms-2 "
                      style={{ width: "150px" }}
                    >
                      <img
                        src={
                          allCoin
                            ? allCoin.find((obj) => obj.symbol === receiveToken)
                                ?.tokenIcon
                            : ""
                        }
                        alt=""
                        style={{ width: "25px", height: "25px" }}
                      />

                      {/* receive dropdown  */}

                      <div
                        className="mt-1 position-relative"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <div
                          className=""
                          onClick={() => !isTokenDetailsPage && setOpen(!open)}
                        >
                          <div
                            className={` d-flex align-items-center justify-content-between rounded`}
                            id="receiveButton"
                          >
                            {receiveTokenName
                              ? receiveTokenName?.length > 15
                                ? receiveTokenName?.substring(0, 15) + "..."
                                : receiveTokenName
                              : initialtokenName}
                            <BiChevronDown
                              size={20}
                              className={` ms-1`}
                              style={{
                                transform: open ? "rotate(180deg)" : "none",
                              }}
                              id="receiveButton"
                            />
                          </div>
                          <p className="text-xs mb-0" id="receiveButton">
                            On {network}
                          </p>
                        </div>
                        {open && (
                          <TokenSelectionDropdown
                            searchTerm={inputValue}
                            setSearchTerm={setInputValue}
                            allCoin={allCoin}
                            isDrodownOpen={open}
                            setIsDropdownOpen={setOpen}
                            setToken={setReceiveToken}
                            setTokenName={setReceiveTokenName}
                            additionalStyles={{ top: "-150px", right: "-20px" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {isBasketDetailsPage && (
                  <>
                    {isCoinPriceLOading ||
                    Object?.keys(allCoinPrice)?.length === 0 ? (
                      <div
                        className="d-flex justify-content-center align-items-center w-100"
                        style={{ minHeight: "200px" }}
                      >
                        <CircularProgress size={20} className="" />
                      </div>
                    ) : (
                      <>
                        {singleBasket?.cryptoBasketTokens?.map(
                          (token, index) => (
                            <div
                              class="d-flex flex-row justify-content-between align-items-center w-100 rounded-md overflow-hidden border hover:border-violet-500 border-#EEEEEE mb-2 py-1 px-3"
                              key={index}
                            >
                              <div
                                class=" pl-2 text-sm font-medium  justify-center"
                                style={{ width: "150px" }}
                              >
                                <div class="d-flex">
                                  <p className="mb-0">
                                    {getBasketsSingleTokensPrice(token)}
                                  </p>
                                  <p className="mb-0">
                                    ({token?.tokenPercent}%)
                                  </p>
                                </div>
                                <div className="m-0 p-0">
                                  <span class="text-xs font-normal">
                                    1 {token?.symbol} ={" "}
                                    {allCoinPrice[
                                      token?.symbol
                                    ]?.price?.toFixed(2)}{" "}
                                    {allCoinPrice[token?.symbol]?.currency}
                                  </span>
                                </div>
                              </div>

                              <div class="">
                                <div class=" leading-tight focus:outline-none shadow-xs w-100 d-flex flex-row align-items-center pl-5 align-items-center gap-2">
                                  <img
                                    src={token?.icon}
                                    alt=""
                                    className="rounded-circle"
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                    }}
                                  />
                                  <div class="">
                                    <p className="mb-0">{token?.token}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </>
                    )}
                  </>
                )}

                {!isBasketDetailsPage && (
                  <p className=" text-sm mt-2 mb-1 ">
                    {receiveTokenPrice && (
                      <>
                        1 {receiveToken} = ₹{receiveTokenPrice?.toFixed(2)}
                      </>
                    )}
                  </p>
                )}

                <div className="">
                  <Accordion className="border">
                    <AccordionSummary
                      as={Card.Header}
                      eventKey="0"
                      className="d-flex justify-content-between align-items-center"
                      style={{
                        backgroundColor: `${
                          appCtx?.isDarkMode ? "white" : "white"
                        }`,
                        color: "black",
                      }}
                      onClick={() => setExpanded(!expanded)}
                    >
                      <p className="text-[#3B0076] font-medium text-md mb-0">
                        You Pay ₹{sendAmount}
                      </p>
                      <p className="ms-4 mb-0">View Breakup</p>
                      <div className="d-flex align-items-center ms-2">
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          rotation={expanded ? 180 : 0}
                          className="ms-1"
                          style={themedColor}
                        />
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      eventKey="0"
                      style={{
                        backgroundColor: `${
                          appCtx?.isDarkMode ? "white" : "white"
                        }`,
                        color: "black",
                      }}
                    >
                      <>
                        <div className=" text-sm font-medium">
                          <div className="d-flex justify-content-between align-items-center ">
                            <p className="mb-1">Platform Fee (0.4%)</p>
                            <p className="mb-1">₹{platformFee || 0}</p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-1">Network Fee</p>
                            <p className="mb-1"> ₹{networkFee || 0}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center text-sm font-medium">
                          <p className="mb-1">Total Fee</p>
                          <p className="mb-1">₹{totalFee || 0}</p>
                        </div>
                        {isBasketDetailsPage && (
                          <p className="text-sm font-medium mt-1">
                            Network fee includes{" "}
                            {tokenArray?.map((token, index) => {
                              const isLastToken =
                                index === tokenArray.length - 1;
                              return (
                                <span
                                  key={index}
                                  className="text-sm font-medium ml-2"
                                >
                                  {nativeToken[basketBlockchain]} to {token}
                                  {!isLastToken &&
                                    index !== tokenArray.length - 2 &&
                                    ", "}
                                  {!isLastToken &&
                                    index === tokenArray.length - 2 &&
                                    " and "}
                                </span>
                              );
                            })}
                          </p>
                        )}
                      </>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div class="row mt-4 align-items-center">
                  <div class="col">
                    <button
                      onClick={() => {
                        setCurrentComponent(2);
                        setUpiClicked(true);
                      }}
                      disabled={!sendAmount || receiveAmountLoading}
                      class="w-100 mb-3 py-2 text-md font-weight-bold text-white bg-success border border-success rounded active:text-secondary  focus:outline-none focus:ring"
                    >
                      UPI
                    </button>
                  </div>
                  <div class="col">
                    <button
                      onClick={() => {
                        setCurrentComponent(2);
                        setBankClicked(true);
                      }}
                      disabled={!sendAmount || receiveAmountLoading}
                      class="w-100 mb-3 py-2 text-md font-weight-bold text-white bg-primary border border-primary rounded active:text-secondary hover:bg-transparent hover:text-primary focus:outline-none focus:ring"
                    >
                      BANK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentComponent === 2 && (
            <div className="p-3">
              {upiClicked && (
                <div class=" border d-flex flex-column gap-3 justify-content-center align-items-center p-5 mb-4">
                  <p class=" font-weight-bold text-md">Send ₹{sendAmount}</p>
                  <img
                    src="/images/onrampqrcode.jpg"
                    alt="qrcode"
                    style={{ width: "120px", height: "120px" }}
                  />
                  <div class="d-flex align-items-center">
                    <p class=" font-weight-semibold mb-0">
                      UPI-ID:
                      <span class=" font-weight-bold">talewallet@kotak</span>
                    </p>
                    <div
                      onClick={() => {
                        navigator.clipboard.writeText(`talewallet@kotak`);
                        setIsCopied(true);
                      }}
                      className="d-flex align-items-center ms-3"
                      style={{ cursor: "pointer" }}
                    >
                      {isCopied ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className=""
                          style={themedColor}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCopy}
                          className=""
                          style={themedColor}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {bankClicked && (
                <div class=" border d-flex flex-column gap-3 justify-content-center align-items-center p-2 mb-2">
                  <p class="text-violet-900 font-weight-bold text-md">
                    Send ₹{sendAmount}
                  </p>
                  <div class="text-violet-700 text-xs">
                    <table class="table table-borderless">
                      <tbody>
                        <tr>
                          <td class="py-1 pl-2">Name</td>
                          <td class="py-1 pl-2">
                            Nextales Technology Private Limited
                          </td>
                          <td class="py-2 px-2">
                            <div
                              onClick={() =>
                                handleBankDataCopy(
                                  " Nextales Technology Private Limited",
                                  0
                                )
                              }
                              className="d-flex align-items-center ms-3"
                              style={{ cursor: "pointer" }}
                            >
                              {isBankDataCopied[0] ? (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className=""
                                  style={themedColor}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  className=""
                                  style={themedColor}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-1 pl-2">Account Number</td>
                          <td class="py-1 pl-2">1947549911</td>
                          <td class="py-1 px-2">
                            <div
                              onClick={() =>
                                handleBankDataCopy("1947549911", 1)
                              }
                              className="d-flex align-items-center ms-3"
                              style={{ cursor: "pointer" }}
                            >
                              {isBankDataCopied[1] ? (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className=""
                                  style={themedColor}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  className=""
                                  style={themedColor}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-1 pl-2">IFSC Code</td>
                          <td class="py-1 pl-2">KKBK0000958</td>
                          <td class="py-2 px-2">
                            <div
                              onClick={() =>
                                handleBankDataCopy("KKBK0000958", 2)
                              }
                              className="d-flex align-items-center ms-3"
                              style={{ cursor: "pointer" }}
                            >
                              {isBankDataCopied[2] ? (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className=""
                                  style={themedColor}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  className=""
                                  style={themedColor}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-1 pl-2">Type</td>
                          <td class="py-1 pl-2">Current</td>
                          <td class="py-2 px-2">
                            <div
                              onClick={() => handleBankDataCopy("Current", 3)}
                              className="d-flex align-items-center ms-3"
                              style={{ cursor: "pointer" }}
                            >
                              {isBankDataCopied[3] ? (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className=""
                                  style={themedColor}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  className=""
                                  style={themedColor}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-1 pl-2">Bank</td>
                          <td class="py-1 pl-2">Kotak Mahindra Bank</td>
                          <td class="py-2 px-2">
                            <div
                              onClick={() =>
                                handleBankDataCopy("Kotak Mahindra Bank", 5)
                              }
                              className="d-flex align-items-center ms-3"
                              style={{ cursor: "pointer" }}
                            >
                              {isBankDataCopied[5] ? (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className=""
                                  style={themedColor}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  className=""
                                  style={themedColor}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              <div className="text-sm text-violet-900  ">
                <p className="text-red-500  text-md">
                  Add code <b>{fourDigit}</b> in the message while transfering
                </p>
                <p>Use GPay, PhonePe, Paytm app or IMPS through bank app </p>
                <div className=" mt-2">
                  <p>
                    After making the payment, click on "I HAVE PAID" button{" "}
                  </p>
                </div>
              </div>

              {!loader ? (
                <button
                  onClick={() => {
                    handlePaid();
                  }}
                  className="btn btn-primary w-100"
                >
                  I HAVE PAID
                </button>
              ) : (
                <button className="btn btn-primary w-100">
                  <CircularProgress size={16} />
                </button>
              )}
            </div>
          )}
          {currentComponent === 3 && (
            <div className="p-4">
              <p className="">Verifying your payment</p>
              <p className="">
                ₹{sendAmount} * {formattedDate}
              </p>
              <p className="">Expected time duration 24 hour</p>

              <button
                onClick={() => {
                  closeModal();
                }}
                className="btn btn-primary w-100 "
              >
                Okay
              </button>
            </div>
          )}

          <div
            className="d-flex flex-column align-items-center mx-auto px-4 pb-2"
            style={{ width: "400px" }}
          >
            <p className="text-sm">
              This payment method works for Indian bank accounts. If you don't
              have an Indian bank account, try our partner&nbsp;
              <span
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    window.location.pathname === "/wallet" ||
                    window.location.pathname === "/token" ||
                    window.location.pathname === "/tokes"
                  ) {
                    handleBuyAllToken();
                  } else if (window.location.href.includes("token")) {
                    handleBuyAlgos(symbol);
                  } else if (window.location.href.includes("crypto-basket")) {
                    handleBasketSubscribe();
                  } else if (symbol) {
                    handleBuyAlgos(symbol, address); // for link generated by fund manager
                  }
                  closeModal();
                }}
              >
                Onramp.money
              </span>
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TaleWalletOnRampModal;
