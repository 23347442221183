import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MainWrapper from '../wrapper/MainWrapper';
import { ProtocolList } from '../../pages/cryptobasket/ProtocolList'; // Import the ProtocolList

const GenerateDataPage = () => {
  const { model } = useParams(); // Get the model from the URL
  const navigate = useNavigate();

  // Find the matching protocol from ProtocolList
  const protocolData = ProtocolList.find((protocol) => protocol.model === model);

  // If protocolData is not found, display an error or a fallback message
  if (!protocolData) {
    return (
      <MainWrapper>
        <div className="container-fluid">
          <h1>Protocol not found</h1>
        </div>
      </MainWrapper>
    );
  }

  const goToPlayground = () => {
    navigate(`/playground/${protocolData.model}`); // Navigate to the playground page with the model
  };

  const goToProjects = () => {
    navigate('/projects'); // Navigate to the projects page
  };

  // Convert YouTube URL to embeddable format
  const getEmbedUrl = (url) => {
    const videoId = url.split('/').pop();
    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <MainWrapper>
      <div
        className="container-fluid"
        style={{ paddingBottom: '50px', backgroundColor: '#f7f7f7' }}
      >
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8" style={{ position: 'relative' }}>
            {/* Action Buttons */}
            <div
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              <button
                className="btn btn-primary"
                style={{ marginRight: '10px', marginBottom: '10px' }}
                onClick={goToPlayground}
              >
                Simulation Studio
              </button>
              <button
                className="btn btn-outline-primary"
                style={{ marginRight: '10px', marginBottom: '10px' }}
                onClick={goToProjects}
              >
                Project Deployment
              </button>
            </div>

            {/* Hero Section */}
            <div
              style={{
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '10px',
                color: '#000',
                textAlign: 'center',
                marginTop: '80px',
                marginBottom: '20px',
                border: '1px solid #ccc',
              }}
            >
              <h1
                style={{
                  fontSize: '28px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                }}
              >
                {protocolData.title}
              </h1>
            </div>

            {/* Video Section */}
             {/*{protocolData.video_url && (
              <div
                style={{
                  marginBottom: '20px',
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    paddingBottom: '56.25%', // 16:9 aspect ratio
                    height: 0,
                    overflow: 'hidden',
                    borderRadius: '10px',
                    border: '1px solid #ccc',
                    backgroundColor: '#fff',
                  }}
                >
                  <iframe
                    src={getEmbedUrl(protocolData.video_url)} // Use the formatted URL
                    title={protocolData.name}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      border: '0',
                    }}
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            )}*/}

            {/* Description Section */}
            <div
              style={{
                backgroundColor: '#fff',
                padding: '15px',
                borderRadius: '10px',
                color: '#333',
                marginBottom: '20px',
                border: '1px solid #ddd',
              }}
            >
              <p style={{ fontSize: '14px', marginBottom: '10px' }}>
                {protocolData.description}
              </p>
            </div>

            {/* Details Section */}
            <div
              style={{
                backgroundColor: '#fff',
                padding: '15px',
                borderRadius: '10px',
                color: '#333',
                marginBottom: '20px',
                border: '1px solid #ddd',
              }}
            >
              <p style={{ fontSize: '14px', marginBottom: '10px' }}>
                <strong>Tags:</strong> {protocolData.tags.join(', ')}
              </p>
              <p style={{ fontSize: '14px', marginBottom: '10px' }}>
                <strong>Level:</strong> {protocolData.level}
              </p>
              <p style={{ fontSize: '14px', marginBottom: '10px' }}>
                <strong>Scoring:</strong> {protocolData.scoring}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Media Query for mobile responsiveness */}
      <style jsx>{`
        @media (max-width: 768px) {
          .container-fluid {
            padding: 10px;
          }
          .col-md-10 {
            padding: 10px;
          }
          iframe {
            width: 100%;
          }
        }
      `}</style>
    </MainWrapper>
  );
};

export default GenerateDataPage;
