import React, { useEffect, useState } from "react";
import MainWrapper from "../../component/wrapper/MainWrapper";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBolt,
  faCaretRight,
  faFilter,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

import { Button, Dropdown } from "react-bootstrap";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { CircularProgress } from "@mui/material";
import { EsgPoolsCompaniesData, selectedEsgPoolsKeys } from "../../config";

const EsgPoolsDetails = () => {
  const { id } = useParams();
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();
  const themedColor = "black";
  //const themedColor = "black";
  const filterboxesColor =  "white";
  const searchbg =  "#F0F1F3";
  const themedbgColor = "#F9F9F9";
  const themedCardBgColor = "#F5F6FA";
  const dropdownVariant =  "light";
  const [esgPoolsDataLoading, seEsgPoolsDataLoading] = useState(false);
  const [esgPoolsDetailsLoading, seEsgPoolsDetailsLoading] = useState(false);
  const [esgPoolsData, setEsgPoolsData] = useState([]);
  const [esgPoolsDetails, setEsgPoolsDetails] = useState([]);
  useEffect(() => {
    seEsgPoolsDataLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_VORTX_URL}/esg/pool/${id}/data`,
      },
      (data) => {
        setEsgPoolsData(data);
        seEsgPoolsDataLoading(false);
      },
      (error) => {
        seEsgPoolsDataLoading(false);
      }
    );
  }, []);
  useEffect(() => {
    seEsgPoolsDetailsLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_VORTX_URL}/esg/pool/${id}/detail`,
      },
      (data) => {
        setEsgPoolsDetails(data);
        seEsgPoolsDetailsLoading(false);
      },
      (error) => {
        seEsgPoolsDetailsLoading(false);
      }
    );
  }, []);

  // const filteredEsgPoolsDataList = esgPoolsData?.map((item) =>
  //   selectedEsgPoolsKeys?.reduce((obj, key) => {
  //     obj[key] = item[key];
  //     return obj;
  //   }, {})
  // );

  const CompaniesCard = ({ data }) => (
    <div className="col-12 col-md-3 ">
      <div className="card">
        <div class="align-items-center d-flex justify-content-between p-3">
          <div className="w-100">
            <h4 className="mb-1 text-center">{data?.value}</h4>
            <p className="mb-0 text-center">{data?.display_name}</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <MainWrapper>
      <div>
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            {esgPoolsDataLoading ? (
              <div
                class="d-flex justify-content-center align-items-center card"
                style={{ minHeight: "300px" }}
              >
                <CircularProgress
                  className=""
                  fontSize="small"
                  style={{ width: "25px", height: "25px" }}
                />
              </div>
            ) : (
              <div class="row">
                {/* portfolio card  */}
                <div className="col-12">
                  <div className="card px-4">
                    <div className="card-header border-0 pb-3">
                      {/* <h4 className="card-title">My Portfolio</h4> */}
                    </div>
                    <div className="card-body px-0 pt-0">
                      <div class="">
                        <div
                          class="form "
                          style={{
                            position: "relative",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="custom-z-index"
                            style={{
                              position: "absolute",
                              top: "15px",
                              left: "15px",
                              color: themedColor,
                            }}
                          />
                          <input
                            type="text"
                            class="form-control form-input rounded-lg"
                            style={{
                              backgroundColor: searchbg,
                            }}
                            placeholder="Search"
                          />

                          <div
                            className="d-flex flex-row gap-2 align-items-center justify-content-end custom-z-index  cursor-pointer "
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "120px",
                              color: themedColor,
                            }}
                          >
                            <p className="fs-12  mb-0">Filter</p>
                            <div
                              className="rounded-pill text-center"
                              style={{
                                border: `1px solid ${filterboxesColor}`,
                                padding: "0px 5px",
                                backgroundColor: filterboxesColor,
                              }}
                            >
                              <p className="fs-12 mb-0">01/01/20 - 01/01/21</p>
                            </div>
                            <div
                              className="rounded-pill text-center"
                              style={{
                                border: `1px solid ${filterboxesColor}`,
                                padding: "0px 5px",
                                backgroundColor: filterboxesColor,
                              }}
                            >
                              <p className="fs-12 mb-0">200 user</p>
                            </div>
                            <div
                              className="rounded-pill text-center"
                              style={{
                                border: `1px solid ${filterboxesColor}`,
                                padding: "0px 5px",
                                backgroundColor: filterboxesColor,
                              }}
                            >
                              <p className="fs-12 mb-0">
                                <FontAwesomeIcon
                                  icon={faFilter}
                                  className="custom-z-index me-1"
                                  style={{
                                    color: themedColor,
                                  }}
                                />
                                All
                              </p>
                            </div>
                          </div>
                          <div
                            className="custom-z-index"
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "0px",
                            }}
                          >
                            <button
                              className="px-4 py-1 rounded-lg border-0 "
                              style={{
                                backgroundColor: "#8DEFAE",
                                height: "43px",
                              }}
                            >
                              <p className="mb-0 fs-12 fw-medium">Report</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="card px-4">
                    {/* button section  */}

                    <div className="w-100 d-flex justify-content-between align-items-center mt-4">
                      <div className="d-flex gap-2 align-items-center">
                        <h3>Analytics</h3>
                        <div className="">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant={dropdownVariant}
                              className="rounded-md ms-2"
                            >
                              Basic Preset
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                key=""
                                // onClick={() => {
                                //   setValue("");
                                //   dispatch(appActions.setCountry(""));
                                // }}
                              >
                                preset 1
                              </Dropdown.Item>
                              {/* {country?.map((res) => (
                            <Dropdown.Item
                              key={res?.country}
                              value={res?.country}
                              onClick={() => {
                                setValue(res?.country);
                                dispatch(appActions.setCountry(res?.country));
                              }}
                            >
                              {res?.country}
                            </Dropdown.Item>
                          ))} */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="">
                        <Button variant="light">
                          Whitelisted <FontAwesomeIcon icon={faBolt} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 ">
                  <div className="card coin-content">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <img
                          src="/images/avatar/1.jpg"
                          className=" me-2 rounded-circle"
                          width={25}
                          alt=""
                        />{" "}
                        <div className="">
                          <h5 className="mb-0">Base Metrics</h5>
                          <p className="mb-0">1 AM - 6 PM</p>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div>
                          <ul className="">
                            <li>
                              <div className="w-100 d-flex justify-content-between align-items-center">
                                <div className="d-flex gap-2 align-items-center">
                                  <div className="media me-2 media-primary">
                                    <i className="fa fa-home"></i>
                                  </div>
                                  <div className="d-flex gap-2">
                                    <h6 className="mb-1">23</h6>
                                    <p className="mb-0">Critical</p>
                                  </div>
                                </div>
                                <div className="">
                                  <FontAwesomeIcon icon={faCaretRight} />
                                </div>
                              </div>
                              <div className="w-100 border my-2"></div>
                            </li>
                            <li>
                              <div className="w-100 d-flex justify-content-between align-items-center">
                                <div className="d-flex gap-2 align-items-center">
                                  <div className="media me-2 media-primary">
                                    <i className="fa fa-home"></i>
                                  </div>
                                  <div className="d-flex gap-2">
                                    <h6 className="mb-1">23</h6>
                                    <p className="mb-0">Critical</p>
                                  </div>
                                </div>
                                <div className="">
                                  <FontAwesomeIcon icon={faCaretRight} />
                                </div>
                              </div>
                              <div className="w-100 border my-2"></div>
                            </li>
                            <li>
                              <div className="w-100 d-flex justify-content-between align-items-center">
                                <div className="d-flex gap-2 align-items-center">
                                  <div className="media me-2 media-primary">
                                    <i className="fa fa-home"></i>
                                  </div>
                                  <div className="d-flex gap-2">
                                    <h6 className="mb-1">23</h6>
                                    <p className="mb-0">Critical</p>
                                  </div>
                                </div>
                                <div className="">
                                  <FontAwesomeIcon icon={faCaretRight} />
                                </div>
                              </div>
                              <div className="w-100 border my-2"></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 ">
                  <div className="card coin-content">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <img
                          src="/images/avatar/1.jpg"
                          className=" me-2 rounded-circle"
                          width={25}
                          alt=""
                        />{" "}
                        <div className="">
                          <h5 className="mb-0">Productivity</h5>
                          <p className="mb-0">By department</p>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div class="align-items-center d-flex justify-content-between">
                          <div class="">
                            <img
                              src="/images/avatar/1.jpg"
                              className=" me-2 rounded-circle"
                              width={100}
                              alt=""
                            />
                          </div>
                          <div class="c-heading">
                            <span class="text-dark font-w600 mb-2 d-block">
                              7hr 32 m
                            </span>
                            <ul>
                              <li>satelite</li>
                              <li>satelite</li>
                              <li>satelite</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 ">
                  <div className="card coin-content">
                    <div className="card-body">
                      <div className="mt-4">
                        <div class="align-items-center d-flex justify-content-between">
                          <div class="c-heading">
                            <p className="mb-0">Heatmap</p>
                            <div className="d-flex align-items-end gap-2">
                              <h2 className="">346</h2>
                              <div
                                className="text-center"
                                style={{
                                  borderRadius: "5px",
                                  border: "1px solid #CCCCCC",
                                  padding: "5px 10px",
                                  backgroundColor: "#CCCCCC",
                                }}
                              >
                                <p className="text-success mb-0">0%</p>
                              </div>
                            </div>
                            <ul className="mt-4">
                              <li>
                                <div className="d-flex align-items-center gap-2">
                                  <div className="">
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      style={{
                                        color: themedColor,
                                      }}
                                    />
                                  </div>
                                  <p className="mb-0">satelite</p>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex align-items-center gap-2">
                                  <div className="">
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      style={{
                                        color: themedColor,
                                      }}
                                    />
                                  </div>
                                  <p className="mb-0">satelite</p>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex align-items-center gap-2">
                                  <div className="">
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      style={{
                                        color: themedColor,
                                      }}
                                    />
                                  </div>
                                  <p className="mb-0">satelite</p>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="">
                            <img
                              src="/images/avatar/1.jpg"
                              className=" me-2 "
                              width={300}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="card px-4">
                    {/* button section  */}

                    <div className="w-100 d-flex justify-content-between align-items-center mt-4">
                      <div className="d-flex gap-2 align-items-center">
                        <h3>Companies</h3>
                        <p className="-mt-4 text-muted">12</p>
                      </div>
                    </div>
                  </div>
                </div>

                {EsgPoolsCompaniesData?.map((data) => (
                  <CompaniesCard key={data.key} data={data} />
                ))}
                <div className="col-12  ">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex gap-2 align-items-center">
                        <h3>Users</h3>
                        <p className="-mt-4 text-muted">12</p>
                      </div>
                    </div>
                    <div className="card-body">
                      <div class="table-responsive dataTabletrade ">
                        <table
                          id="example-2"
                          class="table display orderbookTable"
                          style={{ minWidth: "845px" }}
                        >
                          <thead>
                            <tr>
                              <th>NAME</th>
                              <th>PROJECT DETAILS</th>
                              <th>ROLE</th>
                              <th>UID</th>
                              <th>EXPIRE DATE</th>
                              <th>STATUS</th>
                              <th>WATERFALL</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              style={{ cursor: "pointer" }}
                              //   onClick={(e) => {
                              //     if (!e.target.classList.contains("view-button")) {
                              //       navigate(
                              //         `/token/${item?.buyTokenId}/${item?.buyToken}`
                              //       );
                              //     }
                              //   }}
                            >
                              <td>
                                <div className="d-flex gap-2 align-items-center">
                                  <img
                                    src="/images/avatar/1.jpg"
                                    className=" me-2 rounded-circle"
                                    width={25}
                                    alt=""
                                  />
                                  <div className="media-body">
                                    <h6 className="mb-1">Jhon Snow</h6>
                                    <small className="d-block">
                                      js@gamil.com
                                    </small>
                                  </div>
                                </div>
                              </td>
                              <td>details...</td>

                              <td>trader</td>
                              <td>1223131</td>
                              <td>30/3/2025</td>
                              <td>Active</td>
                              <td>~~~~</td>

                              <td
                                className=""
                                // onClick={() => {
                                //   setOpenTpSLModal(true);
                                // }}
                                style={{ cursor: "pointer" }}
                              >
                                ....
                              </td>
                              {/* <td
        className=""
        onClick={() => {
          setOpenTpSLModal(true);
        }}
        style={{ cursor: "pointer" }}
      >
        <span className="view-button">View</span>
      </td> */}
                            </tr>
                            <tr
                              style={{ cursor: "pointer" }}
                              //   onClick={(e) => {
                              //     if (!e.target.classList.contains("view-button")) {
                              //       navigate(
                              //         `/token/${item?.buyTokenId}/${item?.buyToken}`
                              //       );
                              //     }
                              //   }}
                            >
                              <td>
                                <div className="d-flex gap-2 align-items-center">
                                  <img
                                    src="/images/avatar/1.jpg"
                                    className=" me-2 rounded-circle"
                                    width={25}
                                    alt=""
                                  />
                                  <div className="media-body">
                                    <h6 className="mb-1">Jhon Snow</h6>
                                    <small className="d-block">
                                      js@gamil.com
                                    </small>
                                  </div>
                                </div>
                              </td>
                              <td>details...</td>

                              <td>trader</td>
                              <td>1223131</td>
                              <td>30/3/2025</td>
                              <td>Active</td>
                              <td>~~~~</td>

                              <td
                                className=""
                                // onClick={() => {
                                //   setOpenTpSLModal(true);
                                // }}
                                style={{ cursor: "pointer" }}
                              >
                                ....
                              </td>
                              {/* <td
        className=""
        onClick={() => {
          setOpenTpSLModal(true);
        }}
        style={{ cursor: "pointer" }}
      >
        <span className="view-button">View</span>
      </td> */}
                            </tr>
                            <tr
                              style={{ cursor: "pointer" }}
                              //   onClick={(e) => {
                              //     if (!e.target.classList.contains("view-button")) {
                              //       navigate(
                              //         `/token/${item?.buyTokenId}/${item?.buyToken}`
                              //       );
                              //     }
                              //   }}
                            >
                              <td>
                                <div className="d-flex gap-2 align-items-center">
                                  <img
                                    src="/images/avatar/1.jpg"
                                    className=" me-2 rounded-circle"
                                    width={25}
                                    alt=""
                                  />
                                  <div className="media-body">
                                    <h6 className="mb-1">Jhon Snow</h6>
                                    <small className="d-block">
                                      js@gamil.com
                                    </small>
                                  </div>
                                </div>
                              </td>
                              <td>details...</td>

                              <td>trader</td>
                              <td>1223131</td>
                              <td>30/3/2025</td>
                              <td>Active</td>
                              <td>~~~~</td>

                              <td
                                className=""
                                // onClick={() => {
                                //   setOpenTpSLModal(true);
                                // }}
                                style={{ cursor: "pointer" }}
                              >
                                ....
                              </td>
                              {/* <td
        className=""
        onClick={() => {
          setOpenTpSLModal(true);
        }}
        style={{ cursor: "pointer" }}
      >
        <span className="view-button">View</span>
      </td> */}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

export default EsgPoolsDetails;
