import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = () => {
  const options = {
    chart: {
      type: "bar",
      height: 50,
      stacked: true,
      toolbar: {
        show: true,
      },
      sparkline: {
        //enabled: true
      },
      offsetX: 0,
    },
    series: [
      {
        name: "New Clients",
        data: [
          180, 150, 200, 100, 150, 180, 150, 170, 115, 190, 140, 80, 70, 90,
          120, 50,
        ],
      },
    ],

    plotOptions: {
      bar: {
        columnWidth: "20%",
        endingShape: "rounded",
        startingShape: "rounded",
        borderRadius: 5,

        colors: {
          backgroundBarColors: [
            "#F8F8F8",
            "#F8F8F8",
            "#F8F8F8",
            "#F8F8F8",
            "#F8F8F8",
            "#F8F8F8",
            "#F8F8F8",
            "#F8F8F8",
            "#F8F8F8",
            "#F8F8F8",
            "#F8F8F8",
            "#F8F8F8",
          ],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 5,
        },
      },
      distributed: true,
    },
    colors: ["#4D4BE0"],
    grid: {
      show: false,
      borderColor: "#F8F8F8",
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
      colors: ["#000"],
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        opacity: 1,
      },
    },
    xaxis: {
      categories: [
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
      ],
      labels: {
        style: {
          colors: "var(--text)",
          fontSize: "13px",
          fontFamily: "poppins",
          fontWeight: 100,
          cssClass: "apexcharts-xaxis-label",
        },
      },
      crosshairs: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "round",
      width: 0,
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: "var(--text)",
          fontSize: "13px",
          fontFamily: "poppins",
          fontWeight: 100,
        },
      },
    },

    tooltip: {
      x: {
        show: true,
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={options.series}
        type="bar"
        height={options.chart.height}
      />
    </div>
  );
};

export default BarChart;
