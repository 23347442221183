import React, { useEffect, useState } from "react";
import MainWrapper from "../../component/wrapper/MainWrapper";
import { useSelector } from 'react-redux';
import {
  CircularProgress,
  MenuItem,
  Button,
  Select,
  InputLabel,
  FormControl,
  Card,
  CardContent,
  CardActions,
  Grid,
  IconButton,
  Menu,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";
import {
  Add as AddIcon,
  Folder as FolderIcon,
  ArrowRight as ArrowRightIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import AddProjectModal from "../../component/addProjectModal/AddProjectModal";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const Projects = () => {
  const [isProjectsLoading, setIsProjectsLoading] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [openAddProjectModal, setOpenAddProjectModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortMethod, setSortMethod] = useState("newestFirst");
  const [duplicateProjectData, setDuplicateProjectData] = useState(null);
  const makeRequest = useAuthorizedHttp();
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [apiData, setApiData] = useState(null);

  const navigate = useNavigate();

  const handleMenuOpen = (event, projectId) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentProjectId(projectId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentProjectId(null);
  };

  // Access the user ID from Redux store
  const appCtx = useSelector((state) => state.app);
  const userId = localStorage.getItem("userId");
  const authToken = localStorage.getItem("authToken");
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");

  // New state variables for the alert box
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    setIsProjectsLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_VORTX_URL}/project/list`,
        method: "get",
        headers: {
          "X-Auth-Token": authToken,
          "Content-Type": "application/json",
        },
      },
      (data) => {
        setAllProjects(data);
        setFilteredProjects(data);
        setIsProjectsLoading(false);
        setError(null);
        setApiData(data);
      },
      () => {
        setIsProjectsLoading(false);
        setError("Failed to load projects. Please try again.");
      }
    );
  }, [openAddProjectModal, makeRequest, userId, appCtx.authToken]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleFilterAndSort(searchTerm, sortMethod);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, sortMethod, allProjects]);

  const handleFilterAndSort = (searchValue, sortOption) => {
    let filtered = allProjects.filter((project) =>
      project.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    switch (sortOption) {
      case "alphabetical_asc":
        filtered.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case "alphabetical_desc":
        filtered.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case "created_newest":
        filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
      case "created_oldest":
        filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        break;
      case "recent":
      default:
        filtered.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    }

    setFilteredProjects(filtered);
  };

  const handleSortChange = (event) => {
    setSortMethod(event.target.value);
  };

  const handleCardAction = (action, projectId) => {
    const selectedProject = allProjects.find((p) => p.projectId === projectId);

    switch (action) {
      case "view":
        localStorage.setItem('selectedProjectName', selectedProject.name);
        navigate(`/projects/${projectId}`, {
          state: {
            selectedProject,
            allProjects,
          },
        });
        break;
      case "edit":
        // Handle edit action here
        break;
      case "duplicate":
        setDuplicateProjectData({
          name: `${selectedProject.name} Copy`,
          startDate: selectedProject.startDate,
          endDate: selectedProject.endDate,
          description: selectedProject.description,
        });
        setOpenAddProjectModal(true);
        break;
      case "delete":
        // Handle delete action here
        break;
      default:
        break;
    }
    handleMenuClose();
  };

  return (
    <MainWrapper>
      <div style={{ padding: "24px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "24px",
          }}
        >
          <div>
            <Typography variant="h5" component="h2" style={{ fontWeight: 600 }}>
              Projects
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Continuously Synthesize Satellite Images.
            </Typography>
          </div>
          <div>
            <Button variant="outlined" startIcon={<FolderIcon />}>
              {filteredProjects.length} Project
              {filteredProjects.length !== 1 ? "s" : ""}
            </Button>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "24px",
            borderRadius: "8px",
            boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginBottom: "16px",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setOpenAddProjectModal(true)}
              style={{ marginRight: "16px", marginBottom: "16px" }}
            >
              Create
            </Button>
            <FormControl
              variant="outlined"
              style={{
                minWidth: 200,
                marginRight: "16px",
                marginBottom: "16px",
              }}
            >
              <InputLabel>Sort By</InputLabel>
              <Select
                label="Sort By"
                value={sortMethod}
                onChange={handleSortChange}
              >
                <MenuItem value="newestFirst">Newest to Oldest</MenuItem>
                <MenuItem value="oldestFirst">Oldest to Newest</MenuItem>
                <MenuItem value="alphabetical_asc">A - Z</MenuItem>
                <MenuItem value="alphabetical_desc">Z - A</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{ marginBottom: "16px" }}
          >
            Navigate to your projects.
          </Typography>

          {error && (
            <Alert severity="error" style={{ marginBottom: "16px" }}>
              {error}
            </Alert>
          )}

          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              onClose={() => setOpenSnackbar(false)}
              severity="info"
              sx={{ width: '100%' }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>

          {isProjectsLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                minHeight: "300px",
              }}
            >
              <CircularProgress />
            </div>
          ) : filteredProjects?.length === 0 ? (
            <div style={{ marginTop: "40px" }}>
              <Typography variant="body1" align="center">
                No projects found. Start your first project.
              </Typography>
            </div>
          ) : (
            <Grid container spacing={3}>
              {filteredProjects.map((project) => (
                <Grid item xs={12} sm={6} md={4} key={project.projectId}>
                  <Card
                    variant="outlined"
                    onClick={() => {
                      localStorage.setItem('selectedProjectName', project.name);
                      navigate(`/projects/${project.projectId}`, {
                        state: { selectedProject: project, allProjects },
                      });
                    }}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardContent style={{ flexGrow: 1 }}>
                      <IconButton
                        aria-label="settings"
                        onClick={(e) => handleMenuOpen(e, project.projectId)}
                        style={{ position: "absolute", top: 8, right: 8 }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Typography variant="h6" component="div">
                        {project.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {project.description || "No description provided."}
                      </Typography>
                      <div style={{ marginTop: "16px" }}>
                        <Typography variant="caption" color="textSecondary">
                          {project.startDate
                            ? format(new Date(project.startDate), "MMM dd, yyyy")
                            : "Start Date"}{" "}
                          -{" "}
                          {project.endDate
                            ? format(new Date(project.endDate), "MMM dd, yyyy")
                            : "End Date"}
                        </Typography>
                      </div>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        color="primary"
                        endIcon={<ArrowRightIcon />}
                        onClick={(e) => {
                          e.stopPropagation();
                          localStorage.setItem('selectedProjectName', project.name);
                          navigate(`/projects/${project.projectId}`, {
                            state: { selectedProject: project, allProjects },
                          });
                        }}
                      >
                        Enter Project
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </div>

      <Menu
        id="project-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleCardAction("view", currentProjectId)}>
          View
        </MenuItem>
        <MenuItem onClick={() => handleCardAction("edit", currentProjectId)}>
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleCardAction("duplicate", currentProjectId)}>
          Duplicate
        </MenuItem>
        <MenuItem onClick={() => handleCardAction("delete", currentProjectId)}>
          Delete
        </MenuItem>
      </Menu>

      <AddProjectModal
        openModal={openAddProjectModal}
        setOpenModal={setOpenAddProjectModal}
        duplicateProjectData={duplicateProjectData}
      />
    </MainWrapper>
  );
};

export default Projects;
