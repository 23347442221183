// AddDataModal.jsx

import React, { useCallback, useEffect, useState } from "react";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useAuthorizedHttp from "../../../hooks/use-authorized-http";
import UploadTimeLine from "../uploadDataTimeline/uploadTimeline";
import AddDataFileUpload from "../addDataFileUpload";

const AddDataModal = ({ openModal, setOpenModal }) => {
  const [loading, setLoading] = useState(false);
  const appCtx = useSelector((state) => state.app);
  const [stacMetadata, setStacMetadata] = useState(null);
  const [metadataPreview, setMetadataPreview] = useState("");
  const [assetFiles, setAssetFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);

  const makeRequest = useAuthorizedHttp();

  const handleClose = () => {
    if (!loading) {
      setOpenModal(false);
      resetForm();
    }
  };

  const resetForm = () => {
    setCurrentStep(1);
    setStacMetadata(null);
    setMetadataPreview("");
    setAssetFiles([]);
    setUploadProgress(0);
  };

  const handleMetadataChange = useCallback((file) => {
    if (file && file.type === "application/json") {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);
          // Basic validation for STAC compliance
          if (json.stac_version && json.type === "FeatureCollection") {
            setStacMetadata(json);
            setMetadataPreview(JSON.stringify(json, null, 2));
            setCurrentStep(2);
            toast.success("STAC metadata file selected successfully.");
          } else {
            toast.error("Invalid STAC JSON file.");
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
          toast.error("Failed to parse JSON file.");
        }
      };
      reader.readAsText(file);
    } else {
      toast.error("Please upload a valid JSON file for STAC metadata.");
    }
  }, []);

  const handleAssetFilesChange = useCallback((files) => {
    const validFiles = Array.from(files).filter((file) =>
      ["image/png", "image/jpeg", "image/tiff"].includes(file.type)
    );

    if (validFiles.length !== files.length) {
      toast.warn("Some files were ignored due to invalid formats.");
    }

    setAssetFiles(validFiles);
    if (validFiles.length > 0) {
      setCurrentStep(3);
      toast.success("Asset files selected successfully.");
    }
  }, []);

  const handleSubmit = async () => {
    if (!stacMetadata || assetFiles.length === 0) {
      toast.error("Please provide STAC metadata and at least one asset file.");
      return;
    }

    setLoading(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append("metadata", new Blob([JSON.stringify(stacMetadata)], { type: "application/json" }));
    assetFiles.forEach((file) => {
      formData.append("assets", file);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/stac/upload`,
        formData,
        {
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        }
      );

      if (response.status === 200) {
        toast.success("STAC data uploaded successfully!");
        handleClose();
      } else {
        toast.error("Failed to upload STAC data.");
      }
    } catch (error) {
      console.error("Upload error:", error);
      toast.error("An error occurred during the upload.");
    } finally {
      setLoading(false);
      setUploadProgress(0);
    }
  };

  return (
    <Modal show={openModal} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload STAC Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UploadTimeLine currentIndex={currentStep} />
        {currentStep === 1 && (
          <div className="text-center">
            <h5>Step 1: Upload STAC Metadata</h5>
            <p>Upload a valid STAC JSON file containing your metadata.</p>
            <AddDataFileUpload
              accept=".json,application/json"
              onFileSelect={handleMetadataChange}
              multiple={false}
              label="Select STAC JSON File"
            />
            {metadataPreview && (
              <pre className="bg-light p-2 mt-3" style={{ maxHeight: "200px", overflowY: "auto" }}>
                {metadataPreview}
              </pre>
            )}
          </div>
        )}

        {currentStep === 2 && (
          <div className="text-center">
            <h5>Step 2: Upload Asset Files</h5>
            <p>Upload the associated asset files (e.g., images) for your STAC data.</p>
            <AddDataFileUpload
              accept="image/png,image/jpeg,image/tiff"
              onFileSelect={handleAssetFilesChange}
              multiple={true}
              label="Select Asset Files"
            />
            {assetFiles.length > 0 && (
              <ul className="mt-3 list-unstyled">
                {assetFiles.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            )}
          </div>
        )}

        {currentStep === 3 && (
          <div className="text-center">
            <h5>Step 3: Review and Upload</h5>
            <p>Review your STAC metadata and asset files before uploading.</p>
            <div className="mt-3 text-start">
              <h6>STAC Metadata:</h6>
              <pre className="bg-light p-2" style={{ maxHeight: "200px", overflowY: "auto" }}>
                {metadataPreview}
              </pre>
              <h6>Asset Files:</h6>
              <ul>
                {assetFiles.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            </div>
            {loading && (
              <div className="my-3">
                <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated striped />
              </div>
            )}
            <Button variant="primary" onClick={handleSubmit} disabled={loading}>
              {loading ? `Uploading... ${uploadProgress}%` : "Confirm and Upload"}
            </Button>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={loading}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDataModal;
